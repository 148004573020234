import React, { useState, useEffect } from 'react';
import _debounce from 'lodash/debounce';
import { Card } from 'react-bootstrap';
import { DataGrid } from '@mui/x-data-grid';
import { viewEmployeeSalarySetup } from '../../services/api/api';
import HeaderMobile from '../../layouts/HeaderMobile';
import Header from '../../layouts/Header';

const ViewSalarySetupTwo = () => {
  const [employeeData, setEmployeeData] = useState([]);
  const [loading, setLoading] = useState(true);

  // useEffect(() => {
  //   fetchData();
  // }, []);

  // const fetchData = async () => {
  //   try {
  //     const response = await viewEmployeeSalarySetup();
  //     const data = response.data[0].emp_salary_master.map((item, index) => ({
  //       id: index + 1,
  //       name: item.basic[0]?.name || 'N/A',
  //       employee_id: item.employee_id || 'N/A',
  //       basic_salary: item.basic[0]?.amount || 'N/A',
  //       allowance: item.allowence[0]?.amount || 'N/A',
  //       deduction: item.deduction[0]?.amount || 'N/A',
  //     }));
  //     setEmployeeData(data);
  //   } catch (error) {
  //     console.error('Error fetching data:', error);
  //   } finally {
  //     setLoading(false);
  //   }
  // };

  const fetchData = async () => {
    try {
      const response = await viewEmployeeSalarySetup();
      const data = response.data[0].emp_salary_master.map((item, index) => ({
        id: index + 1,
        name: item.basic[0]?.name || 'N/A',
        employee_id: item.employee_id || 'N/A',
        basic_salary: item.basic[0]?.amount || 'N/A',
        allowance: item.allowence[0]?.amount || 'N/A',
        deduction: item.deduction[0]?.amount || 'N/A',
      }));
      setEmployeeData(data);
    } catch (error) {
      console.error('Error fetching data:', error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    const debouncedFetchData = _debounce(fetchData, 100);

    debouncedFetchData();

    return () => {
      debouncedFetchData.cancel();
    };
  }, []);
  const columns = [
    { field: 'id', headerName: 'Serial Number', flex: 1 },
    { field: 'name', headerName: 'Name', flex: 1 },
    { field: 'employee_id', headerName: 'Employee ID', flex: 1 },
    { field: 'basic_salary', headerName: 'Basic Salary', flex: 1 },
    { field: 'allowance', headerName: 'Allowance', flex: 1 },
    { field: 'deduction', headerName: 'Deduction', flex: 1 },
  ];

  return (
    <React.Fragment>
      <HeaderMobile />
      <Header />
      <div className="main p-4 p-lg-5 mt-5">
        <Card className="popup-card empHistory">
          <Card.Body>
            {loading ? (
              <div className="loader-container">
                <div className="loader"></div>
              </div>
            ) : (
              <div style={{ height: 500, width: '100%', backgroundColor: 'white' }}>
                <DataGrid
                  rows={employeeData}
                  columns={columns}
                  pageSize={5}
                  autoHeight
                  headerClassName="custom-header"
                  components={{
                    NoRowsOverlay: () => (
                      <div style={{ textAlign: 'center', padding: '20px' }}>No data available</div>
                    ),
                  }}
                />
              </div>
            )}
          </Card.Body>
        </Card>
      </div>
    </React.Fragment>
  );
};

export default ViewSalarySetupTwo;



