import React, { useEffect, useState } from 'react';
import HeaderMobile from '../../../layouts/HeaderMobile';
import Header from '../../../layouts/Header';
import { remainLeave, remainLeavePagination, searchRemainLeave } from '../../../services/api/api';
import { Table, Form, InputGroup, Button } from 'react-bootstrap';
import { TextField, Tooltip } from '@mui/material';

export const PendingLeaves = () => {
    const [leavesData, setLeavesData] = useState([]);
    const [searchTerm, setSearchTerm] = useState('');
    const [loading, setLoading] = useState(true);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(0);
    const itemsPerPage = 100;

    useEffect(() => {
        if (!searchTerm) {
            fetchRemainingLeaves();
        } else {
            searchEmpRemainingLeaves();
        }
    }, [currentPage, searchTerm]);

    const fetchRemainingLeaves = async () => {
        try {
            setLoading(true);
            const response = await remainLeavePagination(currentPage, itemsPerPage);
            if (response) {
                setLeavesData(response.data);
                setTotalPages(response.totalPages || 1);
            }
        } catch (error) {
            console.error('Error fetching data:', error);
        } finally {
            setLoading(false);
        }
    };

    const searchEmpRemainingLeaves = async () => {
        try {
            const response = await searchRemainLeave(currentPage, itemsPerPage, searchTerm);
            if (response) {
                setLeavesData(response.data);
                setTotalPages(response.totalPages || 1);
            }
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };

    const filteredData = leavesData.filter(item =>
        item.employee_total_leave.employee_name
            .toLowerCase()
            .includes(searchTerm.toLowerCase())
    );

    const handlePageChange = (pageNumber) => {
        setCurrentPage(pageNumber);
    };

    return (
        <React.Fragment>
            <HeaderMobile />
            <Header />
            <div
                style={{
                    padding: '16px',
                    marginTop: '30px',
                    borderRadius: '8px',
                }}
                className="main p-4 p-lg-5 mt-5"
            >
                {loading ? (
                    <div className="loader-container">
                        <div className="loader"></div>
                    </div>
                ) : (
                    <>
                        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-start', width: '100%' }}>
                            <TextField
                                label="Search by Employee Name"
                                variant="outlined"
                                value={searchTerm}
                                onChange={e => setSearchTerm(e.target.value)}
                                size="small"
                                style={{ marginBottom: 9, marginTop: '20px' }}
                            />
                        </div>
                        <div
                            style={{
                                height: '70vh',
                                width: '70vw',
                                overflow: 'auto',
                                scrollbarWidth: 'none', // For Firefox
                                '-ms-overflow-style': 'none', // For Internet Explorer and Edge
                            }}
                            className="custom-scroll"
                        >
                            <style>
                                {`
                                    .custom-scroll::-webkit-scrollbar {
                                        display: none; // Hide scrollbar for Chrome, Safari, and Opera
                                    }
                                `}
                            </style>
                            <Table
                                striped
                                hover
                                responsive
                                style={{
                                    margin: '0',
                                    borderCollapse: 'collapse',
                                }}
                            >
                                <thead
                                    style={{
                                        backgroundColor: '#f1f1f1',
                                        position: 'sticky',
                                        top: 0,
                                        zIndex: 1,
                                    }}
                                >
                                    <tr>
                                        <th style={{ padding: '12px 16px', textAlign: 'center', fontSize: '0.9rem', border:'none', }}>Employee Name</th>
                                        <th style={{ padding: '12px 16px', textAlign: 'center', fontSize: '0.9rem', border:'none', }}>Total Leaves</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {filteredData.map(item => (
                                        <tr key={item.employee_total_leave._id}>
                                            <td style={{ padding: '12px 16px', textAlign: 'center' }}>
                                                {item.employee_total_leave.employee_name}
                                            </td>
                                            <td style={{ padding: '12px 16px', textAlign: 'center' }}>
                                                {item.employee_total_leave.totalLeaves}
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </Table>
                        </div>

                        {/* Pagination Controls */}
                        {totalPages > 0 && (
                            <div
                                className="pagination mt-4 d-flex justify-content-center"
                                style={{
                                    width: '70vw', // Match the width of the table container
                                    margin: '0 auto' // Center the pagination within the container
                                }}
                            >
                                {Array.from({ length: totalPages }, (_, index) => (
                                    <Button
                                        key={index + 1}
                                        onClick={() => handlePageChange(index + 1)}
                                        className={`mx-1 ${currentPage === index + 1 ? 'active' : ''}`}
                                        style={{
                                            padding: '8px 16px',
                                            fontSize: '0.9rem',
                                        }}
                                    >
                                        {index + 1}
                                    </Button>
                                ))}
                            </div>
                        )}
                    </>
                )}
            </div>
        </React.Fragment>
    );
};
