import React, { useState, useEffect, useRef } from 'react';
import Header from '../layouts/Header';
import HeaderMobile from '../layouts/HeaderMobile';
import { Card, Button, Container } from "react-bootstrap";
// import '../../styles/globals.css';


import { Link } from "react-router-dom";
import $ from 'jquery';
import 'datatables.net';

const Jobs = () => {
  const tableRef = useRef(null);
  const [activeButton, setActiveButton] = useState('open');

  const handleButtonClick = (button) => {
    setActiveButton(button);
  };

  useEffect(() => {

    



    if ($.fn.DataTable) {
      if ($.fn.DataTable.isDataTable('#commonTable')) {
        tableRef.current = $('#commonTable').DataTable();
        tableRef.current.destroy();
      }

    

      tableRef.current = $('#commonTable').DataTable({




        data: [
          // Sample data
          [1,'Software Developer', 'Indeed', 'John Doe', 'johndeo@gmail.com', '23/10/2023', 'Active'],
          [2,'Frontend Developer', 'Indeed', 'Jack Deo', 'johndeo@gmail.com', '23/10/2023', 'Active'],
          [3,'Software Developer', 'Google', 'Alice Johnson', 'alice@gmail.com', '23/10/2023', 'Active'],
          [4,'Software Developer', 'Microsoft', 'Bob Smith', 'bob@gmail.com', '23/10/2023', 'Inactive'],
          [5,'Web Developer', 'Amazon', 'Eva Brown', 'eva@gmail.com', '23/10/2023', 'Active'],
          [6,'UI/UX Designer', 'Apple', 'Charlie Wilson', 'charlie@gmail.com', '23/10/2023', 'Active'],
          [7,'Database Administrator', 'Facebook', 'David Lee', 'david@gmail.com', '23/10/2023', 'Inactive'],
          [8,'Network Engineer', 'LinkedIn', 'Ella White', 'ella@gmail.com', '23/10/2023', 'Active'],
          [9,'Data Analyst', 'Twitter', 'Frank Davis', 'frank@gmail.com', '23/10/2023', 'Active'],
          [10,'Software Tester', 'Netflix', 'Grace Thomas', 'grace@gmail.com', '23/10/2023', 'Active'],
          [11,'Project Manager', 'Spotify', 'Henry Clark', 'henry@gmail.com', '23/10/2023', 'Inactive'],
          // Additional 20 records
          // ['Frontend Developer', 'Google', 'Michael Brown', 'michael@gmail.com', '23/10/2023', 'Active'],
          // ['Backend Developer', 'Microsoft', 'Linda Johnson', 'linda@gmail.com', '23/10/2023', 'Inactive'],
          // ['Mobile App Developer', 'Amazon', 'William Smith', 'william@gmail.com', '23/10/2023', 'Active'],
          // ['DevOps Engineer', 'Apple', 'Susan Wilson', 'susan@gmail.com', '23/10/2023', 'Active'],
          // ['UI Designer', 'Facebook', 'Richard Lee', 'richard@gmail.com', '23/10/2023', 'Inactive'],
          // ['Network Administrator', 'LinkedIn', 'Olivia White', 'olivia@gmail.com', '23/10/2023', 'Active'],
          // ['Data Scientist', 'Twitter', 'James Davis', 'james@gmail.com', '23/10/2023', 'Active'],
          // ['QA Tester', 'Netflix', 'Mary Thomas', 'mary@gmail.com', '23/10/2023', 'Active'],
          // ['Product Manager', 'Spotify', 'Robert Clark', 'robert@gmail.com', '23/10/2023', 'Inactive'],
          // ['Full Stack Developer', 'Google', 'Karen Brown', 'karen@gmail.com', '23/10/2023', 'Active'],
          // ['Frontend Engineer', 'Amazon', 'Matthew Smith', 'matthew@gmail.com', '23/10/2023', 'Active'],
          // ['Database Analyst', 'Apple', 'Sarah Wilson', 'sarah@gmail.com', '23/10/2023', 'Active'],
          // ['Network Specialist', 'Facebook', 'Daniel Lee', 'daniel@gmail.com', '23/10/2023', 'Inactive'],
          // ['Business Analyst', 'LinkedIn', 'Patricia White', 'patricia@gmail.com', '23/10/2023', 'Active'],
          // ['Security Engineer', 'Twitter', 'John Davis', 'john@gmail.com', '23/10/2023', 'Active'],
          // ['Mobile App Designer', 'Netflix', 'Jennifer Thomas', 'jennifer@gmail.com', '23/10/2023', 'Active'],
          // ['Cloud Architect', 'Spotify', 'Thomas Clark', 'thomas@gmail.com', '23/10/2023', 'Inactive'],
          // ['Game Developer', 'Google', 'Nancy Brown', 'nancy@gmail.com', '23/10/2023', 'Active'],
          // ['AI Specialist', 'Amazon', 'Kevin Smith', 'kevin@gmail.com', '23/10/2023', 'Active'],

        ],
        columns: [
          { title: 'Sr.No.'},
          { title: 'Job Title' },
          { title: 'Ref#' },
          { title: 'Candidates' },
          { title: 'Email' },
          { title: 'Date Posted' },
          { title: 'Job Status' },
          { title: 'Actions' },
        ],
        paging: true,
        ordering: true,
        searching: true,
        columnDefs: [
          {
            targets: -1,
            orderable: false,
            searchable: false,
            render: function (data, type, full, meta) {
              return createActionButton(full);
            },
          },
        ],
        responsive: true,
      });
    }
    const updatePaginationStyles = () => {
      const paginationElements = $('.paginate_button');
      paginationElements.each(function (index) {
        const $this = $(this);
        if ($this.hasClass('current')) {
          $this.css({
            border: '0px solid blue',
            padding: '3px 8px 3px 8px',
            borderRadius: '3px',
            color: 'white',
            fontWeight: 500,
            background: '#506fd9',
          });
        }
      });
    };
    tableRef.current.on('draw.dt', updatePaginationStyles);
    updatePaginationStyles();

  }, []);

  const createActionButton = (data) => {
    const viewButton = `<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-eye-fill view" viewBox="0 0 16 16">
        <path d="M10.5 8a2.5 2.5 0 1 1-5 0 2.5 2.5 0 0 1 5 0z"/>
        <path d="M0 8s3-5.5 8-5.5S16 8 16 8s-3 5.5-8 5.5S0 8 0 8zm8 3.5a3.5 3.5 0 1 0 0-7 3.5 3.5 0 0 0 0 7z"/>
    </svg>`;

    const applyButton = '<button class="apply-button">Apply</button>';

    // Style the Apply button with CSS
    const applyButtonStyle = `
        .apply-button {
            background-color: #007BFF;
            color: white;
            border: none;
            padding: 5px 10px;
            border-radius: 5px;
            cursor: pointer;
        }
        .apply-button:hover {
            background-color: #0056b3;
        }
    `;

    // Add the CSS styles
    const styleElement = document.createElement('style');
    styleElement.innerHTML = applyButtonStyle;
    document.head.appendChild(styleElement);

    // Combine the view button and styled apply button
    const actionButtons = `${viewButton} <span style="margin-right: 8px;"></span>${applyButton} `;

    return actionButtons;
};



  $('#commonTable tbody').on('click', 'button', function () {
    const data = tableRef.current.row($(this).parents('tr')).data();
    const action = $(this).data('action');

    switch (action) {
      case 'view':
        console.log(`View: ${data[0]} ${data[1]}`);
        break;
      case 'edit':
        console.log(`Edit: ${data[0]} ${data[1]}`);
        break;
      case 'delete':
        console.log(`Delete: ${data[0]} ${data[1]}`);
        break;
      case 'pdf':
        console.log(`Pdf: ${data[0]} ${data[1]}`);
        break;
      default:
        break;
    }
  });

  return (
    <React.Fragment>
    
      <div className="main p-4 p-lg-5 mt-39">
        {/* <div className="breadcrumb-warning d-flex justify-content-between ot-card">
          <div>
            <ol className="breadcrumb ot-breadcrumb ot-breadcrumb-basic">
              <li className="mx-2">
                <h4>Jobs|</h4>
              </li>
              <li className="mx-2">
                <Button
                  variant=""
                  className={`mb-3 btn-1 ${activeButton === 'posted' ? 'active' : ''}`}
                  onClick={() => handleButtonClick('posted')}
                >
                  Posted Jobs(0)
                </Button>
              </li>
              <li className="mx-2">
                <Button
                  variant=""
                  className={`mb-3 btn-1 ${activeButton === 'career' ? 'active' : ''}`}
                  onClick={() => handleButtonClick('career')}
                >
                  Posted On a Career Site(0)
                </Button>
              </li>
            </ol>
          </div>
          <nav aria-label="breadcrumb">
            <Button variant="primary" className="mb-3">
              Post a Job
            </Button>
          </nav>
        </div> */}

        <Card className="popup-card">
          <Card.Body>
            <div className="breadcrumb-warning d-flex justify-content-between ot-card">
              <div>
                <ol className="breadcrumb ot-breadcrumb ot-breadcrumb-basic">
                  <li className="mx-1">
                    <Button
                      variant=""
                      className={`mb-3 btn-1 ${activeButton === 'open' ? 'active' : ''}`}
                      onClick={() => handleButtonClick('open')}
                      style={{
                        backgroundColor: activeButton === 'open' ? '#506fd9' : 'white',
                        color: activeButton === 'open' ? 'white' : 'black',
                      }}
                    >
                      Job Lists
                    </Button>
                  </li>
                  {/* <li className="mx-2">
                    <Button
                      variant=""
                      className={`mb-3 btn-1 ${activeButton === 'closed' ? 'active' : ''}`}
                      onClick={() => handleButtonClick('closed')}
                      style={{
                        backgroundColor: activeButton === 'closed' ? '#506fd9' : 'white',
                        color: activeButton === 'closed' ? 'white' : 'black',
                      }}
                    >
                      Closed(0)
                    </Button>
                  </li> */}
                </ol>
              </div>

              {/* <nav aria-label="breadcrumb">
                <Button variant="primary" className="mb-3">
                  <Link to="/pages/Jobs/jobform">
                    <span style={{ color: "white" }}>
                      <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-plus-lg" viewBox="0 0 16 16">
                        <path fill-rule="evenodd" d="M8 2a.5.5 0 0 1 .5.5v5h5a.5.5 0 0 1 0 1h-5v5a.5.5 0 0 1-1 0v-5h-5a.5.5 0 0 1 0-1h5v-5A.5.5 0 0 1 8 2Z" />
                      </svg>
                      Create a Job
                    </span>
                  </Link>
                </Button>
              </nav> */}
            </div>

            <table id="commonTable" className="display table   table-striped table-curved" width="100%" ref={tableRef}></table>
          </Card.Body>
        </Card>
      </div>
    </React.Fragment>
  );
}

export default Jobs;
