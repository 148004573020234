import React, { useState, useEffect, useRef } from 'react';
import _debounce from 'lodash/debounce';
import Header from '../../../layouts/Header';
import HeaderMobile from '../../../layouts/HeaderMobile';
import { Card, Col, Row } from "react-bootstrap";
import $ from 'jquery';
import 'datatables.net';
import '../../../styles/globals.css'
import { deleteShift, viewShift } from '../../../services/api/api';
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { Button } from "react-bootstrap";
import { deleteShiftId } from '../../../services/api/api';
import { Modal, OverlayTrigger, Tooltip } from "react-bootstrap";
import { toast } from "react-toastify";
import { DataGrid } from '@mui/x-data-grid';
import { TextField } from '@mui/material';



const Shift = () => {
  const navigate = useNavigate();
  const tableRef = useRef(null);
  const [shiftData, setshiftData] = useState([]);
  const [shiftNo, setShiftNo] = useState(null);
  const [loading, setLoading] = useState(true);
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [deleteShiftId, setdeleteShiftId] = useState(null);
  const [searchText, setSearchText] = useState('');
  const [masterId, setMasterId] = useState();


  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Month starts from 0
    const year = date.getFullYear();

    return `${day}/${month}/${year}`;
  };
  const formatShiftTime = (time, fieldName) => {
    const formatTime = (time) => {
      const date = new Date(`1970-01-01T${time}`);
      return date.toLocaleTimeString('en-US', { hour: 'numeric', minute: 'numeric', hour12: true });
    };

    return {
      [fieldName]: formatTime(time),
    };
  };
  const reversedRows = [...shiftData].reverse();
  const columns = [

    {
      field: 'id',
      headerName: 'Sr.', align: 'center', headerAlign: 'center',
      // width: 50,
      flex: 1,
      renderCell: (params) => (
        <span>
          {reversedRows.indexOf(params.row) + 1}
        </span>
      ),
    },
    { field: 'shift_no', headerName: 'Shift No.', flex: 3, align: 'center', headerAlign: 'center', },
    { field: 'company_start_time', headerName: 'Company Start Time', flex: 3, align: 'center', headerAlign: 'center', valueGetter: (params) => formatShiftTime(params.row.company_start_time, 'company_start_time').company_start_time },
    { field: 'company_end_time', headerName: 'Company End Time', flex: 3, align: 'center', headerAlign: 'center', valueGetter: (params) => formatShiftTime(params.row.company_end_time, 'company_end_time').company_end_time },

    { field: 'company_break_time', headerName: 'Company Break Time', flex: 3, align: 'center', headerAlign: 'center', },
    { field: 'company_break_fine', headerName: 'Company Break Fine', flex: 3, align: 'center', headerAlign: 'center', },
    { field: 'company_late_fine', headerName: 'Company Late Fine', flex: 3, align: 'center', headerAlign: 'center', },

    {
      field: 'actions', headerName: 'Actions', width: 100, align: 'center', headerAlign: 'center', renderCell: (params) => (
        <>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="16"
            fill="black"
            className="bi bi-pencil-fill edit ebtn"
            viewBox="0 0 16 16"
            data-id={params.row._id}
            style={{ cursor: 'pointer', marginRight: "15px" }}
            onClick={() => {
              const id = params.row._id;
              const shiftNo = params.row.shift_no;
              navigate(`/edit_shift/${id}/${masterId}/${shiftNo}`);
              // console.log("viewemployee id", params);
            }}
          >
            <path d="M12.854.146a.5.5 0 0 0-.707 0L10.5 1.793 14.207 5.5l1.647-1.646a.5.5 0 0 0 0-.708l-3-3zm.646 6.061L9.793 2.5 3.293 9H3.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.207l6.5-6.5zm-7.468 7.468A.5.5 0 0 1 6 13.5V13h-.5a.5.5 0 0 1-.5-.5V12h-.5a.5.5 0 0 1-.5-.5V11h-.5a.5.5 0 0 1-.5-.5V10h-.5a.499.499 0 0 1-.175-.032l-.179.178a.5.5 0 0 0-.11.168l-2 5a.5.5 0 0 0 .65.65l5-2a.5.5 0 0 0 .168-.11l.178-.178z" />
          </svg>

          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="16"
            fill="black"
            className="bi bi-trash3-fill delete delete-button"
            viewBox="0 0 16 16"
            data-id={params.row._id}
            style={{ cursor: 'pointer' }}
            onClick={() => {
              const id = params.row._id;
              openConfirmationModal(id);
            }}
          >
            <path d="M11 1.5v1h3.5a.5.5 0 0 1 0 1h-.538l-.853 10.66A2 2 0 0 1 11.115 16h-6.23a2 2 0 0 1-1.994-1.84L2.038 3.5H1.5a.5.5 0 0 1 0-1H5v-1A1.5 1.5 0 0 1 6.5 0h3A1.5 1.5 0 0 1 11 1.5Zm-5 0v1h4v-1a.5.5 0 0 0-.5-.5h-3a.5.5 0 0 0-.5.5ZM4.5 5.029l.5 8.5a.5.5 0 1 0 .998-.06l-.5-8.5a.5.5 0 1 0-.998.06Zm6.53-.528a.5.5 0 0 0-.528.47l-.5 8.5a.5.5 0 0 0 .998.058l.5-8.5a.5.5 0 0 0-.47-.528ZM8 4.5a.5.5 0 0 0-.5.5v8.5a.5.5 0 0 0 1 0V5a.5.5 0 0 0-.5-.5Z" />
          </svg>
        </>
      )
    },
  ];
  const handleSearch = (event) => {
    setSearchText(event.target.value);
  };

  const filterRows = () => {
    if (!searchText) {
      return reversedRows;
    }

    return reversedRows.filter((row) =>
      Object.values(row).some(
        (value) =>
          typeof value === 'string' && value.toLowerCase().includes(searchText.toLowerCase())
      )
    );
  };

  // const fetchData = async () => {
  //   try {
  //     const formattedData = await viewShift();

  //     if (!formattedData || !formattedData.length) {
  //       throw new Error('Failed to fetch data.');
  //     }

  //     const companyShift = formattedData[0].company_time;
  //     setMasterId(formattedData[0]._id);
  //     const shiftArray = Object.values(companyShift).map(item => ({

  //       _id: item._id,
  //       shift_no: item.shift_no || 'N/A',
  //       company_start_time: item.company_start_time || 'N/A',
  //       company_end_time: item.company_end_time || 'N/A',
  //       company_break_time: item.company_break_time || 'N/A',
  //       company_break_fine: item.company_break_fine || 'N/A',
  //       company_late_fine: item.company_late_fine || 'N/A',
  //     }));

  //     setshiftData(shiftArray);
  //   } catch (error) {
  //     console.error('Error fetching data:', error);
  //   } finally {
  //     setLoading(false);
  //   }
  // };

  // useEffect(() => {
  //   fetchData();
  // }, []);

  const fetchData = async () => {
    try {
      const formattedData = await viewShift();

      if (!formattedData || !formattedData.length) {
        throw new Error('Failed to fetch data.');
      }

      const companyShift = formattedData[0].company_time;
      setMasterId(formattedData[0]._id);
      const shiftArray = Object.values(companyShift).map(item => ({
        _id: item._id,
        shift_no: item.shift_no || 'N/A',
        company_start_time: item.company_start_time || 'N/A',
        company_end_time: item.company_end_time || 'N/A',
        company_break_time: item.company_break_time || 'N/A',
        company_break_fine: item.company_break_fine || 'N/A',
        company_late_fine: item.company_late_fine || 'N/A',
      }));

      setshiftData(shiftArray);
      setShiftNo(shiftArray.shift_no)
    } catch (error) {
      console.error('Error fetching data:', error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    const debouncedFetchData = _debounce(fetchData, 100);

    debouncedFetchData();

    return () => {
      debouncedFetchData.cancel();
    };
  }, []);

  const handledeleteShift = async (id, masterId) => {
    setdeleteShiftId(id);
    setShowConfirmation(true);

    try {
      const response = await deleteShift(id, masterId);
      if (response && response.status === 'success') {
        if (!toast.isActive('shiftDeletedToast')) {
          toast.success('Shift deleted successfully',
            {
              toastId: 'shiftDeletedToast',
              autoClose: 1000,
            });
        }
        fetchData();

      } else {
        // Handle failure case
      }
    } catch (error) {
      console.error('Error deleting shift:', error);
    }
  };


  const createActionButton = (data) => {
    const id = data[0];

    const viewButton = `<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-eye-fill view" viewBox="0 0 16 16" style="cursor: pointer;">
  <path d="M10.5 8a2.5 2.5 0 1 1-5 0 2.5 2.5 0 0 1 5 0z"/>
  <path d="M0 8s3-5.5 8-5.5S16 8 16 8s-3 5.5-8 5.5S0 8 0 8zm8 3.5a3.5 3.5 0 1 0 0-7 3.5 3.5 0 0 0 0 7z"/>
  </svg>`;

    const editButton = `
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="16"
        height="16"
        fill="currentColor"
        class="bi bi-pencil-fill edit edit-button"
        viewBox="0 0 16 16"
        data-id="${id}"
        data-masterId="${masterId}"
        data-shiftNo="${shiftNo}"
        style="cursor: pointer;">
        <path d="M12.854.146a.5.5 0 0 0-.707 0L10.5 1.793 14.207 5.5l1.647-1.646a.5.5 0 0 0 0-.708l-3-3zm.646 6.061L9.793 2.5 3.293 9H3.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.207l6.5-6.5zm-7.468 7.468A.5.5 0 0 1 6 13.5V13h-.5a.5.5 0 0 1-.5-.5V12h-.5a.5.5 0 0 1-.5-.5V11h-.5a.5.5 0 0 1-.5-.5V10h-.5a.499.499 0 0 1-.175-.032l-.179.178a.5.5 0 0 0-.11.168l-2 5a.5.5 0 0 0 .65.65l5-2a.5.5 0 0 0 .168-.11l.178-.178z"/>
      </svg>
    `;


    const deleteButton = `
  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-trash3-fill delete delete-button" viewBox="0 0 16 16" 
  data-id="${id}" style="cursor: pointer;">
  //   <path d="M11 1.5v1h3.5a.5.5 0 0 1 0 1h-.538l-.853 10.66A2 2 0 0 1 11.115 16h-6.23a2 2 0 0 1-1.994-1.84L2.038 3.5H1.5a.5.5 0 0 1 0-1H5v-1A1.5 1.5 0 0 1 6.5 0h3A1.5 1.5 0 0 1 11 1.5Zm-5 0v1h4v-1a.5.5 0 0 0-.5-.5h-3a.5.5 0 0 0-.5.5ZM4.5 5.029l.5 8.5a.5.5 0 1 0 .998-.06l-.5-8.5a.5.5 0 1 0-.998.06Zm6.53-.528a.5.5 0 0 0-.528.47l-.5 8.5a.5.5 0 0 0 .998.058l.5-8.5a.5.5 0 0 0-.47-.528ZM8 4.5a.5.5 0 0 0-.5.5v8.5a.5.5 0 0 0 1 0V5a.5.5 0 0 0-.5-.5Z"/>
  // </svg>
  `;


    return ` ${editButton} <span style="margin-right: 8px;"></span> ${deleteButton} <span style="margin-right: 8px;"></span> ${viewButton} <span style="margin-right: 8px;"></span> `;
  };



  const openConfirmationModal = (id) => {
    setdeleteShiftId(id);
    setShowConfirmation(true);
  };
  const closeConfirmationModal = () => {
    setShowConfirmation(false);
  };
  // $('#commonTable tbody').on('click', '.delete-button', function () {
  //   const id = $(this).data('id');
  //   openConfirmationModal(id);
  // });
  return (
    <React.Fragment>
      <HeaderMobile />
      <Header />
      {/* Your header components */}
      <div className="main p-4 p-lg-5 mt-5">
        {/* Your breadcrumb section */}
        <div className="breadcrumb-warning d-flex justify-content-between ot-card">
          <div>
            <ol className="breadcrumb ot-breadcrumb ot-breadcrumb-basic">
              {/* <li className="mx-2">
                  <h4>Holidays</h4>
                </li> */}
              <li class="breadcrumb-item ">
                <Link to="/admin/dashboard">Dashboard</Link>
              </li>
              <li class="breadcrumb-item active"><Link to="/pages/hr/shift">Shifts</Link></li>
              {/* <li class="breadcrumb-item active">Lists</li> */}
            </ol>
          </div>
          <nav aria-label="breadcrumb">


            <Link to="/pages/hr/shiftsform" >
              <div>
                <button
                  className={`btn${shiftData.length >= 4 ? ' disabled' : ''} btn-primary`}
                  disabled={shiftData.length >= 4}
                  type="submit"
                >
                  <span>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      fill="currentColor"
                      className="bi bi-plus-lg"
                      viewBox="0 0 16 16"
                    >
                      <path
                        fillRule="evenodd"
                        d="M8 2a.5.5 0 0 1 .5.5v5h5a.5.5 0 0 1 0 1h-5v5a.5.5 0 0 1-1 0v-5h-5a.5.5 0 0 1 0-1h5v-5A.5.5 0 0 1 8 2Z"
                      />
                    </svg>
                    Add
                  </span>
                </button>
              </div>

            </Link>


          </nav>
        </div>
        <Card className="popup-card empHistory">
          <Card.Body>
            {/* <div style={{ display: 'flex', justifyContent: "center", alignItems: 'center', justifyContent: 'flex-end', marginBottom: "1rem" }}>
              <label htmlFor="searchInput"><strong></strong></label>
              <input
                type="text"
                className='p-1 w-25 ms-2'
                placeholder="Search"
                value={searchText}
                onChange={handleSearch}
              />
            </div> */}
            <div className="col-md-12 ">
              <Row>
                <Col md={9} className="mb-3 pt-3">
                </Col>
                <Col md={3} className="mb-3 pt-3 d-flex justify-content-end pe-0">
                  <TextField
                    label="Search"
                    variant="outlined"
                    value={searchText}
                    onChange={handleSearch}
                    size="small"

                  />
                </Col>
              </Row>
            </div>
            {loading ? (
              <div className="loader-container">
                <div className="loader"></div>
              </div>
            ) : (
              <div style={{ height: 500, width: '100%', backgroundColor: 'white' }}>
                <DataGrid
                  rows={filterRows()}
                  columns={columns}
                  pageSize={5}
                  responsive
                  autoHeight
                  // checkboxSelection
                  getRowId={(row) => row._id}
                  components={{
                    NoRowsOverlay: () => <div style={{ textAlign: 'center', padding: '20px' }}>No data available</div>,
                  }}
                />
              </div>
            )}
          </Card.Body>
        </Card>

      </div>

      <Modal show={showConfirmation} onHide={closeConfirmationModal}>
        <Modal.Header closeButton>
          <Modal.Title>Confirm Deletion</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Are you sure you want to delete this shift ?
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={closeConfirmationModal}>
            Cancel
          </Button>
          <Button
            variant="danger"
            onClick={() => {
              handledeleteShift(deleteShiftId, masterId);
              closeConfirmationModal();
            }}

          >
            Delete
          </Button>

        </Modal.Footer>
      </Modal>
    </React.Fragment>
  );

}
export default Shift;


































