import React, { useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { editsalarystructuresetup, getSingleEmployeeDetails, viewdepartmentpagination, viewemployeepaggination, viewLogo } from '../../services/api/api';

const SalaryPDF = () => {
    const location = useLocation();
    const { rowData, year, month, selectedEmployee } = location.state || {}; // Access rowData passed from employeesalary.js
    const [singleEmp, setSingleEmp] = useState(null);
    const [singleLogo, setCompanyLogo] = useState(null);
    const [matchedEmployee, setMatchedEmployee] = useState({})
    const ignoredKeys = ['id', 'basic', 'deduction', 'late_violation', 'leave_violation', 'break_violation', 'allowance', 'month', 'name', 'net_total', 'serial', 'totalDeductions', 'year', 'totalEarning', 'deduction_monthly_total', 'gross_month_total'];

    useEffect(() => {
        fetchEmployeeData()
    }, [])

    const fetchEmployeeData = async () => {
        try {
            const response = await viewemployeepaggination(1, 100);
            let responseData = await viewdepartmentpagination(1, 100);

            console.log(response);
            console.log(responseData);
            console.log("******************************", singleEmp);

            const data = responseData.data;

            // if (Array.isArray(data)) {
            //     const result = data.find(item => item.company_department._id == singleEmp.department); // Adjust the condition as needed
            //     console.log("aaaaaaaaaaaaaaaaaa",result);
            // } else {
            //     console.error("Data is not an array", data);
            // }

            // Check for a valid response
            if (!response || !response.success || !Array.isArray(response.data)) {
                throw new Error('Failed to fetch data or no data available.');
            }

            // Set rows based on data length
            if (response.data.length != 0) {
                const selectedEmployeeName = selectedEmployee.name;
                console.log("===============================================", selectedEmployeeName);

                const matchedEmployee = response.data.find(item => {
                    const { employeeData } = item;
                    console.log(employeeData);

                    const fullName = `${employeeData.first_name} ${employeeData.last_name}`;
                    console.log(fullName);
                    return fullName == selectedEmployeeName;
                });

                console.log('Matched Employee:', matchedEmployee);

                setMatchedEmployee(matchedEmployee)

            }
        } catch (error) {
            console.error('Error fetching data:', error);
        } finally {
        }
    };


    const roundValue = (value) => {
        if (value === null || value === undefined) {
            return ''; // Handle null or undefined values
        }

        const rounded = value > 0.5 ? Math.ceil(value) : Math.floor(value);
        return rounded % 1 === 0 ? rounded.toString() : rounded;
    };

    // const renderRows = (data) => {
    //     // const filteredKeys = Object.keys(data).filter(key => !ignoredKeys.includes(key));
    //     const rowPairs = [];
    //     console.log(data);
    //     console.log(selectedEmployee);


    //     const sumArray = (arr) => arr.reduce((acc, val) => acc + Number(val), 0);

    //     // Days Details and Bank Details Section
    //     rowPairs.push(
    //         <React.Fragment key="days-bank-details-section">
    //             <tr>
    //                 <th scope="row" colSpan="2" className='text-center' style={{ backgroundColor: '#f0f0f0' }}>Days Details</th>
    //                 <th scope="row" colSpan="2" className='text-center' style={{ backgroundColor: '#f0f0f0' }}>Bank Details</th>
    //             </tr>
    //             <tr>
    //                 <th scope="row">Working Days</th>
    //                 <td>{data.working_days}</td>
    //                 <th scope="row">Branch Name</th>
    //                 <td>{data.branch_name}</td>
    //             </tr>
    //             <tr>
    //                 <th scope="row">Present Days</th>
    //                 <td>{data.present_days}</td>
    //                 <th scope="row">Account No</th>
    //                 <td>{data.account_no}</td>
    //             </tr>
    //             <tr>
    //                 <th scope="row">Absent Days</th>
    //                 <td>{data.absent_days}</td>
    //                 <th scope="row">PAN No</th>
    //                 <td>{data.PAN_no}</td>
    //             </tr>
    //             <tr>
    //                 <td></td>
    //                 <td></td>
    //                 <th scope="row">IFSC Code</th>
    //                 <td>{data.IFSC_code}</td>
    //             </tr>
    //             <tr>
    //                 <td></td>
    //                 <td></td>
    //                 <th scope="row">Bank Name</th>
    //                 <td>{data.bank_name}</td>
    //             </tr>
    //         </React.Fragment>
    //     );

    //     rowPairs.push(
    //         <React.Fragment key="earnings-deductions-section">
    //             <tr>
    //                 <th scope="row" colSpan="2" className='text-center' style={{ backgroundColor: '#f0f0f0' }}>Earnings</th>
    //                 <th scope="row" colSpan="2" className='text-center' style={{ backgroundColor: '#f0f0f0' }}>Deductions</th>
    //             </tr>
    //             <tr>
    //                 <th scope="row">Basic</th>
    //                 <td>{sumArray(selectedEmployee.basic.map(b => roundValue(b.monthly)))}</td>
    //                 <th scope="row">Break Violation</th>
    //                 <td>{data.break_violation}</td>
    //             </tr>
    //             <tr>
    //                 <th scope="row">Allowance</th>
    //                 <td>{sumArray(selectedEmployee.allowence.map(item => roundValue(item.monthly) || 0))}</td>
    //                 <th scope="row">Late Violation</th>
    //                 <td>{data.late_violation}</td>
    //             </tr>
    //             <tr>
    //                 <th scope="row"></th>
    //                 <td></td>
    //                 <th scope="row">Leave Violation</th>
    //                 <td>{data.leave_violation}</td>
    //             </tr>
    //             <tr>
    //                 <th scope="row"></th>
    //                 <td></td>
    //                 <th scope="row">Other Deductions</th>
    //                 <td>{sumArray(selectedEmployee.allowence.map(item => roundValue(item.monthly) || 0))}</td>
    //             </tr>
    //             <tr>
    //                 <td colSpan="2"></td>
    //                 <td className='text-center' style={{ backgroundColor: '#f0f0f0' }}>Net Total</td>
    //                 <td className='text-center'>{data.net_total}</td>
    //             </tr>
    //         </React.Fragment>
    //     );

    //     return rowPairs;
    // };




    // ------------------------------------------

    const renderRows = (data) => {
        const rowPairs = [];

        console.log(data);
        const basicName = data?.basic?.[0]?.name || ''; // Set a default value if basic is empty
        const basicMonthly = data?.basic?.[0]?.monthly || 0; // Monthly value, default to 0

        // Prepare allowance data
        const allowances = data?.allowence || [];

        let earningsArray = [...data.basic, ...data.allowence]

        const totalEarnings = earningsArray?.reduce((acc, earning) => acc + (earning?.monthly || 0), 0);


        const earningsRows = earningsArray?.map((earnings, index) => (
            <div key={`allowance-${index}`}>
                <strong>{earnings?.name}</strong>
            </div>
        ));

        const actualEarningsValue = earningsArray?.map((earnings, index) => (
            <div key={`allowance-${index}`}>
                <span>{earnings?.monthly || 0}</span>
            </div>
        ));
        const payableEarningValue = earningsArray?.map((earnings, index) => (
            <div key={`allowance-${index}`}>
                <span>{earnings?.monthly || 0}</span>
            </div>
        ));

        // Calculate actual and payable values based on allowances
        const actualValue = allowances?.reduce((acc, allowance) => acc + (allowance?.value || 0), basicMonthly);
        const payableValue = actualValue; // Assuming payable value is the same as actual

        const deductionsArray = [
            ...data.deduction,
            { name: "Break", monthly: data?.break_violation },
            { name: "Leave", monthly: data?.leave_violation },
            { name: "Late", monthly: data?.late_violation }
        ];

        const totalDeductions = deductionsArray.reduce((acc, deduction) => acc + (deduction?.monthly || 0), 0);


        // Log the new array to the console
        console.log(deductionsArray);

        const deductionsRows = deductionsArray?.map((deductions, index) => (
            <div key={`deductions-${index}`}>
                <strong>{deductions?.name}</strong>
            </div>
        ));


        const payableDeductionsValue = deductionsArray?.map((deductions, index) => (
            <div key={`deductions-${index}`}>
                <span>{deductions?.monthly || 0}</span>
            </div>
        ));

        // Prepare violation data
        const violations = [
            { label: 'Break Violation', value: data?.break_violation || 0 },
            { label: 'Late Violation', value: data?.late_violation || 0 },
            { label: 'Leave Violation', value: data?.leave_violation || 0 },
        ];

        const violationRows = violations?.map((violation, index) => (
            <div key={`violation-${index}`}>
                <span>{violation?.label}</span>: {violation?.value}
            </div>
        ));

        // Extract working days, present days, and absent days
        const workingDays = data?.working_days || 0;
        const presentDays = data?.present_days || 0;
        const absentDays = data?.absent_days || 0;
        const extraDays = data?.totalExtraDays || 0;

        let netTotal = numberToWords(totalEarnings - totalDeductions)

        rowPairs.push(
            <React.Fragment key="profile-earnings-and-deductions">
                <tr>
                    <th scope="row" className='text-center' style={{ backgroundColor: '#f0f0f0' }}>

                    </th>
                    <th scope="row" className='text-center' style={{ backgroundColor: '#f0f0f0' }}>

                    </th>
                    <th scope="row" className='text-left' style={{ backgroundColor: '#f0f0f0', fontWeight: 'bold' }}>
                        Earnings
                    </th>
                    <th scope="row" className='text-center' style={{ backgroundColor: '#f0f0f0', fontWeight: 'bold' }}>
                        Actual
                    </th>
                    <th scope="row" className='text-right' style={{ backgroundColor: '#f0f0f0', fontWeight: 'bold' }}>
                        Payable
                    </th>
                    <th scope="row" className='text-left' style={{ backgroundColor: '#f0f0f0', fontWeight: 'bold' }}>
                        Deductions
                    </th>
                    <th scope="row" className='text-right' style={{ backgroundColor: '#f0f0f0', fontWeight: 'bold' }}>
                        Payable
                    </th>
                </tr>
                <tr>
                    <td className='text-left' style={{ borderRight: '2px solid transparent' }}>
                        <div><strong>Name:</strong></div>
                        <div><strong>Employee ID:</strong></div>
                        <div><strong>Date of Birth:</strong></div>
                        <div><strong>Date of Joining:</strong></div>
                        <div>
                            <strong>Designation:</strong>
                        </div>
                        <div>
                            <strong>Department:</strong>
                        </div>

                        <div><strong>Site:</strong></div>
                        <div><strong>PF No:</strong></div>
                        <div><strong>UAN No:</strong></div>
                        <div><strong>ESIC No:</strong></div>
                        <div><strong>Bank Account No:</strong></div>
                        <div><strong>Working Days:</strong></div>
                        <div><strong>Present Days:</strong></div>
                        <div><strong>Absent Days:</strong></div>
                        <div><strong>Extra Days:</strong></div>
                    </td>
                    <td className='text-left' style={{ borderRight: '2px solid #ccc' }}>
                        <div> {data?.name}</div>
                        <div>{data?.employee_id}</div>
                        <div>{singleEmp?.birth_date ? convertDateFormat(singleEmp?.birth_date) : ''}</div>
                        <div> {singleEmp?.joining_date ? convertDateFormat(singleEmp?.joining_date) : ''}</div>
                        <div>
                            {singleEmp?.designationName || ''}
                        </div>
                        <div>
                            {singleEmp?.departmentName || ''}
                        </div>

                        <div> {data?.branchNewName || '-'}</div>
                        <div> {data?.pf_no || '-'}</div>
                        <div> {data?.uan_no || '-'}</div>
                        <div> {data?.esic_no || '-'}</div>
                        <div> {data?.bank_information?.[0]?.account_no || '-'}</div>
                        <div> {workingDays}</div>
                        <div> {presentDays}</div>
                        <div> {absentDays}</div>
                        <div> {extraDays}</div>
                    </td>
                    <td className='text-left' style={{ borderRight: '1px solid transparent' }}>
                        {earningsRows}
                    </td>
                    <td className='text-center' style={{ borderRight: '1px solid transparent' }}>
                        {actualEarningsValue}
                    </td>
                    <td className='text-right' style={{ borderRight: '2px solid #ccc' }}>
                        {payableEarningValue}
                    </td>
                    <td className='text-left' style={{ borderRight: '1px solid transparent' }}>
                        {deductionsRows}
                    </td>
                    <td className='text-right'>
                        {payableDeductionsValue}
                    </td>

                </tr>
                <tr>
                    <td colSpan={2} style={{ borderRight: '2px solid #ccc' }}></td>
                    <td colSpan={3} className='text-left' style={{ borderRight: '2px solid #ccc' }}>
                        <div>
                            Total : {totalEarnings}
                        </div>
                    </td>
                    <td colSpan={2} className='text-left'>

                        <div>
                            Total : {totalDeductions}
                        </div>
                    </td>
                </tr>
                <tr>
                    <td colSpan={2} className='text-left' style={{ borderRight: '2px solid transparent' }}>Net Payable : (Rs) {netTotal} Only</td>
                    <td colSpan={3} className='text-left' style={{ borderRight: '2px solid transparent ' }}></td>

                    <td colSpan={2} className='text-right' style={{ fontWeight: 'bold', fontSize: '1rem', paddingRight: '3rem' }}>{totalEarnings - totalDeductions}/-</td>

                </tr>
            </React.Fragment>
        );

        return rowPairs;
    };



    function numberToWords(num) {
        const belowTwenty = [
            '', 'One', 'Two', 'Three', 'Four', 'Five', 'Six', 'Seven', 'Eight', 'Nine',
            'Ten', 'Eleven', 'Twelve', 'Thirteen', 'Fourteen', 'Fifteen', 'Sixteen',
            'Seventeen', 'Eighteen', 'Nineteen'
        ];

        const tens = ['', '', 'Twenty', 'Thirty', 'Forty', 'Fifty', 'Sixty', 'Seventy', 'Eighty', 'Ninety'];

        const thousands = ['Thousand', 'Lakh', 'Crore'];

        if (num === 0) return 'Zero';

        let words = '';

        // Handle Lakhs
        if (num >= 100000) {
            words += helper(Math.floor(num / 100000)) + ' Lakh ';
            num %= 100000;
        }

        // Handle Thousands
        if (num >= 1000) {
            words += helper(Math.floor(num / 1000)) + ' Thousand ';
            num %= 1000;
        }

        // Handle Hundreds
        if (num >= 100) {
            words += helper(Math.floor(num / 100)) + ' Hundred ';
            num %= 100;
        }

        // Handle Tens and Ones
        if (num > 0) {
            words += helper(num);
        }

        return words.trim();
    }

    function helper(num) {
        const belowTwenty = [
            '', 'One', 'Two', 'Three', 'Four', 'Five', 'Six', 'Seven', 'Eight', 'Nine',
            'Ten', 'Eleven', 'Twelve', 'Thirteen', 'Fourteen', 'Fifteen', 'Sixteen',
            'Seventeen', 'Eighteen', 'Nineteen'
        ];

        const tens = ['', '', 'Twenty', 'Thirty', 'Forty', 'Fifty', 'Sixty', 'Seventy', 'Eighty', 'Ninety'];

        if (num < 20) return belowTwenty[num];
        else if (num < 100) return tens[Math.floor(num / 10)] + (num % 10 ? ' ' + belowTwenty[num % 10] : '');
        else return belowTwenty[Math.floor(num / 100)] + ' Hundred' + (num % 100 ? ' ' + helper(num % 100) : '');
    }

    // Example usage:
    const number = 151890;
    console.log(numberToWords(number)); // Output: "One Lakh Fifty-One Thousand Eight Hundred Ninety"





    function convertDateFormat(originalDate) {
        // Split the date into its components
        const [year, month, day] = originalDate.split('-');

        // Reformat to DD-MM-YYYY
        return `${day}-${month}-${year}`;
    }




    // const handlePrint = () => {
    //     const originalContents = document.body.innerHTML;
    //     const printContents = document.getElementById('print-section').innerHTML;

    //     document.body.innerHTML = printContents;

    //     window.print();

    //     document.body.innerHTML = originalContents;
    // };


    // const handlePrint = () => {
    //     window.print();
    // };

    const handlePrint = () => {
        // Temporarily hide elements
        document.querySelectorAll('.headerNew, .footer, .breadcrumb-warning, .button-oneX, .salary-report, .line-below')
            .forEach(el => el.style.display = 'none');

        // Print the page
        window.print();

        // Restore elements after printing
        document.querySelectorAll('.headerNew, .footer, .breadcrumb-warning, .button-oneX, .salary-report, .line-below')
            .forEach(el => el.style.display = '');
    };


    useEffect(() => {
        handleEditEmployee();
        getCompanyLogo();

    }, [rowData, selectedEmployee]);

    const handleEditEmployee = async () => {
        try {
            const response = await getSingleEmployeeDetails({ id: `${selectedEmployee.employee_id}` });
            console.log("edit API response:", response);

            setSingleEmp(response);
        } catch (error) {
            console.error("Error fetching salary structure setup:", error);
        }
    };
    const getCompanyLogo = async () => {
        try {
            const response = await viewLogo();
            setCompanyLogo(response);
        } catch (error) {
            console.error("Error fetching salary structure setup:", error);
        }
    };
    console.log('singleLogo:', singleLogo);

    console.log('rowData:', rowData);
    console.log('rowData:', selectedEmployee);
    console.log('singleEmp:', singleEmp);
    // const netTotalInWords = rowData ? numberToWords(rowData.net_total) : '';
    return (
        <div>

            <style>
                {`
  @media print {
      body {
          width: 100% !important;
          padding: 0 !important;
          margin: 0 !important;
      }
      .headerNew, .footer, .breadcrumb-warning, .button-oneX, .salary-report, .line-below {
          display: none !important;
      }
      .main {
          display: block !important;
          width: 100% !important;
          padding: 0 !important;
          margin: 0 !important;
      }
      .print-section {
        //   display: block !important;
        //   width: 100% !important;
        //   padding: 0 !important;
        //   margin: 0 !important;
         width: 8.5in; /* For standard letter size */
    margin: 0 auto;
    padding: 0;
      }
      .table-bordered {
          width: 100% !important;
          border-collapse: collapse !important;
      }
  }
  `}
            </style>





            <div className="headerNew">
            </div>

            <div className="main p-4 p-lg-5 mt-5">
                {/* <div className="mb-4 border-bottom text-center">
                    <h3 className="mb-0 pb- border-bottom custome-btn">Salary Report</h3>
                </div> */}

                <div className="breadcrumb-warning d-flex justify-content-between ot-card">
                    <div>
                        <ol className="breadcrumb ot-breadcrumb ot-breadcrumb-basic">
                            {/* <li className="breadcrumb-item active">Salary Report</li> */}
                        </ol>
                    </div>
                    <div className="d-flex justify-content-between ">
                        <nav aria-label="breadcrumb" style={{ marginRight: '1rem', }}>
                            <Link to="/admin/employeesalary" state={{ fromSalaryPDF: true }} className="btn btn-primary mb-3 button-oneX" style={{
                                backgroundColor: '#007bff',
                                color: 'white',
                                textDecoration: 'none',
                                padding: '0.5rem 1rem',
                                borderRadius: '0.25rem',
                                border: '0px'
                            }}>
                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-arrow-left" viewBox="0 0 16 16" style={{ marginRight: '10px' }}>
                                    <path fillRule="evenodd" d="M15 8a.5.5 0 0 0-.5-.5H2.707l3.147-3.146a.5.5 0 1 0-.708-.708l-4 4a.5.5 0 0 0 0 .708l4 4a.5.5 0 0 0 .708-.708L2.707 8.5H14.5A.5.5 0 0 0 15 8z" />
                                </svg>
                                Back
                            </Link>
                        </nav>
                        <nav aria-label="breadcrumb">
                            <div>
                                <button className="button-oneX btn-primary" onClick={handlePrint} style={{
                                    backgroundColor: '#007bff',
                                    color: 'white',
                                    border: 'none',
                                    padding: '7px 12px',
                                    borderRadius: '0.25rem',
                                    display: 'flex',
                                    alignItems: 'center',
                                    marginTop: '0.4rem'
                                }}>
                                    <span>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-printer" viewBox="0 0 16 16" style={{ marginRight: '10px' }}>
                                            <path d="M2.5 8a.5.5 0 1 0 0-1 .5.5 0 0 0 0 1" />
                                            <path d="M5 1a2 2 0 0 0-2 2v2H2a2 2 0 0 0-2 2v3a2 2 0 0 0 2 2h1v1a2 2 0 0 0 2 2h6a2 2 0 0 0 2-2v-1h1a2 2 0 0 0 2-2V7a2 2 0 0 0-2-2h-1V3a2 2 0 0 0-2-2zM4 3a1 1 0 0 1 1-1h6a1 1 0 0 1 1 1v2H4zm1 5a2 2 0 0 0-2 2v1H2a1 1 0 0 1-1-1V7a1 1 0 0 1 1-1h12a1 1 0 0 1 1 1v3a1 1 0 0 1-1 1h-1v-1a2 2 0 0 0-2-2zm7 2v3a1 1 0 0 1-1 1H5a1 1 0 0 1-1-1v-3a1 1 0 0 1 1-1h6a1 1 0 0 1 1 1" />
                                        </svg>Print</span>
                                </button>
                            </div>
                        </nav>
                    </div>
                </div>

                <div className="row"
                    style={{
                        boxShadow: "0 4px 8px 0 rgba(0,0,0,0.2)",
                        borderRadius: "10px",
                        overflow: "hidden",
                    }}
                >
                    <div className="row">
                        <div id="print-section" className="col-md-12">
                            <div className="text-center lh-1 mb-2">
                                <Link to="" className="">
                                    {singleLogo ? (
                                        <img src={singleLogo} style={{ width: "150px" }} alt="Company Logo" />
                                    ) : (
                                        <img src="" style={{ width: "100px" }} alt="Company Logo" />
                                    )}
                                </Link>
                                <div className=" p-3 mt-4">
                                    <div className="d-flex flex-column">
                                        <span className="fw-bold">
                                            <p>PAYSLIP FOR {month.toUpperCase()} {year}</p>
                                        </span>


                                    </div>
                                </div>
                            </div>

                            <div className="container mt-4">
                                <table className="table" >
                                    <tbody style={{}}>
                                        {renderRows(selectedEmployee)}
                                    </tbody>
                                </table>
                            </div>



                            <div className="d-flex justify-content-end">
                                <div className="d-flex flex-column mt-2">
                                    <span className="mt-4">Authorised Signatory</span> </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    );
};
export default SalaryPDF;


