import React, { useState, useEffect } from "react";
import { Button, Card, Col, Form, Row, Spinner, InputGroup, Nav, Container } from "react-bootstrap";
import { Link, useNavigate, useLocation } from "react-router-dom";
import Prism from "prismjs";
import "react-toastify/dist/ReactToastify.css";
import { toast } from "react-toastify";
import { companyLogin, submitForgetPasswordForm, viewLogo } from "../services/api/api";
import { useHistory } from "react-router-dom";
import { Grid } from 'gridjs-react';
import 'gridjs/dist/theme/mermaid.css';

import { Eye, EyeSlash } from 'react-bootstrap-icons';
import bg1 from "../assets/img/bg1.jpg";
import bgN1 from "../assets/img/bgN1.jpg";
import bgY1 from "../assets/img/bgY1.png";
import bgS1 from "../assets/img/bgS1.jpg";

import pageSvg from "../assets/svg/forgot_password.svg";

export function ForgotPassword() {

  const [loading, setLoading] = useState(false);
  const [email, setEmail] = useState('');


  const handleEmailChange = async (e) => {
    const email = e.target.value;

    console.log(email);

    setEmail(email)
  }

  const navigate = useNavigate();

  const forgotPassword = async () => {

    try {
      console.log(email);

      setLoading(true);
      const response = await submitForgetPasswordForm(email);
      console.log(response);
      if (response) {
        toast.success(response.message, {
          position: 'top-center',
          autoClose: 1000,
        });
        setEmail('')
        navigate('/signin')
      }
    } catch (error) {
      console.error('Error fetching data:', error);
      setEmail('')
    } finally {
      setEmail('')
      setLoading(false);
    }
  }

  document.body.classList.remove("sidebar-show");

  return (

    <div className="d-flex align-items-center justify-content-center" style={{ padding: '50px 0', backgroundImage: `url(${bgS1})`, backgroundSize: 'cover' }}>
      <section className="vh-100" >

        <div class="container py-1 h-95">
          <div class="row d-flex justify-content-center align-items-center h-100">
            <div class="col col-xl-10">
              <div class="card" style={{ border: 'none', borderRadius: '1rem', boxShadow: '0' }}>
                <Link to="/signin" style={{ margin: '1rem 0 0 1rem' }}>
                  <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="black" className="bi bi-arrow-left" viewBox="0 0 16 16">
                    <path fillRule="evenodd" d="M15 8a.5.5 0 0 0-.5-.5H2.707l3.147-3.146a.5.5 0 1 0-.708-.708l-4 4a.5.5 0 0 0 0 .708l4 4a.5.5 0 0 0 .708-.708L2.707 8.5H14.5A.5.5 0 0 0 15 8z" />
                  </svg>

                </Link>
                <div class="row g-0">
                  <div class="col-md-7 col-lg-5 d-none d-md-flex justify-content-center align-items-center">
                    <img
                      src={bgY1}
                      alt="login form"
                      className="img-fluid"
                      style={{ width: '100%', padding: '150px 0', height: 'auto', objectFit: 'cover' }}
                    />
                  </div>

                  <div class="col-md-5 col-lg-7 d-flex justify-content-center align-items-center">
                    <Card className="w-100" style={{ maxWidth: '500px' }}>
                      <Card.Body className="">
                        <Card.Title className="mb-4">Reset Your Password</Card.Title>
                        <Card.Text className="mb-4">
                          Enter the email address registered on your account
                        </Card.Text>
                        <Row className="g-3">
                          <Col sm="12">
                            <Form.Control type="text" onChange={handleEmailChange} placeholder="Enter email address" />
                          </Col>
                          {loading ? (
                            <div className="loader-containerForgot">
                              <div className="loader"></div>
                            </div>
                          ) : (
                            <Col sm="12">
                              <Button variant="" onClick={forgotPassword} className="btn btn-primary" >
                                Send
                              </Button>
                            </Col>
                          )}
                        </Row>
                      </Card.Body>
                    </Card>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}