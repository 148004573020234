
import React, { useState, useEffect, useRef } from 'react';
import _debounce from 'lodash/debounce';
import Header from "../../../layouts/Header";
import HeaderMobile from "../../../layouts/HeaderMobile";
import { Card, Button, Modal, Row, Col } from "react-bootstrap";
import $ from 'jquery';
import 'datatables.net';
import { viewDesignation, deleteDesignation, viewdesignationpagination, BASE_URL, searchdesignationpagination } from '../../../services/api/api';
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { DataGrid } from '@mui/x-data-grid';
import * as XLSX from 'xlsx'; // Import XLSX library
import { TextField } from '@mui/material';

const Designation = () => {
  const navigate = useNavigate();
  const tableRef = useRef(null);
  const [designationData, setDesignationData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [refreshData, setRefreshData] = useState(1);
  const [deleteDesignationId, setDeleteDesignationId] = useState(null);
  const [masterId, setMasterId] = useState();
  const [searchText, setSearchText] = useState('');


  const [rows, setRows] = useState([]);
  const [totalPages, setTotalPages] = useState(1);
  const [page, setPage] = useState(1);
  const [pageSize, setpageSize] = useState(100);
  const [count, setCount] = useState(0);
  const pageRef = useRef(page);
  const [error, setError] = useState(null);

  useEffect(() => {
    pageRef.current = page;
  }, [page]);

  const customStyles = {
    grid: {
      border: '1px solid #ddd',
    },
    cell: {
      border: 'none', // Remove all borders
      borderBottom: '1px solid #ddd', // Only horizontal border
      padding: '0.5rem 0.5rem',
      color: 'var(--bs-table-color-state, var(--bs-table-color-type, #000000b8))', // Using custom properties for text color
      // backgroundColor: 'white', // Using custom properties for background color
      fontWeight: '500', // Making text important
      fontFamily: 'system-ui, -apple-system, "Segoe UI", Roboto, Helvetica, Arial, sans-serif', // Highly standard font family
    },
    columnHeader: {
      border: 'none', // Remove all borders
      borderBottom: '1px solid #ddd', // Only horizontal border
      padding: '0.5rem 0.5rem',
      color: 'var(--bs-table-color-state, var(--bs-table-color-type, #000))', // Using custom properties for text color
      backgroundColor: 'var(--bs-table-bg)', // Using custom properties for background color

      fontWeight: 'bold',
      fontFamily: 'system-ui, -apple-system, "Segoe UI", Roboto, Helvetica, Arial, sans-serif', // Highly standard font family
    },
    lastColumn: {
      borderRight: 'none',
    },
  };


  // const fetchData = async () => {
  //   try {
  //     const formattedData = await viewDesignation();

  //     if (!formattedData || !formattedData.length) {
  //       throw new Error('Failed to fetch data.');
  //     }

  //     const companyDesignation = formattedData[0].company_designation;
  //     setMasterId(formattedData[0]._id);
  //     const designationsArray = Object.values(companyDesignation).map(item => ({

  //       _id: item._id,
  //       designation_name: item.designation_name || 'N/A',
  //     }));

  //     setDesignationData(designationsArray);
  //   } catch (error) {
  //     console.error('Error fetching data:', error);
  //   } finally {
  //     setLoading(false);
  //   }
  // };

  // useEffect(() => {
  //   fetchData();
  // }, []);


  // const fetchData = async () => {
  //   try {
  //     const response = await viewdesignationpagination(page, pageSize);
  //     const { data, totalPages } = response;
  //     console.log(response);


  //     const designationsArray = data.map(value => ({
  //       _id: value.company_designation?._id || 'N/A',  // Use `company_designation` for ID
  //       designation_name: value.company_designation?.designation_name,  // Extract designation name
  //     }));
  //     console.log(designationsArray);


  //     setMasterId(data[0]?._id || null);
  //     setDesignationData(designationsArray);
  //     setTotalPages(response.totalPages);
  //     setpageSize(pageSize);
  //     setRows(designationsArray);
  //   } catch (error) {
  //     console.error('Error fetching data:', error);
  //   } finally {
  //     setLoading(false);
  //   }
  // };

  const fetchData = async () => {
    try {
      const response = await viewdesignationpagination(page, pageSize);
      const { data, totalPages } = response;
      console.log(response);

      // If data is empty, set designationsArray to an empty array
      const designationsArray = data.length > 0
        ? data.map(value => ({
          _id: value.company_designation?._id || 'N/A',
          designation_name: value.company_designation?.designation_name,
        }))
        : []; // Set to empty array if no data

      console.log(designationsArray);

      setMasterId(data[0]?._id || null);
      setTotalPages(totalPages);
      setpageSize(pageSize);

      if (response.data.length == 0) {
        setDesignationData([]); // Set to empty array if no records
        console.log('No data available');
      }
      else {

        const designations = response.data.map((item, index) => {
          const designationData = item.company_designation || {};

          return {

            id: index + 1,
            _id: designationData._id,
            designation: designationData.designation_name || 'N/A',

          };
        });


        setDesignationData(designations); // Use the response data directly
        setRows(designations);
      }

      // setDesignationData(designationsArray);

      // Always set rows to designationsArray, which can be empty
      // setRows(designationsArray);
    } catch (error) {
      console.error('Error fetching data:', error);
    } finally {
      setLoading(false);
    }
  };

  const searchData = async () => {
    try {
      const response = await searchdesignationpagination(page, pageSize, searchText);
      const { data, totalPages } = response;
      console.log(response);

      // If data is empty, set designationsArray to an empty array
      const designationsArray = data.length > 0
        ? data.map(value => ({
          _id: value.company_designation?._id || 'N/A',
          designation_name: value.company_designation?.designation_name,
        }))
        : []; // Set to empty array if no data

      console.log(designationsArray);

      setMasterId(data[0]?._id || null);
      setTotalPages(totalPages);
      setpageSize(pageSize);

      if (response.data.length == 0) {
        setDesignationData([]); // Set to empty array if no records
        console.log('No data available');
      }
      else {

        const designations = response.data.map((item, index) => {
          const designationData = item.company_designation || {};

          return {

            id: index + 1,
            _id: designationData._id,
            designation: designationData.designation_name || 'N/A',

          };
        });


        setDesignationData(designations); // Use the response data directly
        setRows(designations);
      }

      // setDesignationData(designationsArray);

      // Always set rows to designationsArray, which can be empty
      // setRows(designationsArray);
    } catch (error) {
      console.error('Error fetching data:', error);
    } finally {
      setLoading(false);
    }
  };


  const handlePageChange = (event) => {
    const newPage = parseInt(event.target.value, 10);
    setPage(newPage);
    console.log('Selected Page:', newPage);
  };

  const handlePageSizeChange = (event) => {
    console.log('Page Change Event:', event);
    const newPageSize = parseInt(event.target.value, 10);
    setpageSize(newPageSize);
    console.log('Selected Page Size:', newPageSize);
  };

  const getToken = () => {
    const token = sessionStorage.getItem('token');
    return token ? `Bearer ${token}` : '';
  };





  useEffect(() => {
    const debouncedFetchData = _debounce(fetchData, 100);

    debouncedFetchData();

    return () => {
      debouncedFetchData.cancel();
    };
  }, [page, pageSize]);
  const reversedRows = [...designationData].reverse();

  const columns = [
    {
      field: 'id',
      headerName: 'Sr.',
      width: 50,
      align: 'center',
      headerAlign: 'center',
      flex: 2,
      // renderCell: (params) => (
      //   <span>
      //     {rows.indexOf(params.row) + 1}
      //   </span>
      // ),
    },
    {
      field: 'designation',
      headerName: 'Designation',
      width: 700,
      align: 'center',
      headerAlign: 'center',
      flex: 2,
      // renderCell: (params) => (
      //   <span>{params?.value}</span>
      // ),
    },
    {
      field: 'actions',
      headerName: 'Actions',
      width: 200,
      align: 'center',
      headerAlign: 'center',
      flex: 1,
      renderCell: (params) => (
        <>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="16"
            fill="black"
            className="bi bi-pencil-fill edit ebtn"
            viewBox="0 0 16 16"
            data-id={params.row._id}
            style={{ cursor: 'pointer', marginRight: '15px' }}
            onClick={() => {
              const id = params.row._id;
              navigate(`/edit_designation/${id}/${masterId}`);
            }}
          >
            <path d="M12.854.146a.5.5 0 0 0-.707 0L10.5 1.793 14.207 5.5l1.647-1.646a.5.5 0 0 0 0-.708l-3-3zm.646 6.061L9.793 2.5 3.293 9H3.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.207l6.5-6.5zm-7.468 7.468A.5.5 0 0 1 6 13.5V13h-.5a.5.5 0 0 1-.5-.5V12h-.5a.5.5 0 0 1-.5-.5V11h-.5a.5.5 0 0 1-.5-.5V10h-.5a.499.499 0 0 1-.175-.032l-.179.178a.5.5 0 0 0-.11.168l-2 5a.5.5 0 0 0 .65.65l5-2a.5.5 0 0 0 .168-.11l.178-.178z" />
          </svg>

          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="16"
            fill="black"
            className="bi bi-trash3-fill delete delete-button"
            viewBox="0 0 16 16"
            data-id={params.row._id}
            style={{ cursor: 'pointer' }}
            onClick={() => {
              const id = params.row._id;
              openConfirmationModal(id);
            }}
          >
            <path d="M11 1.5v1h3.5a.5.5 0 0 1 0 1h-.538l-.853 10.66A2 2 0 0 1 11.115 16h-6.23a2 2 0 0 1-1.994-1.84L2.038 3.5H1.5a.5.5 0 0 1 0-1H5v-1A1.5 1.5 0 0 1 6.5 0h3A1.5 1.5 0 0 1 11 1.5Zm-5 0v1h4v-1a.5.5 0 0 0-.5-.5h-3a.5.5 0 0 0-.5.5ZM4.5 5.029l.5 8.5a.5.5 0 1 0 .998-.06l-.5-8.5a.5.5 0 1 0-.998.06Zm6.53-.528a.5.5 0 0 0-.528.47l-.5 8.5a.5.5 0 0 0 .998.058l.5-8.5a.5.5 0 0 0-.47-.528ZM8 4.5a.5.5 0 0 0-.5.5v8.5a.5.5 0 0 0 1 0V5a.5.5 0 0 0-.5-.5Z" />
          </svg>
        </>
      ),
    },
  ];





  const s2ab = (s) => {
    const buf = new ArrayBuffer(s.length);
    const view = new Uint8Array(buf);
    for (let i = 0; i !== s.length; ++i) {
      view[i] = s.charCodeAt(i) & 0xFF;
    }
    return buf;
  };

  const handleExportDesignationData = async () => {
    setLoading(true); // Show loading state

    try {
      const response = await fetch(`${BASE_URL}/exportDesignation`, {
        method: 'POST',
        headers: {
          'Accept': 'application/json',
          "Content-Type": "application/json",
          "Authorization": getToken(),
        },
      });

      if (!response.ok) {
        throw new Error('Network response was not ok');
      }

      const data = await response.json();

      // Convert JSON data to CSV
      const csvData = convertToCSV(data); // Ensure you have a function to convert data to CSV

      // Create a Blob and trigger the download
      const blob = new Blob([csvData], { type: 'text/csv' });
      const link = document.createElement('a');
      link.href = URL.createObjectURL(blob);
      link.download = 'Designation Data.csv'; // Change the file name as needed
      link.click();

      // Clean up
      URL.revokeObjectURL(link.href);

      toast.success("Designation exported successfully!", {
        position: "top-center",
        autoClose: 1000,
      });


    } catch (error) {
      console.error("Fetch error: ", error);
      setError(error.message || 'An error occurred');



    } finally {
      setLoading(false); // Hide loading state
    }
  };

  function convertToCSV(data) {
    const array = [Object.keys(data[0])].concat(data);

    return array.map(row => {
      return Object.values(row).map(value =>
        typeof value === 'string' ? `"${value.replace(/"/g, '""')}"` : value
      ).join(',');
    }).join('\n');
  }

  const handleSearch = (event) => {
    setSearchText(event.target.value);
  };

  const filterRows = () => {
    if (!searchText) {
      return [...designationData].reverse();
    }

    return [...designationData].reverse().filter((row) =>
      Object.values(row).some(
        (value) =>
          typeof value === 'string' && value.toLowerCase().includes(searchText.toLowerCase())
      )
    );
  };

  useEffect(() => {
    if (!searchText) {
      fetchData();
    }
    else {
      searchData();
    }
  }, [refreshData, page, pageSize, searchText])// Fetch data whenever shouldRefresh changes


  const handleDeleteDesignation = async (id, masterId) => {
    setDeleteDesignationId(id);
    setShowConfirmation(true);

    try {
      const response = await deleteDesignation(id, masterId);
      // if (response && response.status === 'success') {
      //   if (!toast.isActive('designationDeletedToast')) {
      //     toast.success('Designation deleted successfully', { toastId: 'designationDeletedToast' });
      //   }
      //   // fetchData();
      //   setRefreshData(prev => prev + 1);
      // } else {
      //   // Handle failure case
      // }
      if (response) {
        toast.success('Designation delected successfully', {
          position: "top-center",
          autoClose: 1000,
        });
        fetchData();
        setRefreshData(prev => prev + 1);
      }
      else {
        toast.error('Something went wrong', {
          position: "top-center",
          autoClose: 1000,
        });
      }
    } catch (error) {
      console.error('Error deleting designation:', error);
    }
  };

  const openConfirmationModal = (id) => {
    setDeleteDesignationId(id);
    setShowConfirmation(true);
  };

  const closeConfirmationModal = () => {
    setShowConfirmation(false);
  };

  return (
    <React.Fragment>
      {/* Header components */}
      <HeaderMobile />
      <Header />


      <div className="main p-4 p-lg-5 mt-5">
        {/* Breadcrumb section */}
        <div className="breadcrumb-warning d-flex justify-content-between ot-card">
          <div>
            <ol className="breadcrumb ot-breadcrumb ot-breadcrumb-basic">
              {/* <li className="mx-2">
                  <h4>Holidays</h4>
                </li> */}
              <li class="breadcrumb-item ">
                <Link to="/admin/dashboard">Dashboard</Link>
              </li>
              <li class="breadcrumb-item active"><Link to="/admin/designation">Designations</Link></li>
              {/* <li class="breadcrumb-item active">Lists</li> */}
            </ol>
          </div>
          <nav aria-label="breadcrumb">

            <Link to="/pages/hr/adddesignationform">
              <div><button className="btn btn-primary" type="submit"><span>
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-plus-lg" viewBox="0 0 16 16">
                  <path fill-rule="evenodd" d="M8 2a.5.5 0 0 1 .5.5v5h5a.5.5 0 0 1 0 1h-5v5a.5.5 0 0 1-1 0v-5h-5a.5.5 0 0 1 0-1h5v-5A.5.5 0 0 1 8 2Z" />
                </svg>Add</span></button></div>
            </Link>

          </nav>
        </div>
        <Card className="popup-card empHistory">
          <Card.Body>
            <div style={{ display: 'flex', justifyContent: "center", alignItems: 'center', justifyContent: 'space-between', marginBottom: "1rem" }}>
              <Button className='btn btn-primary border-0' onClick={handleExportDesignationData}>Export</Button>
              {/* <div style={{ display: 'flex', alignItems: 'center' }}>
                <label htmlFor="searchInput"></label>
                <input
                  type="text"
                  className='p-1 w-75 ms-2'
                  placeholder="Search"
                  value={searchText}
                  onChange={handleSearch}
                />
              </div> */}
              <div className="col-md-11">
                <Row>
                  <Col md={9} className="mb-3 pt-3">
                  </Col>
                  <Col md={3} className="mb-3 pt-3 d-flex justify-content-end pe-0">
                    <TextField
                      label="Search"
                      variant="outlined"
                      value={searchText}
                      onChange={handleSearch}
                      size="small"

                    />
                  </Col>
                </Row>
              </div>
            </div>


            {loading ? (
              <div className="loader-container">
                <div className="loader"></div>
              </div>
            ) : (

              // <div style={customStyles.grid}>
              //   <DataGrid
              //     className="gridColor"
              //     rows={rows}
              //     columns={columns}
              //     pageSize={pageSize}
              //     pagination
              //     paginationMode="client"
              //     getRowId={(row) => row._id}
              //     components={{
              //       Pagination: () => (
              //         <div className="custom-select-container">
              //           <div>
              //             <label htmlFor="page-size">Rows per page: </label>
              //             <select
              //               id="page-size"
              //               value={pageSize}
              //               onChange={handlePageSizeChange}  // Using the handlePageSizeChange function
              //             >
              //               <option value={1}>1</option>
              //               <option value={10}>10</option>
              //               <option value={20}>20</option>
              //               <option value={50}>50</option>
              //             </select>
              //           </div>
              //           <div>
              //             <label htmlFor="page-number">Page: </label>
              //             <select
              //               className="page-number"
              //               id="page-number"
              //               value={page}
              //               onChange={handlePageChange}  // Using the handlePageChange function
              //             >
              //               {[...Array(totalPages).keys()].map((pageNumber) => (
              //                 <option key={pageNumber} value={pageNumber + 1}>
              //                   {pageNumber + 1}
              //                 </option>
              //               ))}
              //             </select>
              //           </div>
              //         </div>
              //       ),
              //       NoRowsOverlay: () => (
              //         <div style={{ textAlign: 'center', padding: '20px' }}>
              //           No records available
              //         </div>
              //       ),
              //     }}
              //     componentsProps={{
              //       cell: { style: customStyles.cell },
              //       columnHeader: { style: customStyles.columnHeader },
              //     }}
              //     rowCount={count}        // Total row count
              //     page={page - 1}         // Syncing the page number
              //     onPageChange={(newPage) => setPage(newPage + 1)} // This keeps the actual page selection from 0-index to 1-index
              //     onPageSizeChange={handlePageSizeChange} // To handle changes in the page size
              //     rowsPerPageOptions={[]} // To disable the default options provided by the DataGrid
              //   />
              // </div>

              <div style={{ height: '70vh', width: '100%', backgroundColor: 'white' }}>
                <DataGrid
                  className="gridColor"
                  rows={rows} // The data array to be displayed
                  columns={columns} // Your defined columns
                  pageSize={pageSize} // Current page size
                  pagination
                  paginationMode="client"
                  getRowId={(row) => row._id} // Use '_id' as the unique identifier
                  components={{
                    Pagination: () => (
                      <div className="custom-select-container">
                        <div>
                          <label htmlFor="page-size">Rows per page: </label>
                          <select
                            id="page-size"
                            value={pageSize}
                            onChange={handlePageSizeChange} // Handle page size changes
                          >
                            {/* <option value={5}>5</option>
                            <option value={10}>10</option>
                            <option value={20}>20</option> */}
                            <option value={50}>50</option>
                            <option value={100}>100</option>
                          </select>
                        </div>
                        <div>
                          <label htmlFor="page-number">Page: </label>
                          <select
                            className="page-number"
                            id="page-number"
                            value={page}
                            onChange={handlePageChange} // Handle page changes
                          >
                            {[...Array(totalPages).keys()].map((pageNumber) => (
                              <option key={pageNumber} value={pageNumber + 1}>
                                {pageNumber + 1}
                              </option>
                            ))}
                          </select>
                        </div>
                      </div>
                    ),
                    NoRowsOverlay: () => (
                      <div style={{ textAlign: 'center', padding: '20px' }}>
                        No Designation available
                      </div>
                    ),
                  }}
                  componentsProps={{
                    cell: { style: customStyles.cell }, // Your custom cell styles
                    columnHeader: { style: customStyles.columnHeader }, // Your custom header styles
                  }}
                  rowCount={rows.length} // Use the length of rows to show total records
                  page={page - 1} // Current page (0-based index)
                  onPageChange={(newPage) => setPage(newPage + 1)} // Handle page change
                  rowsPerPageOptions={[]} // Avoid displaying the default rows per page options
                />
              </div>

            )}
          </Card.Body>

        </Card>
      </div>

      <Modal show={showConfirmation} onHide={closeConfirmationModal}>
        <Modal.Header closeButton>
          <Modal.Title>Confirm Deletion</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Are you sure you want to delete this designation?
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={closeConfirmationModal}>
            Cancel
          </Button>
          {/* <Button
              variant="danger"
              onClick={() => {
                handledeleteHoliday(deleteHolidayId);
                closeConfirmationModal();
              }}
            >
              Delete
            </Button> */}
          <Button
            variant="danger"
            onClick={() => {
              handleDeleteDesignation(deleteDesignationId, masterId);
              closeConfirmationModal();
            }}

          >
            Delete
          </Button>

        </Modal.Footer>
      </Modal>
    </React.Fragment>
  );
};

export default Designation;
