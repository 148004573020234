import React, { useEffect, useState } from 'react';
import Header from '../../layouts/Header';
import HeaderMobile from '../../layouts/HeaderMobile';
import { getAllJobDetails, getJobFullDetails, updateJobDetails } from '../../services/api/api';
import { Link, useNavigate, useParams } from 'react-router-dom';
import Select from 'react-select';
import ReactQuill from 'react-quill';
import countryList from 'react-select-country-list';
import { toast } from 'react-toastify';

export const EditJobs = () => {
    const { id } = useParams();
    const navigate = useNavigate();
    const [loading, setLoading] = useState(true);
    const [formData, setFormData] = useState({
        companyName: '',
        branch: [],
        jobTitle: '',
        jobType: '',
        skills: [],
        numberOfOpening: '',
        fromSalary: '',
        toSalary: '',
        country: '',
        state: '',
        city: '',
        pincode: '',
        address: '',
        advertisingPlatform: [],
        companyLink: '',
        benefits: '',
        requirements: ''
    });
    const [formErrors, setFormErrors] = useState({}); // Initialize form errors state
    const [branchOptions, setBranchOptions] = useState([]);

    useEffect(() => {
        handleEdit(id);
    }, [id]);
    const [countries, setCountries] = useState([]);

    useEffect(() => {
        const countryOptions = countryList().getData();
        setCountries(countryOptions);
    }, []);

    const handleEdit = async (id) => {
        try {
            setLoading(true);
            const response = await getJobFullDetails(id);

            if (response && response.length > 0) {
                const jobDetails = response[0].company_job; // Access the job details
                setFormData({
                    companyName: jobDetails.companyName,
                    branch: jobDetails.branch || [],
                    jobTitle: jobDetails.jobTitle,
                    jobType: jobDetails.jobType,
                    skills: jobDetails.skills || [],
                    numberOfOpening: jobDetails.numberOfOpening,
                    fromSalary: jobDetails.fromSalary,
                    toSalary: jobDetails.toSalary,
                    country: jobDetails.country,
                    state: jobDetails.state,
                    city: jobDetails.city,
                    pincode: jobDetails.pincode,
                    address: jobDetails.address,
                    advertisingPlatform: jobDetails.advertisingPlatform || [],
                    companyLink: jobDetails.companyLink,
                    benefits: jobDetails.benefits,
                    requirements: jobDetails.requirements
                });
            }
            setLoading(false);
        } catch (error) {
            console.error('Error fetching job details:', error);
            setLoading(false);
        }
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevData) => ({ ...prevData, [name]: value }));
    };

    const handleSelectChange = (name, selectedOptions) => {
        const values = selectedOptions ? selectedOptions.map(option => option.label) : [];
        setFormData((prevData) => ({ ...prevData, [name]: values }));
    };
    const handleMultiSelectChange = (name, selectedOptions) => {
        const values = selectedOptions ? selectedOptions.map(option => option.value) : [];
        setFormData((prevData) => ({ ...prevData, [name]: values }));
    };

    const handleQuillChange = (name, value) => {
        setFormData((prevData) => ({ ...prevData, [name]: value }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        // Initialize an errors object
        const errors = {};

        // Perform validations
        if (!formData.jobTitle) {
            errors.jobTitle = "Job Title is required.";
        }
        if (!formData.jobType) {
            errors.jobType = "Job Type is required.";
        }
        if (!formData.branch.length) {
            errors.branch = "At least one branch is required.";
        }
        if (formData.numberOfOpening <= 0) {
            errors.numberOfOpening = "Number of Openings must be greater than zero.";
        }
        if (!formData.country) {
            errors.country = "Country is required.";
        }
        if (!formData.state) {
            errors.state = "State is required.";
        }
        if (!formData.city) {
            errors.city = "City is required.";
        }
        if (!formData.pincode) {
            errors.pincode = "Pincode is required.";
        }
        if (!formData.address) {
            errors.address = "Address is required.";
        }
        if (!formData.skills.length) {
            errors.skills = "At least one skill is required.";
        }

        // Check if there are any errors
        if (Object.keys(errors).length > 0) {
            setFormErrors(errors); // Set the errors in state
            return; // Stop the submission if there are errors
        }

        // Proceed with the API call if there are no errors
        try {
            const response = await updateJobDetails(id, formData);
            console.log(response);

            if (response) {
                toast.success('Job Updated Successfully', {
                    position: "top-center",
                    autoClose: 1000,
                });
                navigate('/pages/recruitment/job');
            }
        } catch (error) {
            console.error("Error updating job details:", error);
            // Handle any errors during the API call (optional)
        }
    };


    useEffect(() => {
        fetchCompanyDetails()
    }, [])

    const fetchCompanyDetails = async () => {
        try {
            setLoading(true)
            const response = await getAllJobDetails();
            console.log("response", response);
            if (response.success) {
                const companyName = response.data.company_name;
                const branches = response.data[0].company_branch.map(branch => ({
                    value: branch.branch_name,
                    label: branch.branch_name,
                }));


                setBranchOptions(branches);

                setFormData(prevFormData => ({
                    ...prevFormData,
                    companyName: companyName,
                    branch: prevFormData.branch || [], // Keep existing selected branches
                }));
                setLoading(false)
            }
        } catch (error) {
            setLoading(false)
        } finally {
            setLoading(false)
        }
    };

    // Simulated options for the Select components (replace these with your actual options)
    // const branchOptions = [
    //     { value: 'Vadodara', label: 'Vadodara' },
    //     { value: 'Ahmedabad', label: 'Ahmedabad' }
    // ];

    const skillsOptions = [
        { value: "javascript", label: "JavaScript" },
        { value: "react", label: "React" },
        { value: "nodejs", label: "Node.js" },
        { value: "python", label: "Python" },
        { value: "java", label: "Java" },
        { value: "css", label: "CSS" },
    ];

    const advertisingOptions = [
        { value: 'Social Media', label: 'Social Media' },
        { value: 'Digital Media', label: 'Digital Media' },
        { value: 'Print Media', label: 'Print Media' },
        { value: 'Website', label: 'Website' }
    ];

    return (
        <React.Fragment>
            <HeaderMobile />
            <Header />
            <div className="main p-4 p-lg-5 mt-5">
                <div className="breadcrumb-warning d-flex justify-content-between ot-card"></div>
                <div></div>
                <div>
                    <nav aria-label="breadcrumb">
                        <Link to="/pages/recruitment/job" className="btn btn-primary mb-3">
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-arrow-left" viewBox="0 0 16 16">
                                <path fillRule="evenodd" d="M15 8a.5.5 0 0 0-.5-.5H2.707l3.147-3.146a.5.5 0 1 0-.708-.708l-4 4a.5.5 0 0 0 0 .708l4 4a.5.5 0 0 0 .708-.708L2.707 8.5H14.5A.5.5 0 0 0 15 8z" />
                            </svg>
                            Back
                        </Link>
                    </nav>
                </div>
                {loading ? (
                    <div className="loader-container">
                        <div className="loader"></div>
                    </div>
                ) : (
                    <form onSubmit={handleSubmit}>
                        <div className="row">
                            {/* Company Name */}
                            <div className="col-md-6 mb-3">
                                <label htmlFor="companyName" className="form-label">Company Name</label>
                                <input
                                    type="text"
                                    className={`form-control ${formErrors.companyName && "is-invalid"}`}
                                    id="companyName"
                                    name="companyName"
                                    value={formData.companyName}
                                    readOnly
                                    style={{ cursor: 'not-allowed' }}
                                />
                                {formErrors.companyName && <div className="invalid-feedback">{formErrors.companyName}</div>}
                            </div>

                            {/* Branch */}
                            <div className="col-md-6 mb-3">
                                <label htmlFor="branch" className="form-label">Branch</label>
                                <Select
                                    isMulti
                                    isSearchable
                                    options={branchOptions.filter(option => !formData.branch.includes(option.value))} // Filter out selected options
                                    value={branchOptions.filter(option => formData.branch.includes(option.value))} // Prepopulate with selected values
                                    onChange={(selected) => handleMultiSelectChange("branch", selected)}
                                    className={`form-control p-0 ${formErrors.branch && "is-invalid"}`}
                                />
                                {formErrors.branch && <div className="invalid-feedback">{formErrors.branch}</div>}
                            </div>

                            {/* Job Title */}
                            <div className="col-md-6 mb-3">
                                <label htmlFor="jobTitle" className="form-label">Job Title</label>
                                <input
                                    type="text"
                                    className={`form-control ${formErrors.jobTitle && "is-invalid"}`}
                                    id="jobTitle"
                                    name="jobTitle"
                                    value={formData.jobTitle}
                                    onChange={handleInputChange}
                                />
                                {formErrors.jobTitle && <div className="invalid-feedback">{formErrors.jobTitle}</div>}
                            </div>

                            {/* Job Type */}
                            <div className="col-md-6 mb-3">
                                <label htmlFor="jobType" className="form-label">Job Type</label>
                                <select
                                    className={`form-select ${formErrors.jobType && "is-invalid"}`}
                                    id="jobType"
                                    name="jobType"
                                    value={formData.jobType}
                                    onChange={handleInputChange}
                                >
                                    <option value="">Select Job Type</option>
                                    <option value="Full-Time">Full-Time</option>
                                    <option value="Part-Time">Part-Time</option>
                                </select>
                                {formErrors.jobType && <div className="invalid-feedback">{formErrors.jobType}</div>}
                            </div>

                            {/* Skills */}
                            <div className="col-md-6 mb-3">
                                <label htmlFor="skills" className="form-label">Skills</label>
                                <Select
                                    isMulti
                                    isSearchable
                                    options={skillsOptions}
                                    value={skillsOptions.filter(option => formData.skills.includes(option.value))}
                                    onChange={(selected) => handleMultiSelectChange("skills", selected)}
                                    className={`form-control p-0 ${formErrors.skills && "is-invalid"}`}
                                />
                                {formErrors.skills && <div className="invalid-feedback">{formErrors.skills}</div>}
                            </div>

                            {/* Number of Openings */}
                            <div className="col-md-6 mb-3">
                                <label htmlFor="numberOfOpening" className="form-label">Number of Openings</label>
                                <input
                                    type="number"
                                    className={`form-control ${formErrors.numberOfOpening && "is-invalid"}`}
                                    id="numberOfOpening"
                                    name="numberOfOpening"
                                    value={formData.numberOfOpening || ""}
                                    onChange={handleInputChange}
                                />
                                {formErrors.numberOfOpening && <div className="invalid-feedback">{formErrors.numberOfOpening}</div>}
                            </div>

                            {/* From Salary */}
                            <div className="col-md-6 mb-3">
                                <label htmlFor="fromSalary" className="form-label">From Salary</label>
                                <input
                                    type="text"
                                    className={`form-control ${formErrors.fromSalary && "is-invalid"}`}
                                    id="fromSalary"
                                    name="fromSalary"
                                    value={formData.fromSalary}
                                    onChange={handleInputChange}
                                />
                                {formErrors.fromSalary && <div className="invalid-feedback">{formErrors.fromSalary}</div>}
                            </div>

                            {/* To Salary */}
                            <div className="col-md-6 mb-3">
                                <label htmlFor="toSalary" className="form-label">To Salary</label>
                                <input
                                    type="text"
                                    className={`form-control ${formErrors.toSalary && "is-invalid"}`}
                                    id="toSalary"
                                    name="toSalary"
                                    value={formData.toSalary}
                                    onChange={handleInputChange}
                                />
                                {formErrors.toSalary && <div className="invalid-feedback">{formErrors.toSalary}</div>}
                            </div>

                            {/* Country */}
                            <div className="col-md-6 mb-3">
                                <label htmlFor="country" className="form-label">Country</label>
                                <Select
                                    options={countries}
                                    value={countries.find(option => option.label == formData.country) || null}
                                    onChange={(selected) => handleSelectChange("country", [selected])}
                                    className={`form-control p-0 ${formErrors.country && "is-invalid"}`}
                                />
                                {formErrors.country && <div className="invalid-feedback">{formErrors.country}</div>}
                            </div>


                            {/* State */}
                            <div className="col-md-6 mb-3">
                                <label htmlFor="state" className="form-label">State</label>
                                <input
                                    type="text"
                                    className={`form-control ${formErrors.state && "is-invalid"}`}
                                    id="state"
                                    name="state"
                                    value={formData.state}
                                    onChange={handleInputChange}
                                />
                                {formErrors.state && <div className="invalid-feedback">{formErrors.state}</div>}
                            </div>

                            {/* City */}
                            <div className="col-md-6 mb-3">
                                <label htmlFor="city" className="form-label">City</label>
                                <input
                                    type="text"
                                    className={`form-control ${formErrors.city && "is-invalid"}`}
                                    id="city"
                                    name="city"
                                    value={formData.city}
                                    onChange={handleInputChange}
                                />
                                {formErrors.city && <div className="invalid-feedback">{formErrors.city}</div>}
                            </div>

                            {/* Pincode */}
                            <div className="col-md-6 mb-3">
                                <label htmlFor="pincode" className="form-label">Pincode</label>
                                <input
                                    type="text"
                                    className={`form-control ${formErrors.pincode && "is-invalid"}`}
                                    id="pincode"
                                    name="pincode"
                                    value={formData.pincode}
                                    onChange={handleInputChange}
                                />
                                {formErrors.pincode && <div className="invalid-feedback">{formErrors.pincode}</div>}
                            </div>

                            {/* Address */}
                            <div className="col-md-12 mb-3">
                                <label htmlFor="address" className="form-label">Address</label>
                                <textarea
                                    className={`form-control ${formErrors.address && "is-invalid"}`}
                                    id="address"
                                    name="address"
                                    value={formData.address}
                                    onChange={handleInputChange}
                                ></textarea>
                                {formErrors.address && <div className="invalid-feedback">{formErrors.address}</div>}
                            </div>

                            {/* Advertising Platform */}
                            <div className="col-md-6 mb-3">
                                <label htmlFor="advertisingPlatform" className="form-label">Advertising Platform</label>
                                <Select
                                    isMulti
                                    isSearchable
                                    options={advertisingOptions}
                                    value={advertisingOptions.filter(option => formData.advertisingPlatform.includes(option.value))}
                                    onChange={(selected) => handleSelectChange("advertisingPlatform", selected)}
                                    className={`form-control p-0 ${formErrors.advertisingPlatform && "is-invalid"}`}
                                />
                                {formErrors.advertisingPlatform && <div className="invalid-feedback">{formErrors.advertisingPlatform}</div>}
                            </div>

                            {/* Company Link */}
                            <div className="col-md-6 mb-3">
                                <label htmlFor="companyLink" className="form-label">Company Link (LinkedIn)</label>
                                <input
                                    type="text"
                                    className={`form-control ${formErrors.companyLink && "is-invalid"}`}
                                    id="companyLink"
                                    name="companyLink"
                                    value={formData.companyLink}
                                    onChange={handleInputChange}
                                />
                                {formErrors.companyLink && <div className="invalid-feedback">{formErrors.companyLink}</div>}
                            </div>

                            {/* Benefits */}
                            <div className="col-md-12 mb-5" style={{ height: '20vh' }}> {/* Apply custom class for more spacing */}
                                <label htmlFor="benefits" className="form-label">Benefits</label>
                                <ReactQuill
                                    // theme="snow"
                                    value={formData.benefits}
                                    onChange={(value) => handleQuillChange('benefits', value)}
                                    className={`form-control ${formErrors.benefits && "is-invalid"}`}
                                    style={{ height: '80%', border: 'none' }}

                                />
                                {formErrors.benefits && <div className="invalid-feedback">{formErrors.benefits}</div>}
                            </div>

                            {/* Requirements */}
                            <div className="col-md-12 mb-5" style={{ height: '20vh' }}> {/* Use custom class for consistency */}
                                <label htmlFor="requirements" className="form-label">Requirements</label>
                                <ReactQuill
                                    // theme="snow"
                                    value={formData.requirements}
                                    onChange={(value) => handleQuillChange('requirements', value)}
                                    className={`form-control ${formErrors.requirements && "is-invalid"}`}
                                    style={{ height: '80%', border: 'none' }}
                                />
                                {formErrors.requirements && <div className="invalid-feedback">{formErrors.requirements}</div>}
                            </div>



                        </div>
                        <button type="submit" onClick={handleSubmit} className="btn btn-primary">Update Job</button>
                    </form>
                )}
            </div>

        </React.Fragment>
    );
};
