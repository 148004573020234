import React, { useState } from "react";
import { Form, Button, Modal, Table } from "react-bootstrap";
import { FaEdit, FaTrash } from "react-icons/fa";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { addSalarySetup } from "../../services/api/api";
import { Link, useNavigate } from "react-router-dom";
import HeaderMobile from "../../layouts/HeaderMobile";
import Header from "../../layouts/Header";

const SalaryStructure = () => {
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    name: "",
    basicItems: [
      { type: "Basic", status: 'Active', name: "CTC", component: 'CTC', deduct_type: "Fixed", default: true },
    ],
    allowanceItems: [
      { type: "Allowance", status: 'Active', name: "HRA", component: 'HRA', deduct_type: "Fixed", default: true },
      { type: "Allowance", status: 'Active', name: "Conv. All", component: 'Conv. All', deduct_type: "Fixed", default: true },
      { type: "Allowance", status: 'Active', name: "Driver All.", component: 'Driver All.', deduct_type: "Fixed", default: true },
      { type: "Allowance", status: 'Active', name: "Sp. Allo", component: 'Sp. Allo', deduct_type: "Fixed", default: true },
      { type: "Allowance", status: 'Active', name: "Incentive", component: 'Incentive', deduct_type: "Fixed", default: true },
    ],
    deductionItems: [
      { type: "Deduction", status: 'Active', name: "PF", component: 'PF', deduct_type: "Fixed", default: true },
      { type: "Deduction", status: 'Active', name: "ESI", component: 'ESI', deduct_type: "Fixed", default: true },
      { type: "Deduction", status: 'Active', name: "Proff. Tax", component: 'Proff. Tax', deduct_type: "Fixed", default: true },
      { type: "Deduction", status: 'Active', name: "L.W.F", component: 'L.W.F', deduct_type: "Fixed", default: true },
      { type: "Deduction", status: 'Active', name: "T.D.S", component: 'T.D.S', deduct_type: "Fixed", default: true },
    ],
  });

  const [showModal, setShowModal] = useState(false);
  const [modalAction, setModalAction] = useState("add");
  const [selectedType, setSelectedType] = useState("");
  const [newItem, setNewItem] = useState({ name: "", component: '', deduct_type: "", type: '' });
  const [editItemDetails, setEditItemDetails] = useState({
    name: '',
    type: '',
  });

  // Delete Modal States
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [deleteItemName, setDeleteItemName] = useState("");
  const [deleteType, setDeleteType] = useState("Allowance");
  const [selectedItem, setSelectedItem] = useState(null);


  // const handleShowModal = (action, name, deduct_type, type, component) => {
  //   console.log(selectedType);

  //   setModalAction(action);
  //   setEditItemDetails({ name, type });
  //   if (type) {
  //     setSelectedType(type)
  //   }
  //   console.log(selectedItem);
  //   if (name && deduct_type && type && component) {

  //     // setNewItem({ name: name, deduct_type: deduct_type, type: type, component: name });
  //     setNewItem({ name: name, deduct_type, type, component });
  //     setSelectedItem({ name, deduct_type, type, component });

  //     console.log(name, deduct_type, type, component);


  //   }
  //   else {
  //     setNewItem({ name: '', deduct_type: "Fixed", type: '', component: '' });
  //     setSelectedType('')
  //   }
  //   setShowModal(true);
  // };


  const handleShowModal = (action, name, deduct_type, type, component) => {
    setModalAction(action);
    setEditItemDetails({ name, type });

    // Set the selected type if available
    if (type) {
      setSelectedType(type);
    }

    // If it's an edit action, set selected item details for editing
    if (action === "edit" && name && deduct_type && type && component) {
      setNewItem({ name, deduct_type, type, component });
      setSelectedItem({ name, deduct_type, type, component });
    } else if (action === "add") {
      // For adding a new item, initialize with default values and ensure component matches name
      setNewItem({
        name: '',
        deduct_type: "Fixed",
        type: selectedType || '',
        component: '',
        default: false
      });
      setSelectedType('');
    }

    setShowModal(true);
  };


  const handleCloseModal = () => {
    console.log(newItem);
    console.log(selectedType);
    setSelectedType('')

    setShowModal(false);
    // setNewItem({ name: "", deduct_type: "Fixed" }); // Reset the newItem state
    setEditItemDetails({ name: '', type: '' }); // Reset editItemDetails state
    setNewItem({ name: '', deduct_type: 'fixed', type: '' })

  };

  const handleModalSave = async (e) => {
    const item = { type: selectedType, ...selectedItem };
    const additem = { type: selectedType, ...newItem };
    console.log(item);
    console.log(additem);


    let updatedItems;

    if (modalAction === "edit") {
      // Determine which items array to update based on selectedType
      if (selectedType === "Allowance") {
        updatedItems = formData.allowanceItems.map((existingItem) => {
          if (existingItem.component == selectedItem.component) {
            return { ...existingItem, ...selectedItem };
          }
          return existingItem;
        });
      } else if (selectedType === "Deduction") {
        updatedItems = formData.deductionItems.map((existingItem) => {
          if (existingItem.component === selectedItem.component) {
            return { ...existingItem, ...selectedItem };
          }
          return existingItem;
        });
      } else {
        // Handle the case for basicItems
        updatedItems = formData.basicItems.map((existingItem) => {
          if (existingItem.component == selectedItem.component) { // Assuming basicItems match by name only
            return { ...existingItem, ...selectedItem };
          }
          return existingItem;
        });
      }

      console.log(updatedItems);

      setFormData((prevState) => ({
        ...prevState,
        [selectedType === "Allowance" ? 'allowanceItems' : selectedType === "Deduction" ? 'deductionItems' : 'basicItems']: updatedItems,
      }));
    }
    else {
      // Adding new item logic remains the same
      console.log(selectedType);

      if (selectedType == "Allowance") {
        setFormData((prevState) => ({
          ...prevState,
          allowanceItems: [...prevState.allowanceItems, { ...additem, default: additem.default || false, status: 'Active' }],
        }));
      } else if (selectedType == "Deduction") {
        setFormData((prevState) => ({
          ...prevState,
          deductionItems: [...prevState.deductionItems, { ...additem, default: additem.default || false, status: 'Active' }],
        }));
      } else {
        // Adding to basicItems
        setFormData((prevState) => ({
          ...prevState,
          basicItems: [...prevState.basicItems, { ...additem, default: additem.default || false, status: 'Active' }],
        }));
      }
    }

    // Log the updated formData
    console.log("Updated formData:", formData);

    toast.success(`Successfully ${modalAction === "edit" ? "updated" : "added"} ${newItem.name}!`);
    setShowModal(false); // Un-comment this if you want to close the modal

  };

  const handleStatusChange = (index, newStatus, type) => {
    console.log(newStatus);

    if (type == 'Basic') {
      const updatedItems = formData.basicItems.map((item, idx) => {
        if (idx == index) {
          return { ...item, status: newStatus }; // Update the status
        }
        return item;
      });
      setFormData({ ...formData, basicItems: updatedItems });
    }
    if (type == 'Allowance') {
      const updatedItems = formData.allowanceItems.map((item, idx) => {
        if (idx == index) {
          return { ...item, status: newStatus }; // Update the status
        }
        return item;
      });
      setFormData({ ...formData, allowanceItems: updatedItems });
    }
    if (type == 'Deduction') {
      const updatedItems = formData.deductionItems.map((item, idx) => {
        if (idx == index) {
          return { ...item, status: newStatus }; // Update the status
        }
        return item;
      });
      setFormData({ ...formData, deductionItems: updatedItems });
    }
  };

  const handleAddSalarySetup = async (e) => {
    console.log(formData);
    e.preventDefault();
    try {
      const payload = {
        name: formData.name,
        basic: formData.basicItems.map((item) => ({
          name: item.name,
          component: item.component,
          deduct_type: item.deduct_type,
          default: item.default,
          status: item.status,
        })),
        allowences: formData.allowanceItems.map(item => ({
          name: item.name,
          component: item.component,
          deduct_type: item.deduct_type,
          default: item.default,
          status: item.status,
        })),
        deductions: formData.deductionItems.map(item => ({
          name: item.name,
          component: item.component,
          deduct_type: item.deduct_type,
          default: item.default,
          status: item.status,
        })),
      };

      const response = await addSalarySetup(payload);
      console.log("responsejson", response)
      if (response) {
        toast.success('Salary Structure added successfully.');
        // setFormData({
        //   name: '',
        //   basic: [],
        //   deductions: [],
        //   allowences: []
        // });
        // navigate("/admin/Viewempsalary");

      }
    } catch (error) {
      console.error(error);
      toast.success('Salary setup added successfully.');
    }

  };



  const handleShowDeleteModal = () => {
    setShowDeleteModal(true);
    setDeleteItemName(""); // Reset item name
  };

  const handleCloseDeleteModal = () => setShowDeleteModal(false);

  const handleDeleteModalSave = () => {
    // Check if trying to delete a default item
    const isDefault =
      (deleteType === "Allowance" && formData.allowanceItems.some(item => item.name === deleteItemName && item.name.includes("All"))) ||
      (deleteType === "Deduction" && formData.deductionItems.some(item => item.name === deleteItemName && item.name.includes("Tax")));

    if (isDefault) {
      toast.error("You cannot remove default values!"); // Show error toast if trying to delete default values
    } else {
      if (deleteType === "Allowance") {
        setFormData((prevState) => ({
          ...prevState,
          allowanceItems: prevState.allowanceItems.filter(item => item.name !== deleteItemName),
        }));
      } else {
        setFormData((prevState) => ({
          ...prevState,
          deductionItems: prevState.deductionItems.filter(item => item.name !== deleteItemName),
        }));
      }
      toast.success(`${deleteItemName} successfully deleted!`); // Show success toast
      setShowDeleteModal(false);
    }
  };



  return (
    <React.Fragment>
      <HeaderMobile />
      <Header />
      <div className="main p-4 p-lg-5 mt-5">
        {/* <h3 className="mb-4">Add Salary Structure</h3> */}
        {/* <div className="breadcrumb-warning d-flex justify-content-between ot-card">
          <div>
            <ol className="breadcrumb ot-breadcrumb ot-breadcrumb-basic">

              <li class="breadcrumb-item ">
                <Link to="/admin/dashboard">Dashboard</Link>
              </li>
              <li class="breadcrumb-item active"><Link to="/admin/Viewempsalary">Salary Structure</Link></li>

            </ol>
          </div>
          <nav aria-label="breadcrumb">

            <nav aria-label="breadcrumb">
              <Link to="/admin/Viewempsalary" className="btn btn-danger mb-3">
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-arrow-left" viewBox="0 0 16 16">
                  <path fillRule="evenodd" d="M15 8a.5.5 0 0 0-.5-.5H2.707l3.147-3.146a.5.5 0 1 0-.708-.708l-4 4a.5.5 0 0 0 0 .708l4 4a.5.5 0 0 0 .708-.708L2.707 8.5H14.5A.5.5 0 0 0 15 8z" />
                </svg>
                Back
              </Link>
            </nav>

          </nav>
        </div> */}

        <div className="d-flex justify-content-between">
          <div>

            {/* Add button for new item */}
            <Button variant="primary" onClick={() => handleShowModal("add")} className="me-2">
              Add Salary Name
            </Button>

            {/* Remove button */}
            <Button variant="danger" onClick={handleShowDeleteModal} className="me-2">
              Remove Salary Name
            </Button>
          </div>
          <nav aria-label="breadcrumb">
            {/* <Link to="/admin/Viewempsalary" className="btn btn-danger mb-3">
              <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-arrow-left" viewBox="0 0 16 16">
                <path fillRule="evenodd" d="M15 8a.5.5 0 0 0-.5-.5H2.707l3.147-3.146a.5.5 0 1 0-.708-.708l-4 4a.5.5 0 0 0 0 .708l4 4a.5.5 0 0 0 .708-.708L2.707 8.5H14.5A.5.5 0 0 0 15 8z" />
              </svg>
              Back
            </Link> */}
          </nav>
        </div>

        <Form>
          <Form.Group>
            <Form.Label>Name:</Form.Label>
            <Form.Control
              type="text"
              name="name"
              required
              value={formData.name}
              onChange={(e) => setFormData({ ...formData, name: e.target.value })}
            />
          </Form.Group>

          <br />

          <div style={{ maxHeight: '60vh', overflowY: 'auto' }} className="dynamic-table">
            <Table bordered hover className="table ">
              <thead style={{ position: 'sticky', top: 0, backgroundColor: 'white', zIndex: 1 }}>
                <tr style={{ textAlign: 'center' }}>
                  <th style={{ fontWeight: 'bold', fontSize: '0.9rem', fontFamily: 'sans-serif', padding: '1rem' }}>Sr No.</th>
                  <th style={{ fontWeight: 'bold', fontSize: '0.9rem', fontFamily: 'sans-serif', padding: '1rem' }}>Type</th>
                  <th style={{ fontWeight: 'bold', fontSize: '0.9rem', fontFamily: 'sans-serif', padding: '1rem' }}>Name</th>
                  <th style={{ fontWeight: 'bold', fontSize: '0.9rem', fontFamily: 'sans-serif', padding: '1rem' }}>Component</th>
                  {/* <th style={{ fontWeight: 'bold', fontSize: '0.9rem', fontFamily: 'sans-serif', padding: '1rem' }}>Add/Deduct Type</th> */}
                  <th style={{ fontWeight: 'bold', fontSize: '0.9rem', fontFamily: 'sans-serif', padding: '1rem' }} >Status</th>
                  <th style={{ fontWeight: 'bold', fontSize: '0.9rem', fontFamily: 'sans-serif', padding: '1rem' }}>Action</th>
                </tr>
              </thead>
              <tbody>
                {formData.basicItems.map((item, index) => (
                  <tr key={index} style={{ paddingTop: '1rem' }}>
                    <td style={{ fontWeight: 'bold', fontFamily: 'sans-serif', paddingTop: '1.3rem' }}>{index + 1}</td>
                    <td style={{ fontWeight: 'bold', fontFamily: 'sans-serif', paddingTop: '1.3rem' }}>{item.type}</td>
                    <td style={{ fontWeight: 'bold', fontFamily: 'sans-serif', paddingTop: '1.3rem' }}>{item.name}</td>
                    <td style={{ fontWeight: 'bold', fontFamily: 'sans-serif', paddingTop: '1.3rem' }}>{item.component}</td>
                    <td style={{ fontWeight: 'bold', fontFamily: 'sans-serif', paddingTop: '1.3rem' }}>
                      <select
                        value={item.status}
                        onChange={(e) => handleStatusChange(index, e.target.value, item.type)}
                        style={{
                          fontWeight: 'bold',
                          fontFamily: 'sans-serif',
                          padding: '0.3rem',
                          color: item.status === 'Active' ? 'green' : 'red',
                          border: 'none',
                          backgroundColor: 'transparent',
                        }}
                      >
                        <option value="Active">Active</option>
                      </select>
                    </td>
                    <td>
                      <Button variant="link" onClick={() => handleShowModal("edit", item.name, item.deduct_type, item.type, item.component)}>
                        <FaEdit color="black" />
                      </Button>
                    </td>
                    {/* <td style={{ fontWeight: 'bold', fontFamily: 'sans-serif', paddingTop: '1.3rem' }}>{item.deduct_type}</td> */}
                  </tr>
                ))}
                {formData.allowanceItems.map((item, index) => (
                  <tr key={index}  >
                    <td style={{ fontWeight: 'bold', fontFamily: 'sans-serif', paddingTop: '1.3rem' }}>{index + formData.basicItems.length + 1}</td>
                    <td style={{ fontWeight: 'bold', fontFamily: 'sans-serif', paddingTop: '1.3rem' }}>{item.type}</td>
                    <td style={{ fontWeight: 'bold', fontFamily: 'sans-serif', paddingTop: '1.3rem' }}>{item.name}</td>
                    <td style={{ fontWeight: 'bold', fontFamily: 'sans-serif', paddingTop: '1.3rem' }}>{item.component}</td>
                    <td style={{ fontWeight: 'bold', fontFamily: 'sans-serif', paddingTop: '1.3rem' }}>
                      <select
                        value={item.status}
                        onChange={(e) => handleStatusChange(index, e.target.value, item.type)}
                        style={{
                          fontWeight: 'bold',
                          fontFamily: 'sans-serif',
                          padding: '0.3rem',
                          color: item.status === 'Active' ? 'green' : 'red',
                          border: 'none',
                          backgroundColor: 'transparent',
                        }}
                      >
                        <option value="Active">Active</option>
                        <option value="Inactive">Inactive</option>
                      </select>
                    </td>
                    {/* <td style={{ fontWeight: 'bold', fontFamily: 'sans-serif', paddingTop: '1.3rem' }}>{item.deduct_type}</td> */}
                    <td>
                      <Button variant="link" onClick={() => handleShowModal("edit", item.name, item.deduct_type, item.type, item.component)}>
                        <FaEdit color="black" />
                      </Button>
                    </td>
                  </tr>
                ))}
                {formData.deductionItems.map((item, index) => (
                  <tr key={index}>
                    <td style={{ fontWeight: 'bold', fontFamily: 'sans-serif', paddingTop: '1.3rem' }}>{index + formData.basicItems.length + formData.allowanceItems.length + 1}</td>
                    <td style={{ fontWeight: 'bold', fontFamily: 'sans-serif', paddingTop: '1.3rem' }}>{item.type}</td>
                    <td style={{ fontWeight: 'bold', fontFamily: 'sans-serif', paddingTop: '1.3rem' }}>{item.name}</td>
                    <td style={{ fontWeight: 'bold', fontFamily: 'sans-serif', paddingTop: '1.3rem' }}>{item.component}</td>
                    <td style={{ fontWeight: 'bold', fontFamily: 'sans-serif', paddingTop: '1.3rem' }}>
                      <select
                        value={item.status}
                        onChange={(e) => handleStatusChange(index, e.target.value, item.type)}
                        style={{
                          fontWeight: 'bold',
                          fontFamily: 'sans-serif',
                          padding: '0.3rem',
                          color: item.status === 'Active' ? 'green' : 'red',
                          border: 'none',
                          backgroundColor: 'transparent',
                        }}
                      >
                        <option value="Active">Active</option>
                        <option value="Inactive">Inactive</option>
                      </select>
                    </td>
                    {/* <td style={{ fontWeight: 'bold', fontFamily: 'sans-serif', paddingTop: '1.3rem' }}>{item.deduct_type}</td> */}
                    <td>
                      <Button variant="link" >
                        <FaEdit color="black" onClick={() => handleShowModal("edit", item.name, item.deduct_type, item.type, item.component)} />
                      </Button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </div>
          <br />
          <Button variant="primary" type="submit" onClick={handleAddSalarySetup}>
            Add
          </Button>
        </Form>

        <Modal show={showModal} onHide={handleCloseModal}>
          <Modal.Header closeButton>
            <Modal.Title>{modalAction == "add" ? "Add Allowance/Deduction" : "Edit Item"}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {modalAction == "add" ? (
              <>

                <Form.Group>
                  <Form.Label>Select Type</Form.Label>
                  <Form.Control
                    as="select"
                    value={selectedType}
                    required
                    onChange={(e) => {
                      setSelectedType(e.target.value);
                      setNewItem({ ...newItem, type: e.target.value })
                      console.log(e.target.value);
                    }}

                  >
                    <option value="" selected disabled>Select</option>
                    <option value="Allowance">Allowance</option>
                    <option value="Deduction">Deduction</option>
                  </Form.Control>
                </Form.Group>
                <Form.Group>
                  <Form.Label>Name</Form.Label>
                  <Form.Control
                    type="text"
                    value={newItem.name}
                    required
                    onChange={(e) => {
                      const value = e.target.value;
                      setNewItem({ ...newItem, name: value, component: value });
                      console.log("New Name:", newItem); // Log the new name
                    }}
                  />
                </Form.Group>

                <Form.Group>
                  <Form.Label>Add/Deduct Type</Form.Label>
                  <Form.Control
                    as="select"
                    value={newItem.deduct_type}
                    onChange={(e) => {
                      const value = e.target.value;
                      setNewItem({ ...newItem, deduct_type: value });
                      console.log("New Deduct Type:", value);
                      console.log(newItem);
                    }}
                  >
                    <option value="Fixed">Fixed</option>
                    <option value="Percentage">Percentage</option>
                  </Form.Control>
                </Form.Group>
              </>
            ) : (
              <>
                <Form.Group>
                  <Form.Label>Name</Form.Label>
                  <Form.Control
                    type="text"
                    // readOnly
                    value={modalAction == "edit" ? selectedItem.name : newItem.name}
                    onChange={(e) => {
                      const value = e.target.value;
                      if (modalAction == "edit") {
                        setSelectedItem({ ...selectedItem, name: value });
                        console.log("Editing Name:", value); // Log the edited name
                      } else {
                        setNewItem({ ...newItem, name: value });
                        console.log("New Name:", value); // Log the new name for add action
                      }
                    }}
                  />
                </Form.Group>
                <Form.Group>
                  <Form.Label>Add/Deduct Type</Form.Label>
                  <Form.Control
                    as="select"
                    value={modalAction === "edit" ? selectedItem.deduct_type : newItem.deduct_type}
                    onChange={(e) => {
                      const value = e.target.value;
                      if (modalAction == "edit") {
                        setSelectedItem({ ...selectedItem, deduct_type: value });
                        console.log("Editing Deduct Type:", value); // Log the edited deduct type
                      } else {
                        setNewItem({ ...newItem, deduct_type: value });
                        console.log("New Deduct Type:", value); // Log the new deduct type for add action
                      }
                    }}
                  >
                    <option value="Fixed">Fixed</option>
                    <option value="Percentage">Percentage</option>
                  </Form.Control>
                </Form.Group>
              </>
            )}
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleCloseModal}>
              Close
            </Button>
            <Button variant="primary" onClick={handleModalSave}>
              Save
            </Button>
          </Modal.Footer>
        </Modal>


        <Modal show={showDeleteModal} onHide={handleCloseDeleteModal}>
          <Modal.Header closeButton>
            <Modal.Title>Delete Item</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form.Group>
              <Form.Label>Select Type</Form.Label>
              <Form.Control
                as="select"
                value={deleteType}
                onChange={(e) => {
                  setDeleteType(e.target.value);
                  setDeleteItemName(""); // Reset the selected item when type changes
                }}
              >
                <option value="Allowance">Allowance</option>
                <option value="Deduction">Deduction</option>
              </Form.Control>
            </Form.Group>
            <Form.Group>
              <Form.Label>Select Item to Delete</Form.Label>
              <Form.Control
                as="select"
                value={deleteItemName}
                onChange={(e) => setDeleteItemName(e.target.value)}
              >
                <option value="">Select Item</option>
                {(deleteType === "Allowance"
                  ? formData.allowanceItems.filter(item => !item.default) // Show non-default allowance items
                  : formData.deductionItems.filter(item => !item.default) // Show non-default deduction items
                ).map((item, index) => (
                  <option key={index} value={item.name}>
                    {item.name}
                  </option>
                ))}
              </Form.Control>
            </Form.Group>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleCloseDeleteModal}>
              Close
            </Button>
            <Button variant="danger" onClick={handleDeleteModalSave}>
              Delete
            </Button>
          </Modal.Footer>
        </Modal>


        <ToastContainer />
      </div >
    </React.Fragment>
  );
};

export default SalaryStructure;