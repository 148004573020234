import React, { useState, useEffect } from 'react';

import { useNavigate, useParams } from 'react-router-dom';
import Header from '../../layouts/Header';
import HeaderMobile from '../../layouts/HeaderMobile';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Form } from 'react-bootstrap';
import { Link } from "react-router-dom";

const Automateduser = () => {
   

    return (
        <React.Fragment>
            <HeaderMobile />
            <Header />

            <div className="main p-4 p-lg-5 mt-5">
                <div>

                    <h4>Automated User</h4>
                    <div className="breadcrumb-warning d-flex justify-content-between ot-card">
                        <div>
                            <ol className="breadcrumb ot-breadcrumb ot-breadcrumb-basic">
                                <li className="breadcrumb-item">
                                    <Link to="/admin/dashboard">Dashboard</Link>
                                </li>
                                {/* <li className="breadcrumb-item active">
                                    <Link to="/pages/hr/branch">Branches</Link>
                                </li> */}
                                <li className="breadcrumb-item active">Automated User</li>
                            </ol>
                        </div>
                        {/* <nav aria-label="breadcrumb">
                            <Link to="/pages/hr/branch" className="btn btn-danger mb-3">
                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-arrow-left" viewBox="0 0 16 16">
                                    <path fillRule="evenodd" d="M15 8a.5.5 0 0 0-.5-.5H2.707l3.147-3.146a.5.5 0 1 0-.708-.708l-4 4a.5.5 0 0 0 0 .708l4 4a.5.5 0 0 0 .708-.708L2.707 8.5H14.5A.5.5 0 0 0 15 8z" />
                                </svg>
                                Back
                            </Link>
                        </nav> */}
                    </div>


                    <div className="row"
                        style={{
                            boxShadow: "0 4px 8px 0 rgba(0,0,0,0.2)",
                            borderRadius: "10px",
                            overflow: "hidden",
                        }}
                    >


                        <div className="col-md-12">
                            <div className="bg-white p-4 rounded" style={{ height: "100%" }}>
                                {/* <form onSubmit={handleSubmit}>
                                    <div className="row">
                                        <div className="col-md-6">
                                            <div className="mb-3">
                                                <label htmlFor="branch_name" className="form-label">
                                                    Branch name
                                                </label>
                                                <input
                                                    type="text"
                                                    className={`form-control ${errors.branch_name && 'is-invalid'}`}
                                                    id="branch_name"
                                                    name="branch_name"
                                                    value={state.branch_name}
                                                    onChange={handleChange}
                                                />
                                                {errors.branch_name && (
                                                    <div className="invalid-feedback">{errors.branch_name}</div>
                                                )}
                                            </div>


                                        </div>
                                        <div className="col-md-6">



                                        </div>
                                    </div>
                                    <button type="submit" className="btn btn-primary">
                                        Update
                                    </button>
                                </form> */}
                                <h3>No data availabel </h3>
                            </div>
                        </div>
                    </div>


                </div>
            </div>
        </React.Fragment>
    );
};

export default Automateduser;
