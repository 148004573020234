import React, { useState, useEffect, useRef } from 'react';
import _debounce from 'lodash/debounce';
import Header from '../../../layouts/Header';
import HeaderMobile from '../../../layouts/HeaderMobile';
import { Card, Button } from 'react-bootstrap';
import { useNavigate, Link } from 'react-router-dom';
import { viewEmployeeBreakCount, getId, searchEmployeeBreakCount } from '../../../services/api/api';
import { toast } from 'react-toastify';
import DatePicker from 'react-datepicker';
import { Calendar } from 'react-bootstrap-icons';

const EmployeeBreakhistory = () => {
    const navigate = useNavigate();
    const [breakcountData, setBreakcountData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [employeeId, setEmployeeId] = useState('');
    const [searchText, setSearchText] = useState('');
    const [selectedDate, setSelectedDate] = useState(new Date());

    useEffect(() => {
        const empId = getId();
        setEmployeeId(empId);
    }, []);

    // const fetchData = async () => {
    //     try {
    //         const formattedData = await viewEmployeeBreakCount(employeeId);

    //         if (!formattedData || !formattedData.data.length) {
    //             throw new Error('Failed to fetch data.');
    //         }

    //         const breakList = formattedData.data[0].break_list;
    //         const branchArray = breakList.flatMap((item, index) =>
    //             Object.entries(item.attendance).map(([date, attendanceData]) => ({
    //                 id: `${index}_${date}`,
    //                 date: attendanceData.date,
    //                 name: attendanceData.name,
    //                 type: attendanceData.breaks[0].type,
    //                 time: formatTime(attendanceData.breaks[0].time),
    //                 breaks: attendanceData.breaks.map(breakItem => ({
    //                     type: breakItem.type,
    //                     time: formatTime(breakItem.time),
    //                     duration_minutes: breakItem.duration_minutes || 0,
    //                 })),
    //             }))
    //         );

    //         setBreakcountData(branchArray);
    //     } catch (error) {
    //         console.error('Error fetching data:', error);
    //     } finally {
    //         setLoading(false);
    //     }
    // };

    // useEffect(() => {
    //     fetchData();
    // }, [employeeId]);

    const fetchData = async () => {
        try {
          const formattedData = await viewEmployeeBreakCount(employeeId);
      
          if (!formattedData || !formattedData.data.length) {
            throw new Error('Failed to fetch data.');
          }
      
          const breakList = formattedData.data[0].break_list;
          const branchArray = breakList.flatMap((item, index) =>
            Object.entries(item.attendance).map(([date, attendanceData]) => ({
              id: `${index}_${date}`,
              date: attendanceData.date,
              name: attendanceData.name,
              type: attendanceData.breaks[0].type,
              time: formatTime(attendanceData.breaks[0].time),
              breaks: attendanceData.breaks.map(breakItem => ({
                type: breakItem.type,
                time: formatTime(breakItem.time),
                duration_minutes: breakItem.duration_minutes || 0,
              })),
            }))
          );
      
          setBreakcountData(branchArray);
        } catch (error) {
          console.error('Error fetching data:', error);
        } finally {
          setLoading(false);
        }
      };
      
      const debouncedFetchData = _debounce(fetchData, 100);
      
      useEffect(() => {
        debouncedFetchData();
      
        return () => {
          debouncedFetchData.cancel();
        };
      }, [employeeId]);
      

 
      

    const reversedRows = [...breakcountData].reverse();

    const formatTime = (time) => {
        const formattedTime = new Date(`2022-01-01 ${time}`);
        return formattedTime.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });
    };

    const calculateTotalDuration = (breaks) => {
        const totalDuration = breaks.reduce((total, breakItem) => total + breakItem.duration_minutes, 0);

        const hours = Math.floor(totalDuration / 60);
        const minutes = totalDuration % 60;

        return `${hours}h ${minutes}m`;
    };

    const handleSearch = (event) => {
        setSearchText(event.target.value);
    };

    const fetchBreakData = async () => {
        try {
            setLoading(true);
            const year = selectedDate.getFullYear();
            const month = selectedDate.getMonth() + 1;
            const formattedData = await searchEmployeeBreakCount(employeeId, year, month);

            if (!formattedData || !formattedData.data.length) {
                throw new Error('Failed to fetch data.');
            }

            const breakList = formattedData.data[0].break_list;

            const branchArray = breakList.flatMap((item, index) =>
                Object.entries(item.attendance).map(([date, attendanceData]) => ({
                    id: `${index}_${date}`,
                    date: attendanceData.date,
                    name: attendanceData.name,
                    type: attendanceData.breaks[0].type,
                    time: formatTime(attendanceData.breaks[0].time),
                    breaks: attendanceData.breaks.map(breakItem => ({
                        type: breakItem.type,
                        time: formatTime(breakItem.time),
                        duration_minutes: breakItem.duration_minutes || 0,
                    })),
                }))
            );

            setBreakcountData(branchArray);
        } catch (error) {
            console.error('Error fetching data:', error);
        } finally {
            setLoading(false);
        }
    };

    // useEffect(() => {
    //     fetchBreakData();
    // }, [employeeId, selectedDate]);
    useEffect(() => {
        const debouncedFetchBreakData = _debounce(fetchBreakData, 100);
    
        debouncedFetchBreakData();
    
        return () => {
            debouncedFetchBreakData.cancel();
        };
    }, [employeeId, selectedDate]);

    return (
        <React.Fragment>
            <HeaderMobile />
            <Header />


            <Card className="popup-card">
                <Card.Body>{loading ? (
                    <div style={{ textAlign: 'center', margin: '20px' }}>
                        <div style={{ border: '2px solid #ccc', borderRadius: '5px', padding: '20px' }}>
                            Loading...
                        </div>
                    </div>
                ) : (
                    <table className="table" style={{ marginTop: '20px', boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)', borderRadius: '8px', overflow: 'hidden' }}>
                        <thead className="thead-dark">
                            <tr>
                                <th style={{ textAlign: 'center' }}>Date</th>
                                <th style={{ textAlign: 'center' }}>Name</th>
                                <th style={{ textAlign: 'center' }}>Total Break Duration</th>
                            </tr>
                        </thead>
                        <tbody>
                            {/* {reversedRows.map((row, index) => (
                                    <tr key={index} style={{ textAlign: 'center' }}>
                                        <td>{row.date}</td>
                                        <td>{row.name}</td>
                                        <td>
                                            <button className="btn btn-success">
                                                {calculateTotalDuration(row.breaks)}
                                            </button>
                                        </td>

                                    </tr>
                                ))} */}

                            {reversedRows.map((row, index) => (
                                <tr key={index} style={{ textAlign: 'center' }}>
                                    <td>{row.date}</td>
                                    <td>{row.name}</td>
                                    <td>
                                        {row.breaks.length > 0 ? (
                                            
                                            <button className="btn btn-success">
                                            {calculateTotalDuration(row.breaks)}
                                        </button>
                                        ) : (
                                            <button className="btn btn-success">
                                             Is On the Break
                                            </button>
                                        )}
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>

                )}


                </Card.Body>
            </Card>

        </React.Fragment>
    );
};

export default EmployeeBreakhistory;



















