// // import React, { useEffect, useState } from "react";
// // import { useNavigate } from "react-router-dom";

// // const Thankyoumsg = () => {
// //   const navigate = useNavigate();
// //   const [loaded, setLoaded] = useState(false);

// //   useEffect(() => {
// //     // Prevent back navigation
// //     const handlePopState = (event) => {
// //       event.preventDefault();
// //       navigate("/", { replace: true });
// //     };

// //     window.history.pushState(null, null, window.location.href);
// //     window.addEventListener("popstate", handlePopState);

// //     setLoaded(true);

// //     return () => {
// //       window.removeEventListener("popstate", handlePopState);
// //     };
// //   }, [navigate]);

// //   const fadeInAnimation = {
// //     animation: "fadeIn 1s ease-in-out",
// //     animationFillMode: "forwards"
// //   };

// //   const scaleUpAnimation = {
// //     transform: loaded ? "scale(1)" : "scale(0)",
// //     transition: "transform 0.3s"
// //   };

// //   return (
// //     <React.Fragment>
// //       <style>
// //         {`
// //           @keyframes fadeIn {
// //             0% { opacity: 0; transform: translateY(-20px); }
// //             100% { opacity: 1; transform: translateY(0); }
// //           }
// //           body, html {
// //             height: 100%;
// //             margin: 0;
// //             display: flex;
// //             justify-content: center;
// //             align-items: center;
// //             background: linear-gradient(to right, #e0eafc, #cfdef3); /* Subtle gradient */
// //             font-family: 'Arial', sans-serif;
// //           }
// //           .thank-you-container {
// //             text-align: center;
// //             background: rgba(255, 255, 255, 0.95); /* Slightly more opaque for better readability */
// //             padding: 4rem;
// //             border-radius: 20px;
// //             box-shadow: 0 4px 15px rgba(0,0,0,0.1);
// //             max-width: 800px;
// //             width: 100%;
// //             transition: transform 0.3s;
// //             ${scaleUpAnimation}
// //           }
// //           .thank-you-container h1 {
// //             font-size: 3.5rem;
// //             color: #343a40;
// //             margin-bottom: 1.5rem;
// //           }
// //           .thank-you-container p {
// //             font-size: 1.5rem;
// //             color: #6c757d;
// //           }
// //         `}
// //       </style>
// //       <div className="thank-you-container" style={fadeInAnimation}>
// //         <h1>Thank you for submitting your application!</h1>
// //       </div>
// //     </React.Fragment>
// //   );
// // };

// // export default Thankyoumsg;


// import React, { useEffect, useState } from "react";
// import { useNavigate } from "react-router-dom";

// const Thankyoumsg = () => {
//   const navigate = useNavigate();
//   const [loaded, setLoaded] = useState(false);

//   useEffect(() => {
//     // Prevent back navigation
//     const handlePopState = (event) => {
//       event.preventDefault();
//       navigate("/", { replace: true });
//     };

//     window.history.pushState(null, null, window.location.href);
//     window.addEventListener("popstate", handlePopState);

//     setLoaded(true);

//     return () => {
//       window.removeEventListener("popstate", handlePopState);
//     };
//   }, [navigate]);

//   const fadeInAnimation = {
//     animation: "fadeIn 1s ease-in-out",
//     animationFillMode: "forwards"
//   };

//   const scaleUpAnimation = {
//     transform: loaded ? "scale(1)" : "scale(0)",
//     transition: "transform 0.5s"
//   };

//   const backgroundStyle = {
//     background: "linear-gradient(to right, #4b6cb7, #182848)", /* Gradient background */
//     height: "100vh",
//     display: "flex",
//     justifyContent: "center",
//     alignItems: "center"
//   };

//   return (
//     <React.Fragment>
//       <style>
//         {`
//           @keyframes fadeIn {
//             0% { opacity: 0; transform: translateY(-20px); }
//             100% { opacity: 1; transform: translateY(0); }
//           }
//           body, html {
//             margin: 0;
//             font-family: 'Arial', sans-serif;
//           }
//           .thank-you-container {
//             text-align: center;
//             background: rgba(255, 255, 255, 0.1); /* Slightly opaque white background */
//             padding: 4rem;
//             border-radius: 20px;
//             box-shadow: 0 4px 15px rgba(0,0,0,0.2);
//             max-width: 800px;
//             width: 100%;
//             transition: transform 0.5s, box-shadow 0.5s;
//             ${scaleUpAnimation}
//           }
//           .thank-you-container h1 {
//             font-size: 3.5rem;
//             color: #fff; /* White text color */
//             margin-bottom: 1.5rem;
//           }
//           .thank-you-container p {
//             font-size: 1.5rem;
//             color: #ccc; /* Light text color */
//           }
//         `}
//       </style>
//       <div style={backgroundStyle}>
//         <div className="thank-you-container" style={fadeInAnimation}>
//           <h1>Thank you for submitting your application!</h1>
//           {/* <p>We'll get back to you as soon as possible.</p> */}
//         </div>
//       </div>
//     </React.Fragment>
//   );
// };

// export default Thankyoumsg;


import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { dashboardCommonapi } from "../services/api/api";
import { Link, NavLink } from "react-router-dom";

const Thankyoumsg = () => {
  const navigate = useNavigate();
  const [loaded, setLoaded] = useState(false);


  useEffect(() => {
    // Prevent back navigation
    const handlePopState = (event) => {
      event.preventDefault();
      navigate("/", { replace: true });
    };

    window.history.pushState(null, null, window.location.href);
    window.addEventListener("popstate", handlePopState);

    setLoaded(true);

    return () => {
      window.removeEventListener("popstate", handlePopState);
    };
  }, [navigate]);

  const fadeInAnimation = {
    animation: "fadeIn 1s ease-in-out",
    animationFillMode: "forwards"
  };

  const scaleUpAnimation = {
    transform: loaded ? "scale(1)" : "scale(0)",
    transition: "transform 0.5s"
  };

  const backgroundStyle = {
    background: "linear-gradient(to bottom, #cdecff, #a2c8ff)", /* Gradient from very light blue to slightly darker blue */
    height: "100vh",
    display: "flex",
    justifyContent: "center",
    alignItems: "center"
  };
  const iconStyle = {
    marginRight: "0.5rem", 
    color: "#4a9f56" 
  };
  

  const [logoUrl, setLogoUrl] = useState('');
  useEffect(() => {
    fetchLogo();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  

  const fetchLogo = async () => {
    try {
      const response = await dashboardCommonapi();
      const logoUrl = response.logo_view;
      setLogoUrl(logoUrl);
    } catch (error) {
      console.error("Error fetching logo:", error);
    } finally {
      setLoaded(true);
    }
  };

 


 

 

  
  
  

  return (
    <React.Fragment>
      <style>
        {`
          @keyframes fadeIn {
            0% { opacity: 0; transform: translateY(-20px); }
            100% { opacity: 1; transform: translateY(0); }
          }
          body, html {
            margin: 0;
            font-family: 'Arial', sans-serif;
          }
          .thank-you-container {
            text-align: center;
            background: #fff; /* White background */
            padding: 4rem;
            border-radius: 20px;
            box-shadow: 0 4px 15px rgba(0,0,0,0.2);
            max-width: 800px;
            width: 100%;
            transition: transform 0.5s, box-shadow 0.5s;
            ${scaleUpAnimation}
          }
          .thank-you-container h1 {
            font-size: 2.5rem;
            color: #333; /* Dark text color */
            margin-bottom: 1.5rem;
          }
          .thank-you-container p {
            font-size: 1.5rem;
            color: #666; /* Medium text color */
          }
        `}
      </style>
     
      <div style={backgroundStyle}>
  <div className="thank-you-container" style={fadeInAnimation}>
    <Link to="" className="sidebar-logo">
      {logoUrl ? (
        <img src={logoUrl} style={{ width: "200px", marginBottom: "20px" }} alt="Company Logo" />
      ) : (
        <img src="/payroll.png" style={{ width: "150px", marginBottom: "20px" }} alt="Default Logo" />
      )}
    </Link>
    <h1 style={{ marginBottom: "20px" }}>Thank you for submitting your application <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-emoji-smile" viewBox="0 0 16 16" style={iconStyle}>
      <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14m0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16"/>
      <path d="M4.285 9.567a.5.5 0 0 1 .683.183A3.5 3.5 0 0 0 8 11.5a3.5 3.5 0 0 0 3.032-1.75.5.5 0 1 1 .866.5A4.5 4.5 0 0 1 8 12.5a4.5 4.5 0 0 1-3.898-2.25.5.5 0 0 1 .183-.683M7 6.5C7 7.328 6.552 8 6 8s-1-.672-1-1.5S5.448 5 6 5s1 .672 1 1.5m4 0c0 .828-.448 1.5-1 1.5s-1-.672-1-1.5S9.448 5 10 5s1 .672 1 1.5"/>
    </svg>
      !</h1>
    {/* <p>We'll get back to you as soon as possible.</p> */}
   
    
    
    <span><a href="http://my.windsonpayroll.com">windsonpayroll.com</a> &nbsp;© 2024 All Rights Reserved</span>
  </div>
</div>

    </React.Fragment>
  );
};

export default Thankyoumsg;

