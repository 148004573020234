import React, { useState } from 'react';
import Header from '../../layouts/Header';
import { Link } from 'react-router-dom';

const RoleForm = () => {
  const [formData, setFormData] = useState({
    name: '',
    status: 'active',
    webLogin: '',
    appLogin: '',
  });

  const [formErrors, setFormErrors] = useState({});

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const validateForm = () => {
    const errors = {};
    if (!formData.name) errors.name = 'Name is required.';
    // You can add additional validation rules for webLogin and appLogin here
    setFormErrors(errors);
    return Object.keys(errors).length === 0;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (validateForm()) {
      // Form is valid, you can submit it to your server or perform any desired action
      console.log(formData);
    }
  };

  return (
    <>
      <Header />
      <div className="main p-4 p-lg-5 mt-5">
        <h4>Add Role</h4>
        <div className="breadcrumb-warning d-flex justify-content-between ot-card">
          <div>
            <ol className="breadcrumb ot-breadcrumb ot-breadcrumb-basic">
              <li className="breadcrumb-item">
                <Link to="/dashboard/helpdesk">Dashboard</Link>
              </li>
              <li className="breadcrumb-item active">
                <Link to="/pages//hr/role">Role</Link>
              </li>
              <li className="breadcrumb-item active">Add Role</li>
            </ol>
          </div>
          <nav aria-label="breadcrumb">
            <Link to="/pages/hr/role" className="btn btn-primary mb-3">
              <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-arrow-left" viewBox="0 0 16 16">
                <path fillRule="evenodd" d="M15 8a.5.5 0 0 0-.5-.5H2.707l3.147-3.146a.5.5 0 1 0-.708-.708l-4 4a.5.5 0 0 0 0 .708l4 4a.5.5 0 0 0 .708-.708L2.707 8.5H14.5A.5.5 0 0 0 15 8z" />
              </svg>
              Back
            </Link>
          </nav>
        </div>

        <div
          className="row"
          style={{
            boxShadow: '0 4px 8px 0 rgba(0,0,0,0.2)',
            borderRadius: '10px',
            overflow: 'hidden',
          }}
        >
          <div className="col-md-12">
            <div className="bg-white p-4 rounded" style={{ height: '100%' }}>
              <form onSubmit={handleSubmit}>
                <div className="mb-3">
                  <label htmlFor="name" className="form-label">
                    Name
                  </label>
                  <input
                    type="text"
                    className={`form-control ${formErrors.name && 'is-invalid'}`}
                    id="name"
                    name="name"
                    value={formData.name}
                    onChange={handleInputChange}
                    placeholder="Enter name"
                  />
                  {formErrors.name && (
                    <div className="invalid-feedback">{formErrors.name}</div>
                  )}
                </div>
                <div className="mb-3">
                  <label htmlFor="status" className="form-label">
                    Status
                  </label>
                  <select
                    className="form-select"
                    id="status"
                    name="status"
                    value={formData.status}
                    onChange={handleInputChange}
                  >
                    <option value="active">Active</option>
                    <option value="inactive">Inactive</option>
                  </select>
                </div>
                <div className="mb-3">
                  <label htmlFor="webLogin" className="form-label">
                    WebLogin
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="webLogin"
                    name="webLogin"
                    value={formData.webLogin}
                    onChange={handleInputChange}
                    placeholder="Enter web login details"
                  />
                </div>
                <div className="mb-3">
                  <label htmlFor="appLogin" className="form-label">
                    AppLogin
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="appLogin"
                    name="appLogin"
                    value={formData.appLogin}
                    onChange={handleInputChange}
                    placeholder="Enter app login details"
                  />
                </div>
                <button type="submit" className="btn btn-primary">
                  Submit
                </button>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default RoleForm;
