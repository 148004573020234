import React, { useState, useEffect } from 'react';
import _debounce from 'lodash/debounce';
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import { getId, searchEmployeeBreakCount, singleViewEmployeeAttendance } from '../../services/api/api';
import HeaderMobile from '../../layouts/HeaderMobile';
import Header from '../../layouts/Header';
import { Card, CardBody, Button, Form, Row, Col, Spinner } from "react-bootstrap";
import { Link } from "react-router-dom";

const EmployeeBreakCalendar = () => {
    const [events, setEvents] = useState([]);
    const [searchParams, setSearchParams] = useState({
        year: new Date().getFullYear(),
        month: new Date().getMonth() + 1, // Months are 0-indexed, so adding 1
    });
    const [loading, setLoading] = useState(false);

    // useEffect(() => {
    //     fetchEmployeeBreakCount();
    // }, [searchParams]);
    useEffect(() => {
        const debouncedFetchEmployeeBreakCount = _debounce(fetchEmployeeBreakCount, 0);

        debouncedFetchEmployeeBreakCount();

        return () => {
            debouncedFetchEmployeeBreakCount.cancel();
        };
    }, [searchParams]);

    const fetchEmployeeBreakCount = async () => {
        try {
            setLoading(true);
            const employeeId = getId();
            const apiResponse = await searchEmployeeBreakCount(
                employeeId,
                searchParams.year,
                searchParams.month
            );
            console.log(apiResponse);
            const parsedEvents = parseApiResponse(apiResponse);
            setEvents(parsedEvents);
        } catch (error) {
            console.error('Error fetching employee break count:', error);
        } finally {
            setLoading(false);
        }
    };

    const handleSearch = (e) => {
        e.preventDefault();
        fetchEmployeeBreakCount();
    };

    const handleYearChange = (e) => {
        setSearchParams({ ...searchParams, year: e.target.value });
    };

    const handleMonthChange = (e) => {
        setSearchParams({ ...searchParams, month: e.target.value });
    };

    // const parseApiResponse = (apiResponse) => {
    //     const formattedEvents = {};

    //     apiResponse.data.forEach((employeeData) => {
    //         employeeData.break_list.forEach((breakItem) => {
    //             for (const date in breakItem.attendance) {
    //                 if (breakItem.attendance.hasOwnProperty(date)) {
    //                     const attendanceData = breakItem.attendance[date];
    //                     const breaks = attendanceData.breaks || [];

    //                     let inBreakTime = null;
    //                     let totalDuration = 0;

    //                     breaks.forEach((breakInfo) => {
    //                         const breakType = breakInfo.type;
    //                         const breakTime = breakInfo.time;
    //                         const duration = breakInfo.duration_minutes || 0;
    //                         if (duration === 0) {
    //                             duration = 1;
    //                         }
    //                         if (breakType === "IN") {
    //                             inBreakTime = breakTime;
    //                         } else if (breakType === "OUT" && inBreakTime !== null) {
    //                             // Calculate duration only if there was a previous "IN" break
    //                             totalDuration += duration;
    //                         }
    //                     });

    //                     if (inBreakTime !== null) {
    //                         if (!formattedEvents[date]) {
    //                             formattedEvents[date] = {
    //                                 title: `Total Break: ${totalDuration} mins`,
    //                                 date: date,
    //                                 backgroundColor: '#3c57b1',
    //                                 borderColor: '#000000',
    //                                 borderRadius: '5px',
    //                                 durationSum: totalDuration,
    //                                 breakTime: inBreakTime,
    //                             };
    //                         } else {
    //                             formattedEvents[date].durationSum += totalDuration;
    //                         }
    //                     }
    //                 }
    //             }
    //         });
    //     });

    //     return Object.values(formattedEvents);
    // };


    const parseApiResponse = (apiResponse) => {
        const formattedEvents = {};

        apiResponse.data.forEach((employeeData) => {
            employeeData.break_list.forEach((breakItem) => {
                for (const date in breakItem.attendance) {
                    if (breakItem.attendance.hasOwnProperty(date)) {
                        const attendanceData = breakItem.attendance[date];
                        const breaks = attendanceData.breaks || [];

                        let inBreakTime = null;
                        let totalDuration = 0;

                        breaks.forEach((breakInfo) => {
                            const breakType = breakInfo.type;
                            const breakTime = breakInfo.time;
                            let duration = breakInfo.duration_minutes || 0;

                            // If duration is 0, consider it as 1 minute
                            if (duration === 0) {
                                duration = 1;
                            }

                            if (breakType === "IN") {
                                inBreakTime = breakTime;
                            } else if (breakType === "OUT" && inBreakTime !== null) {
                                // Calculate duration only if there was a previous "IN" break
                                totalDuration += duration;
                            }
                        });

                        if (inBreakTime !== null) {
                            if (!formattedEvents[date]) {
                                formattedEvents[date] = {
                                    title: `Total Break: ${totalDuration} mins`,
                                    date: date,
                                    backgroundColor: '#3c57b1',
                                    borderColor: '#000000',
                                    borderRadius: '5px',
                                    durationSum: totalDuration,
                                    breakTime: inBreakTime,
                                };
                            }
                            else {
                                formattedEvents[date].durationSum += totalDuration;
                            }
                        }
                    }
                }
            });
        });

        return Object.values(formattedEvents);
    };






    return (
        <React.Fragment>
            {/* Header components */}
            <HeaderMobile />
            <Header />
            <div className="main p-4 p-lg-5 mt-5">
                <div className="mb-4 border-bottom text-center">
                    <h3 className="mb-0 pb- border-bottom custome-btn">Employee Break Report</h3>
                </div>
                <div className="col-md-12">
                    <div className="breadcrumb-warning d-flex justify-content-between ot-card">
                        <div>
                            <ol className="breadcrumb ot-breadcrumb ot-breadcrumb-basic">
                                <li className="breadcrumb-item">
                                    <Link to="/admin/dashboard">Dashboard</Link>
                                </li>
                                <li className="breadcrumb-item active">
                                    <Link to="/employee/breakview">Break Details</Link>
                                </li>
                                <li className="breadcrumb-item active">List</li>
                            </ol>
                        </div>
                    </div>
                    <Card style={{ boxShadow: "0 4px 8px 0 rgba(0,0,0,0.2)", borderRadius: "10px", overflow: "hidden", border: "none" }}>
                        <CardBody>
                            <Form onSubmit={handleSearch}>
                                <Row className="mb-3">
                                    <Col>
                                        <Form.Control
                                            type="number"
                                            placeholder="Year"
                                            value={searchParams.year}
                                            onChange={handleYearChange}
                                        />
                                    </Col>
                                    <Col>
                                        <Form.Control
                                            as="select"
                                            value={searchParams.month}
                                            onChange={handleMonthChange}
                                        >
                                            <option value="1">January</option>
                                            <option value="2">February</option>
                                            <option value="3">March</option>
                                            <option value="4">April</option>
                                            <option value="5">May</option>
                                            <option value="6">June</option>
                                            <option value="7">July</option>
                                            <option value="8">August</option>
                                            <option value="9">September</option>
                                            <option value="10">October</option>
                                            <option value="11">November</option>
                                            <option value="12">December</option>
                                        </Form.Control>
                                    </Col>
                                    <Col>
                                        <Button type="submit">Search</Button>
                                    </Col>
                                </Row>
                            </Form>

                            {loading ? (
                                <Spinner animation="border" role="status">
                                    <span className="visually-hidden">Loading...</span>
                                </Spinner>
                            ) : (
                                <FullCalendar
                                    plugins={[dayGridPlugin]}
                                    initialView="dayGridMonth"
                                    events={events}
                                    eventContent={(eventInfo) => (
                                        <div>
                                            <div>{eventInfo.event.title}</div>
                                            {eventInfo.event.extendedProps.durationSum && (
                                                <div style={{ fontSize: '12px', color: 'white' }}>
                                                    {/* <strong>Total Duration: {eventInfo.event.extendedProps.durationSum} min</strong> */}
                                                </div>
                                            )}
                                        </div>
                                    )}
                                />
                            )}
                        </CardBody>
                    </Card>
                </div>
            </div>
        </React.Fragment>
    );
};

export default EmployeeBreakCalendar;



