import React, { useEffect, useState } from 'react'
import Header from "../../layouts/Header";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { adWorkingDays, basicSetup } from "../../services/api/api"
import { Form, Table } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { Link } from "react-router-dom";

import '../../styles/adduserform.css';

const AddWorkingDays = () => {
    const [monthlyAllowLeave, setMonthlyAllowLeave] = useState('');
    const [submitting, setSubmitting] = useState(false);
    const [monthlyAllowLeaveError, setMonthlyAllowLeaveError] = useState('');
    const validateForm = (state) => {
        let errors = {};
        // Your validation logic here
        return errors;
    }
    const handleMonthlyAllowLeaveChange = (e) => {
        const { value } = e.target;

        // Check if the entered value is numeric
        if (/^\d*$/.test(value) || value === '') {
            // If it's numeric or empty, update the state
            setMonthlyAllowLeave(value);

            // Clear any previous error messages
            setMonthlyAllowLeaveError('');
        } else {
            // If it's not numeric, display an error message
            setMonthlyAllowLeaveError('Please enter numeric values only');
        }
    };

    const navigate = useNavigate();
    const [toggleState, setToggleState] = useState({
        sunday: false,
        monday: false,
        tuesday: false,
        wednesday: false,
        thursday: false,
        friday: false,
        saturday: false,
    });
    const [errors, setErrors] = useState({});

    useEffect(() => {
        const savedData = JSON.parse(sessionStorage.getItem('workingDaysData')) || {};
        setMonthlyAllowLeave(savedData.monthly_allow_leave || '');

        // Set the initial state for toggle buttons
        setToggleState((prevState) => {
            const newState = { ...prevState };

            Object.keys(savedData)
                .filter((key) => key !== 'monthly_allow_leave')
                .forEach((key) => {
                    // Parse boolean values for toggle states
                    newState[key] = savedData[key] === true;
                });

            return newState;
        });
    }, []);

    const handleAccessChange = (day, checked) => {
        setToggleState((prevState) => ({
            ...prevState,
            [day]: checked,
        }));
    };
    const handleSubmit = async (e) => {
        e.preventDefault();
        // if (submitting) {

        //     return;
        // }
        const validationErrors = validateForm();
        if (Object.keys(validationErrors).length > 0) {
            setErrors(validationErrors);
            return;
        }
        // alert('Workig days submitted')
        setSubmitting(true);
        try {
            const dataToSend = {
                ...toggleState,
                monthly_allow_leave: monthlyAllowLeave || '',
            };
            sessionStorage.setItem('workingDaysData', JSON.stringify(dataToSend));
            console.log('Request Payload:', JSON.stringify(dataToSend));
            const response = await adWorkingDays(dataToSend);


            // console.log(data);
            // console.log(data.success);
            // console.log(data.message);
            if (response.message) {
                toast.success(response.message, {
                    position: 'top-center',
                    autoClose: 5000,
                });

                setSubmitting(false);

                const storedSetupData = JSON.parse(sessionStorage.getItem('setupData'));
                const updateProfileData = {
                    ...storedSetupData, // Spread the stored values
                    workingDays: "true", // Update companyProfile field
                };
                console.log(updateProfileData);
                const secondResponse = await basicSetup(updateProfileData);
                console.log(secondResponse);
                // navigate('pages/workingdays')
                // navigate('/admin/dashboard');
            }
            else {

                toast.warning(response.message, {
                    position: 'top-center',
                    autoClose: 1000,
                });
            }
        } catch (error) {
            // alert(  'aaaaa' );

            toast.success('Working Day Added successfully', {
                position: 'top-right',
                autoClose: 1000,
            });
            setSubmitting(false);
        }
        // navigate('/pages/workingdays/addworkingdays');
    };




    return (
        <React.Fragment>
            <Header />
            <div className="main p-4 p-lg-5 mt-5">
                <div className="mb-4 border-bottom text-center">
                    <h3 className="mb-0 pb- border-bottom custome-btn">Add Workingdays</h3>
                </div>
                <div className="breadcrumb-warning d-flex justify-content-between ot-card">
                    <div>
                        <ol className="breadcrumb ot-breadcrumb ot-breadcrumb-basic">
                            <li className="breadcrumb-item">
                                <Link to="/admin/dashboard">Dashboard</Link>
                            </li>
                            <li className="breadcrumb-item active">
                                <Link to="/pages/workingdays">Working Days</Link>
                            </li>
                            <li className="breadcrumb-item active">Add Working Days</li>
                        </ol>
                    </div>
                    <nav aria-label="breadcrumb">
                        <Link to="/pages/workingdays/viewworkingdays" className="btn btn-primary mb-3">
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-arrow-left" viewBox="0 0 16 16">
                                <path fillRule="evenodd" d="M15 8a.5.5 0 0 0-.5-.5H2.707l3.147-3.146a.5.5 0 1 0-.708-.708l-4 4a.5.5 0 0 0 0 .708l4 4a.5.5 0 0 0 .708-.708L2.707 8.5H14.5A.5.5 0 0 0 15 8z" />
                            </svg>
                            Back
                        </Link>
                    </nav>
                </div>
                <div className="row"
                    style={{
                        boxShadow: "0 4px 8px 0 rgba(0,0,0,0.2)",
                        borderRadius: "10px",
                        overflow: "hidden",
                    }}
                >
                    <div className="col-md-12">
                        <div className="bg-white p-4 rounded" style={{ height: "100%" }}>
                            <Table striped bordered hover>
                                <thead>
                                    <tr>
                                        <th>Working Day</th>
                                        <th>Value</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {Object.keys(toggleState).map((day, index) => (
                                        <tr key={index}>
                                            <td>{day.charAt(0).toUpperCase() + day.slice(1)}</td>
                                            <td>
                                                <Form.Check
                                                    type="switch"
                                                    id={day}
                                                    label={day.charAt(0).toUpperCase() + day.slice(1)}
                                                    checked={toggleState[day]}
                                                    onChange={(e) => handleAccessChange(day, e.target.checked)}
                                                />
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </Table>
                            <div className='mt-3 text-dark'>
                                <label>Monthly Allow Leave</label>
                                <input
                                    className='ms-3'
                                    type='text'
                                    name='monthly_allow_leave'
                                    value={monthlyAllowLeave}
                                    onChange={handleMonthlyAllowLeaveChange}
                                />
                                {monthlyAllowLeaveError && (
                                    <div className="invalid-feedback">{monthlyAllowLeaveError}</div>
                                )}
                            </div>
                            <div className="text-center mt-4">
                                <button type="submit" className="btn btn-primary" onClick={handleSubmit}
                                    disabled={submitting}>

                                    {submitting ? 'Submitting...' : 'Submit'}
                                </button>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </React.Fragment>
    );
};

export default AddWorkingDays
