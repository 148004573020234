
import React, { Component } from "react";
import { Link, NavLink } from "react-router-dom";
import PerfectScrollbar from "react-perfect-scrollbar";
import userAvatar from "../assets/img/img1.jpg";
import {
    dashboardMenu,
    employeedashboardMenu,
    admindashboardmenu,
    applicationsMenu,
    pagesMenu,
    salary
    // uiElementsMenu
} from "../data/Menu";
import { dashboardCommonapi, viewLogo } from "../services/api/api";

export default class Sidebar extends Component {

    toggleFooterMenu = (e) => {
        e.preventDefault();

        let parent = e.target.closest(".sidebar");
        parent.classList.toggle("footer-menu-show");
    }




    // state = {
    //     logoUrl: null,
    //     isLogoFetched: false,
    //   };

    //   componentDidMount() {
    //     if (!this.state.isLogoFetched) {
    //       this.fetchLogo();
    //     }
    //   }

    //   fetchLogo = async () => {
    //     debugger
    //     try {
    //       const response = await dashboardCommonapi();
    //       const logoUrl = response.logo_view;
    //       this.setState({ logoUrl, isLogoFetched: true });
    //     } catch (error) {

    //     }
    //   };
    state = {
        logoUrl: null,
        isLogoFetched: false,
        logoVisible: false
    };

    componentDidMount() {
        this.fetchLogo();
    }

    componentDidUpdate(prevProps, prevState) {
        // If logoUrl has changed, you can perform actions like force re-render or log a message.
        if (prevState.logoUrl !== this.state.logoUrl) {
            console.log("Logo URL has changed. Re-rendering Sidebar...");
            // You can force update if needed, but React handles re-rendering automatically
        }
    }

    //   componentDidMount() {
    //     if (sessionStorage.getItem("userType") !== "employee") {
    //     this.fetchLogo();
    //   }


    fetchLogo = async () => {
        if (this.fetchingLogo) {
            return;
        }
        try {
            this.fetchingLogo = true;
            const response = await dashboardCommonapi();
            const logoUrl = response.logo_view;
            this.setState({ logoUrl, isLogoFetched: true });
        } catch (error) {
        } finally {
            this.fetchingLogo = false;
        }
    };



    render() {
        const { logoUrl } = this.state;

        return (
            <div className="sidebar">
                {/* <div className="sidebar-header">
                   
                    {logoUrl && <img src={logoUrl} style={{ width: "150px" }} alt="Company Logo" />}
                   
                </div> */}
                <div className="sidebar-header">
                    {/* <Link to="" className="sidebar-logo"> */}
                    {/* <Link to="" className="">
                        {logoUrl ? (
                            <img src={logoUrl} style={{ width: "25%" }} alt="Company Logo" />
                        ) : (
                            <div className="">

                                <img src="" style={{ width: "25%" }} alt="Company Logo" />
                            // <img src="/payroll.png" style={{ width: "50%" }} alt="Default Logo" />
                            </div>
                        )}
                    </Link> */}

                    <Link to="" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                        {logoUrl ? (
                            <img src={logoUrl} style={{ width: "43%", padding: "5%" }} alt="Company Logo" />
                        ) : (
                            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                <img src="" style={{ width: "43%" , padding: "5%"}} alt="Company Logo" />
                            </div>
                        )}
                    </Link>
                </div>



                <PerfectScrollbar className="sidebar-body" ref={ref => this._scrollBarRef = ref}>
                    <SidebarMenu onUpdateSize={() => this._scrollBarRef.updateScroll()} />
                </PerfectScrollbar>
                {/* <div className="sidebar-footer">
                     <div className="sidebar-footer-top">
                         <div className="sidebar-footer-thumb">
                             <img src={userAvatar} alt="" />
                         </div>
                         <div className="sidebar-footer-body">
                             <h6><Link to="../pages/profile.html">Shaira Diaz</Link></h6>
                             <p>Premium Member</p>
                         </div>
                         <Link onClick={this.toggleFooterMenu} to="" className="dropdown-link"><i className="ri-arrow-down-s-line"></i></Link>
                     </div>
                     <div className="sidebar-footer-menu">
                         <nav className="nav">
                             <Link to=""><i className="ri-edit-2-line"></i> Edit Profile</Link>
                             <Link to=""><i className="ri-profile-line"></i> View Profile</Link>
                             </nav>
                         <hr />
                         <nav className="nav">
                             <Link to=""><i className="ri-question-line"></i> Help Center</Link>
                             <Link to=""><i className="ri-lock-line"></i> Privacy Settings</Link>
                             <Link to=""><i className="ri-user-settings-line"></i> Account Settings</Link>
                             <Link to=""><i className="ri-logout-box-r-line"></i> Log Out</Link>
                         </nav>
                     </div>
                 </div> */}
            </div>
        )
    }
}

class SidebarMenu extends Component {
    populateMenu = (m) => {
        const menu = m.map((m, key) => {
            let sm;
            if (m.submenu) {
                sm = m.submenu.map((sm, key) => {
                    return (
                        <NavLink to={sm.link} className="nav-sub-link" key={key}>{sm.label}</NavLink>
                    )
                })
            }

            return (
                <li key={key} className="nav-item">
                    {(!sm) ? (
                        <NavLink to={m.link} className="nav-link"><i className={m.icon}></i> <span>{m.label}</span></NavLink>
                    ) : (
                        <div onClick={this.toggleSubMenu} className="nav-link has-sub"><i className={m.icon}></i> <span>{m.label}</span></div>
                    )}
                    {m.submenu && <nav className="nav nav-sub">{sm}</nav>}
                </li>
            )
        });

        return (
            <ul className="nav nav-sidebar">
                {menu}
            </ul>
        );
    }

    // Toggle menu group
    toggleMenu = (e) => {
        e.preventDefault();

        let parent = e.target.closest('.nav-group');
        parent.classList.toggle('show');

        this.props.onUpdateSize();
    }

    // Toggle submenu while closing siblings' submenu
    toggleSubMenu = (e) => {
        e.preventDefault();

        let parent = e.target.closest('.nav-item');
        let node = parent.parentNode.firstChild;

        while (node) {
            if (node !== parent && node.nodeType === Node.ELEMENT_NODE)
                node.classList.remove('show');
            node = node.nextElementSibling || node.nextSibling;
        }

        parent.classList.toggle('show');

        this.props.onUpdateSize();
    }







    // sidebar menu logic dipali start
    state = {
        isEmployeeDashboard: window.location.pathname === '/employee/dashboard' || '/edit_emp',
        isAdminDashboard: window.location.pathname === '/admin/dashboard' || '/pages/user/viewuser' || '/edit_employee/'
            || '/edit_designation/' || '/edit_department/' || '/edit_holiday/' || '/edit_branch/' || '/edit_shift/' || '/edit_user/'
            || '/edit_resignation/' || '/edit_announcement/' || '/companyprofile' || 'edit_workingday' || '/admin/edit_salary_setup/' || '/edit_empattendance',

        // showEmployeeMenu: true,
        // showAdminMenu:true,
        // showEmployeeMenu: window.location.pathname === '/employee/dashboard' || '/breakreport',
        // showAdminMenu: window.location.pathname !== '/employee/dashboard',
        // showEmployeeMenu: window.location.pathname !== '/admin/dashboard',






        isEmployeeDashboard: window.location.pathname === '/employee/dashboard' || window.location.pathname === '/breakreport' || window.location.pathname === '/empleave/addempleaves'
            || window.location.pathname === '/pages/attendance' || window.location.pathname === '/salary'
            || window.location.pathname === '/pages/hr/resignationform'
            || window.location.pathname === '/pages/filemanager'
            || window.location.pathname === '/employee/changepassword'
            || window.location.pathname === '/edit_emp'
            || window.location.pathname === '/employee/profile'
            || window.location.pathname === '/employee/empbreaktime'
            || window.location.pathname === '/employee/break'
            || window.location.pathname === '/calender'
            || window.location.pathname === '/employee/attendanceview'
            || window.location.pathname === '/employee/attendance'
            || window.location.pathname === '/employee/addleave'
            || window.location.pathname === '/emoloyee/resignation'
            || window.location.pathname === '/employee/breakview'
            || window.location.pathname === '/employee/salaryreport',





        isAdminDashboard: window.location.pathname !== '/employee/dashboard' && window.location.pathname !== '/breakreport' && window.location.pathname !== '/empleave/addempleaves'
            && window.location.pathname !== '/pages/attendance' && window.location.pathname !== '/salary'
            && window.location.pathname !== '/pages/hr/resignationform'
            && window.location.pathname !== '/pages/filemanager'
            && window.location.pathname !== '/employee/changepassword'
            && window.location.pathname !== '/edit_emp'
            && window.location.pathname !== '/employee/profile'
            && window.location.pathname !== '/employee/empbreaktime'
            && window.location.pathname !== '/employee/break'
            && window.location.pathname !== '/calender'
            && window.location.pathname !== '/employee/attendanceview'
            && window.location.pathname !== '/employee/attendance'
            && window.location.pathname !== '/employee/addleave'
            && window.location.pathname !== '/emoloyee/resignation'
            && window.location.pathname !== '/employee/breakview'
            && window.location.pathname !== '/employee/salaryreport',





        showEmployeeMenu: window.location.pathname === '/employee/dashboard' || window.location.pathname === '/breakreport' || window.location.pathname === '/empleave/addempleaves'
            || window.location.pathname === '/pages/attendance' || window.location.pathname === '/salary'
            || window.location.pathname === '/pages/hr/resignationform'
            || window.location.pathname === '/pages/filemanager'
            || window.location.pathname === '/employee/changepassword'
            || window.location.pathname === '/edit_emp'
            || window.location.pathname === '/employee/profile'
            || window.location.pathname === '/employee/empbreaktime'
            || window.location.pathname === '/employee/break'
            || window.location.pathname === '/calender'
            || window.location.pathname === '/employee/attendanceview'
            || window.location.pathname === '/employee/attendance'
            || window.location.pathname === '/employee/addleave'
            || window.location.pathname === '/emoloyee/resignation'
            || window.location.pathname === '/employee/breakview'
            || window.location.pathname === '/employee/salaryreport',





        showAdminMenu: window.location.pathname !== '/employee/dashboard' && window.location.pathname !== '/breakreport' && window.location.pathname !== '/empleave/addempleaves'
            && window.location.pathname !== '/pages/attendance' && window.location.pathname !== '/salary'
            && window.location.pathname !== '/pages/hr/resignationform'
            && window.location.pathname !== '/pages/filemanager'
            && window.location.pathname !== '/employee/changepassword'
            && window.location.pathname !== '/edit_emp'
            && window.location.pathname !== '/emp/profile'
            && window.location.pathname !== '/employee/empbreaktime'
            && window.location.pathname !== '/employee/break'
            && window.location.pathname !== '/calender'
            && window.location.pathname !== '/employee/attendanceview'
            && window.location.pathname !== '/employee/attendance'
            && window.location.pathname !== '/employee/addleave'
            && window.location.pathname !== '/emoloyee/resignation'
            && window.location.pathname !== '/employee/breakview'
            && window.location.pathname !== '/employee/salaryreport',



    };



    toggleMenu = () => {
        this.setState(prevState => ({
            showEmployeeMenu: !prevState.showEmployeeMenu,
            showAdminMenu: !prevState.showAdminMenu,
        }));
    };


    // sidebar menu logic dipali end



    render() {
        const { isEmployeeDashboard, showEmployeeMenu } = this.state;
        const { isAdminDashboard, showAdminMenu } = this.state;





        return (

            <React.Fragment>
                {/* <div className="nav-group show">
                     <div className="nav-label" onClick={this.toggleMenu}>Recruitment</div>
                     {this.populateMenu(dashboardMenu)}
                 </div>  */}
                {isAdminDashboard && (
                    <div className={`nav-group ${showAdminMenu ? 'show' : ''}`}>
                        {/* <div className="nav-label" onClick={this.toggleMenu}>Recruitment</div> */}
                        <div className="nav-menu">
                            {this.populateMenu(admindashboardmenu)}
                        </div>
                    </div>
                )}
                {isEmployeeDashboard && (
                    <div className={`nav-group ${showEmployeeMenu ? 'show' : 'hid'}`}>
                        {/* <div className="nav-label" onClick={this.toggleMenu}>Recruitment</div> */}
                        {this.populateMenu(employeedashboardMenu)}
                    </div>
                )}


                {/* <div className="nav-group show">
                     <div className="nav-label" onClick={this.toggleMenu}>Dashboard</div>
                     {this.populateMenu(dashboardMenu)}
                 </div> */}

                {/* <div className="nav-group show">
                     <div className="nav-label" onClick={this.toggleMenu}>Recrument</div>
                     {this.populateMenu(applicationsMenu)}
                 </div> */}
                {/* <div className="nav-group show">
                     <div className="nav-label" onClick={this.toggleMenu}>Leave</div>
                     {this.populateMenu(pagesMenu)}
                 </div> */}
                {/* <div className="nav-group show">
                     <div className="nav-label" onClick={this.toggleMenu}>Settings</div>
                     {this.populateMenu(salary)}
                 </div> */}
                {/* <div className="nav-group show">
                     <div className="nav-label" onClick={this.toggleMenu}>Attandance & Break</div>
                     {this.populateMenu(uiElementsMenu)}
                 </div> */}
                {/* <div className="nav-group show">
                     <div className="nav-label" onClick={this.toggleMenu}>Recrument</div>
                     {this.populateMenu(uiElementsMenu)}
                 </div> */}
                {/* <div className="nav-group show">
                     <div className="nav-label" onClick={this.toggleMenu}>Leave</div>
                     {this.populateMenu(uiElementsMenu)}
                 </div> */}
                {/* <div className="nav-group show">
                     <div className="nav-label" onClick={this.toggleMenu}>File Manager</div>
                     {this.populateMenu(uiElementsMenu)}
                 </div> */}
                {/* <div className="nav-group show">
                     <div className="nav-label" onClick={this.toggleMenu}>Settings</div>
                     {this.populateMenu(uiElementsMenu)}
                 </div> */}
            </React.Fragment>
        )
    }
}

window.addEventListener("click", function (e) {
    // Close sidebar footer menu when clicked outside of it
    let tar = e.target;
    let sidebar = document.querySelector(".sidebar");
    if (!tar.closest(".sidebar-footer") && sidebar) {
        sidebar.classList.remove("footer-menu-show");
    }

    // Hide sidebar offset when clicked outside of sidebar
    if (!tar.closest(".sidebar") && !tar.closest(".menu-link")) {
        document.querySelector("body").classList.remove("sidebar-show");
    }
});

window.addEventListener("load", function () {
    let skinMode = localStorage.getItem("sidebar-skin");
    let HTMLTag = document.querySelector("html");

    if (skinMode) {
        HTMLTag.setAttribute("data-sidebar", skinMode);
    }
});