import React, { useState } from "react";
import Header from "../../layouts/Header";
import HeaderMobile from "../../layouts/HeaderMobile";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { addDepartment } from "../../services/api/api";
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import { Button } from "react-bootstrap";

const EmpDepartmentForm = () => {
  const navigate = useNavigate();
  const [state, setState] = useState({
    department_name: "",
  });

  const [errors, setErrors] = useState({});

  const handleChange = (e) => {
    const { name, value } = e.target;
    setState((prevState) => ({
      ...prevState,
      [name]: value,
    }));
    setErrors((prevErrors) => ({
      ...prevErrors,
      [name]: "",
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const validationErrors = validateForm(state);
    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
      return;
    }
 






      try {
        const response = await addDepartment(state);
        const data = JSON.parse(response);
        if (data.success === true) {
          toast.success(data.message, {
            position: "top-center",
            autoClose: 5000,
          });
        } else {
          toast.warning(data.message, {
            position: "top-center",
            autoClose: 5000,
          });
        }
      } catch (error) {
        toast.success("Department Added successfully", {
          position: "top-right",
          autoClose: 3000,
        });
        navigate("/pages/employee/employeeform");
      }
      
      
  };

  const validateForm = (state) => {
    let errors = {};

    if (!state.department_name) {
      errors.department_name = "Department name is required";
    }

    return errors;
  };

  return (
    <React.Fragment>
        <HeaderMobile />
        <Header />
        <div className="main p-4 p-lg-5 mt-5">
            <h4>Add Department</h4>
            <div className="breadcrumb-warning d-flex justify-content-between ot-card">
                <div>
                    <ol className="breadcrumb ot-breadcrumb ot-breadcrumb-basic">
                        <li className="breadcrumb-item">
                            <Link to="/admin/dashboard">Dashboard</Link>
                        </li>
                        <li className="breadcrumb-item active">
                            <Link to="/pages/department">Department</Link>
                        </li>
                        <li className="breadcrumb-item active">Add Department</li>
                    </ol>
                </div>
                <nav aria-label="breadcrumb">
                    <Link to="/pages/employee/employeeform" className="btn btn-primary mb-3">
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-arrow-left" viewBox="0 0 16 16">
                            <path fillRule="evenodd" d="M15 8a.5.5 0 0 0-.5-.5H2.707l3.147-3.146a.5.5 0 1 0-.708-.708l-4 4a.5.5 0 0 0 0 .708l4 4a.5.5 0 0 0 .708-.708L2.707 8.5H14.5A.5.5 0 0 0 15 8z" />
                        </svg>
                        Back
                    </Link>
                </nav>
            </div>
            <div className="row"
                style={{
                    boxShadow: "0 4px 8px 0 rgba(0,0,0,0.2)",
                    borderRadius: "10px",
                    overflow: "hidden",
                }}
            >


                <div className="col-md-12">
                    <div className="bg-white p-4 rounded" style={{ height: "100%" }}>
                    <form onSubmit={handleSubmit}>
                    <div className="mb-3">
                        <label htmlFor="department_name" className="form-label">
                            Department Name
                        </label>
                        <input
                            type="text"
                            className={`form-control ${errors.department_name && "is-invalid"}`}
                            id="department_name"
                            name="department_name"
                            value={state.department_name}
                            onChange={handleChange}
                        />
                        {errors.department_name && (
                            <div className="invalid-feedback">{errors.department_name}</div>
                        )}
                    </div>
                    {/* Other department fields can be added here */}
                    <Button variant="primary" type="submit">
                        Submit
                    </Button>
                </form>
                    </div>
                </div>
            </div>
        </div>
    </React.Fragment>
);
};

export default EmpDepartmentForm;
