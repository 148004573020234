import React, { useEffect, useState } from 'react'
import HeaderMobile from '../../../layouts/HeaderMobile'
import Header from '../../../layouts/Header'
import { useNavigate } from 'react-router-dom';
import { Card, Button, Modal, Row, Col, Form, Table } from "react-bootstrap";
import { TextField, Tooltip } from '@mui/material';
import { viewemployeeleavepaggination, viewperemployeeleavepaggination } from '../../../services/api/api';
import { DataGrid } from '@mui/x-data-grid';

const EmpLeaveStatus = () => {
    const navigate = useNavigate();
    const [selectedDate, setSelectedDate] = useState({
        year: new Date().getFullYear(),  // Set current year as initial value
        month: new Date().getMonth() + 1  // Set current month (1-12) as initial value
    });
    const [years, setYears] = useState([]);
    const [months, setMonths] = useState([]);
    const [loading, setLoading] = useState(true);
    let id = sessionStorage.getItem('id')

    console.log(id);

    const [totalPages, setTotalPages] = useState(1);
    const [page, setPage] = useState(1);
    const [pageSize, setpageSize] = useState(100);
    const [masterId, setMasterId] = useState();
    const [employeeDataData, setAddEmployeeDataData] = useState([]);
    const [searchText, setSearchText] = useState('');

    const handlePageChange = (event) => {
        const newPage = parseInt(event.target.value, 10);
        setPage(newPage);
        console.log('Selected Page:', newPage);
    };

    const handlePageSizeChange = (event) => {
        console.log('Page Change Event:', event);
        const newPageSize = parseInt(event.target.value, 10);
        setpageSize(newPageSize);
        console.log('Selected Page Size:', newPageSize);
    };

    useEffect(() => {

        const generatedYears = Array.from({ length: 31 }, (_, i) => 2020 + i);
        setYears(generatedYears);

        const monthNames = [
            { name: 'january', number: 1 },
            { name: 'february', number: 2 },
            { name: 'march', number: 3 },
            { name: 'april', number: 4 },
            { name: 'may', number: 5 },
            { name: 'june', number: 6 },
            { name: 'july', number: 7 },
            { name: 'august', number: 8 },
            { name: 'september', number: 9 },
            { name: 'october', number: 10 },
            { name: 'november', number: 11 },
            { name: 'december', number: 12 },
        ];
        setMonths(monthNames);
    }, []);

    useEffect(() => {
        fetchData();
    }, [selectedDate.year, selectedDate.month, page, pageSize])


    const fetchData = async () => {
        const currentDate = new Date();
        const currentYear = currentDate.getFullYear();
        const currentMonth = currentDate.getMonth() + 1; // getMonth() returns 0-11

        // Use selected date or current date
        const yearToUse = selectedDate.year || currentYear;
        const monthToUse = selectedDate.month || currentMonth;

        let toastDisplayed = false;

        console.log(`Fetching data for Year: ${yearToUse}, Month: ${monthToUse}`);

        try {
            setLoading(true)
            console.log(toastDisplayed);

            const formattedData = await viewperemployeeleavepaggination(page, pageSize, yearToUse, monthToUse, id,);
            console.log(formattedData);

            if (formattedData.data && formattedData.data.length > 0) {
                const employeeLeaveData = formattedData.data.map((item, index) => {
                    const employeeLeave = item.employee_leave || {};

                    return {
                        id: item._id,
                        ind: index + 1, // Adjusting index for display purposes
                        subId: employeeLeave._id || '', // Ensure subId is correctly mapped
                        employee_name: employeeLeave.employee_name || 'N/A',
                        employee_id: employeeLeave.employee_id || 'N/A',
                        total_days: employeeLeave.total_days || 'N/A',
                        leave_type: employeeLeave.leave_type || 'N/A',
                        from_date: employeeLeave.from_date || 'N/A',
                        to_date: employeeLeave.to_date || 'N/A',
                        total_days: employeeLeave.total_days || 'N/A',
                        remaining_leaves: employeeLeave.remaining_leaves !== null ? String(employeeLeave.remaining_leaves) : '0',
                        leave_reason: employeeLeave.leave_reason || 'N/A',
                        statusName: employeeLeave.statusName || '', // Adjusted to use status directly
                    };
                });

                console.log(employeeLeaveData);
                setMasterId(formattedData.data[0]._id); // Get the master ID from the first item
                setAddEmployeeDataData(employeeLeaveData); // Set the formatted data
                setTotalPages(formattedData.totalPages); // Set total pages
                setpageSize(formattedData.pageSize);
            }
            else {
                setAddEmployeeDataData([]);
            }
        } catch (error) {
            setLoading(false)
            console.error('Error fetching data:', error);
        } finally {
            setLoading(false);
        }
    };

    const columns = [

        {
            field: 'ind',
            headerName: 'Sr. No',
            width: 50,
            align: 'center', headerAlign: 'center',
            flex: 0.5,
        },
        { field: 'employee_name', headerName: 'Employee', width: 100, flex: 1, align: 'center', headerAlign: 'center', },
        { field: 'leave_type', headerName: 'Leave Type', width: 100, flex: 1, align: 'center', headerAlign: 'center', },
        { field: 'from_date', headerName: 'From', width: 100, flex: 1, align: 'center', headerAlign: 'center', },
        { field: 'to_date', headerName: 'To', width: 100, flex: 1, align: 'center', headerAlign: 'center' },
        // { field: 'remaining_leaves', headerName: 'Remaining', width: 100, flex: 1, align: 'center', headerAlign: 'center', },
        { field: 'leave_reason', headerName: 'Reason', width: 150, flex: 1, align: 'center', headerAlign: 'center', },
        {
            field: 'statusName',
            headerName: 'Status',
            align: 'center',
            width: 100,
            flex: 1,
            headerAlign: 'center',
            renderCell: (params) => {
                console.log(params);
                let color;
                switch (params.value) {
                    case 'Pending':
                        color = 'blue';
                        break;
                    case 'Approve':
                        color = 'green';
                        break;
                    case 'Reject':
                        color = 'red';
                        break;
                    case 'Paid Leave':
                        color = 'purple';
                        break;
                    default:
                        color = 'black'; // Default color if none match
                }

                return (
                    <div style={{ color: color, textAlign: 'center' }}>
                        {params.row.statusName}
                    </div>
                );
            }
        },
    ];

    const handleChange = (e) => {
        const { name, value } = e.target;

        if (name == 'year') {
            setSelectedDate((prev) => ({
                ...prev,
                year: value,
            }));
        } else if (name == 'month') {
            const monthNumber = months.find(month => month.name === value).number;
            setSelectedDate((prev) => ({
                ...prev,
                month: monthNumber,
            }));
        }
    };

    const handleSearch = (event) => {
        setSearchText(event.target.value);
    };

    return (
        <React.Fragment>
            <HeaderMobile />
            <Header />
            <div className="main p-4 p-lg-5 mt-5">
                <div className="col-md-12">
                    <Card className="popup-card empHistory">
                        <Card.Body>

                            <div className="col-md-12 mb-3">
                                <Row>
                                    <Col md={2} className="mb-3 pt-3">
                                        <Form.Group>
                                            <Form.Control
                                                as="select"
                                                name="year"
                                                value={selectedDate.year}
                                                onChange={(e) => {
                                                    handleChange(e)
                                                    // handleviewsalarystructuresetup(e)
                                                }}
                                            >
                                                <option value="">Select Year</option>
                                                {years.map((year) => (
                                                    <option key={year} value={year}>
                                                        {year}
                                                    </option>
                                                ))}
                                            </Form.Control>
                                        </Form.Group>
                                    </Col>

                                    <Col md={2} className="mb-3 pt-3">
                                        <Form.Group>
                                            <Form.Control
                                                as="select"
                                                name="month"
                                                value={months.find(month => month.number == selectedDate.month)?.name || ''}  // Set the value attribute
                                                onChange={handleChange}
                                            >
                                                <option value="">Select Month</option>
                                                {months.map((month) => (
                                                    <option key={month.number} value={month.name}>
                                                        {month.name.charAt(0).toUpperCase() + month.name.slice(1)}
                                                    </option>
                                                ))}
                                            </Form.Control>
                                        </Form.Group>
                                    </Col>
                                    <Col md={5} className="mb-3 pt-3">
                                    </Col>
                                    <Col md={3} className="mb-3 pt-3 d-flex justify-content-end pe-5">
                                        <Button className='btn btn-primary border-0' onClick={() => navigate('/employee/addleave')}>
                                            Apply Leave
                                        </Button>
                                    </Col>
                                </Row>

                                {loading ? (
                                    <div className="loader-container">
                                        <div className="loader"></div>
                                    </div>
                                ) : (
                                    <div style={{ height: '70vh', width: '70vw', backgroundColor: 'white' }}>
                                        <DataGrid
                                            rows={employeeDataData}
                                            columns={columns}
                                            pageSize={pageSize}
                                            pagination
                                            paginationMode="client"
                                            getRowId={(row) => row.subId}
                                            components={{
                                                Pagination: () => (
                                                    <div className="custom-select-container">
                                                        <div>
                                                            <label htmlFor="page-size-reversed">Rows per page: </label>
                                                            <select
                                                                id="page-size-reversed"
                                                                value={pageSize}
                                                                onChange={handlePageSizeChange} // Handle page size changes
                                                            >
                                                                <option value={50}>50</option>
                                                                <option value={100}>100</option>
                                                            </select>
                                                        </div>
                                                        <div>
                                                            <label htmlFor="page-number-reversed">Page: </label>
                                                            <select
                                                                className="page-number"
                                                                id="page-number-reversed"
                                                                value={page}
                                                                onChange={handlePageChange} // Handle page changes
                                                            >
                                                                {[...Array(totalPages)].map((_, pageNumber) => (
                                                                    <option key={pageNumber} value={pageNumber + 1}>
                                                                        {pageNumber + 1}
                                                                    </option>
                                                                ))}
                                                            </select>
                                                        </div>
                                                    </div>
                                                ),
                                                NoRowsOverlay: () => (
                                                    <div style={{ textAlign: "center", padding: "20px" }}>
                                                        No data available
                                                    </div>
                                                ),
                                            }}

                                            rowCount={employeeDataData.length} // Use the length of reversedRows to show total records
                                            page={page - 1} // Current page (0-based index)
                                            onPageChange={(newPage) => setPage(newPage + 1)} // Handle page change
                                            rowsPerPageOptions={[]}
                                        />
                                    </div>
                                )}
                            </div>
                        </Card.Body>
                    </Card>
                </div>
            </div>
        </React.Fragment>
    )
}

export default EmpLeaveStatus;