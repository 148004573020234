
import React, { useState, useEffect } from 'react';
import {  editResignation, updateResignation } from '../../../services/api/api';
import { useNavigate, useParams } from 'react-router-dom';
import Header from '../../../layouts/Header';
import HeaderMobile from '../../../layouts/HeaderMobile';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Form } from 'react-bootstrap';
import { Link } from "react-router-dom";

const EditResignation = () => {
  const navigate = useNavigate();
  const { id, masterId } = useParams();

  const [state, setState] = useState({
      _id: '',
      company_id: '',
      reason: '',
      notice_date: '',
      resignation_date: '',
  });

  const [errors, setErrors] = useState({});

  useEffect(() => {
    async function fetchResignationData() {
        try {
            const resignationData = await editResignation(id, masterId);

            if (resignationData && resignationData.success) {
                const resignation = resignationData.success;

                setState({
                    _id: resignation._id || '',
                    company_id: resignation.company_id || '',
                    reason: resignation.reason || '',
                    notice_date: resignation.notice_date || '', // Populate notice_date
                    resignation_date: resignation.resignation_date || '', // Populate resignation_date
                });
            } else {
                console.error('No resignation data found');
            }
        } catch (error) {
            console.error('Error fetching resignation data:', error);
        }
    }

    fetchResignationData();
}, [id, masterId]);

  const handleChange = (e) => {
      const { name, value } = e.target;
      setState((prevState) => ({
          ...prevState,
          [name]: value,
      }));
      setErrors((prevErrors) => ({
          ...prevErrors,
          [name]: '',
      }));
  };

  const handleSubmit = async (e) => {
      e.preventDefault();
      const validationErrors = validateForm(state);
      if (Object.keys(validationErrors).length > 0) {
          setErrors(validationErrors);
          return;
      }

      try {
          const response = await updateResignation(id, masterId, state);
          if (response && response.success && response.success.length > 0) {
              toast.success(response.success[0].message, {
                  position: 'top-center',
                  autoClose: 5000,
              });
          } else {
              toast.success('resignation updated successfully', {
                  position: 'top-center',
                  autoClose: 5000,
              });
              navigate('/pages/hr/resignation');
          }
      } catch (error) {
          console.error('An error occurred:', error);
          toast.error('An error occurred', {
              position: 'top-right',
              autoClose: 3000,
          });
      }
  };

  const validateForm = (state) => {
      let errors = {};
      // Add validations if necessary
      return errors;
  };

//   const [displayedNoticeDate, setDisplayedNoticeDate] = useState('');
//   const [displayedResignationDate, setDisplayedResignationDate] = useState('');
  
//   const formatDate = (dateString) => {
//     const date = new Date(dateString);
//     const year = date.getFullYear();
//     let month = (1 + date.getMonth()).toString().padStart(2, '0');
//     let day = date.getDate().toString().padStart(2, '0');
  
//     return `${year}-${month}-${day}`;
//   };
//   useEffect(() => {
//     if (state.notice_date) {
//       setDisplayedNoticeDate(formatDate(state.notice_date));
//     }
//   }, [state.notice_date]);
  
//   useEffect(() => {
//     if (state.resignation_date) {
//       setDisplayedResignationDate(formatDate(state.resignation_date));
//     }
//   }, [state.resignation_date]);

// const handleDateChange = (event) => {
//   const { name, value } = event.target;

//   setState((prevState) => ({
//       ...prevState,
//       [name]: value,
//   }));

//   if (name === 'notice_date') {
//       setDisplayedNoticeDate(formatDate(value));
//   } else if (name === 'resignation_date') {
//       setDisplayedResignationDate(formatDate(value));
//   }
// };

const [displayedResignationDate, setDisplayedResignationDate] = useState('');
const [displayedNoticeDate, setDisplayedNoticeDate] = useState('');

const formatDate = (dateString) => {
    const date = new Date(dateString);
    return date.toLocaleDateString('en-US');
};

useEffect(() => {
    if (state.resignation_date) {
        setDisplayedResignationDate(formatDate(state.resignation_date));
    }
    if (state.notice_date) {
        setDisplayedNoticeDate(formatDate(state.notice_date));
    }
}, [state.resignation_date, state.notice_date]);

const handleResignationDateChange = (event) => {
    const newDate = event.target.value;
    setState((prevState) => ({
        ...prevState,
        resignation_date: newDate,
    }));
    setDisplayedResignationDate(formatDate(newDate));
};

const handleNoticeDateChange = (event) => {
    const newDate = event.target.value;
    setState((prevState) => ({
        ...prevState,
        notice_date: newDate,
    }));
    setDisplayedNoticeDate(formatDate(newDate));
};

  return (
      <React.Fragment>
          <HeaderMobile />
          <Header />



          <div className="main p-4 p-lg-5 mt-5">
              <div>
                  <h4>Edit Resignation</h4>
                  <div className="breadcrumb-warning d-flex justify-content-between ot-card">
                      <div>
                          <ol className="breadcrumb ot-breadcrumb ot-breadcrumb-basic">
                              <li className="breadcrumb-item">
                                  <Link to="/admin/dashboard">Dashboard</Link>
                              </li>
                              <li className="breadcrumb-item active">
                                  <Link to="/pages/hr/resignation">Resignations</Link>
                              </li>
                              <li className="breadcrumb-item active">Edit Resignation</li>
                          </ol>
                      </div>
                      <nav aria-label="breadcrumb">
                          <Link to="/pages/hr/resignation" className="btn btn-primary mb-3">
                              <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-arrow-left" viewBox="0 0 16 16">
                                  <path fillRule="evenodd" d="M15 8a.5.5 0 0 0-.5-.5H2.707l3.147-3.146a.5.5 0 1 0-.708-.708l-4 4a.5.5 0 0 0 0 .708l4 4a.5.5 0 0 0 .708-.708L2.707 8.5H14.5A.5.5 0 0 0 15 8z" />
                              </svg>
                              Back
                          </Link>
                      </nav>
                  </div>



                  <div className="row"
                      style={{
                          boxShadow: "0 4px 8px 0 rgba(0,0,0,0.2)",
                          borderRadius: "10px",
                          overflow: "hidden",
                      }}
                  >


                      <div className="col-md-12">
                          <div className="bg-white p-4 rounded" style={{ height: "100%" }}>
                              <form onSubmit={handleSubmit}>
                                  <div className="row">
                                      
                                          <div className="mb-3">
                                              <label htmlFor="reason" className="form-label">
                                                 Reason
                                              </label>
                                              <input
                                                  type="text"
                                                  className={`form-control ${errors.reason && 'is-invalid'}`}
                                                  id="reason"
                                                  name="reason"
                                                  value={state.reason}
                                                  onChange={handleChange}
                                              />
                                              {errors.department_name && (
                                                  <div className="invalid-feedback">{errors.reason}</div>
                                              )}
                                          </div>


                                    

                                          <div className="mb-3">
                                              <label htmlFor="notice_date" className="form-label">
                                                  Notice Date
                                              </label>
                                              <input
                                                  type="date"
                                                  className={`form-control ${errors.notice_date && 'is-invalid'}`}
                                                  id="notice_date"
                                                  name="notice_date"
                                                  value={state.notice_date}
                                                  onChange={handleNoticeDateChange}
                                              />
                                              
                                              {errors.notice_date && (
                                                  <div className="invalid-feedback">{errors.notice_date}</div>
                                              )}
                                          </div>

                                          <div className="mb-3">
                                              <label htmlFor="resignation_date" className="form-label">
                                               Resignation Date
                                              </label>
                                              <input
                                                  type="date"
                                                  className={`form-control ${errors.resignation_date && 'is-invalid'}`}
                                                  id="resignation_date"
                                                  name="resignation_date"
                                                  value={state.resignation_date}
                                                  onChange={handleResignationDateChange}
                                              />
                                              {errors.resignation_date && (
                                                  <div className="invalid-feedback">{errors.resignation_date}</div>
                                              )}
                                          </div>

                                  </div>
                                  <button type="submit" className="btn btn-primary">
                                      Update
                                  </button>
                              </form>
                          </div>
                      </div>
                  </div>


              </div>
          </div>
      </React.Fragment>
  );
};

export default EditResignation
