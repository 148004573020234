import React, { useState, useEffect } from 'react';
import { editWorkingDays, updateWorkingDays } from '../../services/api/api';
import { useNavigate, useParams } from 'react-router-dom';
import Header from '../../layouts/Header';
import HeaderMobile from '../../layouts/HeaderMobile';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Form } from 'react-bootstrap';
import { Link } from "react-router-dom";

const EditWorkingDays = () => {

    const navigate = useNavigate();
    const { id, masterId } = useParams();
    const initialState = {
        _id: '',
        company_id: '',
        sunday: false,
        monday: false,
        tuesday: false,
        wednesday: false,
        thursday: false,
        friday: false,
        saturday: false,
        monthly_allow_leave: '',
    };

    const [state, setState] = useState(initialState);
    const [originalState, setOriginalState] = useState(initialState); // Store the original state
    const [errors, setErrors] = useState({});

    useEffect(() => {
        async function fetchWorkingData() {
            try {
                const workingDaysData = await editWorkingDays(id);

                console.log(workingDaysData);

                if (workingDaysData && workingDaysData.success) {
                    const workingday = workingDaysData.success;

                    const newState = {
                        _id: workingday._id || '',
                        company_id: workingday.company_id || '',
                        sunday: workingday.sunday || false,
                        monday: workingday.monday || false,
                        tuesday: workingday.tuesday || false,
                        wednesday: workingday.wednesday || false,
                        thursday: workingday.thursday || false,
                        friday: workingday.friday || false,
                        saturday: workingday.saturday || false,
                        monthly_allow_leave: workingday.monthly_allow_leave || '',
                    };

                    setState(newState);
                    setOriginalState(newState); // Set the original state
                } else {
                    console.error('No leave data found');
                }
            } catch (error) {
                console.error('Error fetching leave data:', error);
            }
        }

        fetchWorkingData();
    }, [id, masterId]);

    const handleMonthlyAllowLeaveChange = (e) => {
        const value = e.target.value;
        const isNumeric = /^-?\d*\.?\d+$/.test(value); // Regular expression to check if the value is numeric

        if (isNumeric || value === '') { // Only update state if numeric or empty string
            const parsedValue = isNumeric ? parseFloat(value) : ''; // Parse value to float or set as empty string if not numeric

            setState(prevState => ({
                ...prevState,
                monthly_allow_leave: parsedValue, // Set the parsed value for monthly_allow_leave
            }));
        }
    };

    const handleToggleChange = (day) => {
        setState((prevState) => ({
            ...prevState,
            [day]: !prevState[day],
        }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        const validationErrors = validateForm(state);
        if (Object.keys(validationErrors).length > 0) {
            setErrors(validationErrors);
            return;
        }

        try {
            // Merge the original state with the current state
            const mergedState = { ...originalState, ...state };

            const response = await updateWorkingDays(id, masterId, mergedState);
            if (response && response.success && response.success.length > 0) {
                toast.success(response.success[0].message, {
                    position: 'top-center',
                    autoClose: 5000,
                });
            } else {
                toast.success('Working Day updated successfully', {
                    position: 'top-center',
                    autoClose: 5000,
                });
                navigate('/pages/workingdays/viewworkingdays');
            }
        } catch (error) {
            console.error('An error occurred:', error);
            toast.error('An error occurred', {
                position: 'top-right',
                autoClose: 3000,
            });
        }
    };

    const validateForm = (state) => {
        let errors = {};
        // Add validations if necessary
        return errors;
    };

    return (
        <React.Fragment>
            <HeaderMobile />
            <Header />

            <div className="main p-4 p-lg-5 mt-5">
                <div>
                    {/* <h4>Edit Working Day</h4> */}
                    <div className="breadcrumb-warning d-flex justify-content-between ot-card">
                        <div>
                            <ol className="breadcrumb ot-breadcrumb ot-breadcrumb-basic">
                                <li className="breadcrumb-item">
                                    <Link to="/admin/dashboard">Dashboard</Link>
                                </li>
                                <li className="breadcrumb-item active">
                                    <Link to="/pages/workingdays">Working Days</Link>
                                </li>
                                <li className="breadcrumb-item active">Edit Working Day</li>
                            </ol>
                        </div>
                        <nav aria-label="breadcrumb">
                            <Link to="/pages/workingdays/viewworkingdays" className="btn btn-primary mb-3">
                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-arrow-left" viewBox="0 0 16 16">
                                    <path fillRule="evenodd" d="M15 8a.5.5 0 0 0-.5-.5H2.707l3.147-3.146a.5.5 0 1 0-.708-.708l-4 4a.5.5 0 0 0 0 .708l4 4a.5.5 0 0 0 .708-.708L2.707 8.5H14.5A.5.5 0 0 0 15 8z" />
                                </svg>
                                Back
                            </Link>
                        </nav>
                    </div>

                    <div className="row"
                        style={{
                            boxShadow: "0 4px 8px 0 rgba(0,0,0,0.2)",
                            borderRadius: "10px",
                            overflow: "hidden",
                        }}
                    >

                        <div className="col-md-12">
                            <div className="bg-white p-4 rounded" style={{ height: "100%" }}>
                                <table className="table">
                                    <thead>
                                        <tr>
                                            <th>Day</th>
                                            <th>Value</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {Object.keys(state).map((day) => {
                                            if (day !== '_id' && day !== 'company_id' && day !== 'monthly_allow_leave') {
                                                return (
                                                    <tr key={day}>
                                                        <td>{day.charAt(0).toUpperCase() + day.slice(1)}</td>
                                                        <td>
                                                            <Form.Check
                                                                type="switch"
                                                                id={day}
                                                                label={day.charAt(0).toUpperCase() + day.slice(1)}
                                                                checked={state[day]} // Check if value is true
                                                                onChange={() => handleToggleChange(day)}
                                                            />
                                                        </td>
                                                    </tr>
                                                );
                                            }
                                            return null; // Exclude _id, company_id, and monthly_allow_leave fields
                                        })}
                                    </tbody>
                                </table>

                                <div className='mt-3 text-dark'>
                                    <label>Monthly Allow Leave</label>
                                    <input
                                        className='ms-3'
                                        type='text'
                                        name='monthly_allow_leave'
                                        value={state.monthly_allow_leave} // Bind input value to state
                                        onChange={handleMonthlyAllowLeaveChange}
                                    />
                                </div>
                                <div className="text-center mt-4">
                                    <button type="submit" className="btn btn-primary" onClick={handleSubmit}>
                                        Submit
                                    </button>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </React.Fragment>
    );
}

export default EditWorkingDays;
