
import React, { useState, useEffect } from 'react';
import _debounce from 'lodash/debounce';

import { editEmployee, updateUser, viewBranch, viewDepartment, viewDesignation, viewShift } from '../../services/api/api';
import { updateEmployee } from '../../services/api/api';
import Header from '../../layouts/Header';
import HeaderMobile from '../../layouts/HeaderMobile';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Form } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { Link } from "react-router-dom";
import Select from 'react-select';
import Button from '@mui/material/Button';



const EditEmp = () => {
  const navigate = useNavigate();
  const currentPath = window.location.pathname;
  const parts = currentPath.split('/');
  const id = parts[parts.length - 1];
  const [loading, setLoading] = useState(true);
  const [masterId, setMasterId] = useState();

  const [branches, setBranchs] = useState([]);
  const [selectedBranchIds, setSelectedBranchIds] = useState([]);
  const [selectedBranchOptions, setSelectedBranchOptions] = useState([]);


  const formatDate = (dateString) => {
    const date = new Date(dateString);
    return date.toLocaleDateString('en-US'); // Change 'en-US' to your desired locale
  };

  const [departments, setDepartments] = useState([]);
  const [designations, setDesignations] = useState([]);
  const [shifts, setShifts] = useState([]);


  const [state, setState] = useState({
    _id: '',
    company_id: '',
    first_name: '',
    last_name: '',
    email: '',
    joining_date: '',
    phone: '',
    department: '',
    designation: '',
    password: '',
    salary: '',
    shift: '',
    branch: '',
    bank_name: '',
    account_no: '',
    IFSC_code: '',
    branch_name: '',
    PAN_no: '',
    emergency_name: '',
    emergency_relation: '',
    emergency_phone: '',

    // Other fields...
  });



  const [errors, setErrors] = useState({});

 
  // useEffect(() => {
  //   async function fetchemployeeData() {
  //     try {
  //       const employeeData = await editEmployee(id);
  //       console.log("editEmployee",employeeData);
  
  //       if (employeeData && employeeData.success && employeeData.success.length > 0) {
  //         const employee = employeeData.success[0];
  
  //         setState({
  //           _id: employee._id || '',
  //           company_id: employee.company_id || '',
  //           first_name: employee.first_name || '',
  //           last_name: employee.last_name || '',
  //           email: employee.email || '',
  //           joining_date: employee.joining_date || '',
  //           birth_date: employee.birth_date || '',
  //           phone: employee.phone || '',
  //           department: employee.department || '',
  //           designation: employee.designation || '',
  //           branch: employee.branch || '',
  //           password: employee.password || '',
  //           salary: employee.salary || '',
  //           shift: employee.shift || '',
  //           file: employee.file || [],
  //           bank_name: employee.bank_information && employee.bank_information.length > 0
  //             ? employee.bank_information[0].bank_name || ''
  //             : '',
  //           account_no: employee.bank_information && employee.bank_information.length > 0
  //             ? employee.bank_information[0].account_no || ''
  //             : '',
  //           IFSC_code: employee.bank_information && employee.bank_information.length > 0
  //             ? employee.bank_information[0].IFSC_code || ''
  //             : '',
  //           branch_name: employee.bank_information && employee.bank_information.length > 0
  //             ? employee.bank_information[0].branch_name || ''
  //             : '',
  //           PAN_no: employee.bank_information && employee.bank_information.length > 0
  //             ? employee.bank_information[0].PAN_no || ''
  //             : '',
  //           emergency_name: employee.emergency_contact && employee.emergency_contact.length > 0
  //             ? employee.emergency_contact[0].emergency_name || ''
  //             : '',
  //           emergency_relation: employee.emergency_contact && employee.emergency_contact.length > 0
  //             ? employee.emergency_contact[0].emergency_relation || ''
  //             : '',
  //           emergency_phone: employee.emergency_contact && employee.emergency_contact.length > 0
  //             ? employee.emergency_contact[0].emergency_phone || ''
  //             : '',
  //         });
  //       } else {
  //         console.error('No employee data found');
  //       }
  //     } catch (error) {
  //       console.error('Error fetching employee data:', error);
  //     } finally {
  //       setLoading(false);
  //     }
  //   }
  
  //   fetchemployeeData();
  // }, [id]);
  
  useEffect(() => {
    const fetchemployeeData = async () => {
        try {
            const employeeData = await editEmployee(id);
            console.log(employeeData);

            if (employeeData && employeeData.success && employeeData.success.length > 0) {
                const employee = employeeData.success[0];

                setState({
                    _id: employee._id || '',
                    company_id: employee.company_id || '',
                    first_name: employee.first_name || '',
                    last_name: employee.last_name || '',
                    email: employee.email || '',
                    joining_date: employee.joining_date || '',
                    phone: employee.phone || '',
                    department: employee.department || '',
                    designation: employee.designation || '',
                    branch: employee.branch || '',
                    salary: employee.salary || '',
                    shift: employee.shift || '',
                    file: employee.file || [],
                    bank_information: employee.bank_information || [],
                    emergency_contact: employee.emergency_contact || [],
                });
            } else {
                console.error('No employee data found');
            }
        } catch (error) {
            console.error('Error fetching employee data:', error);
        }
    };

    const debouncedFetchEmployeeData = _debounce(fetchemployeeData, 100);

    debouncedFetchEmployeeData();

    return () => {
        debouncedFetchEmployeeData.cancel();
    };
}, [id]);
  

  const [actualPassword, setActualPassword] = useState('');





  const handleChange = (e) => {
    const { name, value } = e.target;
    setState((prevState) => ({
      ...prevState,
      [name]: value,

    }));
    setErrors((prevErrors) => ({
      ...prevErrors,
      [name]: '',
    }));
  };



  const handleSubmit = async (e) => {
    e.preventDefault();
    const validationErrors = validateForm(state);
    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
      return;
    }

    try {
      // const response = await updateEmployee(id, state);
      const response = await updateEmployee(id, {
        ...state,
        branch_id: selectedBranchOptions.map((branch) => branch.label).join(','),
      });
      if (response && response.success && response.success.length > 0) {
        toast.success(response.success[0].message, {
          position: 'top-center',
          autoClose: 1000,
        });
      } else {
        toast.success('employee updated successfully', {
          position: 'top-center',
          autoClose: 1000,
        });
        navigate('/employee/profile');
      }
    } catch (error) {
      console.error('An error occurred:', error);
      toast.error('An error occurred', {
        position: 'top-right',
        autoClose: 1000,
      });
    }
  };



  // Fetch departments on initial render
  // useEffect(() => {
  //   fetchDepartments();
  // }, []);
  // useEffect(() => {
  //   fetchDesignation();

  // }, []);
  // useEffect(() => {
  //   fetchShift()
  // }, []);

  const validateForm = (state) => {
    let errors = {};
    // Add validations if necessary 
    return errors;
  };



  const [displayedDate, setDisplayedDate] = useState('');

  useEffect(() => {
    if (state.joining_date) {
      setDisplayedDate(formatDate(state.joining_date));
    }
  }, [state.joining_date]);

  const handleDateChange = (event) => {
    const newDate = event.target.value;
    setState((prevState) => ({
      ...prevState,
      joining_date: newDate,
    }));
    setDisplayedDate(formatDate(newDate));
  };



  const handlePasswordChange = (event) => {
    const newPassword = event.target.value;
    setActualPassword(newPassword); // Update the actual password
    setState((prevState) => ({
      ...prevState,
      password: newPassword, // Update the password in the state
    }));
  };



  const [showPassword, setShowPassword] = useState(false); // State to control showing password

  // ... (Other existing code remains the same)

  const handleTogglePassword = () => {
    setShowPassword((prevState) => !prevState); // Toggle show/hide password
  };


  // const fetchDepartments = async () => {
  //   try {
  //     const formattedData = await viewDepartment();

  //     if (!formattedData || !formattedData.length) {
  //       throw new Error('Failed to fetch data.');
  //     }

  //     const companyBranch = formattedData[0].company_department;
  //     setMasterId(formattedData[0]._id);
  //     const departmentsArray = Object.values(companyBranch).map(item => ({
  //       _id: item._id,
  //       department_name: item.department_name || 'N/A',
  //     }));

  //     setDepartments(departmentsArray);
  //   } catch (error) {
  //     console.error('Error fetching data:', error);
  //   } finally {
  //     setLoading(false);
  //   }
  // };


  // const fetchDesignation = async () => {
  //   try {
  //     const formattedData = await viewDesignation();

  //     if (!formattedData || !formattedData.length) {
  //       throw new Error('Failed to fetch data.');
  //     }

  //     const companyDesignation = formattedData[0].company_designation;
  //     setMasterId(formattedData[0]._id);
  //     const designationsArray = Object.values(companyDesignation).map((item) => ({
  //       _id: item._id,
  //       designation_name: item.designation_name || 'N/A',
  //     }));

  //     setDesignations(designationsArray);
  //   } catch (error) {
  //     console.error('Error fetching data:', error);
  //   } finally {
  //     setLoading(false);
  //   }
  // };



  // const fetchShift = async () => {
  //   try {
  //     // Fetch shift data
  //     const formattedData = await viewShift();

  //     // Process fetched data and set it in state
  //     if (!formattedData || !formattedData.length) {
  //       throw new Error('Failed to fetch data.');
  //     }

  //     const companyShift = formattedData[0].company_time;
  //     setMasterId(formattedData[0]._id);
  //     const shiftsArray = Object.values(companyShift).map(item => ({
  //       _id: item._id,
  //       shift_no: item.shift_no || 'N/A',
  //     }));

  //     setShifts(shiftsArray);
  //   } catch (error) {
  //     console.error('Error fetching data:', error);
  //   } finally {
  //     setLoading(false);
  //   }
  // };







  // branch code start
  const handleBranchSelectChange = (selectedOptions) => {
    setSelectedBranchOptions(selectedOptions);
    const selectedIds = selectedOptions.map((option) => option.value);
    setSelectedBranchIds(selectedIds);
    console.log(selectedIds);
  };

  // useEffect(() => {

  //   fetchBranch();

  // }, [])

  // const fetchBranch = async () => {

  //   try {
  //     const formattedData = await viewBranch();

  //     if (!formattedData || !formattedData.length) {
  //       throw new Error('Failed to fetch data.');
  //     }

  //     const companyBranch = formattedData[0].company_branch;
  //     setMasterId(formattedData[0]._id);
  //     const branchsArray = Object.values(companyBranch).map(item => ({
  //       _id: item._id,
  //       branch_name: item.branch_name || 'N/A',
  //     }));

  //     setBranchs(branchsArray);
  //   } catch (error) {
  //     console.error('Error fetching data:', error);
  //   } finally {
  //     setLoading(false);
  //   }
  // };
  const handleChangeb = (selectedOptions) => {
    const selectedBranchIds = selectedOptions.map((option) => option.value).join(',');
    setState((prevState) => ({
      ...prevState,
      branch: selectedBranchIds,
    }));
    setErrors((prevErrors) => ({
      ...prevErrors,
      branch: '',
    }));
  };
  // branch code end







  return (
    <React.Fragment>
      <HeaderMobile />
      <Header />
      <div className="main p-4 p-lg-5 mt-5">
        <div>
          <h2>Edit employee</h2>
          <div className="breadcrumb-warning d-flex justify-content-between ot-card">
            <div>
              <ol className="breadcrumb ot-breadcrumb ot-breadcrumb-basic">
                <li className="breadcrumb-item">
                  <Link to="/admin/dashboard">Dashboard</Link>
                </li>
                <li className="breadcrumb-item active">
                  <Link to="/employee/profile">Employees</Link>
                </li>
                <li className="breadcrumb-item active">Edit Employees</li>
              </ol>
            </div>
            <nav aria-label="breadcrumb">
              <Link to="/employee/profile" className="btn btn-danger mb-3">
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-arrow-left" viewBox="0 0 16 16">
                  <path fillRule="evenodd" d="M15 8a.5.5 0 0 0-.5-.5H2.707l3.147-3.146a.5.5 0 1 0-.708-.708l-4 4a.5.5 0 0 0 0 .708l4 4a.5.5 0 0 0 .708-.708L2.707 8.5H14.5A.5.5 0 0 0 15 8z" />
                </svg>
                Back
              </Link>
            </nav>
          </div>

          <div className="row"
            style={{
              boxShadow: "0 4px 8px 0 rgba(0,0,0,0.2)",
              borderRadius: "10px",
              overflow: "hidden",
            }}
          >


            <div className="col-md-12 formcontrols">



              <div className="bg-white p-4 rounded" style={{ height: "100%" }}>
                <form onSubmit={handleSubmit}>
                  <div className="mb-4 border-bottom text-center">
                    <h3 className="mb-0 pb-2 border-bottom custome-btn">Profile Information</h3>
                  </div>
                  <div className="row">
                    <div className="col-md-4">
                      <div className="mb-3">
                        <label htmlFor="first_name" className="form-label">
                          First Name
                        </label>
                        <input
                          type="text"
                          className={`form-control custom-input ${errors.first_name && 'is-invalid'}`}
                          id="first_name"
                          name="first_name"
                          value={state.first_name}
                          onChange={handleChange}
                        />
                        {errors.first_name && <div className="invalid-feedback">{errors.first_name}</div>}
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div className="mb-3">
                        <label htmlFor="last_name" className="form-label">
                          Last Name
                        </label>
                        <input
                          type="text"
                          className={`form-control custom-input ${errors.last_name && 'is-invalid'}`}
                          id="last_name"
                          name="last_name"
                          value={state.last_name}
                          onChange={handleChange}
                        />
                        {errors.last_name && <div className="invalid-feedback">{errors.last_name}</div>}
                      </div>
                    </div>
                    <div className="col-md-4">

                      <div className="mb-3">
                        <label htmlFor="joining_date" className="form-label">
                          Joining Date
                        </label>
                        <input
                          type="date"
                          className={`form-control custom-input ${errors.joining_date && 'is-invalid'}`}
                          id="joining_date"
                          name="joining_date"
                          value={state.joining_date}
                          onChange={handleDateChange}
                          disabled

                        />
                        {errors.joining_date && (
                          <div className="invalid-feedback">{errors.joining_date}</div>
                        )}
                      </div>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-md-4">
                      <div className="mb-3">
                        <label htmlFor="email" className="form-label">
                          Email
                        </label>
                        <input
                          type="text"
                          className={`form-control custom-input ${errors.email && 'is-invalid'}`}
                          id="email"
                          name="email"
                          value={state.email}
                          onChange={handleChange}
                        />
                        {errors.email && <div className="invalid-feedback">{errors.email}</div>}
                      </div>
                    </div>

                    <div className="col-md-4">
                      <div className="mb-3">
                        <label htmlFor="phone" className="form-label">
                          Phone
                        </label>
                        <input
                          type="text"
                          className={`form-control custom-input ${errors.phone && 'is-invalid'}`}
                          id="phone"
                          name="phone"
                          value={state.phone}
                          onChange={handleChange}
                        />
                        {errors.phone && <div className="invalid-feedback">{errors.phone}</div>}
                      </div>
                    </div>





                    {/* <div className="col-md-4">
                      <div className="mb-3">
                        <label htmlFor="designation" className="form-label">
                          Branch
                        </label>
                        <Select
                          isMulti
                          options={branches.map((branch) => ({
                            value: branch._id,
                            label: branch.branch_name,
                          }))}
                          value={selectedBranchOptions}
                          onChange={(selectedOptions) => {
                            const selectedBranches = selectedOptions.map((option) => option.value);
                            setSelectedBranchOptions(selectedOptions);
                            setSelectedBranchIds(selectedBranches);
                          }}
                          inputValue={state.branch}
                          onInputChange={(inputValue) => {
                            handleChange({
                              target: {
                                name: 'branch',
                                value: inputValue,
                              },
                            });
                          }}
                          isClearable
                          placeholder="Select or type branches"
                        />


                        {errors.branch && (
                          <div className="invalid-feedback">{errors.branch}</div>
                        )}








                        {errors.branch && (
                          <div className="invalid-feedback">{errors.branch}</div>
                        )}
                      </div>







                    </div> */}

                  </div>




                  <div className="row">

                  </div>


                  <div className="row">
                    {/* <div className="col-md-4">
                      <div className="mb-3">
                        <label htmlFor="designation" className="form-label">
                          Designation
                        </label>
                        <div style={{ maxHeight: '200px', overflowY: 'auto' }}>
                          <select
                            className={`form-select ${errors.designation && 'is-invalid'}`}
                            id="designation"
                            name="designation"
                            value={state.designation}
                            onChange={handleChange}
                            disabled
                          >
                            <option value="">Select Designation</option>
                            {designations.map((designation) => (
                              <option key={designation._id} value={designation.designation._id}>
                                {designation.designation_name}
                              </option>
                            ))}
                          </select>
                        </div>                      </div>


                    </div> */}
                    {/* <div className="col-md-4">
                      <div className="mb-3">
                        <label htmlFor="salary" className="form-label">
                          Salary
                        </label>
                        <input
                          type="text"
                          className={`form-control custom-input ${errors.salary && 'is-invalid'}`}
                          id="salary"
                          name="salary"
                          value={state.salary}
                          onChange={handleChange}
                          disabled
                        />
                        {errors.salary && <div className="invalid-feedback">{errors.salary}</div>}
                      </div>
                    </div> */}
                    {/* <div className="col-md-4">
                      <div className="mb-3">
                        <label htmlFor="department" className="form-label">
                          Department
                        </label>
                        <div style={{ maxHeight: '200px', overflowY: 'auto' }}>
                          <select
                            className={`form-select ${errors.department && 'is-invalid'}`}
                            id="department"
                            name="department"
                            value={state.department}
                            onChange={handleChange}
                            disabled
                          >
                            <option value="">Select Department</option>
                            {departments.map((department) => (
                              <option key={department._id} value={department.department_name}>
                                {department.department_name}
                              </option>
                            ))}
                          </select>

                        </div>


                        {errors.department && <div className="invalid-feedback">{errors.department}</div>}
                      </div>

                    </div> */}
                  </div>

                  <div className="mb-4 mt-4 border-bottom text-center">
                    <h3 className="mb-0 pb-2 border-bottom custome-btn">Emergency Contact</h3>
                  </div>

                  <div className="row">
                    <div className="col-md-4">
                      <div className="mb-3">
                        <label htmlFor="emergency_name" className="form-label">
                          Emergency name
                        </label>
                        <input
                          type="text"
                          className={`form-control custom-input ${errors.emergency_name && 'is-invalid'}`}
                          id="emergency_name"
                          name="emergency_name"
                          value={state.emergency_name}
                          onChange={handleChange}
                        />

                        {errors.emergency_name && <div className="invalid-feedback">{errors.emergency_name}</div>}
                      </div>
                    </div>


                    <div className="col-md-4">
                      <div className="mb-3">
                        <label htmlFor="emergency_relation" className="form-label">
                          Emergency relation
                        </label>
                        <input
                          type="text"
                          className={`form-control custom-input ${errors.emergency_relation && 'is-invalid'}`}
                          id="emergency_relation"
                          name="emergency_relation"
                          value={state.emergency_relation}
                          onChange={handleChange}
                        />

                        {errors.emergency_relation && <div className="invalid-feedback">{errors.emergency_relation}</div>}
                      </div>
                    </div>

                    <div className="col-md-4">
                      <div className="mb-3">
                        <label htmlFor="emergency_phone" className="form-label">
                          Emergency phone
                        </label>
                        <input
                          type="number"
                          className={`form-control custom-input ${errors.emergency_phone && 'is-invalid'}`}
                          id="emergency_phone"
                          name="emergency_phone"
                          value={state.emergency_phone}
                          onChange={handleChange}
                        />

                        {errors.emergency_phone && <div className="invalid-feedback">{errors.emergency_phone}</div>}
                      </div>
                    </div>



                  </div>
                  <div className="mb-4 mt-4 border-bottom text-center">
                    <h3 className="mb-0 pb-2 border-bottom custome-btn">Bank Account Details</h3>
                  </div>




                  <div className="row">

                    <div className="col-md-4">
                      <div className="mb-3">
                        <label htmlFor="bank_name" className="form-label">
                          Bank Name
                        </label>
                        <input
                          type="text"
                          className={`form-control custom-input ${errors.bank_name && 'is-invalid'}`}
                          id="bank_name"
                          name="bank_name"
                          value={state.bank_name}
                          onChange={handleChange}
                          disabled
                        />
                        {errors.bank_name && <div className="invalid-feedback">{errors.bank_name}</div>}

                      </div>
                    </div>


                    <div className="col-md-4">
                      <div className="mb-3">
                        <label htmlFor="account_no" className="form-label">
                          Account number
                        </label>
                        <input
                          type="text"
                          className={`form-control custom-input ${errors.account_no && 'is-invalid'}`}
                          id="account_no"
                          name="account_no"
                          value={state.account_no}
                          onChange={handleChange}
                          disabled
                        />
                        {errors.account_no && <div className="invalid-feedback">{errors.account_no}</div>}

                      </div>
                    </div>


                    <div className="col-md-4">
                      <div className="mb-3">
                        <label htmlFor="IFSC_code" className="form-label">
                          IFSC code
                        </label>
                        <input
                          type="text"
                          className={`form-control custom-input ${errors.IFSC_code && 'is-invalid'}`}
                          id="IFSC_code"
                          name="IFSC_code"
                          value={state.IFSC_code}
                          onChange={handleChange}
                          disabled
                        />

                        {errors.IFSC_code && <div className="invalid-feedback">{errors.IFSC_code}</div>}
                      </div>
                    </div>

                  </div>



                  <div className="row">
                    <div className="col-md-4">
                      <div className="mb-3">
                        <label htmlFor="branch_name" className="form-label">
                          Branch name
                        </label>
                        <input
                          type="text"
                          className={`form-control custom-input ${errors.branch_name && 'is-invalid'}`}
                          id="branch_name"
                          name="branch_name"
                          value={state.branch_name}
                          onChange={handleChange}
                          disabled
                        />

                        {errors.branch_name && <div className="invalid-feedback">{errors.branch_name}</div>}
                      </div>
                    </div>


                    <div className="col-md-4">
                      <div className="mb-3">
                        <label htmlFor="PAN_no" className="form-label">
                          PAN number
                        </label>
                        <input
                          type="text"
                          className={`form-control custom-input ${errors.PAN_no && 'is-invalid'}`}
                          id="PAN_no"
                          name="PAN_no"
                          value={state.PAN_no}
                          onChange={handleChange}
                          disabled
                        />

                        {errors.PAN_no && <div className="invalid-feedback">{errors.PAN_no}</div>}
                      </div>
                    </div>
                  </div>




















                  <button type="submit" className="btn btn-primary">
                    Update
                  </button>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div >
    </React.Fragment >
  );
};

export default EditEmp;