import React, { useState } from "react";
import Header from "../../layouts/Header";
import { Link } from "react-router-dom";
import '../../styles/globals.css';

const InterviewForm = () => {
  const [formData, setFormData] = useState({
    candidateName: "",
    designation: "",
    scheduleTime: "",
    status: "",
  });

  const [formErrors, setFormErrors] = useState({});

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const validateForm = () => {
    const errors = {};
    if (!formData.candidateName) errors.candidateName = "Candidate Name is required.";
    if (!formData.designation) errors.designation = "Designation is required.";
    if (!formData.scheduleTime) errors.scheduleTime = "Schedule Time is required.";
    if (!formData.status) errors.status = "Status is required.";

    setFormErrors(errors);
    return Object.keys(errors).length === 0;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (validateForm()) {
      // Form is valid, you can submit it to your server or perform any desired action
      console.log(formData);
    }
  };

  return (
    <React.Fragment>
      <Header />
      <div className="main p-4 p-lg-5 mt-5">
        <h4>Add Interview</h4>

        <div className="breadcrumb-warning d-flex justify-content-between ot-card">
          <div>
            <ol className="breadcrumb ot-breadcrumb ot-breadcrumb-basic">
              <li className="breadcrumb-item">
                <Link to="/dashboard/helpdesk">Dashboard</Link>
              </li>
              <li className="breadcrumb-item active">
                <Link to="/pages/recruitment/interviews">Interviews</Link>
              </li>
              <li className="breadcrumb-item active">Add Interview</li>
            </ol>
          </div>
          <nav aria-label="breadcrumb">
            <Link to="/pages/recruitment/interviews" className="btn btn-primary mb-3">
              <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-arrow-left" viewBox="0 0 16 16">
                <path fillRule="evenodd" d="M15 8a.5.5 0 0 0-.5-.5H2.707l3.147-3.146a.5.5 0 1 0-.708-.708l-4 4a.5.5 0 0 0 0 .708l4 4a.5.5 0 0 0 .708-.708L2.707 8.5H14.5A.5.5 0 0 0 15 8z" />
              </svg>
              Back
            </Link>
          </nav>
        </div>

        <div className="row" style={{ boxShadow: "0 4px 8px 0 rgba(0,0,0,0.2)", borderRadius: "10px", overflow: "hidden" }}>
          <div className="col-md-12">
            <div className="bg-white p-4 rounded" style={{ height: "100%" }}>
              <form onSubmit={handleSubmit} className="form-labels">
                <div className="mb-3">
                  <label htmlFor="candidateName" className="form-label">Candidate Name</label>
                  <input
                    type="text"
                    className={`form-control ${formErrors.candidateName && "is-invalid"}`}
                    id="candidateName"
                    name="candidateName"
                    value={formData.candidateName}
                    onChange={handleInputChange}
                  />
                  {formErrors.candidateName && <div className="invalid-feedback">{formErrors.candidateName}</div>}
                </div>
                <div className="mb-3">
                  <label htmlFor="designation" className="form-label">Designation</label>
                  <input
                    type="text"
                    className={`form-control ${formErrors.designation && "is-invalid"}`}
                    id="designation"
                    name="designation"
                    value={formData.designation}
                    onChange={handleInputChange}
                  />
                  {formErrors.designation && <div className="invalid-feedback">{formErrors.designation}</div>}
                </div>
                <div className="mb-3">
                  <label htmlFor="scheduleTime" className="form-label">Schedule Time</label>
                  <input
                    type="datetime-local"
                    className={`form-control ${formErrors.scheduleTime && "is-invalid"}`}
                    id="scheduleTime"
                    name="scheduleTime"
                    value={formData.scheduleTime}
                    onChange={handleInputChange}
                  />
                  {formErrors.scheduleTime && <div className="invalid-feedback">{formErrors.scheduleTime}</div>}
                </div>
                <div className="mb-3">
                  <label htmlFor="status" className="form-label">Status</label>
                  <select
                    className={`form-select ${formErrors.status && "is-invalid"}`}
                    id="status"
                    name="status"
                    value={formData.status}
                    onChange={handleInputChange}
                  >
                    <option value="">Select Status</option>
                    <option value="Scheduled">Scheduled</option>
                    <option value="Completed">Completed</option>
                    <option value="Cancelled">Cancelled</option>
                    {/* Add more status options here */}
                  </select>
                  {formErrors.status && <div className="invalid-feedback">{formErrors.status}</div>}
                </div>
                <button type="submit" className="btn btn-primary">
                  Submit
                </button>
              </form>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default InterviewForm;
