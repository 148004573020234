import React, { useState, useEffect } from 'react';
import { debounce } from 'lodash';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { editApplicantStatus, updateApplicantStatus } from '../services/api/api';
import HeaderMobile from '../layouts/HeaderMobile';
import Header from '../layouts/Header';
import { toast } from 'react-toastify';

const EditStatus = () => {
    const navigate = useNavigate();
    const { id } = useParams();
    const [submitting, setSubmitting] = useState(false);
    const [loading, setLoading] = useState(true);
    const [status_name, setStatusName] = useState('');

    const [errors, setErrors] = useState({});

    useEffect(() => {
        const fetchBranchData = debounce(async () => {
            try {
                setLoading(true);
                const response = await editApplicantStatus(id);

                console.log(response[0].applicat_status);


                if (response && response.length > 0) {
                    const status = response[0].applicat_status;
                    setStatusName(status.status_name);
                } else {
                    toast.error('No data found', {
                        position: "top-center",
                        autoClose: 1000,
                    });
                }
                setLoading(false);
            } catch (error) {
                console.error('Error fetching branch data:', error);
                setLoading(false);
            }
        }, 2000);

        fetchBranchData();

        return () => {
            fetchBranchData.cancel();
        };
    }, [id]);


    const handleChange = (e) => {
        const { name, value } = e.target;
        setStatusName(value);
        setErrors((prevErrors) => ({
            ...prevErrors,
            [name]: '',
        }));
    };


    const handleSubmit = async (e) => {
        e.preventDefault();

        const validationErrors = {};
        if (!status_name) {
            validationErrors.status_name = 'Status name is required';
        }

        if (Object.keys(validationErrors).length > 0) {
            setErrors(validationErrors);
            return;
        }

        setSubmitting(true);
        try {
            const response = await updateApplicantStatus(id, status_name);

            console.log(response);

            if (response) {
                toast.success('Applicant status updated successfully', {
                    position: 'top-center',
                    autoClose: 2000,
                });
                navigate('/pages/settings/addapplicantstatus');
            } else {
                toast.error('An error occurred while updating status', {
                    position: 'top-center',
                    autoClose: 2000,
                });
                setSubmitting(false);
            }
        } catch (error) {
            console.error('An error occurred:', error);
            toast.error('An error occurred', {
                position: 'top-center',
                autoClose: 2000,
            });
        }
    };

    return (
        <React.Fragment>
            <HeaderMobile />
            <Header />
            <div className="main p-4 p-lg-5 mt-5">
                {/* <div className="mb-4 border-bottom text-center">
                    <h3 className="mb-0 pb- border-bottom custome-btn">Edit Status</h3>
                </div> */}
                <div>
                    <div className="breadcrumb-warning d-flex justify-content-between ot-card">
                        <div>
                            <ol className="breadcrumb ot-breadcrumb ot-breadcrumb-basic">
                                <li className="breadcrumb-item">
                                    <Link to="/admin/dashboard">Dashboard</Link>
                                </li>
                                <li className="breadcrumb-item active">
                                    <Link to="/pages/settings/addapplicantstatus">Applicant Status</Link>
                                </li>
                                <li className="breadcrumb-item active">Edit Status</li>
                            </ol>
                        </div>
                        <nav aria-label="breadcrumb">
                            <Link to="/pages/settings/addapplicantstatus" className="btn btn-primary mb-3">
                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-arrow-left" viewBox="0 0 16 16">
                                    <path fillRule="evenodd" d="M15 8a.5.5 0 0 0-.5-.5H2.707l3.147-3.146a.5.5 0 1 0-.708-.708l-4 4a.5.5 0 0 0 0 .708l4 4a.5.5 0 0 0 .708-.708L2.707 8.5H14.5A.5.5 0 0 0 15 8z" />
                                </svg>
                                Back
                            </Link>
                        </nav>
                    </div>

                    {loading &&
                        <div className="loader-container">
                            <div className="loader"></div>
                        </div>
                    }
                    <div className="row"
                        style={{
                            boxShadow: "0 4px 8px 0 rgba(0,0,0,0.2)",
                            borderRadius: "10px",
                            overflow: "hidden",
                        }}
                    >
                        <div className="col-md-12 formcontrols">
                            <div className="bg-white p-4 rounded" style={{ height: "100%" }}>
                                <form onSubmit={handleSubmit}>
                                    <div className="row">
                                        <div className="col-md-12">
                                            <div className="mb-3">
                                                <label htmlFor="status_name" className="form-label">
                                                    Name
                                                </label>
                                                <input
                                                    type="text"
                                                    className={`form-control ${errors.status_name && 'is-invalid'}`}
                                                    id="status_name"
                                                    name="status_name"
                                                    value={status_name}
                                                    onChange={handleChange}

                                                />
                                                {errors.status_name && (
                                                    <div className="invalid-feedback">{errors.status_name}</div>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                    <button type="submit" className="btn btn-primary" disabled={submitting}>
                                        {submitting ? 'Updating...' : 'Update'}
                                    </button>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
};

export default EditStatus;
