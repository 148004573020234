import React, { useState, useEffect, useRef } from 'react';
import { debounce } from 'lodash';
import { updateDepartment, editHoliday, updateHoliday } from '../../../services/api/api';
import { useNavigate, useParams } from 'react-router-dom';
import Header from '../../../layouts/Header';
import HeaderMobile from '../../../layouts/HeaderMobile';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Button, Card, Col, Row, Nav, Table, ProgressBar, Form, Badge, ListGroup, Spinner, CardBody } from "react-bootstrap";
import { Link } from "react-router-dom";
import interactionPlugin from '@fullcalendar/interaction'; // This plugin is required for dateClick
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';


const EditHoliday = () => {
    const navigate = useNavigate();
    const [submitting, setSubmitting] = useState(false);
    const [loading, setLoading] = useState(false);
    const { id, masterId } = useParams();

    const [state, setState] = useState({
        _id: '',
        company_id: '',
        holiday_name: '',
        holiday_date: '',
        holiday_description: '',
        year: new Date().getFullYear(),
        month: new Date().getMonth(),
        events: [],
    });

    const [errors, setErrors] = useState({});
    const calendarRef = useRef(null);
    const [events, setEvents] = useState([]);
    const [searchParams, setSearchParams] = useState({ year: new Date().getFullYear(), month: new Date().getMonth() + 1 });
    const [selectedDate, setSelectedDate] = useState(null);


    const monthNames = [
        'January', 'February', 'March', 'April', 'May', 'June', 'July', 'August',
        'September', 'October', 'November', 'December'
    ];


    // useEffect(() => {
    //     const fetchHolidayData = debounce(async () => {
    //         try {
    //             setLoading(true);
    //             const holidayData = await editHoliday(id, masterId);

    //             if (holidayData && holidayData.success) {
    //                 const holiday = holidayData.success;

    //                 const year = new Date(holiday.holiday_date).getFullYear();
    //                 const month = new Date(holiday.holiday_date).getMonth(); // Month is 0-indexed (0 for January, 11 for December)

    //                 console.log(year);
    //                 console.log(month);


    //                 setState({
    //                     _id: holiday._id || '',
    //                     company_id: holiday.company_id || '',
    //                     holiday_name: holiday.holiday_name || '',
    //                     holiday_date: holiday.holiday_date || '',
    //                     holiday_description: holiday.holiday_description || '',
    //                     year: year,
    //                     month: month,
    //                 });

    //                 const newEvent = {
    //                     title: holiday.holiday_name,
    //                     start: holiday.holiday_date,  // Use holiday_date as the start of the event
    //                     extendedProps: {
    //                         dotColor: 'yellow',  // Set the color for highlighting
    //                         time: holiday.holiday_date,  // Optional: display the time
    //                     },
    //                 };

    //             } else {
    //                 console.error('No holiday data found');
    //             }
    //             setLoading(false);
    //         } catch (error) {
    //             console.error('Error fetching holiday data:', error);
    //             setLoading(false);
    //         }
    //     }, 100);

    //     fetchHolidayData();

    //     return () => {
    //         fetchHolidayData.cancel();
    //     };
    // }, [id, masterId]);

    useEffect(() => {
        const fetchHolidayData = debounce(async () => {
            try {
                setLoading(true);
                const holidayData = await editHoliday(id, masterId);

                if (holidayData && holidayData.success) {
                    const holiday = holidayData.success;

                    const year = new Date(holiday.holiday_date).getFullYear();
                    const month = new Date(holiday.holiday_date).getMonth(); // Month is 0-indexed

                    console.log(year);
                    console.log(month);

                    // Create a new event for the holiday
                    const newEvent = {
                        title: holiday.holiday_name,  // The holiday name
                        start: holiday.holiday_date,  // The date of the holiday
                        backgroundColor: '#506fd9',   // Directly apply yellow background to the event
                        borderColor: '#506fd9',       // Border color if you want a border
                        extendedProps: {
                            time: holiday.holiday_date,  // Optional, you can include the time if needed
                        },
                    };

                    // Ensure that events is initialized as an array (if it's not already)
                    setState(prevState => ({
                        ...prevState,
                        _id: holiday._id || '',
                        company_id: holiday.company_id || '',
                        holiday_name: holiday.holiday_name || '',
                        holiday_date: holiday.holiday_date || '',
                        holiday_description: holiday.holiday_description || '',
                        year: year,
                        month: month,
                        events: Array.isArray(prevState.events) ? [...prevState.events, newEvent] : [newEvent], // Safe update
                    }));
                } else {
                    console.error('No holiday data found');
                }
                setLoading(false);
            } catch (error) {
                console.error('Error fetching holiday data:', error);
                setLoading(false);
            }
        }, 100);

        fetchHolidayData();

        return () => {
            fetchHolidayData.cancel();
        };
    }, [id, masterId]);




    useEffect(() => {
        if (calendarRef.current) {
            const calendarApi = calendarRef.current.getApi();
            calendarApi.gotoDate(`${state.year}-${String(state.month + 1).padStart(2, '0')}-01`);
        }
    }, [state.month, state.year]);

    const CY = new Date().getFullYear();
    const yearsRange = [];
    for (let i = CY - 100; i <= CY + 100; i++) {
        yearsRange.push(i);
    }
    const handleYearChange = (e) => {
        setState({
            ...state,
            year: e.target.value,
        });
    };

    // Handle month change
    const handleMonthChange = (e) => {
        setState({
            ...state,
            month: parseInt(e.target.value), // Ensure month is stored as an integer (0-11)
        });
    };

    const handleDateClick = (info) => {
        setState(prevState => ({
            ...prevState,
            holiday_date: info.dateStr,  // Set the selected date
            holiday_name: '',  // Reset the holiday name input
        }));
        setSelectedDate(info.dateStr);  // Open the popup with the selected date
    };

    const closePopup = () => {
        setSelectedDate(null);
    };

    const handleChange = (e) => {
        setState(prevState => ({
            ...prevState,
            [e.target.name]: e.target.value,
        }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        // const validationErrors = validateForm(state);
        // if (Object.keys(validationErrors).length > 0) {
        //     setErrors(validationErrors);
        //     return;
        // } if (submitting) {
        //     return;
        // }
        // setSubmitting(true);

        try {
            const response = await updateHoliday(id, masterId, state);
            if (response && response.success && response.success.length > 0) {
                toast.success(response.success[0].message, {
                    position: 'top-center',
                    autoClose: 1000,
                });
            } else {
                toast.success('Holiday updated successfully', {
                    position: 'top-center',
                    autoClose: 1000,
                });
                navigate('/admin/holidays');
            }
        } catch (error) {
            console.error('An error occurred:', error);
            toast.error('An error occurred', {
                position: 'top-right',
                autoClose: 1000,
            });
        } finally {
            setSubmitting(false);
        }
    };

    const validateForm = (state) => {
        let errors = {};
        // Add validations if necessary
        return errors;
    };

    const [displayedDate, setDisplayedDate] = useState('');
    const formatDate = (dateString) => {
        const date = new Date(dateString);
        return date.toLocaleDateString('en-US');
    };
    useEffect(() => {
        if (state.holiday_date) {
            setDisplayedDate(formatDate(state.holiday_date));
        }
    }, [state.holiday_date]);

    const handleDateChange = (event) => {
        const newDate = event.target.value;
        setState((prevState) => ({
            ...prevState,
            holiday_date: newDate,
        }));
        setDisplayedDate(formatDate(newDate));
    };

    return (
        <React.Fragment>
            <HeaderMobile />
            <Header />
            <div className="main p-4 p-lg-5 mt-5">
                <div>
                    <div className="breadcrumb-warning d-flex justify-content-between ot-card">
                        <div>
                            <ol className="breadcrumb ot-breadcrumb ot-breadcrumb-basic">
                                <li className="breadcrumb-item">
                                    <Link to="/admin/dashboard">Dashboard</Link>
                                </li>
                                <li className="breadcrumb-item active">
                                    <Link to="/admin/holidays">Holidays</Link>
                                </li>
                                <li className="breadcrumb-item active">Edit Holidays</li>
                            </ol>
                        </div>
                        <nav aria-label="breadcrumb">
                            <Link to="/admin/holidays" className="btn btn-primary mb-3">
                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-arrow-left" viewBox="0 0 16 16">
                                    <path fillRule="evenodd" d="M15 8a.5.5 0 0 0-.5-.5H2.707l3.147-3.146a.5.5 0 1 0-.708-.708l-4 4a.5.5 0 0 0 0 .708l4 4a.5.5 0 0 0 .708-.708L2.707 8.5H14.5A.5.5 0 0 0 15 8z" />
                                </svg>
                                Back
                            </Link>
                        </nav>
                    </div>

                    {loading &&
                        <div className="loader-container">
                            <div className="loader"></div>
                        </div>
                    }

                    <div className="row"
                        style={{
                            boxShadow: "0 4px 8px 0 rgba(0,0,0,0.2)",
                            borderRadius: "10px",
                            overflow: "hidden",
                        }}
                    >

                        {/* <div className="col-md-12 formcontrols">
                            <div className="bg-white p-4 rounded" style={{ height: "100%" }}>
                                <form onSubmit={handleSubmit}>
                                    <div className="row">
                                        <div className="col-md-6">
                                            <div className="mb-3">
                                                <label htmlFor="holiday_name" className="form-label">
                                                    Holiday name
                                                </label>
                                                <input
                                                    type="text"
                                                    className={`form-control ${errors.holiday_name && 'is-invalid'}`}
                                                    id="holiday_name"
                                                    name="holiday_name"
                                                    value={state.holiday_name}
                                                    onChange={handleChange}
                                                />
                                                {errors.holiday_name && (
                                                    <div className="invalid-feedback">{errors.holiday_name}</div>
                                                )}
                                            </div>


                                        </div>
                                        <div className="col-md-6">

                                            <div className="mb-3">
                                                <label htmlFor="holiday_date" className="form-label">
                                                    Holiday Date
                                                </label>
                                                <input
                                                    type="date"
                                                    className={`form-control ${errors.holiday_date && 'is-invalid'}`}
                                                    id="holiday_date"
                                                    name="holiday_date"
                                                    value={state.holiday_date}
                                                    onChange={handleDateChange}
                                                />
                                                {errors.holiday_date && (
                                                    <div className="invalid-feedback">{errors.holiday_date}</div>
                                                )}
                                            </div>

                                        </div>
                                    </div>

                                    <button type="submit" className="custom-submit-btn" onClick={handleSubmit}
                                        disabled={submitting}>

                                        {submitting ? 'Submitting...' : 'Update'}
                                    </button>
                                </form>
                            </div>
                        </div> */}

                        <Form >
                            <Row className="mb-3">
                                <Col>
                                    <Form.Control as="select" value={state.year} onChange={handleYearChange}>
                                        {yearsRange.map((year) => (
                                            <option key={year} value={year}>
                                                {year}
                                            </option>
                                        ))}
                                    </Form.Control>
                                </Col>
                                <Col>
                                    <Form.Control as="select" value={state.month} onChange={handleMonthChange}>

                                        {monthNames.map((monthName, index) => (
                                            <option key={index} value={index}>
                                                {monthName}
                                            </option>
                                        ))}
                                    </Form.Control>
                                </Col>
                            </Row>
                        </Form>

                        {/* <FullCalendar
                            ref={calendarRef}
                            plugins={[dayGridPlugin, interactionPlugin]}
                            initialView="dayGridMonth"
                            initialDate={`${new Date().getFullYear()}-${String(new Date().getMonth() + 1).padStart(2, '0')}-01`}
                            events={events}
                            dateClick={handleDateClick}
                            headerToolbar={{
                                left: '',
                                center: 'title',
                                right: '',
                            }}
                            eventContent={(eventInfo) => {
                                const dotColor = eventInfo.event.extendedProps.dotColor;
                                const time = eventInfo.event.extendedProps.time;
                                const title = eventInfo.event.title;

                                return (
                                    <div style={{ display: 'flex', alignItems: 'center', backgroundColor: 'transparent' }}>
                                        <div
                                            style={{
                                                width: '10px',
                                                height: '10px',
                                                borderRadius: '30%',
                                                backgroundColor: dotColor,
                                                border: `2px solid ${dotColor}`,
                                                marginRight: '5px',
                                                cursor: 'pointer',
                                                position: 'absolute',
                                                top: '-25px',
                                                left: '4px',
                                            }}
                                            title={time ? `Time: ${time} - ${title}` : title}
                                        ></div>
                                    </div>
                                );
                            }}
                        /> */}


                        <FullCalendar
                            ref={calendarRef}
                            plugins={[dayGridPlugin, interactionPlugin]}
                            initialView="dayGridMonth"
                            initialDate={`${new Date().getFullYear()}-${String(new Date().getMonth() + 1).padStart(2, '0')}-01`}
                            events={state.events}  // Use the events array from state
                            dateClick={handleDateClick} // Attach date click handler
                            headerToolbar={{
                                left: '',
                                center: 'title',
                                right: '',
                            }}
                            eventContent={(eventInfo) => {
                                const title = eventInfo.event.title;  // The holiday name
                                const backgroundColor = eventInfo.event.backgroundColor; // Get background color from event

                                return (
                                    <div
                                        style={{
                                            display: 'flex',
                                            alignItems: 'center',
                                            backgroundColor: backgroundColor,  // Apply background color directly
                                            borderRadius: '5px',  // Optionally add rounded corners
                                            padding: '3px',
                                            fontSize: '12px',
                                        }}
                                    >
                                        {title}  {/* Display the holiday name inside the calendar box */}
                                    </div>
                                );
                            }}
                        />

                        {selectedDate && (
                            <div
                                style={{
                                    position: 'fixed',
                                    top: '50%',
                                    left: '50%',
                                    transform: 'translate(-50%, -50%)',
                                    padding: '1rem',
                                    backgroundColor: '#fff',
                                    boxShadow: '0 4px 16px rgba(0, 0, 0, 0.2)',
                                    zIndex: 1000,
                                    width: '30vw',
                                    borderRadius: '8px',
                                }}
                            >
                                <h3>Add Holiday</h3>
                                <p>Selected Date: {selectedDate}</p>

                                <div className="row">
                                    <div className="col-md-12">
                                        <div className="mb-3">
                                            <label htmlFor="holiday_name" className="form-label">
                                                Holiday Name
                                            </label>
                                            <input
                                                type="text"
                                                className={`form-control ${errors.holiday_name && 'is-invalid'}`}
                                                id="holiday_name"
                                                name="holiday_name"
                                                value={state.holiday_name}
                                                onChange={handleChange}
                                                placeholder="Enter Holiday Name"
                                            />
                                            {errors.holiday_name && (
                                                <div className="invalid-feedback">{errors.holiday_name}</div>
                                            )}
                                        </div>
                                    </div>
                                </div>

                                <button
                                    onClick={handleSubmit}
                                    style={{
                                        backgroundColor: '#007bff',
                                        color: '#fff',
                                        border: 'none',
                                        padding: '10px 15px',
                                        borderRadius: '4px',
                                        cursor: 'pointer',
                                        width: '100%',
                                    }}
                                >
                                    Update
                                </button>
                            </div>
                        )}

                        {selectedDate && (
                            <div
                                onClick={closePopup}
                                style={{
                                    position: 'fixed',
                                    top: 0,
                                    left: 0,
                                    width: '100%',
                                    height: '100%',
                                    backgroundColor: 'rgba(0, 0, 0, 0.5)',
                                    zIndex: 999,
                                }}
                            ></div>
                        )}
                    </div>


                </div>
            </div>

        </React.Fragment>
    );
};

export default EditHoliday;
