import React, { useState, useEffect, useCallback, useRef } from 'react';
import _debounce from 'lodash/debounce';
import Header from '../../layouts/Header';
import HeaderMobile from '../../layouts/HeaderMobile';
import { Form, Button, Modal, Table } from 'react-bootstrap';
import { FaEdit, FaTrash } from "react-icons/fa";
import { useParams } from 'react-router-dom';
import { viewSalarySetup, editSalarySetup, updateSalarySetup } from '../../services/api/api';
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import { ToastContainer, toast } from 'react-toastify';

const Editsalarysetup = () => {
  const { id } = useParams();
  const [formData, setFormData] = useState({
    name: '',
    basic: [],
    deductions: [],
    allowances: []
  });
  const [masterId, setMasterId] = useState('');
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const [fetchedMasterId, setFetchedMasterId] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [modalAction, setModalAction] = useState("add");
  const [selectedType, setSelectedType] = useState("");
  const [newItem, setNewItem] = useState({ name: "", deduct_type: "", type: '', component: '', status: '' });
  const [editItemDetails, setEditItemDetails] = useState({
    name: '',
    type: '',
  });
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [deleteItemName, setDeleteItemName] = useState("");
  const [deleteType, setDeleteType] = useState("Allowance");
  const [selectedItem, setSelectedItem] = useState(null);
  const [errors, setErrors] = useState({});

  const fetchSalaryData = async () => {
    try {
      setLoading(true);
      const responseData = await viewSalarySetup(id);
      const newMasterId = responseData.data[0]._id;
      setMasterId(newMasterId);
      setFetchedMasterId(true);
      setLoading(false);
    }
    catch (error) {

      setLoading(false);
    } finally {
      setLoading(false);
    }
  };

  const debouncedFetchSalaryData = _debounce(fetchSalaryData, 100);

  useEffect(() => {
    debouncedFetchSalaryData();
    return debouncedFetchSalaryData.cancel;
  }, [id]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        const responseData = await editSalarySetup(id, masterId);
        const { data } = responseData;


        if (data) {
          setFormData({
            name: data.name,
            basic: data.basic || [],
            deductions: data.deduction || [],
            allowances: data.allowence || []
          });

          setLoading(false);
        }
      } catch (error) {
      } finally {
        setLoading(false);
      }
    };

    if (id && fetchedMasterId) {
      fetchData();
    }
  }, [id, masterId, fetchedMasterId]);


  const handleShowModal = (action, name, deduct_type, type, component) => {
    console.log(selectedType);
    console.log(action, name, deduct_type, type, component);

    setModalAction(action);

    // If editing, set the selected item details for editing
    if (action === "edit" && name && deduct_type && type && component) {
      setSelectedItem({ name, deduct_type, type, component });
      setNewItem({ name, deduct_type, type, component });
      setSelectedType(type);
    } else {
      // Reset state for adding a new item
      setNewItem({ name: '', deduct_type: '', type: '', component: '' });
      setSelectedType('');
    }

    setShowModal(true);
  };


  const handleCloseModal = () => {
    console.log(newItem);
    console.log(selectedType);
    setSelectedType('');

    setShowModal(false);
    // Reset the newItem state and editItemDetails state
    setEditItemDetails({ name: '', type: '' });
    setNewItem({ name: '', deduct_type: '', type: '', component: '' });
  };




  // const handleModalSave = () => {

  //   const newErrors = {};

  //   // Validate required fields
  //   if (!selectedType) newErrors.selectedType = 'Please select a type.';
  //   if (!newItem.name) newErrors.name = 'Name is required.';
  //   if (!newItem.deduct_type) newErrors.deduct_type = 'Deduct type is required.';

  //   // Update errors state
  //   setErrors(newErrors);

  //   // If no errors, proceed with saving
  //   if (Object.keys(newErrors).length === 0) {
  //     // Your save logic here
  //     console.log('Saving:', { selectedType, newItem });
  //     handleCloseModal();
  //   }

  //   if (modalAction == "add") {
  //     // Create a new item based on the selected type
  //     const newItemData = {
  //       name: newItem.name,
  //       deduct_type: newItem.deduct_type,
  //       component: newItem.component || newItem.name,
  //     };

  //     // Update the formData state
  //     if (selectedType == "Allowance") {
  //       setFormData((prevData) => ({
  //         ...prevData,
  //         allowances: [...prevData.allowances, newItemData]
  //       }));
  //     } else if (selectedType == "Deduction") {
  //       setFormData((prevData) => ({
  //         ...prevData,
  //         deductions: [...prevData.deductions, newItemData]
  //       }));
  //     }
  //   }
  //   else if (modalAction == "edit") {
  //     // Update the existing item
  //     if (selectedItem.type == "Allowance") {
  //       setFormData((prevData) => ({
  //         ...prevData,
  //         allowances: prevData.allowances.map((item) =>
  //           item.component == selectedItem.component ? { ...item, deduct_type: selectedItem.deduct_type } : item
  //         )
  //       }));

  //     }
  //     else if (selectedItem.type == "Basic") {
  //       setFormData((prevData) => ({
  //         ...prevData,
  //         basic: prevData.basic.map((item) =>
  //           item.component == selectedItem.component ? { ...item, deduct_type: selectedItem.deduct_type } : item
  //         )
  //       }));
  //     }
  //     else if (selectedItem.type == "Deduction") {
  //       setFormData((prevData) => ({
  //         ...prevData,
  //         deductions: prevData.deductions.map((item) =>
  //           item.component == selectedItem.component ? { ...item, deduct_type: selectedItem.deduct_type } : item
  //         )
  //       }));
  //     }
  //   }



  //   // Reset the newItem and selectedItem state
  //   setNewItem({ name: "", deduct_type: "" });
  //   setSelectedItem({ name: "", deduct_type: "", type: "" });

  //   // Close the modal
  //   // handleCloseModal();
  // };

  const handleModalSave = () => {
    const newErrors = {};

    // Validate required fields
    if (!selectedType) newErrors.selectedType = 'Please select a type.';
    if (!newItem.name) newErrors.name = 'Name is required.';
    if (!newItem.deduct_type) newErrors.deduct_type = 'Deduct type is required.';

    // Update errors state
    setErrors(newErrors);

    // If no errors, proceed with saving
    if (Object.keys(newErrors).length === 0) {
      console.log('Saving:', { selectedType, newItem });

      if (modalAction == "add") {
        const newItemData = {
          name: newItem.name,
          deduct_type: newItem.deduct_type,
          component: newItem.component || newItem.name,
          default: false
        };

        // Update the formData state for allowances or deductions
        if (selectedType == "Allowance") {
          setFormData((prevData) => ({
            ...prevData,
            allowances: [...prevData.allowances, newItemData],
          }));
        } else if (selectedType == "Deduction") {
          setFormData((prevData) => ({
            ...prevData,
            deductions: [...prevData.deductions, newItemData],
          }));
        }
      } else if (modalAction == "edit") {
        const updatedItem = {
          name: selectedItem.name,
          deduct_type: selectedItem.deduct_type,
          component: selectedItem.component,
        };

        // Update the formData state for allowances or deductions
        if (selectedItem.type == "Allowance") {
          setFormData((prevData) => ({
            ...prevData,
            allowances: prevData.allowances.map((item) =>
              item.component == selectedItem.component ? updatedItem : item
            ),
          }));
        }
        else if (selectedItem.type == "Deduction") {
          setFormData((prevData) => ({
            ...prevData,
            deductions: prevData.deductions.map((item) =>
              item.component == selectedItem.component ? updatedItem : item
            ),
          }
          ));
        }
        else if (selectedItem.type == "Basic") {
          setFormData((prevData) => ({
            ...prevData,
            basic: prevData.basic.map((item) =>
              item.component == selectedItem.component ? updatedItem : item
            ),
          }
          ));
        }
      }

      // Reset the newItem and selectedItem state
      setNewItem({ name: '', deduct_type: '', type: '', component: '' });
      setSelectedItem({ name: '', deduct_type: '', type: '', component: '' });
      setSelectedType('');

      // Close the modal
      handleCloseModal();
    }
  };

  const handleStatusChange = (e, index, type) => {
    const newStatus = e.target.value;
    const updatedData = { ...formData };
    console.log(updatedData);
    console.log(type);
    console.log(index);


    // Check if formData, arrays, and the index are valid before updating
    if (type == 'basic' && updatedData.basic && updatedData.basic[index]) {
      updatedData.basic[index].status = newStatus;
    }
    else if (type == 'allowance' && updatedData.allowances && updatedData.allowances[index]) {
      updatedData.allowances[index].status = newStatus;
    }
    else if (type == 'deduction' && updatedData.deductions && updatedData.deductions[index]) {
      updatedData.deductions[index].status = newStatus;
    }
    else {
      console.error('Invalid type or index for status change');
      return;
    }

    setFormData(updatedData);
  };



  const handleShowDeleteModal = () => {
    setShowDeleteModal(true);
    setDeleteItemName(""); // Reset item name
  };

  const handleCloseDeleteModal = () => setShowDeleteModal(false);

  const handleDeleteModalSave = () => {

    const deleteErrors = {};

    // Validate required fields
    if (!deleteType) deleteErrors.deleteType = 'Please select a type.';
    if (!deleteItemName) deleteErrors.deleteItemName = 'Please select an item to delete.';

    // Update errors state
    setErrors(deleteErrors);

    // If no errors, proceed with deleting
    if (Object.keys(deleteErrors).length === 0) {
      // Your delete logic here
      console.log('Deleting:', deleteItemName);
      handleCloseDeleteModal();
    }

    // Check if trying to delete a default item
    const isDefault =
      (deleteType == "Allowance" && formData.allowances.some(item => item.name == deleteItemName && item.default == true)) ||
      (deleteType == "Deduction" && formData.deductions.some(item => item.name == deleteItemName && item.default == true));

    if (isDefault) {
      toast.error("You cannot remove default values!"); // Show error toast if trying to delete default values
    } else {

      if (deleteType == "Allowance") {
        setFormData((prevState) => ({
          ...prevState,
          allowances: prevState.allowances.filter(item => item.name != deleteItemName),
        }));
      } else {
        setFormData((prevState) => ({
          ...prevState,
          deductions: prevState.deductions.filter(item => item.name != deleteItemName),
        }));
      }
      toast.success(`${deleteItemName} successfully deleted!`); // Show success toast
      // setShowDeleteModal(false);
    }
  };

  const handleUpdateSalaryStructure = async (e) => {
    console.log(formData);

    e.preventDefault();
    try {
      setLoading(true);
      const response = await updateSalarySetup(id, masterId, formData);
      // Handle the response as needed, e.g., show a success message
      console.log('Salary setup updated successfully:', response);

      if (response) {
        toast.success('Salary Structure updated successfully', {
          position: "top-center",
          autoClose: 1000,
        });
      }
      else {
        toast.error('Something went wrong', {
          position: "top-center",
          autoClose: 1000,
        });
      }
    } catch (error) {
      console.error('Error updating salary setup:', error);
      // Handle the error, e.g., show an error message
    } finally {
      setLoading(false);
    }
    // navigate("/admin/Viewempsalary");
  }

  return (
    <React.Fragment>
      <HeaderMobile />
      <Header />
      <div className="main p-4 p-lg-5 mt-5">
        {/* <h3 className="mb-4">Edit Salary Structure</h3>
        <div className="breadcrumb-warning d-flex justify-content-between ot-card">
          <ol className="breadcrumb ot-breadcrumb ot-breadcrumb-basic">
            <li className="breadcrumb-item">
              <Link to="/admin/dashboard">Dashboard</Link>
            </li>
            <li className="breadcrumb-item active">
              <Link to="/admin/Viewempsalary">Salary Structure</Link>
            </li>
          </ol>
          <Link to="/admin/Viewempsalary" className="btn btn-danger mb-3">
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-arrow-left" viewBox="0 0 16 16">
              <path fillRule="evenodd" d="M15 8a.5.5 0 0 0-.5-.5H2.707l3.147-3.146a.5.5 0 1 0-.708-.708l-4 4a.5.5 0 0 0 0 .708l4 4a.5.5 0 0 0 .708-.708L2.707 8.5H14.5A.5.5 0 0 0 15 8z" />
            </svg>
            Back
          </Link>
        </div> */}
        <div className="d-flex justify-content-between">
          <div>

            <Button variant="primary" onClick={() => handleShowModal("add")} className="me-2">
              Add Salary Name
            </Button>
            <Button variant="danger" onClick={handleShowDeleteModal} className="me-2">
              Remove Salary Name
            </Button>
          </div>
          {/* <Link to="/admin/Viewempsalary" className="btn btn-danger">
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-arrow-left" viewBox="0 0 16 16">
              <path fillRule="evenodd" d="M15 8a.5.5 0 0 0-.5-.5H2.707l3.147-3.146a.5.5 0 1 0-.708-.708l-4 4a.5.5 0 0 0 0 .708l4 4a.5.5 0 0 0 .708-.708L2.707 8.5H14.5A.5.5 0 0 0 15 8z" />
            </svg>
            Back
          </Link> */}
        </div>

        {loading ? (
          <div className="loader-container">
            <div className="loader"></div>
          </div>
        ) : (
          <Form>
            <Form.Group>
              <Form.Label>Name:</Form.Label>
              <Form.Control
                type="text"
                name="name"
                required
                value={formData.name}
                onChange={(e) => setFormData({ ...formData, name: e.target.value })}
              />
            </Form.Group>

            <div style={{ maxHeight: '60vh', overflowY: 'auto' }} className="dynamic-table">
              <Table bordered hover className="table mt-3" >
                <thead style={{ position: 'sticky', top: 0, backgroundColor: 'white', zIndex: 1 }}>
                  <tr style={{ textAlign: "center" }}>
                    <th style={{ fontWeight: 'bold', fontSize: '0.9rem', fontFamily: 'sans-serif', padding: '1rem' }}>Sr No.</th>
                    <th style={{ fontWeight: 'bold', fontSize: '0.9rem', fontFamily: 'sans-serif', padding: '1rem' }}>Type</th>
                    <th style={{ fontWeight: 'bold', fontSize: '0.9rem', fontFamily: 'sans-serif', padding: '1rem' }}>Name</th>
                    <th style={{ fontWeight: 'bold', fontSize: '0.9rem', fontFamily: 'sans-serif', padding: '1rem' }}>Component</th>
                    {/* <th style={{ fontWeight: 'bold', fontSize: '0.9rem', fontFamily: 'sans-serif', padding: '1rem' }}>Add/Deduct Type</th> */}
                    <th style={{ fontWeight: 'bold', fontSize: '0.9rem', fontFamily: 'sans-serif', padding: '1rem' }}>Status</th>
                    <th style={{ fontWeight: 'bold', fontSize: '0.9rem', fontFamily: 'sans-serif', padding: '1rem' }}>Action</th>
                  </tr>
                </thead>
                <tbody>
                  {formData?.basic?.length > 0 && formData.basic.map((item, index) => (
                    <tr key={index}>
                      <td style={{ fontWeight: '500', fontFamily: 'sans-serif', paddingTop: '1.3rem' }}>{index + 1}</td>
                      <td style={{ fontWeight: '500', fontFamily: 'sans-serif', paddingTop: '1.3rem' }}>Basic</td>
                      <td style={{ fontWeight: '500', fontFamily: 'sans-serif', paddingTop: '1.3rem' }}>{item.name}</td>
                      <td style={{ fontWeight: '500', fontFamily: 'sans-serif', paddingTop: '1.3rem' }}>{item.component}</td>
                      <td style={{ fontWeight: '500', fontFamily: 'sans-serif', paddingTop: '1.3rem', color: 'green' }}>{item.status}</td>
                      {/* <td style={{ fontWeight: 'bold', fontFamily: 'sans-serif', paddingTop: '1.3rem' }}>{item.deduct_type}</td> */}
                      <td>
                        <Button
                          variant="link"
                          onClick={() => handleShowModal("edit", item.name, item.deduct_type, "Basic", item.component)}
                        >
                          {/* <FaEdit color="black" /> */}
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="16"
                            height="16"
                            fill="black"
                            className="bi bi-pencil-fill edit ebtn"
                            viewBox="0 0 16 16"
                            style={{ cursor: 'pointer', marginRight: "15px" }}
                          >
                            <path d="M12.854.146a.5.5 0 0 0-.707 0L10.5 1.793 14.207 5.5l1.647-1.646a.5.5 0 0 0 0-.708l-3-3zm.646 6.061L9.793 2.5 3.293 9H3.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.207l6.5-6.5zm-7.468 7.468A.5.5 0 0 1 6 13.5V13h-.5a.5.5 0 0 1-.5-.5V12h-.5a.5.5 0 0 1-.5-.5V11h-.5a.5.5 0 0 1-.5-.5V10h-.5a.499.499 0 0 1-.175-.032l-.179.178a.5.5 0 0 0-.11.168l-2 5a.5.5 0 0 0 .65.65l5-2a.5.5 0 0 0 .168-.11l.178-.178z" />
                          </svg>
                        </Button>
                      </td>
                    </tr>
                  ))}

                  {formData?.allowances?.length > 0 && formData.allowances.map((item, index) => (
                    <tr key={`allowance-${index}`}>
                      <td style={{ fontWeight: '500', fontFamily: 'sans-serif', paddingTop: '1.3rem' }}>{formData.basic.length + index + 1}</td>
                      <td style={{ fontWeight: '500', fontFamily: 'sans-serif', paddingTop: '1.3rem' }}>Allowance</td>
                      <td style={{ fontWeight: '500', fontFamily: 'sans-serif', paddingTop: '1.3rem' }}>{item.name}</td>
                      <td style={{ fontWeight: '500', fontFamily: 'sans-serif', paddingTop: '1.3rem' }}>{item.component}</td>
                      {/* <td style={{ fontWeight: 'bold', fontFamily: 'sans-serif', paddingTop: '1.3rem' }}>{item.deduct_type}</td> */}
                      <td style={{ fontWeight: '500', fontFamily: 'sans-serif', paddingTop: '1.3rem' }}>
                        <select
                          value={item.status}
                          onChange={(e) => handleStatusChange(e, index, 'allowance')}
                          style={{
                            fontWeight: '500',
                            fontFamily: 'sans-serif',
                            padding: '0.3rem',
                            color: item.status === 'Active' ? 'green' : 'red',
                            border: 'none',
                            backgroundColor: 'transparent',
                          }}
                        >
                          <option value="Active">Active</option>
                          <option value="Inactive">Inactive</option>
                        </select>
                      </td>
                      <td>
                        <Button
                          variant="link"
                          onClick={() => handleShowModal("edit", item.name, item.deduct_type, "Allowance", item.component)}

                        >
                          {/* <FaEdit color="black" /> */}
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="16"
                            height="16"
                            fill="black"
                            className="bi bi-pencil-fill edit ebtn"
                            viewBox="0 0 16 16"
                            style={{ cursor: 'pointer', marginRight: "15px" }}
                          >
                            <path d="M12.854.146a.5.5 0 0 0-.707 0L10.5 1.793 14.207 5.5l1.647-1.646a.5.5 0 0 0 0-.708l-3-3zm.646 6.061L9.793 2.5 3.293 9H3.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.207l6.5-6.5zm-7.468 7.468A.5.5 0 0 1 6 13.5V13h-.5a.5.5 0 0 1-.5-.5V12h-.5a.5.5 0 0 1-.5-.5V11h-.5a.5.5 0 0 1-.5-.5V10h-.5a.499.499 0 0 1-.175-.032l-.179.178a.5.5 0 0 0-.11.168l-2 5a.5.5 0 0 0 .65.65l5-2a.5.5 0 0 0 .168-.11l.178-.178z" />
                          </svg>
                        </Button>
                      </td>
                    </tr>
                  ))}

                  {formData?.deductions?.length > 0 && formData.deductions.map((item, index) => (
                    <tr key={`deduction-${index}`}>
                      <td style={{ fontWeight: '500', fontFamily: 'sans-serif', paddingTop: '1.3rem' }}>{formData.basic.length + formData.allowances.length + index + 1}</td>
                      <td style={{ fontWeight: '500', fontFamily: 'sans-serif', paddingTop: '1.3rem' }}>Deduction</td>
                      <td style={{ fontWeight: '500', fontFamily: 'sans-serif', paddingTop: '1.3rem' }}>{item.name}</td>
                      <td style={{ fontWeight: '500', fontFamily: 'sans-serif', paddingTop: '1.3rem' }}>{item.component}</td>
                      {/* <td style={{ fontWeight: 'bold', fontFamily: 'sans-serif', paddingTop: '1.3rem' }}>{item.deduct_type}</td> */}
                      <td style={{ fontWeight: '500', fontFamily: 'sans-serif', paddingTop: '1.3rem' }}>
                        <select
                          value={item.status}
                          onChange={(e) => handleStatusChange(e, index, 'deduction')}
                          style={{
                            fontWeight: '500',
                            fontFamily: 'sans-serif',
                            padding: '0.3rem',
                            color: item.status == 'Active' ? 'green' : 'red',
                            border: 'none',
                            backgroundColor: 'transparent',
                          }}
                        >
                          <option value="Active">Active</option>
                          <option value="Inactive">Inactive</option>
                        </select>
                      </td>
                      <td >
                        <Button
                          variant="link"
                          onClick={() => handleShowModal("edit", item.name, item.deduct_type, "Deduction", item.component)}
                        >
                          {/* <FaEdit color="black" /> */}
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="16"
                            height="16"
                            fill="black"
                            className="bi bi-pencil-fill edit ebtn"
                            viewBox="0 0 16 16"
                            style={{ cursor: 'pointer', marginRight: "15px" }}
                          >
                            <path d="M12.854.146a.5.5 0 0 0-.707 0L10.5 1.793 14.207 5.5l1.647-1.646a.5.5 0 0 0 0-.708l-3-3zm.646 6.061L9.793 2.5 3.293 9H3.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.207l6.5-6.5zm-7.468 7.468A.5.5 0 0 1 6 13.5V13h-.5a.5.5 0 0 1-.5-.5V12h-.5a.5.5 0 0 1-.5-.5V11h-.5a.5.5 0 0 1-.5-.5V10h-.5a.499.499 0 0 1-.175-.032l-.179.178a.5.5 0 0 0-.11.168l-2 5a.5.5 0 0 0 .65.65l5-2a.5.5 0 0 0 .168-.11l.178-.178z" />
                          </svg>
                        </Button>
                      </td>
                    </tr>
                  ))}

                  {formData.basic.length === 0 && formData.allowances.length === 0 && formData.deductions.length === 0 && (
                    <tr>
                      <td colSpan="5">No items available</td>
                    </tr>
                  )}
                </tbody>
              </Table>
            </div>


            <Button variant="primary" type="button" onClick={handleUpdateSalaryStructure} className="mt-3">
              Update
            </Button>
          </Form>
        )}

        <Modal show={showModal} onHide={handleCloseModal}>
          <Modal.Header closeButton>
            <Modal.Title>{modalAction === "add" ? "Add Allowance/Deduction" : "Edit Item"}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {modalAction === "add" && (
              <Form.Group>
                <Form.Label>Select Type</Form.Label>
                <Form.Control
                  as="select"
                  value={selectedType}
                  isInvalid={!!errors.selectedType}
                  onChange={(e) => {
                    setSelectedType(e.target.value);
                    setNewItem({ ...newItem, type: e.target.value });
                    setErrors((prev) => ({ ...prev, selectedType: null })); // Clear error
                  }}
                >
                  <option value="" disabled>Select</option>
                  <option value="Allowance">Allowance</option>
                  <option value="Deduction">Deduction</option>
                </Form.Control>
                <Form.Control.Feedback type="invalid">
                  {errors.selectedType}
                </Form.Control.Feedback>
              </Form.Group>
            )}
            <Form.Group>
              <Form.Label>Name</Form.Label>
              <Form.Control
                type="text"
                isInvalid={!!errors.name}
                value={modalAction == "edit" ? selectedItem.name : newItem.name}
                onChange={(e) => {
                  const value = e.target.value;
                  if (modalAction == "edit") {
                    setSelectedItem({ ...selectedItem, name: value });
                  } else {
                    setNewItem({ ...newItem, name: value, component: value });
                  }
                  setErrors((prev) => ({ ...prev, name: null })); // Clear error
                }}
              />
              <Form.Control.Feedback type="invalid">
                {errors.name}
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group>
              <Form.Label>Add/Deduct Type</Form.Label>
              <Form.Control
                as="select"
                isInvalid={!!errors.deduct_type}
                value={modalAction === "edit" ? selectedItem.deduct_type : newItem.deduct_type}
                onChange={(e) => {
                  const value = e.target.value;
                  if (modalAction === "edit") {
                    setSelectedItem({ ...selectedItem, deduct_type: value });
                  } else {
                    setNewItem({ ...newItem, deduct_type: value });
                  }
                  setErrors((prev) => ({ ...prev, deduct_type: null })); // Clear error
                }}
              >
                <option value="">Select Type</option>
                <option value="Fixed">Fixed</option>
                <option value="Percentage">Percentage</option>
              </Form.Control>
              <Form.Control.Feedback type="invalid">
                {errors.deduct_type}
              </Form.Control.Feedback>
            </Form.Group>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleCloseModal}>
              Close
            </Button>
            <Button variant="primary" onClick={handleModalSave}>
              Save
            </Button>
          </Modal.Footer>
        </Modal>

        <Modal show={showDeleteModal} onHide={handleCloseDeleteModal}>
          <Modal.Header closeButton>
            <Modal.Title>Delete Item</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form.Group>
              <Form.Label>Select Type</Form.Label>
              <Form.Control
                as="select"
                value={deleteType}
                isInvalid={!!errors.deleteType}
                onChange={(e) => {
                  setDeleteType(e.target.value);
                  setDeleteItemName(""); // Reset the selected item when type changes
                  setErrors((prev) => ({ ...prev, deleteType: null })); // Clear error
                }}
              >
                <option value="Allowance">Allowance</option>
                <option value="Deduction">Deduction</option>
              </Form.Control>
              <Form.Control.Feedback type="invalid">
                {errors.deleteType}
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group>
              <Form.Label>Select Item to Delete</Form.Label>
              <Form.Control
                as="select"
                value={deleteItemName}
                isInvalid={!!errors.deleteItemName}
                onChange={(e) => {
                  setDeleteItemName(e.target.value);
                  setErrors((prev) => ({ ...prev, deleteItemName: null })); // Clear error
                }}
              >
                <option value="">Select Item</option>
                {(deleteType === "Allowance"
                  ? formData.allowances.filter(item => item.default != true)
                  : formData.deductions.filter(item => item.default != true)
                ).map((item, index) => (
                  <option key={index} value={item.name}>
                    {item.name}
                  </option>
                ))}
              </Form.Control>
              <Form.Control.Feedback type="invalid">
                {errors.deleteItemName}
              </Form.Control.Feedback>
            </Form.Group>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleCloseDeleteModal}>
              Close
            </Button>
            <Button variant="danger" onClick={handleDeleteModalSave}>
              Delete
            </Button>
          </Modal.Footer>
        </Modal>

        <ToastContainer />
      </div>
    </React.Fragment>
  );
};

export default Editsalarysetup;
