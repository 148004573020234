import React, { useState, useEffect, useRef } from 'react';
import _debounce from 'lodash/debounce';
import HeaderMobile from '../../../layouts/HeaderMobile';
import Header from '../../../layouts/Header';
import { Card, Button, Modal, Row, Col, Form, Table } from "react-bootstrap";
import 'datatables.net';
import { viewAddEmployeeLeave, deleteAddEmployeeLeave, approveLeave, viewemployeeleavepaggination, searchemployeeleave } from '../../../services/api/api'
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { DataGrid } from '@mui/x-data-grid';
import * as XLSX from 'xlsx'; // Import XLSX library
import { styled, alpha } from '@mui/material/styles';
// import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import EditIcon from '@mui/icons-material/Edit';
import Divider from '@mui/material/Divider';
import ArchiveIcon from '@mui/icons-material/Archive';
import FileCopyIcon from '@mui/icons-material/FileCopy';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import MenuIcon from '@mui/icons-material/Menu';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEllipsisVertical } from '@fortawesome/free-solid-svg-icons';
import { TextField, Tooltip } from '@mui/material';


const StyledMenu = styled((props) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'right',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'right',
    }}
    {...props}
  />
))(({ theme }) => ({
  '& .MuiPaper-root': {
    borderRadius: 6,
    marginTop: theme.spacing(1),
    minWidth: 180,
    color: 'rgb(55, 65, 81)',
    boxShadow:
      'rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
    '& .MuiMenu-list': {
      padding: '4px 0',
    },
    '& .MuiMenuItem-root': {
      '& .MuiSvgIcon-root': {
        fontSize: 18,
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(1.5),
      },
      '&:active': {
        backgroundColor: alpha(
          theme.palette.primary.main,
          theme.palette.action.selectedOpacity,
        ),
      },
    },
  },
}));

const ViewAddEmpLeave = () => {
  const navigate = useNavigate();
  const tableRef = useRef(null);
  const [employeeDataData, setAddEmployeeDataData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [deleteEMployeeLeaveId, setDeleteEmployeeLeaveId] = useState(null);
  const [masterId, setMasterId] = useState();
  const [searchText, setSearchText] = useState('');
  const [modalOpen, setModalOpen] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [flag, setFlag] = useState(false);
  const [rowStates, setRowStates] = useState({});
  const [leaveData, setLeaveData] = useState({});
  const [years, setYears] = useState([]);
  const [months, setMonths] = useState([]);
  const [status, setStatus] = useState('Pending')
  const [selectedDate, setSelectedDate] = useState({
    year: new Date().getFullYear(),  // Set current year as initial value
    month: new Date().getMonth() + 1  // Set current month (1-12) as initial value
  });

  const [dropdownOpen, setDropdownOpen] = useState(null);
  // const [open, setOpen] = React.useState(true);

  const [anchorEls, setAnchorEls] = React.useState({});
  const [totalPages, setTotalPages] = useState(1);
  const [page, setPage] = useState(1);
  const [pageSize, setpageSize] = useState(100);

  const handlePageChange = (event) => {
    const newPage = parseInt(event.target.value, 10);
    setPage(newPage);
    console.log('Selected Page:', newPage);
  };

  const handlePageSizeChange = (event) => {
    console.log('Page Change Event:', event);
    const newPageSize = parseInt(event.target.value, 10);
    setpageSize(newPageSize);
    console.log('Selected Page Size:', newPageSize);
  };

  const handleClick = (event, id) => {
    setAnchorEls((prev) => ({
      ...prev,
      [id]: event.currentTarget,
    }));
  };

  const handleClose = (id, params, mainId, subId, statusName, status) => {
    console.log(statusName);
    console.log(status);
    console.log(mainId);
    console.log(subId);
    console.log(params);
    handleChangeStatus(mainId, subId, statusName, status, params.row.employee_id, params.row.total_days, params.row.from_date, params.row.to_date)
    setStatus(statusName)
    setAnchorEls((prev) => ({
      ...prev,
      [id]: null,
    }));
  };

  const handleViewEmployee = async (params) => {
    console.log(params);
    setModalOpen(true);
    setLeaveData(params.row)

  };

  const handleChangeStatus = async (id, subId, statusName, status, employee_id, total_days, from_date, to_date) => {
    try {
      const formattedData = await approveLeave(id, subId, status, statusName, employee_id, total_days, from_date, to_date);
      console.log(formattedData);

      if (formattedData.message) {
        toast.success(formattedData.message, { autoClose: 1000 });
        if (!searchText) {
          fetchData();
        }
        else {
          fetchSearchData();
        }
      }
    } catch (error) {
      console.error('Error fetching data:', error);
    } finally {
      setLoading(false);
    }
  }



  useEffect(() => {

    const generatedYears = Array.from({ length: 31 }, (_, i) => 2020 + i);
    setYears(generatedYears);

    const monthNames = [
      { name: 'january', number: 1 },
      { name: 'february', number: 2 },
      { name: 'march', number: 3 },
      { name: 'april', number: 4 },
      { name: 'may', number: 5 },
      { name: 'june', number: 6 },
      { name: 'july', number: 7 },
      { name: 'august', number: 8 },
      { name: 'september', number: 9 },
      { name: 'october', number: 10 },
      { name: 'november', number: 11 },
      { name: 'december', number: 12 },
    ];
    setMonths(monthNames);
  }, []);


  const handleChange = (e) => {
    const { name, value } = e.target;

    if (name == 'year') {
      setSelectedDate((prev) => ({
        ...prev,
        year: value,
      }));
    } else if (name == 'month') {
      const monthNumber = months.find(month => month.name === value).number;
      setSelectedDate((prev) => ({
        ...prev,
        month: monthNumber,
      }));
    }
  };

  const fetchData = async () => {
    const currentDate = new Date();
    const currentYear = currentDate.getFullYear();
    const currentMonth = currentDate.getMonth() + 1; // getMonth() returns 0-11

    // Use selected date or current date
    const yearToUse = selectedDate.year || currentYear;
    const monthToUse = selectedDate.month || currentMonth;

    let toastDisplayed = false;

    console.log(`Fetching data for Year: ${yearToUse}, Month: ${monthToUse}`);

    try {
      setLoading(true)
      console.log(toastDisplayed);

      const formattedData = await viewemployeeleavepaggination(page, pageSize, yearToUse, monthToUse);
      console.log(formattedData);

      // if (formattedData.data && formattedData.data.length > 0) {
      //   const employeeLeaveData = formattedData.data.map((item, index) => {
      //     const employeeLeave = item.employee_leave || {};
      //     let srNo = formattedData.data.length

      //     console.log(srNo);

      //     return {
      //       id: item._id,
      //       ind: index, // Adjusting index for display purposes
      //       length: srNo, // Adjusting index for display purposes
      //       subId: employeeLeave._id || '', // Ensure subId is correctly mapped
      //       employee_name: employeeLeave.employee_name || 'N/A',
      //       employee_id: employeeLeave.employee_id || 'N/A',
      //       total_days: employeeLeave.total_days || 'N/A',
      //       leave_type: employeeLeave.leave_type || 'N/A',
      //       from_date: employeeLeave.from_date || 'N/A',
      //       to_date: employeeLeave.to_date || 'N/A',
      //       total_days: employeeLeave.total_days || 'N/A',
      //       remaining_leaves: employeeLeave.remaining_leaves !== null ? String(employeeLeave.remaining_leaves) : '0',
      //       leave_reason: employeeLeave.leave_reason || 'N/A',
      //       statusName: employeeLeave.statusName || '', // Adjusted to use status directly
      //     };
      //   });


      const sortedEmployeeLeaveData = formattedData.data.sort((a, b) => {
        // Convert the from_date strings into Date objects for comparison
        const dateA = new Date(a.from_date);
        const dateB = new Date(b.from_date);
        return dateB - dateA; // Ascending order, for descending use dateB - dateA
      });

      console.log(sortedEmployeeLeaveData);


      if (sortedEmployeeLeaveData && sortedEmployeeLeaveData.length > 0) {
        const employeeLeaveData = sortedEmployeeLeaveData.map((item, index) => {
          const employeeLeave = item.employee_leave || {};
          let srNo = sortedEmployeeLeaveData.length

          console.log(srNo);

          return {
            id: item._id,
            ind: index, // Adjusting index for display purposes
            length: srNo, // Adjusting index for display purposes
            subId: employeeLeave._id || '', // Ensure subId is correctly mapped
            employee_name: employeeLeave.employee_name || 'N/A',
            employee_id: employeeLeave.employee_id || 'N/A',
            total_days: employeeLeave.total_days || 'N/A',
            leave_type: employeeLeave.leave_type || 'N/A',
            from_date: employeeLeave.from_date || 'N/A',
            to_date: employeeLeave.to_date || 'N/A',
            total_days: employeeLeave.total_days || 'N/A',
            remaining_leaves: employeeLeave.remaining_leaves !== null ? String(employeeLeave.remaining_leaves) : '0',
            leave_reason: employeeLeave.leave_reason || 'N/A',
            statusName: employeeLeave.statusName || '', // Adjusted to use status directly
            appliedOn: employeeLeave.created_at,
          };
        });


        console.log(sortedEmployeeLeaveData);


        console.log(sortedEmployeeLeaveData);
        setMasterId(formattedData.data[0]._id); // Get the master ID from the first item
        setAddEmployeeDataData(employeeLeaveData); // Set the formatted data
        setTotalPages(formattedData.totalPages); // Set total pages
        setpageSize(formattedData.pageSize);
      }
      else {
        setAddEmployeeDataData([]);
        // if (toastDisplayed == false) {
        //   toast.info(`No leaves for this month`, {
        //     position: "top-center",
        //     autoClose: 2000,
        //   });
        //   toastDisplayed = true; // Set flag to true after showing toast
        // }
      }
    } catch (error) {
      setLoading(false)
      console.error('Error fetching data:', error);
    } finally {
      setLoading(false);
    }
  };

  const fetchSearchData = async () => {
    const currentDate = new Date();
    const currentYear = currentDate.getFullYear();
    const currentMonth = currentDate.getMonth() + 1; // getMonth() returns 0-11

    // Use selected date or current date
    const yearToUse = selectedDate.year || currentYear;
    const monthToUse = selectedDate.month || currentMonth;

    console.log(`Fetching data for Year: ${yearToUse}, Month: ${monthToUse}`);

    let toastDisplayed = false;

    try {
      const formattedData = await searchemployeeleave(page, pageSize, yearToUse, monthToUse, searchText);
      console.log(formattedData);

      // if (formattedData.data && formattedData.data.length > 0) {
      //   const employeeLeaveData = formattedData.data.map((item, index) => {
      //     const employeeLeave = item.employee_leave || {};

      //     return {
      //       id: item._id,
      //       ind: index + 1, // Adjusting index for display purposes
      //       subId: employeeLeave._id || '', // Ensure subId is correctly mapped
      //       employee_name: employeeLeave.employee_name || 'N/A',
      //       employee_id: employeeLeave.employee_id || 'N/A',
      //       total_days: employeeLeave.total_days || 'N/A',
      //       leave_type: employeeLeave.leave_type || 'N/A',
      //       from_date: employeeLeave.from_date || 'N/A',
      //       to_date: employeeLeave.to_date || 'N/A',
      //       total_days: employeeLeave.total_days || 'N/A',
      //       remaining_leaves: employeeLeave.remaining_leaves !== null ? String(employeeLeave.remaining_leaves) : '0',
      //       leave_reason: employeeLeave.leave_reason || 'N/A',
      //       statusName: employeeLeave.statusName || '', // Adjusted to use status directly
      //     };
      //   });


      const sortedEmployeeLeaveData = formattedData.data.sort((a, b) => {
        // Convert the from_date strings into Date objects for comparison
        const dateA = new Date(a.from_date);
        const dateB = new Date(b.from_date);
        return dateB - dateA; // Ascending order, for descending use dateB - dateA
      });

      if (sortedEmployeeLeaveData && sortedEmployeeLeaveData.length > 0) {
        const employeeLeaveData = sortedEmployeeLeaveData.map((item, index) => {
          const employeeLeave = item.employee_leave || {};
          let srNo = sortedEmployeeLeaveData.length

          console.log(srNo);

          return {
            id: item._id,
            ind: index, // Adjusting index for display purposes
            length: srNo, // Adjusting index for display purposes
            subId: employeeLeave._id || '', // Ensure subId is correctly mapped
            employee_name: employeeLeave.employee_name || 'N/A',
            employee_id: employeeLeave.employee_id || 'N/A',
            total_days: employeeLeave.total_days || 'N/A',
            leave_type: employeeLeave.leave_type || 'N/A',
            from_date: employeeLeave.from_date || 'N/A',
            to_date: employeeLeave.to_date || 'N/A',
            total_days: employeeLeave.total_days || 'N/A',
            remaining_leaves: employeeLeave.remaining_leaves !== null ? String(employeeLeave.remaining_leaves) : '0',
            leave_reason: employeeLeave.leave_reason || 'N/A',
            statusName: employeeLeave.statusName || '', // Adjusted to use status directly
            appliedOn: employeeLeave.created_at,
          };
        });



        console.log(sortedEmployeeLeaveData);
        setMasterId(formattedData.data[0]._id); // Get the master ID from the first item
        setAddEmployeeDataData(employeeLeaveData); // Set the formatted data
        setTotalPages(formattedData.totalPages); // Set total pages
        setpageSize(formattedData.pageSize);
      }
      else {
        setAddEmployeeDataData([]);
        // if (!toastDisplayed) {
        //   toast.info(`No leaves for this month`, {
        //     position: "top-center",
        //     autoClose: 2000,
        //   });
        //   toastDisplayed = true; // Set flag to true after showing toast
        // }
      }
    } catch (error) {
      console.error('Error fetching data:', error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (!searchText) {
      fetchData();
    }
    else {
      fetchSearchData();
    }
  }, [status, selectedDate.year, selectedDate.month, page, pageSize, searchText])

  const s2ab = (s) => {
    const buf = new ArrayBuffer(s.length);
    const view = new Uint8Array(buf);
    for (let i = 0; i !== s.length; ++i) {
      view[i] = s.charCodeAt(i) & 0xFF;
    }
    return buf;
  };

  const exportToExcel = () => {
    // Create a new workbook
    const workbook = XLSX.utils.book_new();
    // Create a new worksheet with the data
    const worksheet = XLSX.utils.json_to_sheet(
      employeeDataData.map((row, index) => ({
        'Sr.': index + 1,
        'Employee Name': row.employee_name || '', // Update field names here
        'Leave Type': row.leave_type || '', // Update field names here
        'From Date': row.from_date || '', // Update field names here
        'To Date': row.to_date || '', // Update field names here
        'Total Days': row.total_days || '', // Update field names here
        'Remaining Leaves': row.remaining_leaves || '', // Update field names here
        'Leave Reason': row.leave_reason || '', // Update field names here
      }))
    );
    // Add the worksheet to the workbook
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');
    // Convert the workbook to a binary string
    const excelBinaryString = XLSX.write(workbook, { bookType: 'xlsx', type: 'binary' });
    // Convert the binary string to a Blob
    const excelBlob = new Blob([s2ab(excelBinaryString)], { type: 'application/octet-stream' });
    // Create a download link
    const downloadLink = document.createElement('a');
    downloadLink.href = URL.createObjectURL(excelBlob);
    downloadLink.download = 'Employee Leaves Data.xlsx';
    // Trigger the download
    downloadLink.click();
  };
  const reversedRows = [...employeeDataData].reverse();

  const columns = [

    {
      field: 'subId',
      headerName: 'Sr. No',
      minWidth: 50,
      align: 'center',
      headerAlign: 'center',
      flex: 0.5,
      renderCell: (params) => {
        console.log(params);

        return params.row.length - params.row.ind; // Ensure this is returned properly
      }
    },
    { field: 'employee_name', headerName: 'Employee', minWidth: 100, flex: 1, align: 'center', headerAlign: 'center', },
    { field: 'leave_type', headerName: 'Leave Type', minWidth: 100, flex: 1, align: 'center', headerAlign: 'center', },
    { field: 'from_date', headerName: 'From', minWidth: 100, flex: 1, align: 'center', headerAlign: 'center', },
    { field: 'to_date', headerName: 'To', minWidth: 100, flex: 1, align: 'center', headerAlign: 'center' },
    // { field: 'remaining_leaves', headerName: 'Remaining', width: 100, flex: 1, align: 'center', headerAlign: 'center', },
    { field: 'leave_reason', headerName: 'Reason', minWidth: 150, flex: 1, align: 'center', headerAlign: 'center', },
    { field: 'appliedOn', headerName: 'Applied On', minWidth: 150, flex: 2, align: 'center', headerAlign: 'center', },
    {
      field: 'statusName',
      headerName: 'Status',
      align: 'center',
      minWidth: 100,
      flex: 1,
      headerAlign: 'center',
      renderCell: (params) => {
        console.log(params);
        let color;
        switch (params.value) {
          case 'Pending':
            color = 'blue';
            break;
          case 'Approve':
            color = 'green';
            break;
          case 'Reject':
            color = 'red';
            break;
          case 'Paid Leave':
            color = 'purple';
            break;
          default:
            color = 'black'; // Default color if none match
        }

        return (
          <div style={{ color: color, textAlign: 'center' }}>
            {params.row.statusName}
          </div>
        );
      }
    },
    {
      field: 'actions',
      headerName: 'Actions',
      minWidth: 100,
      flex: 1,
      align: 'center',
      headerAlign: 'center',
      renderCell: (params) => {
        console.log(params)

        const { subId: id } = params.row;
        return (
          <div style={{ display: 'flex', alignItems: 'center' }}>


            {/* SVG for Viewing Employee */}
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              fill="black"
              className="bi bi-eye-fill"
              viewBox="0 0 16 16"
              style={{ cursor: 'pointer', marginRight: '0.5rem' }}
              onClick={() => handleViewEmployee(params)}
            >
              <path d="M10.5 8a2.5 2.5 0 1 1-5 0 2.5 2.5 0 0 1 5 0" />
              <path d="M0 8s3-5.5 8-5.5S16 8 16 8s-3 5.5-8 5.5S0 8 0 8m8 3.5a3.5 3.5 0 1 0 0-7 3.5 3.5 0 0 0 0 7" />
            </svg>

            {/* SVG for Deleting */}
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              fill="black"
              className="bi bi-trash3-fill delete delete-button"
              viewBox="0 0 16 16"
              data-id={id}
              style={{ cursor: 'pointer' }}
              onClick={() => openConfirmationModal(id)}
            >
              <path d="M11 1.5v1h3.5a.5.5 0 0 1 0 1h-.538l-.853 10.66A2 2 0 0 1 11.115 16h-6.23a2 2 0 0 1-1.994-1.84L2.038 3.5H1.5a.5.5 0 0 1 0-1H5v-1A1.5 1.5 0 0 1 6.5 0h3A1.5 1.5 0 0 1 11 1.5Zm-5 0v1h4v-1a.5.5 0 0 0-.5-.5h-3a.5.5 0 0 0-.5.5ZM4.5 5.029l.5 8.5a.5.5 0 1 0 .998-.06l-.5-8.5a.5.5 0 1 0-.998.06Zm6.53-.528a.5.5 0 0 0-.528.47l-.5 8.5a.5.5 0 0 0 .998.058l.5-8.5a.5.5 0 0 0-.47-.528ZM8 4.5a.5.5 0 0 0-.5.5v8.5a.5.5 0 0 0 1 0V5a.5.5 0 0 0-.5-.5Z" />
            </svg>

            <Button
              id={`demo-customized-button-${id}`}
              aria-controls={anchorEls[id] ? `demo-customized-menu-${id}` : undefined}
              aria-haspopup="true"
              aria-expanded={anchorEls[id] ? 'true' : undefined}
              variant="contained"
              disableElevation
              onClick={(event) => handleClick(event, id)}
              endIcon={<KeyboardArrowDownIcon />}
            >
              <FontAwesomeIcon icon={faEllipsisVertical} />
            </Button>
            <StyledMenu
              id={`demo-customized-menu-${id}`}
              MenuListProps={{
                'aria-labelledby': `demo-customized-button-${id}`,
              }}
              anchorEl={anchorEls[id]}
              open={Boolean(anchorEls[id])}
              onClose={() => handleClose(id)}
            >
              <MenuItem onClick={() => handleClose(id, params, params.row.id, params.row.subId, "Pending", "1")} disableRipple>
                {/* <EditIcon /> */}
                Pending
              </MenuItem>
              <MenuItem onClick={() => handleClose(id, params, params.row.id, params.row.subId, "Approve", "2")} disableRipple>
                {/* <FileCopyIcon /> */}
                Approve
              </MenuItem>
              {/* <Divider sx={{ my: 0.5 }} /> */}
              <MenuItem onClick={() => handleClose(id, params, params.row.id, params.row.subId, "Paid Leave", "4")} disableRipple>
                {/* <ArchiveIcon /> */}
                Paid Leave
              </MenuItem>
              <MenuItem onClick={() => handleClose(id, params, params.row.id, params.row.subId, "Reject", "3")} disableRipple>
                {/* <MoreHorizIcon /> */}
                Reject
              </MenuItem>
            </StyledMenu>
          </div>
        );
      },
    },

  ];



  const handleSearch = (event) => {
    setSearchText(event.target.value);
  };

  const filterRows = () => {
    if (!searchText) {
      return [...employeeDataData].reverse();
    }

    return [...employeeDataData].reverse().filter((row) =>
      Object.values(row).some(
        (value) =>
          typeof value === 'string' && value.toLowerCase().includes(searchText.toLowerCase())
      )
    );
  };

  const handleDeleteEmployeeLeave = async (id, masterId) => {
    setDeleteEmployeeLeaveId(id);
    setShowConfirmation(true);

    try {
      const response = await deleteAddEmployeeLeave(id, masterId);
      if (response && response.status == 'success') {
        if (!toast.isActive('addEmployeeLeaveDeletedToast')) {
          toast.success('Employee Leave deleted successfully', { toastId: 'addEmployeeLeaveDeletedToast', autoClose: 1000 });
        }
        fetchData();
      } else {
        // Handle failure case
      }
    } catch (error) {
      console.error('Error deleting department:', error);
    }
  };

  const openConfirmationModal = (id) => {
    setDeleteEmployeeLeaveId(id);
    setShowConfirmation(true);
  };

  const closeConfirmationModal = () => {
    setShowConfirmation(false);
  };

  const closeLeaveModal = () => {
    setModalOpen(false);
  };

  const handlePendingModalClose = () => {
    setShowModal(false);
  };

  const handlePendingModalShow = () => {
    setShowModal(true);
  };

  return (
    <React.Fragment>
      {/* Header components */}
      <HeaderMobile />
      <Header />
      <div className="main p-4 p-lg-5 mt-5">
        {/* Breadcrumb section */}
        <div className="breadcrumb-warning d-flex justify-content-between ot-card">
          <div>
            <ol className="breadcrumb ot-breadcrumb ot-breadcrumb-basic">
              <li class="breadcrumb-item ">
                <Link to="/admin/dashboard">Dashboard</Link>
              </li>
              <li class="breadcrumb-item active"><Link to="/pages/empleave/empleave"> Employee Leaves</Link></li>
              {/* <li class="breadcrumb-item active">Lists</li> */}
            </ol>
          </div>
          <nav aria-label="breadcrumb">

          </nav>
        </div>
        <Card className="popup-card empHistory">
          <Card.Body>
            <div style={{ display: 'flex', justifyContent: "center", alignItems: 'center', justifyContent: 'space-between', marginBottom: "1rem" }}>
              <div>
                <Button className='btn btn-primary border-0' onClick={exportToExcel}>Export</Button>
                <Button className='btn btn-primary border-0' onClick={() => {
                  {
                    navigate('/pendingLeaves')
                  }
                }}>Pending Leaves</Button>

              </div>
              {/* <div style={{ display: 'flex', alignItems: 'center' }}>
                <label htmlFor="searchInput"></label>
                <input
                  type="text"
                  className='p-1 w-75 ms-2'
                  placeholder="Search"
                  value={searchText}
                  onChange={handleSearch}
                  />
                  </div> */}
              <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}>
              </div>
            </div>

            <div className="col-md-11 mb-3">
              <Row>
                <Col md={2} className="mb-3 pt-3">
                  <Form.Group>
                    <Form.Control
                      as="select"
                      name="year"
                      value={selectedDate.year}
                      onChange={(e) => {
                        handleChange(e)
                        // handleviewsalarystructuresetup(e)
                      }}
                    >
                      <option value="">Select Year</option>
                      {years.map((year) => (
                        <option key={year} value={year}>
                          {year}
                        </option>
                      ))}
                    </Form.Control>
                  </Form.Group>
                </Col>

                <Col md={2} className="mb-3 pt-3">
                  <Form.Group>
                    <Form.Control
                      as="select"
                      name="month"
                      value={months.find(month => month.number == selectedDate.month)?.name || ''}  // Set the value attribute
                      onChange={handleChange}
                    >
                      <option value="">Select Month</option>
                      {months.map((month) => (
                        <option key={month.number} value={month.name}>
                          {month.name.charAt(0).toUpperCase() + month.name.slice(1)}
                        </option>
                      ))}
                    </Form.Control>
                  </Form.Group>
                </Col>
                <Col md={5} className="mb-3 pt-3">
                </Col>
                <Col md={3} className="mb-3 pt-3 d-flex justify-content-end pe-0">
                  <TextField
                    label="Search by Employee Name"
                    variant="outlined"
                    value={searchText}
                    onChange={handleSearch}
                    size="small"

                  />
                </Col>
              </Row>
            </div>


            {loading ? (
              <div className="loader-container">
                <div className="loader"></div>
              </div>
            ) : (
              <div style={{ height: '70vh', width: '100%', backgroundColor: 'white' }}>
                <DataGrid
                  rows={employeeDataData}
                  columns={columns}
                  pageSize={pageSize}
                  pagination
                  paginationMode="client"
                  getRowId={(row) => row.subId}
                  // pageSize={5}
                  // components={{
                  //   NoRowsOverlay: () => <div style={{ textAlign: 'center', padding: '20px' }}>No data available</div>,
                  // }}
                  components={{
                    Pagination: () => (
                      <div className="custom-select-container">
                        <div>
                          <label htmlFor="page-size-reversed">Rows per page: </label>
                          <select
                            id="page-size-reversed"
                            value={pageSize}
                            onChange={handlePageSizeChange} // Handle page size changes
                          >
                            {/* <option value={1}>1</option>
                            <option value={10}>10</option>
                            <option value={20}>20</option> */}
                            <option value={50}>50</option>
                            <option value={100}>100</option>
                          </select>
                        </div>
                        <div>
                          <label htmlFor="page-number-reversed">Page: </label>
                          <select
                            className="page-number"
                            id="page-number-reversed"
                            value={page}
                            onChange={handlePageChange} // Handle page changes
                          >
                            {[...Array(totalPages)].map((_, pageNumber) => (
                              <option key={pageNumber} value={pageNumber + 1}>
                                {pageNumber + 1}
                              </option>
                            ))}
                          </select>
                        </div>
                      </div>
                    ),
                    NoRowsOverlay: () => (
                      <div style={{ textAlign: "center", padding: "20px" }}>
                        No data available
                      </div>
                    ),
                  }}

                  rowCount={employeeDataData.length} // Use the length of reversedRows to show total records
                  page={page - 1} // Current page (0-based index)
                  onPageChange={(newPage) => setPage(newPage + 1)} // Handle page change
                  rowsPerPageOptions={[]}
                />

              </div>
            )}
          </Card.Body>
        </Card>
      </div>

      <Modal show={modalOpen} onHide={closeLeaveModal} centered size="xl">

        <Modal.Header closeButton>
          <Modal.Title>
            <p className="">Leave Details</p>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ background: '#a7cbf2' }}>

          <div className="row">
            <div className="col-md-12">
              <div className="card mb-3 shadow-sm">
                <div className="card-body">

                  <div className="field-box mb-3">
                    <strong>Employee Name:</strong> {leaveData.employee_name}
                  </div>
                  <div className="field-box mb-3">
                    <strong>From:</strong> {leaveData.from_date}
                  </div>
                  <div className="field-box mb-3">
                    <strong>To Name:</strong> {leaveData.to_date}
                  </div>
                  <div className="field-box mb-3">
                    <strong>Total Days:</strong> {leaveData.total_days}
                  </div>
                  <div className="field-box mb-3">
                    <strong>Remaining Leaves:</strong> {leaveData.remaining_leaves}
                  </div>
                  <div className="field-box mb-3">
                    <strong>Leave Type:</strong> {leaveData.leave_type}
                  </div>
                  <div className="field-box mb-3">
                    <strong>Reason:</strong> {leaveData.leave_reason}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>


        <div>
          <Modal.Body style={{ background: '#a7cbf2' }}>
          </Modal.Body>

        </div>

        <Modal.Footer style={{ background: '#d3d3d375' }}>
          <Button variant="secondary" onClick={closeLeaveModal}>Close</Button>
        </Modal.Footer>
      </Modal>

      <Modal show={showConfirmation} onHide={closeConfirmationModal}>
        <Modal.Header closeButton>
          <Modal.Title>Confirm Delete</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Are you sure you want to delete this Employee Leave?
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={closeConfirmationModal}>
            Cancel
          </Button>
          <Button
            variant="danger"
            onClick={() => {
              handleDeleteEmployeeLeave(deleteEMployeeLeaveId, masterId);
              closeConfirmationModal();
            }}

          >
            Delete
          </Button>

        </Modal.Footer>
      </Modal>


      <Modal show={showModal} onHide={handlePendingModalClose} >
        <Modal.Header closeButton>
          <Modal.Title>Pending Leaves</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Table striped bordered hover>
            <thead>
              <tr>
                <th style={{ textAlign: 'center' }}>Employee</th>
                <th style={{ textAlign: 'center' }}>Leaves</th>
              </tr>
            </thead>
            {/* <tbody>
              {leaveData && leaveData.length > 0 ? (
                leaveData.map((leave, index) => (
                  <tr key={index}>
                    <td>{leave.employee_leave.employee_name}</td>
                    <td>{leave.employee_leave.total_days}</td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan="2" className="text-center">
                    No pending leaves
                  </td>
                </tr>
              )}
            </tbody> */}
          </Table>
        </Modal.Body>

        <Modal.Footer>
          <Button variant="secondary" onClick={handlePendingModalClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>

    </React.Fragment>
  );
};

export default ViewAddEmpLeave
