import React, { useState, useEffect } from 'react';
import _debounce from 'lodash/debounce';
import { addEmployeeAddImage, editEmployee, editEmployeeAddImage, updateUser, viewBranch, viewDepartment, viewDesignation, viewShift } from '../../services/api/api';
import { updateEmployee } from '../../services/api/api';
import Header from '../../layouts/Header';
import HeaderMobile from '../../layouts/HeaderMobile';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Form, Button } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { Link } from "react-router-dom";
import Select from 'react-select';
import imageCompression from 'browser-image-compression';



const EditProfile = () => {

    // const { shiftData } = props;

    const navigate = useNavigate();
    const currentPath = window.location.pathname;
    const parts = currentPath.split('/');
    // const id = parts[parts.length - 1];
    const id = sessionStorage.getItem('id')
    const [loading, setLoading] = useState(true);
    const [masterId, setMasterId] = useState();

    const [branches, setBranchs] = useState([]);
    const [selectedBranchIds, setSelectedBranchIds] = useState([]);
    const [selectedBranchOptions, setSelectedBranchOptions] = useState([]);
    const [updating, setUpdating] = useState(false);
    const [flag, setFlag] = useState()
    const [br, setBr] = useState()



    const formatDate = (dateString) => {
        const date = new Date(dateString);
        return date.toLocaleDateString('en-US'); // Change 'en-US' to your desired locale
    };

    const [departments, setDepartments] = useState([]);
    const [designations, setDesignations] = useState([]);
    const [shifts, setShifts] = useState([]);

    const [selectedImage, setSelectedImage] = useState(null);
    const [image, setImage] = useState(null);
    const [compressedImage, setCompressedImage] = useState(null);
    const [cacheBuster, setCacheBuster] = useState(0);
    const [mimeType, setMimeType] = useState('');

    const refreshImage = () => {
        setCacheBuster(Date.now());
    };



    const [state, setState] = useState({
        _id: '',
        company_id: '',
        first_name: '',
        last_name: '',
        leaves: 0,
        email: '',
        joining_date: '',
        birth_date: '',
        phone: '',
        department: '',
        userField: false,
        designation: '',
        password: '',
        salary: '',
        shift: '',
        branch: '',
        branchName: '',
        image_path: null,




        bank_name: '',
        account_no: '',
        IFSC_code: '',
        branch_name: '',
        PAN_no: '',
        emergency_name: '',
        emergency_relation: '',
        emergency_phone: '',

        // Other fields...
    });
    const [editableBranchName, setEditableBranchName] = useState(state.branchName || '');

    const [errors, setErrors] = useState({});


    useEffect(() => {

        async function fetchemployeeData() {
            // toast.error('Fill up the branch again')
            try {
                setLoading(true);
                const employeeData = await editEmployee(id);
                console.log(employeeData);
                console.log("image_path", employeeData.success.image_path);

                setState({ ...state, branchName: employeeData.branchName })

                if (employeeData && employeeData.success) {
                    const employee = employeeData.success;

                    setState({
                        ...state,
                        _id: employee._id || '',
                        company_id: employee.company_id || '',
                        first_name: employee.first_name || '',
                        // leaves: employee.leaves || '',
                        leaves: employee.leaves != undefined ? employee.leaves : '',
                        last_name: employee.last_name || '',
                        email: employee.email || '',
                        joining_date: employee.joining_date || '',
                        birth_date: employee.birth_date || '',
                        phone: employee.phone || '',
                        department: employee.department || '',
                        userField: employee.userField == 'true' ? true : false,
                        designation: employee.designation || '',
                        branch: employee.branch || '',
                        branchName: employee.branchName || '',
                        password: employee.password || '',
                        salary: employee.salary || '',
                        shift: employee.shift || '',
                        image_path: employee.image_path || null,
                        bank_name: employee.bank_information && employee.bank_information.length > 0
                            ? employee.bank_information[0].bank_name || ''
                            : '',
                        account_no: employee.bank_information && employee.bank_information.length > 0
                            ? employee.bank_information[0].account_no || ''
                            : '',
                        IFSC_code: employee.bank_information && employee.bank_information.length > 0
                            ? employee.bank_information[0].IFSC_code || ''
                            : '',
                        branch_name: employee.bank_information && employee.bank_information.length > 0
                            ? employee.bank_information[0].branch_name || ''
                            : '',
                        PAN_no: employee.bank_information && employee.bank_information.length > 0
                            ? employee.bank_information[0].PAN_no || ''
                            : '',
                        emergency_name: employee.emergency_contact && employee.emergency_contact.length > 0
                            ? employee.emergency_contact[0].emergency_name || ''
                            : '',
                        emergency_relation: employee.emergency_contact && employee.emergency_contact.length > 0
                            ? employee.emergency_contact[0].emergency_relation || ''
                            : '',
                        emergency_phone: employee.emergency_contact && employee.emergency_contact.length > 0
                            ? employee.emergency_contact[0].emergency_phone || ''
                            : '',
                    });

                    const userBranches = employee.branch ? employee.branch.split(',') : [];

                    const selectedBranches = branches
                        .filter((branch) => userBranches.includes(branch.value)) // Assuming branch value is used for comparison
                        .map((branch) => ({
                            value: branch.value,
                            label: branch.label,
                        }));

                    setSelectedBranchOptions(selectedBranches);
                    console.log(selectedBranchOptions);

                    setActualPassword(employee.password || '');

                } else {
                    console.error('No employee data found');
                }
                setLoading(false);
            } catch (error) {
                console.error('Error fetching employee data:', error);
                setLoading(false);
            } finally {
                setLoading(false);
            }
        }

       
        const debouncedFetchfetchemployeeData = _debounce(fetchemployeeData, 200);

        debouncedFetchfetchemployeeData();

        return () => {
            debouncedFetchfetchemployeeData.cancel();
        };
    }, [id]);
    // [branches]

    const [actualPassword, setActualPassword] = useState('');


    const convertTo12Hour = (time) => {
        const [hour, minute] = time.split(':');
        let period = 'AM';
        let hour12 = parseInt(hour, 10);

        if (hour12 >= 12) {
            period = 'PM';
            if (hour12 > 12) hour12 -= 12;
        } else if (hour12 === 0) {
            hour12 = 12;
        }

        return `${hour12}:${minute} ${period}`;
    };


    const handleChange = (e) => {
        const { name, value, type, checked } = e.target;
        const newValue = type == 'checkbox' ? (checked ? "true" : "false") : value

        if (name == 'phone' && isNaN(value)) {
            // If not numeric, set the numeric error
            setErrors((prevErrors) => ({
                ...prevErrors,
                [name]: 'Please enter numeric values only.',
            }));
        } else {
            setErrors((prevErrors) => ({
                ...prevErrors,
                [name]: '',
            }));
            setState((prevState) => ({
                ...prevState,
                // [name]: newValue,
                [name]: name === 'leaves' ? Number(value) : value,
            }));
        }
    };


    const handleSubmit = async (e) => {
        e.preventDefault();

        // Log selected branch options
        console.log(selectedBranchOptions);

        // Validate form
        const validationErrors = validateForm(state);
        if (Object.keys(validationErrors).length > 0) {
            setErrors(validationErrors);
            return;
        }

        // Prevent multiple submissions
        if (updating) {
            return;
        }

        setUpdating(true);

        try {
            // Update employee data
            const response = await updateEmployee(id, {
                ...state,
                branch_id: selectedBranchOptions.map(branch => branch.value).join(','),
            });

            console.log(typeof id);
            const intId = parseInt(id);
            console.log(intId);

            // Handle response
            if (response) {
                toast.success(response.message, {
                    position: "top-center",
                    autoClose: 1000,
                });
            } else {
                toast.error("Something went wrong!", {
                    position: "top-center",
                    autoClose: 1000,
                });
            }

            // Add employee image

            setUpdating(false);
            navigate('/employee/profile');
            await addEmployeeAddImage(intId);

        } catch (error) {
            console.error('An error occurred:', error);
            // toast.error('An error occurred', {
            //   position: 'top-right',
            //   autoClose: 1000,
            // });
        }
    };


    useEffect(() => {
        const fetchDepartmentsData = async () => {
            try {
                // setLoading(true);
                const formattedData = await viewDepartment();

                if (!formattedData || !formattedData.length) {
                    throw new Error('Failed to fetch data.');
                }

                const companyBranch = formattedData[0].company_department;
                setMasterId(formattedData[0]._id);
                const departmentsArray = Object.values(companyBranch).map(item => ({
                    _id: item._id,
                    department_name: item.department_name || 'N/A',
                }));

                setDepartments(departmentsArray);
                // setLoading(false);
            } catch (error) {
                console.error('Error fetching data:', error);
                // setLoading(false);
            } finally {
                // setLoading(false);
            }
        };

        const debouncedFetchDepartmentsData = _debounce(fetchDepartmentsData, 200);

        debouncedFetchDepartmentsData();

        return () => {
            debouncedFetchDepartmentsData.cancel();
        };
    }, []);

    // useEffect(() => {
    //   fetchDesignation();

    // }, []);
    useEffect(() => {
        const fetchDesignationData = async () => {
            try {
                // setLoading(true)
                const formattedData = await viewDesignation();

                if (!formattedData || !formattedData.length) {
                    throw new Error('Failed to fetch data.');
                }

                const companyDesignation = formattedData[0].company_designation;
                setMasterId(formattedData[0]._id);
                const designationsArray = Object.values(companyDesignation).map((item) => ({
                    _id: item._id,
                    designation_name: item.designation_name || 'N/A',
                }));

                setDesignations(designationsArray);
                // setLoading(false);
            } catch (error) {
                console.error('Error fetching data:', error);
                // setLoading(false);
            } finally {
                // setLoading(false);
            }
        };

        const debouncedFetchDesignationData = _debounce(fetchDesignationData, 200);

        debouncedFetchDesignationData();

        return () => {
            debouncedFetchDesignationData.cancel();
        };
    }, []);

    useEffect(() => {
        fetchShift()
    }, []);

    const validateForm = (state) => {
        let errors = {};
        // Add validations if necessary 
        return errors;
    };



    const [displayedDate, setDisplayedDate] = useState('');

    useEffect(() => {
        if (state.joining_date) {
            setDisplayedDate(formatDate(state.joining_date));
        }
    }, [state.joining_date]);
    useEffect(() => {
        if (state.birth_date) {
            setDisplayedDate(formatDate(state.birth_date));
        }
    }, [state.birth_date]);

    const handleDateChange = (event) => {
        const newDate = event.target.value;
        setState((prevState) => ({
            ...prevState,
            joining_date: newDate,
        }));
        setDisplayedDate(formatDate(newDate));
    };



    const handlePasswordChange = (event) => {
        const newPassword = event.target.value;
        setActualPassword(newPassword); // Update the actual password
        setState((prevState) => ({
            ...prevState,
            password: newPassword, // Update the password in the state
        }));
    };



    const [showPassword, setShowPassword] = useState(false); // State to control showing password

    // ... (Other existing code remains the same)

    const handleTogglePassword = () => {
        setShowPassword((prevState) => !prevState); // Toggle show/hide password
    };

    const fetchShift = async () => {
        try {
            // Fetch shift data
            // setLoading(true);
            // console.log(shiftData);

            const formattedData = await viewShift();
            console.log(formattedData);


            // Process fetched data and set it in state
            if (!formattedData || !formattedData.length) {
                throw new Error('Failed to fetch data.');
            }

            const companyShift = formattedData[0].company_time;
            console.log(companyShift);
            setMasterId(formattedData[0]._id);
            const shiftsArray = Object.values(companyShift).map(item => ({
                _id: item._id,
                shift_no: item.shift_no || 'N/A',
                company_start_time: item.company_start_time || 'N/A',
                company_end_time: item.company_end_time || 'N/A',
            }));
            console.log(shiftsArray);


            setShifts(shiftsArray);
        } catch (error) {
            console.error('Error fetching data:', error);
        } finally {
            // setLoading(false);
        }
    };


    // branch code start
    const handleBranchSelectChange = (selectedOptions) => {
        setSelectedBranchOptions(selectedOptions);
        const selectedIds = selectedOptions.map((option) => option.value);
        setSelectedBranchIds(selectedIds);
        console.log(selectedIds);
    };



    useEffect(() => {
        const fetchBranch = async () => {

            try {
                // setLoading(true);
                const formattedData = await viewBranch();

                if (!formattedData || !formattedData.length) {
                    throw new Error('Failed to fetch data.');
                }

                const companyBranch = formattedData[0].company_branch;
                setMasterId(formattedData[0]._id);
                const branchsArray = Object.values(companyBranch).map(item => ({
                    _id: item._id,
                    branch: item.branch_name || 'N/A',
                }));

                setBranchs(branchsArray);
                // setLoading(false);
            } catch (error) {
                console.error('Error fetching data:', error);
                // setLoading(false);
            } finally {
                // setLoading(false);
            }
        };
        const debouncedFetchBranchData = _debounce(fetchBranch, 200);

        debouncedFetchBranchData();

        return () => {
            debouncedFetchBranchData.cancel();
        };
    }, []);


   

    const handleImageChange = async (e) => {
        const file = e.target.files[0];
        setSelectedImage(file);

        if (!file) return;

        let processedFile = file;

        // Function to perform iterative compression
        const compressImageToTargetSize = async (file, targetSizeKB) => {
            const options = {
                maxSizeMB: targetSizeKB / 1024, // Convert KB to MB
                maxWidthOrHeight: 1920,
                useWebWorker: true,
            };
            let compressedFile = file;
            let compressedFileSizeKB = file.size / 1024;

            while (compressedFileSizeKB > targetSizeKB) {
                try {
                    compressedFile = await imageCompression(compressedFile, options);
                    compressedFileSizeKB = compressedFile.size / 1024;
                    // Adjust the options to compress further if needed
                    options.maxSizeMB *= 0.8; // Reduce the target size further for the next iteration
                } catch (error) {
                    console.error('Error compressing image:', error);
                    break;
                }
            }
            return compressedFile;
        };

        // Compress image to less than 10 KB
        processedFile = await compressImageToTargetSize(file, 250);

        // Update the compressed image state
        setCompressedImage(URL.createObjectURL(processedFile));
        setImage(processedFile);

        // Convert the processed file to base64 and extract MIME type
        const reader = new FileReader();
        reader.onloadend = () => {
            const result = reader.result;
            const base64Image = result.split(',')[1];
            const mimeType = result.split(';')[0].split(':')[1];  // Extract MIME type
            setState((prevState) => ({ ...prevState, image_path: base64Image }));
            setMimeType(mimeType);  // Update MIME type state
        };
        reader.readAsDataURL(processedFile);

        // Update cache buster to force image refresh
        setCacheBuster((prev) => prev + 1);
    };

    const handleChangeb = (selectedOptions) => {
        const selectedBranchIds = selectedOptions.map((option) => option.value).join(',');
        setState((prevState) => ({
            ...prevState,
            branch: selectedBranchIds,
        }));
        setErrors((prevErrors) => ({
            ...prevErrors,
            branch: '',
        }));
    };
    useEffect(() => {
        // Set initial selected branches if state.branch has values
        if (state.branch && state.branch.length > 0) {
            const selectedOptions = branches.filter(branch => state.branch.includes(branch._id))
                .map(branch => ({ value: branch._id, label: branch.branch }));
            setSelectedBranchOptions(selectedOptions);
            setSelectedBranchIds(state.branch);
        }
    }, [branches, state.branch]);

    const handleInputChange = (inputValue) => {
        setEditableBranchName(inputValue);
        // Update state.branchName directly as the user types
        handleChange({
            target: {
                name: 'branchName',
                value: inputValue,
            },
        });
    };

    const handleChangeBX = (selectedOptions) => {
        const selectedBranches = selectedOptions.map(option => option.value);
        setSelectedBranchOptions(selectedOptions);
        setSelectedBranchIds(selectedBranches);
    };

    const options = branches.map(branch => ({
        value: branch._id,
        label: branch.branch,
    }));
    // branch code end
    return (
        <React.Fragment>
            <HeaderMobile />
            <Header />
            <div className="main p-4 p-lg-5 mt-5">
               
                <div>
                    <div className="breadcrumb-warning d-flex justify-content-between ot-card">
                        <nav aria-label="breadcrumb">
                            <Link to="/employee/profile" className="btn btn-primary mb-3">
                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-arrow-left" viewBox="0 0 16 16">
                                    <path fillRule="evenodd" d="M15 8a.5.5 0 0 0-.5-.5H2.707l3.147-3.146a.5.5 0 1 0-.708-.708l-4 4a.5.5 0 0 0 0 .708l4 4a.5.5 0 0 0 .708-.708L2.707 8.5H14.5A.5.5 0 0 0 15 8z" />
                                </svg>
                                Back
                            </Link>
                        </nav>
                    </div>

                   
                    {loading ? (
                        <div className="loader-container">
                            <div className="loader"></div>
                        </div>
                    ) : (

                        <div className="row"
                            style={{
                                boxShadow: "0 4px 8px 0 rgba(0,0,0,0.2)",
                                borderRadius: "10px",
                                overflow: "hidden",
                            }}
                        >


                            <div className="col-md-12 formcontrols">
                                <div className="bg-white p-4 rounded" style={{ height: "100%" }}>
                                    <form onSubmit={handleSubmit}>


                                        <div className="row">
                                            <div className="col-md-4">
                                                <div className="mb-3">
                                                    <label htmlFor="first_name" className="form-label">
                                                        First Name
                                                    </label>
                                                    <input
                                                        type="text"
                                                        className={`form-control custom-input ${errors.first_name && 'is-invalid'}`}
                                                        id="first_name"
                                                        name="first_name"
                                                        value={state.first_name}
                                                        onChange={handleChange}
                                                    />
                                                    {errors.first_name && <div className="invalid-feedback">{errors.first_name}</div>}
                                                </div>
                                            </div>
                                            <div className="col-md-4">
                                                <div className="mb-3">
                                                    <label htmlFor="last_name" className="form-label">
                                                        Last Name
                                                    </label>
                                                    <input
                                                        type="text"
                                                        className={`form-control custom-input ${errors.last_name && 'is-invalid'}`}
                                                        id="last_name"
                                                        name="last_name"
                                                        value={state.last_name}
                                                        onChange={handleChange}
                                                    />
                                                    {errors.last_name && <div className="invalid-feedback">{errors.last_name}</div>}
                                                </div>
                                            </div>
                                            <div className="col-md-4">
                                                <div className="mb-3">
                                                    <label htmlFor="email" className="form-label">
                                                        Email
                                                    </label>
                                                    <input
                                                        type="text"
                                                        className={`form-control custom-input ${errors.email && 'is-invalid'}`}
                                                        id="email"
                                                        name="email"
                                                        value={state.email}
                                                        onChange={handleChange}
                                                    />
                                                    {errors.email && <div className="invalid-feedback">{errors.email}</div>}
                                                </div>
                                            </div>
                                        </div>

                                        <div className="row">
                                            <div className="col-md-4">
                                                <div className="mb-3">
                                                    <label htmlFor="phone" className="form-label">
                                                        Phone
                                                    </label>
                                                    <input
                                                        type="number"
                                                        className={`form-control custom-input ${errors.phone && 'is-invalid'}`}
                                                        id="phone"
                                                        name="phone"
                                                        value={state.phone}
                                                        onChange={handleChange}
                                                    />
                                                    {errors.phone && <div className="invalid-feedback">{errors.phone}</div>}

                                                </div>
                                            </div>
                                        
                                            <div className="col-md-4">
                                                <div className="image-upload-container">
                                                    <div className="mb-3">
                                                        <label htmlFor="image" className="form-label fw-bold">
                                                            Update Profile
                                                        </label>
                                                        <input
                                                            type="file"
                                                            id="image"
                                                            name="image"
                                                            className="form-control "
                                                            onChange={handleImageChange}
                                                        />

                                                        {selectedImage ? (
                                                            <div className="image-preview mt-3">
                                                                <img
                                                                    alt="Selected"
                                                                    src={compressedImage ? compressedImage : URL.createObjectURL(selectedImage)}
                                                                    className="fixed-size-image preview-image"
                                                                />
                                                            </div>
                                                        ) : state.image_path ? (
                                                            <div className="image-preview mt-3 centered">
                                                                <img
                                                                    alt="Profile"
                                                                    src={`${state.image_path}?cb=${cacheBuster}`}
                                                                    className="fixed-size-imageX preview-image"
                                                                />
                                                            </div>
                                                        ) : null}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row">
                                        </div>
                                      
                                    
                                        <div className="row">
                                        </div>

                                        <div className="mb-4 mt-4 border-bottom text-center">
                                            <h3 className="mb-0 pb-2 border-bottom custome-btn">Emergency Contact</h3>
                                        </div>

                                        <div className="row">
                                            <div className="col-md-4">
                                                <div className="mb-3">
                                                    <label htmlFor="emergency_name" className="form-label">
                                                        Emergency name
                                                    </label>
                                                    <input
                                                        type="text"
                                                        className={`form-control custom-input ${errors.emergency_name && 'is-invalid'}`}
                                                        id="emergency_name"
                                                        name="emergency_name"
                                                        value={state.emergency_name}
                                                        onChange={handleChange}
                                                    />
                                                    {errors.emergency_name && <div className="invalid-feedback">{errors.emergency_name}</div>}
                                                </div>
                                            </div>
                                            <div className="col-md-4">
                                                <div className="mb-3">
                                                    <label htmlFor="emergency_relation" className="form-label">
                                                        Emergency relation
                                                    </label>
                                                    <input
                                                        type="text"
                                                        className={`form-control custom-input ${errors.emergency_relation && 'is-invalid'}`}
                                                        id="emergency_relation"
                                                        name="emergency_relation"
                                                        value={state.emergency_relation}
                                                        onChange={handleChange}
                                                    />
                                                    {errors.emergency_relation && <div className="invalid-feedback">{errors.emergency_relation}</div>}
                                                </div>
                                            </div>
                                            <div className="col-md-4">
                                                <div className="mb-3">
                                                    <label htmlFor="emergency_phone" className="form-label">
                                                        Emergency phone
                                                    </label>
                                                    <input
                                                        type="number"
                                                        className={`form-control custom-input ${errors.emergency_phone && 'is-invalid'}`}
                                                        id="emergency_phone"
                                                        name="emergency_phone"
                                                        value={state.emergency_phone}
                                                        onChange={handleChange}
                                                    />
                                                    {errors.emergency_phone && <div className="invalid-feedback">{errors.emergency_phone}</div>}
                                                </div>
                                            </div>
                                        </div>
                                        <div className="mb-4 mt-4 border-bottom text-center">
                                            <h3 className="mb-0 pb-2 border-bottom custome-btn">Bank Account Details</h3>
                                        </div>




                                        <div className="row">

                                            <div className="col-md-4">
                                                <div className="mb-3">
                                                    <label htmlFor="bank_name" className="form-label">
                                                        Bank Name
                                                    </label>
                                                    <input
                                                        type="text"
                                                        className={`form-control custom-input ${errors.bank_name && 'is-invalid'}`}
                                                        id="bank_name"
                                                        name="bank_name"
                                                        value={state.bank_name}
                                                        onChange={handleChange}
                                                    />
                                                    {errors.bank_name && <div className="invalid-feedback">{errors.bank_name}</div>}

                                                </div>
                                            </div>


                                            <div className="col-md-4">
                                                <div className="mb-3">
                                                    <label htmlFor="account_no" className="form-label">
                                                        Account number
                                                    </label>
                                                    <input
                                                        type="number"
                                                        className={`form-control custom-input ${errors.account_no && 'is-invalid'}`}
                                                        id="account_no"
                                                        name="account_no"
                                                        value={state.account_no}
                                                        onChange={handleChange}
                                                    />
                                                    {errors.account_no && <div className="invalid-feedback">{errors.account_no}</div>}

                                                </div>
                                            </div>


                                            <div className="col-md-4">
                                                <div className="mb-3">
                                                    <label htmlFor="IFSC_code" className="form-label">
                                                        IFSC code
                                                    </label>
                                                    <input
                                                        type="text"
                                                        className={`form-control custom-input ${errors.IFSC_code && 'is-invalid'}`}
                                                        id="IFSC_code"
                                                        name="IFSC_code"
                                                        value={state.IFSC_code}
                                                        onChange={handleChange}
                                                    />

                                                    {errors.IFSC_code && <div className="invalid-feedback">{errors.IFSC_code}</div>}
                                                </div>
                                            </div>

                                        </div>

                                        <div className="row">
                                            <div className="col-md-4">
                                                <div className="mb-3">
                                                    <label htmlFor="branch_name" className="form-label">
                                                        Branch name
                                                    </label>
                                                    <input
                                                        type="text"
                                                        className={`form-control custom-input ${errors.branch_name && 'is-invalid'}`}
                                                        id="branch_name"
                                                        name="branch_name"
                                                        value={state.branch_name}
                                                        onChange={handleChange}
                                                    />

                                                    {errors.branch_name && <div className="invalid-feedback">{errors.branch_name}</div>}
                                                </div>
                                            </div>


                                            <div className="col-md-4">
                                                <div className="mb-3">
                                                    <label htmlFor="PAN_no" className="form-label">
                                                        PAN number
                                                    </label>
                                                    <input
                                                        type="text"
                                                        className={`form-control custom-input ${errors.PAN_no && 'is-invalid'}`}
                                                        id="PAN_no"
                                                        name="PAN_no"
                                                        value={state.PAN_no}
                                                        onChange={handleChange}
                                                    />

                                                    {errors.PAN_no && <div className="invalid-feedback">{errors.PAN_no}</div>}
                                                </div>
                                            </div>
                                        </div>
                                        <Button className='custom-submit-btn' type="submit" onClick={refreshImage}>
                                            {/* Add Department */}
                                            {updating && (
                                                <span
                                                    className="spinner-border spinner-border-sm me-2"
                                                    role="status"
                                                    aria-hidden="true"
                                                ></span>
                                            )}
                                            {updating ? 'Updatting...' : 'Update'}
                                        </Button>
                                    </form>
                                </div>
                            </div>
                        </div>
                    )}

                    {/* } */}
                </div>
            </div >
        </React.Fragment >
    );
};

export default EditProfile;