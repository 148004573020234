import React, { useState } from "react";
// import { Button, Card, Col, Form, Row , InputGroup } from "react-bootstrap";
import { Button, Card, Col, Form, Row, Spinner, InputGroup } from "react-bootstrap";
import { Link } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import bg1 from "../assets/img/bg1.jpg";
import signupimage from "../assets/img/signupimage.gif";
import bg10 from "../assets/img/bg10.jpg";
import bg12 from "../assets/img/bg12.jpg";
import bg13 from "../assets/img/bg13.jpg";
import bgU1 from "../assets/img/bgU1.jpg";
import bgY1 from "../assets/img/bgY1.jpg";
import { useNavigate } from "react-router-dom";
import { registerCompany } from "../services/api/api";
import { Eye, EyeSlash } from 'react-bootstrap-icons';

export default function Signup() {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);

  const [state, setState] = useState({
    _id: 0,
    company_name: "",
    company_address: "",
    admin_name: "",
    password: "",
    admin_contact: "",
    company_email: "",
    admin_username: "",
    total_employee: "",
    subscription_id: "",
    subscription_status: "",
    otp: "",
    plan_name: "",
    plan_start: "",
    plan_end: "",
    api_status: "",
    company_upload_storage: "",
    register_ip: "",
    last_login_id: "",
    country: "",
    city: "",
    state: "",
    pincode: "",
    fax: "",
    os: "",
  });

  const [errors, setErrors] = useState({});

  const handleChange = (e) => {
    const { name, value, type } = e.target;

    if (type === "tel") {
      const numericValue = value.replace(/\D/g, '').slice(0, 10); // Remove non-numeric characters and keep only the first 10 digits

      setState((prevState) => ({
        ...prevState,
        [name]: numericValue,
      }));

      if (numericValue.length === 10 || numericValue === '') {
        setErrors((prevErrors) => ({
          ...prevErrors,
          [name]: "",
        }));
      } else {
        setErrors((prevErrors) => ({
          ...prevErrors,
          [name]: "Contact number should have 10 digits.",
        }));
      }
    } else {
      setState((prevState) => ({
        ...prevState,
        [name]: value,
      }));

      setErrors((prevErrors) => ({
        ...prevErrors,
        [name]: "",
      }));
    }
  };

  const YourComponent = () => {
    const [state, setState] = useState({
      password: '',
      showPassword: false, // State to control password visibility
    });

    const [errors, setErrors] = useState({
      password: '',
    });
  }


  const handleTogglePassword = () => {
    setState((prevState) => ({
      ...prevState,
      showPassword: !prevState.showPassword,
    }));
  };



  // const handleSubmit = async (e) => {
  //   e.preventDefault();
  //   const validationErrors = validateForm(state);
  //   if (Object.keys(validationErrors).length > 0) {
  //     setErrors(validationErrors);
  //     return;
  //   }
  //   setLoading(true);

  //   try {
  //     console.log("Request Payload:", JSON.stringify(state));

  //     const response = await registerCompany(state);
  //     const data = JSON.parse(response);
  //     if (data.success === true) {
  //       toast.success(data.message, {
  //         position: "top-center",
  //         autoClose: 5000,
  //       });
  //       if (data.data.emailVerificationStatus === 0) {
  //         // navigate("/employee/dashboard");
  //         navigate("/signin");
  //       }

  //     } else {
  //       toast.warning(data.message, {
  //         position: "top-center",
  //         autoClose: 5000,
  //       });
  //     }
  //     setLoading(false);
  //   }
  //   catch (error) {
  //     console.error("An error occurred:", error);
  //     toast.error("An error occurred", {
  //       position: "top-right",
  //       autoClose: 3000,
  //     });
  //   }
  // };


  // const handleSubmit = async (e) => {
  //   e.preventDefault();
  //   const validationErrors = validateForm(state);
  //   if (Object.keys(validationErrors).length > 0) {
  //     setErrors(validationErrors);
  //     return;
  //   }
  //   setLoading(true);

  //   try {
  //     console.log("Request Payload:", JSON.stringify(state));

  //     const response = await registerCompany(state);
  //     const data = JSON.parse(response);

  //     if (data.success === true) {
  //       toast.success(data.message, {
  //         position: "top-center",
  //         autoClose: 5000,
  //       });

  //       if (data.data.emailVerificationStatus === 0) {
  //         navigate("/signin");
  //       }
  //     } else {
  //       if (data.result === "Email already exits!") {
  //         toast.warning("Email already exists. Please use a different email.", {
  //           position: "top-center",
  //           autoClose: 5000,
  //         });
  //       } else {
  //         toast.warning(data.message, {
  //           position: "top-center",
  //           autoClose: 5000,
  //         });
  //       }
  //     }

  //     setLoading(false);
  //   } catch (error) {
  //     console.error("An error occurred:", error);
  //     toast.error("An error occurred", {
  //       position: "top-right",
  //       autoClose: 3000,
  //     });
  //     setLoading(false);
  //   }
  // };



  const handleSubmit = async (e) => {
    e.preventDefault();
    const validationErrors = validateForm(state);
    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
      setLoading(false); // Set loading to false immediately if there are validation errors
      return;
    }
    setLoading(true);

    try {
      console.log("Request Payload:", JSON.stringify(state));

      const response = await registerCompany(state);
      console.log(response);
      // const data = JSON.parse(response);




      if (response.success == true) {
        toast.success('We sent you email verification link on your registered Email', {
          position: "top-center",
          autoClose: 5000,
        });

        if (response.data.emailVerificationStatus == 0) {
          navigate("/signin");
        }
      } else {
        if (response.result === "Email already exits!") {
          toast.warning("Email already exists. Please use a different email.", {
            position: "top-center",
            autoClose: 5000,
          });
        } else {
          toast.warning(response.message, {
            position: "top-center",
            autoClose: 5000,
          });
        }
      }
    } catch (error) {
      console.error("An error occurred:", error);
      toast.error("An error occurred", {
        position: "top-right",
        autoClose: 3000,
      });
    } finally {
      setLoading(false); // Ensure loading is set to false even in case of an error
    }
  };


  const saveFormDataToSessionStorage = (formData) => {
    sessionStorage.setItem("formData", JSON.stringify(formData));
  };

  const validateForm = (formData) => {
    let errors = {};

    if (!formData.admin_name) {
      errors.admin_name = "Admin name is required.";
    }

    if (!formData.company_email) {
      errors.company_email = "Company email is required.";
    } else if (!isValidEmail(formData.company_email)) {
      errors.company_email = "Invalid email format.";
    }

    if (!formData.password) {
      errors.password = "Password is required";
    } else {
      // Password validation criteria
      const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;

      if (!passwordRegex.test(formData.password)) {
        errors.password =
          "Password must be at least 8 characters long and include at least 1 uppercase letter, 1 lowercase letter, 1 special character, and 1 number.";
      }
    }

    // Validation for the new fields
    if (!formData.company_name) {
      errors.company_name = "Company name is required.";
    }
    if (!formData.company_address) {
      errors.company_address = "Company address is required.";
    }

    if (!formData.admin_contact) {
      errors.admin_contact = "Admin contact is required.";
    }

    if (!formData.total_employee) {
      errors.total_employee = "Total employees is required.";
    }

    return errors;
  };

  const isValidEmail = (email) => {
    const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i;
    return emailRegex.test(email);
  };

  return (
    <div className="page-sign d-block py-0">
      <Row className="g-0" >
        <Col md="7" lg="5" xl="4" className="col-wrapper">
          <Card className="card-sign">
            <Card.Header>
              {/* <Link to="/" className="header-logo mb-5">
                dashbyte
              </Link> */}
              <Link to="/" className="header-logo mb-5">
                <img src="payroll.png" style={{ width: "200px" }} alt="Logo" />
              </Link>
              <Card.Title>Sign Up</Card.Title>
              {/* <Card.Text>It's free to signup and only takes a minute.</Card.Text> */}
            </Card.Header>
            <Card.Body>
              <form onSubmit={handleSubmit}>
                <div className="mb-3">
                  <Form.Label>Full Name</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Enter your admin name"
                    name="admin_name"
                    value={state.admin_name}
                    onChange={handleChange}
                  />
                  {errors.admin_name && (
                    <Form.Text className="text-danger">{errors.admin_name}</Form.Text>
                  )}
                </div>
                <div className="mb-3">
                  <Form.Label>Company Name</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Enter your company name"
                    name="company_name"
                    value={state.company_name}
                    onChange={handleChange}
                  />
                  {errors.company_name && (
                    <Form.Text className="text-danger">{errors.company_name}</Form.Text>
                  )}
                </div>
                <div className="mb-3">
                  <Form.Label>Company Address</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Enter your company address"
                    name="company_address"
                    value={state.company_address}
                    onChange={handleChange}
                  />
                  {errors.company_name && (
                    <Form.Text className="text-danger">{errors.company_address}</Form.Text>
                  )}
                </div>
                <div className="mb-3">
                  <Form.Label>Email</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Enter your company email"
                    name="company_email"
                    value={state.company_email}
                    onChange={handleChange}
                  />
                  {errors.company_email && (
                    <Form.Text className="text-danger">{errors.company_email}</Form.Text>
                  )}
                </div>
                <div className="mb-3">
                  <Form.Label>Contact No.</Form.Label>
                  <Form.Control
                    type="tel"
                    placeholder="Enter your admin contact number"
                    name="admin_contact"
                    value={state.admin_contact}
                    onChange={handleChange}
                  />
                  {errors.admin_contact && (
                    <Form.Text className="text-danger">{errors.admin_contact}</Form.Text>
                  )}
                </div>
                <div className="mb-3">
                  <Form.Label>Password</Form.Label>
                  <InputGroup>
                    <Form.Control
                      type={state.showPassword ? "text" : "password"}
                      placeholder="Enter your password"
                      name="password"
                      value={state.password}
                      onChange={handleChange}
                    />
                    <InputGroup.Text onClick={handleTogglePassword} className="password-toggle-icon">
                      {state.showPassword ? <EyeSlash /> : <Eye />} {/* Eye icons */}
                    </InputGroup.Text>
                  </InputGroup>
                  {errors.password && (
                    <Form.Text className="text-danger">{errors.password}</Form.Text>
                  )}
                </div>
                {/* New Fields */}
                <div className="mb-3">
                  <Form.Label>No of Employees</Form.Label>
                  <Form.Control
                    type="number"
                    placeholder="Enter the number of employees"
                    name="total_employee"
                    value={state.total_employee}
                    onChange={handleChange}
                  />
                  {errors.total_employee && (
                    <Form.Text className="text-danger">{errors.total_employee}</Form.Text>
                  )}
                </div>
                {/* End of New Fields */}
                <div className="mb-4">
                  <small>
                    By clicking <strong>Create Account</strong> below, you agree
                    to our terms of service and privacy statement.
                  </small>
                </div>



                {/* <Button variant="primary" className="btn-sign" type="submit">
                  Create Account
                </Button> */}

                <Button type="submit" className="btn-sign" disabled={loading}>
                  Create Account
                </Button>
                {loading && (
                  <div
                    style={{
                      position: "fixed",
                      top: "50%",
                      left: "50%",
                      transform: "translate(-50%, -50%)",
                      zIndex: "9999",
                    }}
                  >
                    <Spinner animation="border" role="status" />
                  </div>
                )}








                {/* <div className="divider">
                  <span>or sign up using</span>
                </div>
                <Row className="gx-2">
                  <Col>
                    <Button variant="" className="btn-facebook">
                      <i className="ri-facebook-fill"></i> Facebook
                    </Button>
                  </Col>
                  <Col>
                    <Button variant="" className="btn-google">
                      <i className="ri-google-fill"></i> Google
                    </Button>
                  </Col>
                </Row> */}
              </form>
            </Card.Body>
            <Card.Footer>
              Already have an account?{" "}
              <Link to="/signin">Sign In</Link>
            </Card.Footer>
          </Card>
        </Col>
        {/* <Col className="d-none d-lg-block">
          <img src={bg1} className="auth-img" alt="" />
        </Col> */}
        {/* <Col className="">
          <img src={bg1} className="" alt="" style={{ width: '100%', }} />
        </Col> */}
        <Col className="" style={{ backgroundColor: 'white', }}>
          {/* <img src={bg1} className="" alt="" style={{ width: '100%' }} /> */}
          <img
            src={signupimage}
            className=""
            alt=""
            style={{
              width: '100%',
              height: '100%',
              // marginLeft: '-400px'
            }}
          />

        </Col>


      </Row>
    </div>
  );
}



// const handleChange = (e) => {
//   const { name, value } = e.target;
//   setState((prevState) => ({
//     ...prevState,
//     [name]: value,
//   }));
//   setErrors((prevErrors) => ({
//     ...prevErrors,
//     [name]: "",
//   }));
// };