import React, { useState, useEffect } from "react";
import { Modal, Button } from "react-bootstrap";
import Header from "../../layouts/Header";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { addUser, addBranch, viewBranch, viewDesignation, addDesignation } from "../../services/api/api";
import { addDepartment } from "../../services/api/api";
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import Select from 'react-select';
import { Form } from "react-bootstrap";


const AdduserForm = () => {
    const [submitting, setSubmitting] = useState(false);
    const [selectedBranches, setSelectedBranches] = useState([]);
    const [selectedBranchIds, setSelectedBranchIds] = useState([]);
    const [selectedBranchOptions, setSelectedBranchOptions] = useState([]);
    const [submittingBr, setSubmittingBr] = useState(false);
    const [branchErrors, setBranchErrors] = useState({});
    const [branchName, setBranchName] = useState("");
    const [showBranchModal, setShowBranchModal] = useState(false);
    const [branches, setBranchs] = useState([]);


    const [submittingDep, setSubmittingDep] = useState(false);
    const [submittingDes, setSubmittingDes] = useState(false);
    const [showDesignationModal, setShowDesignationModal] = useState(false);
    const [designationName, setDesignationName] = useState("");
    const [designations, setDesignations] = useState([]);
    const [designationErrors, setDsignationErrors] = useState({});


    const handleBranchSelectChange = (selectedOptions) => {
        setSelectedBranchOptions(selectedOptions);
        // Extract the selected branch IDs and update the state
        const selectedIds = selectedOptions.map((option) => option.value);
        setSelectedBranchIds(selectedIds);
    };

    useEffect(() => {

        fetchBranch();

    }, [])
    useEffect(() => {
        fetchDesignation()
    }, [])


    const navigate = useNavigate();
    const [state, setState] = useState({
        user_email: "",
        user_name: "",
        user_password: "",
        user_type: "",
        user_add_date: "",
        employee: false,
        payroll: false,
        attendance: false,
        break: false,
        leave: false,
        letters: false,
    });



    const [errors, setErrors] = useState({});

    useEffect(() => {
        setState({
            user_email: "",
            user_name: "",
            user_password: "",
            user_type: "",
            user_add_date: "",
            role: "",
            employee: false,
            payroll: false,
            attendance: false,
            break: false,
            leave: false,
            letters: false,
        });
        setErrors({});
    }, []);




    const handleChange = (e) => {
        const { name, value } = e.target;

        setState((prevState) => ({
            ...prevState,
            [name]: value,
        }));
        setErrors((prevErrors) => ({
            ...prevErrors,
            [name]: "",
        }));
    };
    const handleAccessChange = (field, value) => {
        setState((prevState) => ({
            ...prevState,


            [field]: value ? "true" : "false",

        }));
    };


    const validateForm = (state) => {
        let errors = {};

        if (!state.user_email) {
            errors.user_email = "User email is required";
        }
        if (!state.user_name) {
            errors.user_name = "User Name is required";
        }

        if (!state.user_password) {
            errors.user_password = "Password is required";
        }
        if (!state.user_type) {
            errors.user_type = "User type is required";
        }
        if (!state.user_add_date) {
            errors.user_add_date = "User add date is required";
        }
        if (!state.designation) {
            errors.designation = "Designation is required";
        }


        return errors;
    };


    const customStyles = {
        control: (provided) => ({
            ...provided,
            minHeight: '40px',
            height: 'auto',
        }),
        menu: (provided) => ({
            ...provided,
            marginTop: '2px',
            maxHeight: '180px',
            overflowY: 'auto',
        }),
    };


    const handleSubmit = async (e) => {
        e.preventDefault();
        if (submitting) {
            return;
        }

        const formErrors = validateForm(state);
        if (Object.keys(formErrors).length > 0) {
            setErrors(formErrors);
            return;
        }
        setSubmitting(true);
        try {
            const payloadBranchid = {
                ...state,
                branch_id: selectedBranchIds.join(','),
            };
            const response = await addUser(payloadBranchid);
            const data = JSON.parse(response);
            if (data.success === true) {
                toast.success(data.message, {
                    position: "top-center",
                    autoClose: 5000,
                });

                navigate("/pages/user/viewuser");
            } else {
                toast.success(data.message, {
                    position: "top-center",
                    autoClose: 5000,
                });
                navigate("/pages/user/viewuser");
            }
        } catch (error) {
        } finally {
            setSubmitting(false);
        }
    };


    const [loading, setLoading] = useState(true);
    const [masterId, setMasterId] = useState();











    const handleNearbyBranchClick = () => {
        setShowBranchModal(true);
    };

    const handleCloseBranchModal = () => {
        setShowBranchModal(false);
        setBranchName('');
        setBranchErrors({});
    };
    const fetchBranch = async () => {

        try {
            const formattedData = await viewBranch();

            if (!formattedData || !formattedData.length) {
                throw new Error('Failed to fetch data.');
            }

            const companyBranch = formattedData[0].company_branch;
            setMasterId(formattedData[0]._id);
            const branchsArray = Object.values(companyBranch).map(item => ({
                _id: item._id,
                branch_name: item.branch_name || 'N/A',
            }));

            setBranchs(branchsArray);
        } catch (error) {
            console.error('Error fetching data:', error);
        } finally {
            setLoading(false);
        }
    };
    const handleBranchNameChange = (e) => {
        const { value } = e.target;
        setBranchName(value);
        // Clear the designation error when the user starts typing
        setBranchErrors((prevErrors) => ({
            ...prevErrors,
            branch_name: "",
        }));
    };
    const handleBranchSubmit = async (e) => {
        e.preventDefault();
        if (submittingBr) {
            return;
        }
        if (!branchName.trim()) {
            setBranchErrors({ branch_name: "Branch name is required" });
            return;
        }
        setSubmittingBr(true);

        try {
            const response = await addBranch({ branch_name: branchName });
            const data = JSON.parse(response);

            if (data.success === true) {
                toast.success(data.message, {
                    position: "top-center",
                    autoClose: 1000,
                });
                fetchBranch();
            } else {
                toast.success(data.message, {
                    position: "top-center",
                    autoClose: 1000,
                });
            }
        } catch (error) {
            toast.success("Branch Added successfully", {
                position: "top-center",
                autoClose: 1000,
            });
        } finally {
            handleCloseBranchModal();
            setSubmittingBr(false);
            fetchBranch();
        }
    };
    const handleCloseDesignationModal = () => {
        setShowDesignationModal(false);
        setDesignationName('');
        setDsignationErrors({});
    };
    const handleNearbyDesignationClick = () => {
        setShowDesignationModal(true);
    };
    const fetchDesignation = async () => {
        try {
            const formattedData = await viewDesignation();

            if (!formattedData || !formattedData.length) {
                throw new Error('Failed to fetch data.');
            }

            const companyDesignation = formattedData[0].company_designation;
            setMasterId(formattedData[0]._id);
            const designationsArray = Object.values(companyDesignation).map(item => ({
                _id: item._id,
                designation_name: item.designation_name || 'N/A',
            }));

            setDesignations(designationsArray);
        } catch (error) {
            console.error('Error fetching data:', error);
        } finally {
            setLoading(false);
        }
    };
    const handleDesignationSubmit = async (e) => {
        e.preventDefault();

        if (submittingDes) {
            return;
        }

        if (!designationName.trim()) {
            setDsignationErrors({ designation_name: "Designation name is required" });
            return;
        }
        setSubmittingDes(true);
        try {
            const response = await addDesignation({ designation_name: designationName });
            const data = JSON.parse(response);

            if (data.success === true) {
                toast.success(data.message, {
                    position: "top-center",
                    autoClose: 1000,
                });
                fetchDesignation();
            } else {
                toast.success(data.message, {
                    position: "top-center",
                    autoClose: 1000,
                });
            }
        } catch (error) {
            toast.success("Designation Added successfully", {
                position: "top-center",
                autoClose: 1000,
            });
        } finally {

            handleCloseDesignationModal();
            setSubmittingDes(false);
            fetchDesignation();

        }
    };
    const handleDesignationChange = (e) => {
        setDesignationName(e.target.value);
        if (designationErrors.designation_name) {
            setDsignationErrors({ designation_name: '' });
        }
    };




    return (
        <React.Fragment>
            <Header />
            {/* Your other layout code here */}
            <div className="main p-4 p-lg-5 mt-5">
                {/* Your layout structure */}
                {/* <div className="mb-4 border-bottom text-center">
                        <h3 className="mb-0 pb- border-bottom custome-btn">Add User</h3>
                    </div> */}
                <div className="breadcrumb-warning d-flex justify-content-between ot-card">
                    <div>
                        <ol className="breadcrumb ot-breadcrumb ot-breadcrumb-basic">
                            <li className="breadcrumb-item">
                                <Link to="/admin/dashboard">Dashboard</Link>
                            </li>
                            <li className="breadcrumb-item active">
                                <Link to="/pages/user/viewuser">Users</Link>
                            </li>
                            <li className="breadcrumb-item active">Add Users</li>
                        </ol>
                    </div>
                    <nav aria-label="breadcrumb">
                        <Link to="/pages/user/viewuser" className="btn btn-primary mb-3">
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-arrow-left" viewBox="0 0 16 16">
                                <path fillRule="evenodd" d="M15 8a.5.5 0 0 0-.5-.5H2.707l3.147-3.146a.5.5 0 1 0-.708-.708l-4 4a.5.5 0 0 0 0 .708l4 4a.5.5 0 0 0 .708-.708L2.707 8.5H14.5A.5.5 0 0 0 15 8z" />
                            </svg>
                            Back
                        </Link>
                    </nav>
                </div>



                <div className="row"
                    style={{
                        boxShadow: "0 4px 8px 0 rgba(0,0,0,0.2)",
                        borderRadius: "10px",
                        overflow: "hidden",
                    }}
                >


                    <div className="col-md-12 formcontrols">
                        {/* <div className="bg-white p-4 rounded" style={{ height: "100%" }}> */}
                        <div className="bg-white-rounded">


                            <form onSubmit={handleSubmit}>


                                <div className="row">
                                    <div className="col-md-6">

                                        <div className="mb-3">
                                            <label htmlFor="user_email" className="form-label fw-bold">
                                                Email
                                            </label>
                                            <input
                                                type="email"
                                                className={`form-control custom-input  ${errors.user_email && "is-invalid"}`}
                                                id="user_email"
                                                name="user_email"
                                                value={state.user_email}
                                                onChange={handleChange}
                                                placeholder="Enter..."
                                            />
                                            {errors.user_email && (
                                                <div className="invalid-feedback">{errors.user_email}</div>
                                            )}
                                        </div>

                                    </div>
                                    <div className="col-md-6">

                                        <div className="mb-3">
                                            <label htmlFor="user_name" className="form-label fw-bold">
                                                Name
                                            </label>
                                            <input
                                                type="text"
                                                className={`form-control custom-input ${errors.user_name && "is-invalid"}`}
                                                id="user_name"
                                                name="user_name"
                                                value={state.user_name}
                                                onChange={handleChange}
                                                placeholder="Enter..."
                                            />
                                            {errors.user_name && (
                                                <div className="invalid-feedback">{errors.user_name}</div>
                                            )}
                                        </div>

                                    </div>
                                </div>

                                <div className="row">
                                    <div className="col-md-6">
                                        <div className="mb-3">
                                            <label htmlFor="user_password" className="form-label fw-bold">
                                                Password
                                            </label>
                                            <input
                                                type="password"
                                                className={`form-control custom-input ${errors.user_password && "is-invalid"}`}
                                                id="user_password"
                                                name="user_password"
                                                value={state.user_password}
                                                onChange={handleChange}
                                                placeholder="Enter..."
                                            />
                                            {errors.user_password && (
                                                <div className="invalid-feedback">{errors.user_password}</div>
                                            )}
                                        </div>
                                    </div>
                                    <div className="col-md-6">

                                        <div className="mb-3">
                                            <label htmlFor="user_type" className="form-label fw-bold">
                                                Type
                                            </label>
                                            <input
                                                type="text"
                                                className={`form-control custom-input ${errors.user_type && "is-invalid"}`}
                                                id="user_type"
                                                name="user_type"
                                                value={state.user_type}
                                                onChange={handleChange}
                                                placeholder="Enter..."
                                            />
                                            {errors.user_type && (
                                                <div className="invalid-feedback">{errors.user_type}</div>
                                            )}
                                        </div>

                                    </div>
                                </div>


                                <div className="row">
                                    <div className="col-md-6">
                                        <div className="mb-3">
                                            <label htmlFor="user_add_date" className="form-label fw-bold">
                                                Date
                                            </label>
                                            <input
                                                type="date"
                                                className={`form-control custom-input ${errors.user_add_date && "is-invalid"}`}
                                                id="user_add_date"
                                                name="user_add_date"
                                                value={state.user_add_date}
                                                onChange={handleChange}
                                                placeholder="Enter..."
                                            />
                                            {errors.user_add_date && (
                                                <div className="invalid-feedback">{errors.user_add_date}</div>
                                            )}
                                        </div>
                                    </div>

                                    <div className="col-md-6">
                                        <div className="mb-3">
                                            <label htmlFor="designation" className="form-label fw-bold">
                                                Branch
                                            </label>
                                            <span
                                                className="ms-2"
                                                style={{ cursor: "pointer" }}
                                                onClick={handleNearbyBranchClick}
                                            >
                                                <svg
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    width="16"
                                                    height="16"
                                                    fill="#007bff"
                                                    className="bi bi-plus-circle-fill"
                                                    viewBox="0 0 16 16"
                                                >
                                                    <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0M8.5 4.5a.5.5 0 0 0-1 0v3h-3a.5.5 0 0 0 0 1h3v3a.5.5 0 0 0 1 0v-3h3a.5.5 0 0 0 0-1h-3z" />
                                                </svg>
                                            </span>

                                            <Modal show={showBranchModal} onHide={handleCloseBranchModal}>
                                                <Modal.Header closeButton>
                                                    <Modal.Title>Add Branch</Modal.Title>
                                                </Modal.Header>
                                                <Modal.Body>
                                                    <div className="mb-3">
                                                        <label htmlFor="branch_name" className="form-label fw-bold">
                                                            Branch Name
                                                        </label>
                                                        <input
                                                            type="text"
                                                            className={`form-control custom-input ${branchErrors.branch_name && "is-invalid"}`}
                                                            id="branch_name"
                                                            name="branch_name"
                                                            value={branchName}
                                                            onChange={handleBranchNameChange}
                                                            placeholder="Enter"
                                                            style={{
                                                                borderRadius: '10px',
                                                                boxShadow: '0 0 5px rgba(0, 0, 0, 0.2)',
                                                            }}
                                                        />
                                                        {branchErrors.branch_name && (
                                                            <div className="invalid-feedback">{branchErrors.branch_name}</div>
                                                        )}
                                                    </div>

                                                    <button
                                                        type="submit"
                                                        className="btn btn-primary d-flex align-items-center justify-content-center position-relative"
                                                        onClick={handleBranchSubmit}
                                                        disabled={submittingBr}
                                                    >
                                                        {submittingBr && (
                                                            <span
                                                                className="spinner-border spinner-border-sm me-2"
                                                                role="status"
                                                                aria-hidden="true"
                                                            ></span>
                                                        )}
                                                        {submittingBr ? 'Submitting branch...' : 'Add Branch'}
                                                    </button>
                                                </Modal.Body>
                                            </Modal>
                                            {/* {branches.map((branch) => (
                                                <div key={branch._id} className="form-check">
                                                    <input
                                                        className="form-check-input"
                                                        type="checkbox"
                                                        id={`branchCheckbox-${branch._id}`}
                                                        value={branch._id}
                                                        checked={selectedBranchIds.includes(branch._id)}
                                                        onChange={() => handleBranchCheckboxChange(branch._id)}
                                                    />
                                                    <label className="form-check-label" htmlFor={`branchCheckbox-${branch._id}`}>
                                                        {branch.branch_name}
                                                    </label>
                                                </div>
                                            ))} */}
                                            {/* <Select
                                                isMulti
                                                options={branches.map((branch) => ({
                                                    value: branch._id,
                                                    label: branch.branch_name,
                                                }))}
                                                value={selectedBranchOptions}
                                                onChange={handleBranchSelectChange}
                                                placeholder="Select branches"
                                            /> */}


                                            <Select
                                                isMulti
                                                options={branches.map((branch) => ({
                                                    value: branch.branch_name,
                                                    label: branch.branch_name,
                                                }))}
                                                value={selectedBranchOptions}
                                                onChange={handleBranchSelectChange}
                                                placeholder="Select branches"
                                                closeMenuOnSelect={false}
                                                components={{ DropdownIndicator: () => null }}
                                                isSearchable
                                                hideSelectedOptions={false}
                                            />









                                            {errors.branch && (
                                                <div className="invalid-feedback">{errors.branch}</div>
                                            )}
                                        </div>




                                    </div>
                                </div>


                                <div className="row">
                                    <div className="col-md-6">
                                        <div className="mb-3">
                                            <label htmlFor="designation" className="form-label fw-bold">
                                                Designation
                                            </label>
                                            <span
                                                className="ms-2"
                                                style={{ cursor: "pointer" }}
                                                onClick={handleNearbyDesignationClick}
                                            >
                                                <svg
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    width="16"
                                                    height="16"
                                                    fill="#007bff" // Change the fill color to blue
                                                    className="bi bi-plus-circle-fill"
                                                    viewBox="0 0 16 16"
                                                >
                                                    <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0M8.5 4.5a.5.5 0 0 0-1 0v3h-3a.5.5 0 0 0 0 1h3v3a.5.5 0 0 0 1 0v-3h3a.5.5 0 0 0 0-1h-3z" />
                                                </svg>
                                            </span>

                                            <Modal show={showDesignationModal} onHide={handleCloseDesignationModal}>
                                                <Modal.Header closeButton>
                                                    <Modal.Title>Add Designation</Modal.Title>
                                                </Modal.Header>
                                                <Modal.Body>
                                                    <div className="mb-3">
                                                        <label htmlFor="designation_name" className="form-label fw-bold">
                                                            Designation Name
                                                        </label>
                                                        <input
                                                            type="text"
                                                            className={`form-control custom-input ${designationErrors.designation_name && "is-invalid"}`}
                                                            id="designation_name"
                                                            name="designation_name"
                                                            value={designationName}
                                                            onChange={handleDesignationChange}
                                                            placeholder="Enter..."
                                                            style={{
                                                                borderRadius: '10px',
                                                                boxShadow: '0 0 5px rgba(0, 0, 0, 0.2)',
                                                            }}
                                                        />
                                                        {designationErrors.designation_name && (
                                                            <div className="invalid-feedback">{designationErrors.designation_name}</div>
                                                        )}
                                                    </div>


                                                    <button
                                                        type="submit"
                                                        className="btn btn-primary d-flex align-items-center justify-content-center position-relative"
                                                        onClick={handleDesignationSubmit}
                                                        disabled={submittingDes}
                                                    >
                                                        {submittingDes && (
                                                            <span
                                                                className="spinner-border spinner-border-sm me-2"
                                                                role="status"
                                                                aria-hidden="true"
                                                            ></span>
                                                        )}
                                                        {submittingDes ? 'Submitting designation...' : 'Add Designation'}
                                                    </button>




                                                </Modal.Body>
                                            </Modal>
                                            <select
                                                className={`form-select custom-input ${errors.designation && "is-invalid"}`}
                                                id="designation"
                                                name="designation"
                                                value={state.designation}
                                                onChange={handleChange}
                                            >
                                                <option value="">Select Designation</option>
                                                {designations.map((designation) => (
                                                    <option key={designation._id} value={designation.designation_name}>
                                                        {designation.designation_name}
                                                    </option>
                                                ))}
                                            </select>

                                            {errors.designation && (
                                                <div className="invalid-feedback">{errors.designation}</div>
                                            )}
                                        </div>
                                    </div>
                                </div>




                                <div className="customContainer">
                                    <div className="mb-3">
                                        <Form.Check
                                            type="switch"
                                            id="employee"
                                            label="Employee"
                                            checked={state.employee}
                                            onChange={(e) => handleAccessChange("employee", e.target.checked)}
                                            className="fw-bold"
                                        />
                                    </div>
                                    <div className="mb-3">
                                        <Form.Check
                                            type="switch"
                                            id="payroll"
                                            label="Payroll"
                                            checked={state.payroll}
                                            onChange={(e) => handleAccessChange("payroll", e.target.checked)}
                                            className="fw-bold"
                                        />
                                    </div>
                                    <div className="mb-3">
                                        <Form.Check
                                            type="switch"
                                            id="attendance"
                                            label="Attendance"
                                            checked={state.attendance}
                                            onChange={(e) => handleAccessChange("attendance", e.target.checked)}
                                            className="fw-bold"
                                        />
                                    </div>
                                    <div className="mb-3">
                                        <Form.Check
                                            type="switch"
                                            id="break"
                                            label="Break"
                                            checked={state.break}
                                            onChange={(e) => handleAccessChange("break", e.target.checked)}
                                            className="fw-bold"
                                        />
                                    </div>
                                    <div className="mb-3">
                                        <Form.Check
                                            type="switch"
                                            id="leave"
                                            label="Leave"
                                            checked={state.leave}
                                            onChange={(e) => handleAccessChange("leave", e.target.checked)}
                                            className="fw-bold"
                                        />
                                    </div>
                                    <div className="mb-3">
                                        <Form.Check
                                            type="switch"
                                            id="letters"
                                            label="Letters"
                                            checked={state.letters}
                                            onChange={(e) => handleAccessChange("letters", e.target.checked)}
                                            className="fw-bold"
                                        />
                                    </div>
                                </div>









                                {/* <button type="submit" className="btn btn-primary"
                                    disabled={submitting}>

                                    {submitting ? 'Submitting...' : 'Submit'}
                                </button> */}

                                <button
                                    type="submit"
                                    className={`btn btn-primary ${submitting ? 'disabled' : ''}`}
                                    disabled={submitting}
                                >
                                    {submitting ? 'Submitting...' : 'Add'}
                                </button>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
};

export default AdduserForm;
















