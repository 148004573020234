import React, { useState, useEffect } from "react";
import _debounce from "lodash/debounce";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import {
  getId,
  searchEmployeeBreakCount,
  singleViewEmployeeAttendance,
  viewEmployeeBreakCount,
} from "../../services/api/api";
import HeaderMobile from "../../layouts/HeaderMobile";
import Header from "../../layouts/Header";
import {
  Card,
  CardBody,
  Button,
  Form,
  Row,
  Col,
  Spinner,
} from "react-bootstrap";
import { Link } from "react-router-dom";

const SingleAttendanceView = () => {
  const [masterId, setMasterId] = useState('');
  const [announcements, setAnnouncements] = useState([]);
  const [addEmployeeDataData, setAddEmployeeDataData] = useState([]);
  const [remainingLeaves, setRemainingLeaves] = useState(0);
  const [events, setEvents] = useState([]);
  const [searchParams, setSearchParams] = useState({
    year: new Date().getFullYear(),
    month: new Date().getMonth() + 1, // Months are 0-indexed, so adding 1
  });
  const [loading, setLoading] = useState(false);

  // useEffect(() => {
  //     fetchEmployeeBreakCount();
  // }, [searchParams]);
  useEffect(() => {
    const debouncedFetchEmployeeBreakCount = _debounce(
      fetchEmployeeBreakCount,
      300
    );

    debouncedFetchEmployeeBreakCount();

    return () => {
      debouncedFetchEmployeeBreakCount.cancel();
    };
  }, [searchParams]);

  const fetchEmployeeBreakCount = async () => {
    try {
      setLoading(true);
      const employeeId = getId(); 
      const apiResponse = await singleViewEmployeeAttendance(
        employeeId,
        searchParams.year,
        searchParams.month
      );
      console.log(apiResponse);
      const parsedEvents = parseApiResponse(apiResponse);
      setEvents(parsedEvents);
    } catch (error) {
      console.error("Error fetching employee break count:", error);
    } finally {
      setLoading(false);
    }
  };

  const handleSearch = (e) => {
    e.preventDefault();
    fetchEmployeeBreakCount();
  };

  const handleYearChange = (e) => {
    setSearchParams({ ...searchParams, year: e.target.value });
  };

  const handleMonthChange = (e) => {
    setSearchParams({ ...searchParams, month: e.target.value });
  };

  const parseApiResponse = (apiResponse) => {
    const formattedEvents = [];
    apiResponse.data.forEach((employeeData) => {
      employeeData.attendance_list.forEach((attendance) => {
        attendance.attendance.forEach((attendanceData) => {
          const date = attendanceData.date;
          const presentAttendance = attendanceData.break_start_present;
          const time = attendanceData.IN || '';

          const title = !presentAttendance ? 'Present' : '';

          formattedEvents.push({
            title: title,
            date: date,
            time: time,
            backgroundColor: presentAttendance ? '#000000' : '#00000',
            borderColor: presentAttendance ? '#000000' : '#000000',
            borderRadius: '5px',
          });
        });
      });
    });

    return formattedEvents;
  };
  const [breakcountData, setBreakcountData] = useState([]);

  const [employeeId, setEmployeeId] = useState('');

  const formatTime = (time) => {
    const formattedTime = new Date(`2022-01-01 ${time}`);
    return formattedTime.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });
  };

  const fetchData = async () => {
    try {
      const formattedData = await viewEmployeeBreakCount(employeeId);

      if (!formattedData || !formattedData.data.length) {
        throw new Error('Failed to fetch data.');
      }

      const breakList = formattedData.data[0].break_list;
      const branchArray = breakList.flatMap((item, index) =>
        Object.entries(item.attendance).map(([date, attendanceData]) => ({
          id: `${index}_${date}`,
          date: attendanceData.date,
          name: attendanceData.name,
          type: attendanceData.breaks[0].type,
          time: formatTime(attendanceData.breaks[0].time),
          breaks: attendanceData.breaks.map(breakItem => ({
            type: breakItem.type,
            time: formatTime(breakItem.time),
            duration_minutes: breakItem.duration_minutes || 0,
          })),
        }))
      );

      setBreakcountData(branchArray);
    } catch (error) {
      console.error('Error fetching data:', error);
    } finally {
      setLoading(false);
    }
  };

  const debouncedFetchData = _debounce(fetchData, 200);

  useEffect(() => {
    debouncedFetchData();

    return () => {
      debouncedFetchData.cancel();
    };
  }, [employeeId]);

  return (
    <React.Fragment>
      <HeaderMobile />
      <Header />
      <div className="main p-4 p-lg-5 mt-5">
        <div className="mb-4 border-bottom text-center">
          <h3 className="mb-0 pb- border-bottom custome-btn">
            Employee Attendance Report
          </h3>
        </div>
        <div className="col-md-12">
          <div className="breadcrumb-warning d-flex justify-content-between ot-card">
            <div>
              <ol className="breadcrumb ot-breadcrumb ot-breadcrumb-basic">
                <li class="breadcrumb-item">
                  <Link to="/admin/dashboard">Dashboard</Link>
                </li>
                <li class="breadcrumb-item active">
                  <Link to="/employee/attendanceview">Attendance Details</Link>
                </li>
                <li class="breadcrumb-item active">List</li>
              </ol>
            </div>
          </div>
          <Card
            style={{
              boxShadow: "0 4px 8px 0 rgba(0,0,0,0.2)",
              borderRadius: "10px",
              overflow: "hidden",
              border: "none",
            }}
          >
            <CardBody>
              <Form onSubmit={handleSearch}>
                <Row className="mb-3">
                  <Col>
                    <Form.Control
                      type="number"
                      placeholder="Year"
                      value={searchParams.year}
                      onChange={handleYearChange}
                    />
                  </Col>
                  <Col>
                    <Form.Control
                      as="select"
                      value={searchParams.month}
                      onChange={handleMonthChange}
                    >
                      <option value="1">January</option>
                      <option value="2">February</option>
                      <option value="3">March</option>
                      <option value="4">April</option>
                      <option value="5">May</option>
                      <option value="6">June</option>
                      <option value="7">July</option>
                      <option value="8">August</option>
                      <option value="9">September</option>
                      <option value="10">October</option>
                      <option value="11">November</option>
                      <option value="12">December</option>
                    </Form.Control>
                  </Col>
                  <Col>
                    <Button type="submit">Search</Button>
                  </Col>
                </Row>
              </Form>

              {loading ? (
                <Spinner animation="border" role="status">
                  <span className="visually-hidden">Loading...</span>
                </Spinner>
              ) : (
                <FullCalendar
                plugins={[dayGridPlugin]}
                initialView="dayGridMonth"
                events={events}
                eventContent={(eventInfo) => {
                  return (
                    <div>
                      <div>{eventInfo.event.title}</div>
                      {eventInfo.event.extendedProps.time && (
                        <div style={{ color: 'white' }}>

                          <div>
                            ({eventInfo.event.extendedProps.time})
                          </div>

                        </div>
                      )}
                    </div>
                  );
                }}
              />
              )}
            </CardBody>
          </Card>
        </div>
      </div>
    </React.Fragment>
  );
};

export default SingleAttendanceView;
