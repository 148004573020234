import React, { useEffect, useState } from 'react';
import Header from '../../layouts/Header';
import HeaderMobile from '../../layouts/HeaderMobile';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { addManualSalarySetup, viewEmployee, viewSalarySetup } from '../../services/api/api';

import { Container, Row, Col, Form, Button } from 'react-bootstrap';
import { setTimeout } from 'core-js';
import { useNavigate } from "react-router-dom";

const StaffSalary = () => {

    const navigate = useNavigate();

    const [selectedEmployee, setSelectedEmployee] = useState(null);
    const [formData, setFormData] = useState({
        employee_id: null,
        name: '',
        basic: [{ name: '', deduct_type: '', amount: null }],
        allowence: [{ name: '', deduct_type: '', amount: null }],
        deduction: [{ name: '', deduct_type: '', amount: null }],
        working_days: null,
        absent_days: null,
        present_days: null,
        break_violation: null,
        leave_violation: null,
        late_violation: null,
        net_total: null,
        month: '',
        year: ''
    });

    const [employees, setEmployees] = useState([]);
    const [loading, setLoading] = useState(true);
    const [structureId, setStructureId] = useState('');
    const [salaryData, setSalaryData] = useState(null);
    const [salarySetupData, setSalarySetupData] = useState([]);
    const [submitsalary, Setsubmitsalary] = useState({
        basic: [],
        allowence: [],
        deduction: []
    });
    const [years, setYears] = useState([]);
    const [months, setMonths] = useState([]);

    useEffect(() => {
        // Generate the years array from 2020 to 2050
        const generatedYears = Array.from({ length: 31 }, (_, i) => 2020 + i);
        setYears(generatedYears);

        // Set the months array
        const monthNames = [
            'january', 'february', 'march', 'april', 'may', 'june',
            'july', 'august', 'september', 'october', 'november', 'december'
        ];
        setMonths(monthNames);
    }, []);


    useEffect(() => {
        fetchempData();
        fetchData();
    }, []);

    const [basicFormGroup, setBasicFormGroups] = useState(null);
    const [allowenceFormGroup, setAllowanceFormGroups] = useState(null);
    const [deductionFormGroup, setDeductionFormGroups] = useState(null);

    const fetchempData = async () => {
        try {
            const formData = {};
            const response = await viewEmployee(formData);
            if (!response || !response.success || !response.data) {
                throw new Error('Failed to fetch data.');
            }

            setEmployees(response.data);
        } catch (error) {
            console.error(error);
            toast.error('Failed to fetch employees.');
        } finally {
            setLoading(false);
        }
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        console.log(name, value);
        setFormData({
            ...formData,
            [name]: value
        });
    };

    const handleEmployeeChange = (e) => {
        console.log(selectedEmployee);
        console.log(e);
        let selectedEmployeeId = e.target.value.trim();
        console.log(selectedEmployeeId);
        console.log(employees);

        const employee = employees.find(emp => emp && emp.employeeData && emp.employeeData._id === parseInt(selectedEmployeeId));
        console.log(employee);

        setSelectedEmployee(employee ? employee.employeeData : null);
        console.log(selectedEmployee);

        if (employee) {
            const { first_name, last_name, employee_id } = employee.employeeData;
            const fullName = `${first_name} ${last_name}`;
            setFormData(prevFormData => ({
                ...prevFormData,
                name: fullName
            }));
        }
    };

    const fetchData = async () => {
        try {
            const responseData = await viewSalarySetup();

            if (!responseData || !responseData.data) {
                throw new Error('Failed to fetch data.');
            }

            setSalarySetupData(responseData.data);
            console.log(salarySetupData);
        } catch (error) {
            console.error('Error fetching data:', error);
        } finally {
            setLoading(false);
        }
    };

    const handleStructureChange = async (e) => {
        const selectedStructureId = e.target.value;
        setStructureId(selectedStructureId);

        setLoading(true);

        try {
            const selectedStructure = salarySetupData.find(
                structure => structure.Salary_Structure[selectedStructureId]
            );

            if (selectedStructure) {
                setSalaryData(selectedStructure.Salary_Structure[selectedStructureId]);

                if (selectedStructure.Salary_Structure[selectedStructureId].basic.length > 0) {
                    const basicGroups = renderFormGroups(
                        selectedStructure.Salary_Structure[selectedStructureId].basic,
                        'basic'
                    );
                    setBasicFormGroups(basicGroups);
                }
                //  else {
                //     setBasicFormGroups(null); // Handle empty state
                // }

                if (selectedStructure.Salary_Structure[selectedStructureId].allowence.length > 0) {
                    const allowenceGroups = renderFormGroups(
                        selectedStructure.Salary_Structure[selectedStructureId].allowence,
                        'allowence'
                    );
                    setAllowanceFormGroups(allowenceGroups);
                }
                // else {
                //     setAllowanceFormGroups(null); // Handle empty state
                // }

                if (selectedStructure.Salary_Structure[selectedStructureId].deduction.length > 0) {
                    const deductionGroups = renderFormGroups(
                        selectedStructure.Salary_Structure[selectedStructureId].deduction,
                        'deduction'
                    );
                    setDeductionFormGroups(deductionGroups);
                } else {
                    setDeductionFormGroups(null); // Handle empty state
                }
            } else {
                setBasicFormGroups(null);
                setAllowanceFormGroups(null);
                setDeductionFormGroups(null);
            }
        } finally {
            setLoading(false);
        }
    };

    const renderFormGroups = (data, type) => {
        return data.map((item, index) => (
            <Form.Group key={index} className="mb-3">
                <Form.Label>{item.name}</Form.Label>
                <Form.Control
                    type="number"
                    name={item.name.toLowerCase().replace(/\s+/g, '')}
                    onChange={(e) => handleFormChange(e, type, index, item.deduct_type)}
                />
            </Form.Group>
        ));
    };

    // const handleNetTotal = () => {
    //     let total = 0;
    //     const basic = formData.basic.map(item => item.amount).reduce((a, b) => a + b, 0);
    //     console.log("basic",basic);
    //     const allowance = formData.allowence.map(item => item.amount).reduce((a, b) => a + b, 0);
    //     console.log("allowance",allowance);
    //     const deduction = formData.deduction.map(item => item.amount).reduce((a, b) => a + b, 0);
    //     console.log("deduction",deduction);
    //     const otherDeductions = Number(formData.break_violation) + Number(formData.leave_violation) + Number(formData.late_violation)
    //     console.log("otherDeductions",otherDeductions);

    //     total = total + Number(allowance)
    //     console.log(total);
    //     total = total + Number(basic)
    //     console.log(total);
    //     total = total - Number(deduction)
    //     console.log(total);
    //     total = total - Number(otherDeductions)
    //     console.log(total);
    //     setFormData(prevFormData => ({ ...prevFormData, net_total: Number(total) }));
    // }


    // const handleFormChange = (e, type, index, deduct_type) => {
    //     const { name, value } = e.target;
    //     console.log(e);
    //     console.log(type);
    //     console.log(index);
    //     console.log(name);
    //     console.log(value);
    //     const updatedData = [...formData[type]];

    //     // Update only the specific item at the given index
    //     updatedData[index] = {
    //         ...updatedData[index],
    //         name: name,
    //         amount: value,
    //         deduct_type: deduct_type
    //     };

    //     setFormData({
    //         ...formData,
    //         [type]: updatedData
    //     });
    // };


    // const handleFormChange = (e, type, index, deduct_type) => {
    //     const { name, value } = e.target;

    //     // Ensure formData[type] is initialized as an array if it's null or undefined
    //     const updatedData = formData[type] ? [...formData[type]] : [];

    //     // Update only the specific item at the given index
    //     updatedData[index] = {
    //         ...updatedData[index],
    //         name: name,
    //         amount: value,
    //         deduct_type: deduct_type
    //     };

    //     setFormData({
    //         ...formData,
    //         [type]: updatedData
    //     });
    // };

    // const handleSubmit = async (e) => {
    //     e.preventDefault();
    //     setLoading(true);

    //     try {
    //         const response = await addempSalarySetup(formData);
    //         if (response && response.success) {
    //             toast.success('Salary setup added successfully!');
    //             // Optionally reset the form
    //             setFormData({
    //                 employee_id: '',
    //                 name: '',
    //                 basic: [{ name: '', deduct_type: '', amount: '' }],
    //                 allowence: [{ name: '', deduct_type: '', amount: '' }],
    //                 deduction: [{ name: '', deduct_type: '', amount: '' }],
    //                 working_days: null,
    //                 absent_days: null,
    //                 present_days: null,
    //                 break_violation: null,
    //                 leave_violation: null,
    //                 late_violation: null,
    //             });
    //         } else {
    //             throw new Error('Failed to add salary setup.');
    //         }
    //     } catch (error) {
    //         console.error('Error adding salary setup:', error);
    //         toast.error('Failed to add salary setup.');
    //     } finally {
    //         setLoading(false);
    //     }
    // };




    // const handleStructureChange = (e) => {
    //     const selectedStructureId = e.target.value;
    //     setStructureId(selectedStructureId);

    //     // Fetch selected structure data here
    //     const selectedStructure = salarySetupData.find(structure => structure.id === selectedStructureId);
    //     if (selectedStructure) {
    //         const { basic, allowence, deduction } = selectedStructure; // Adjust this based on your API response structure
    //         setFormData({
    //             ...formData,
    //             basic: basic.map(item => ({ ...item, amount: '' })), // Initialize amounts to empty string
    //             allowence: allowence.map(item => ({ ...item, amount: '' })), // Initialize amounts to empty string
    //             deduction: deduction.map(item => ({ ...item, amount: '' })) // Initialize amounts to empty string
    //         });
    //     }
    // };


    const handleNetTotal = () => {
        let total = 0;

        const basic = formData.basic.reduce((total, item) => total + parseFloat(item.amount || 0), 0);
        console.log("basic", basic);

        const allowance = formData.allowence.reduce((total, item) => total + parseFloat(item.amount || 0), 0);
        console.log("allowance", allowance);

        const deduction = formData.deduction.reduce((total, item) => total + parseFloat(item.amount || 0), 0);
        console.log("deduction", deduction);

        const otherDeductions = parseFloat(formData.break_violation || 0) + parseFloat(formData.leave_violation || 0) + parseFloat(formData.late_violation || 0);
        console.log("otherDeductions", otherDeductions);

        total = total + allowance;
        console.log(total);

        total = total + basic;
        console.log(total);

        total = total - deduction;
        console.log(total);

        total = total - otherDeductions;
        console.log(total);

        setFormData(prevFormData => ({ ...prevFormData, net_total: parseFloat(total.toFixed(2)) }));
    };

    const handleFormChange = (e, type, index, deduction_type) => {
        const { name, value } = e.target;
        const updatedFormData = { ...formData };
        updatedFormData[type][index] = {
            ...updatedFormData[type][index],
            amount: value,
            name: name,
            deduct_type: deduction_type,
        };
        setFormData(updatedFormData);
    };


    const handleSubmit = async (event) => {
        if (event) event.preventDefault();
        setLoading(true);



        try {
            const response = await addManualSalarySetup(formData);
            if (response && response.success) {
                toast.success('Salary setup added successfully!');
                // Optionally reset the form
                setFormData({
                    employee_id: '',
                    name: '',
                    basic: [],
                    allowence: [],
                    deduction: [],
                    working_days: null,
                    absent_days: null,
                    present_days: null,
                    break_violation: null,
                    leave_violation: null,
                    late_violation: null,
                    net_total: null,
                    month: '',
                    year: ''
                });
            } else {
                throw new Error('Failed to add salary setup.');
            }
        } catch (error) {

            // toast.error('Failed to add salary setup.');

        } finally {
            setLoading(false);
        }
        toast.success('Salary setup added successfully!');

    };

    console.log("formData", formData);


    return (
        <React.Fragment>
            <HeaderMobile />
            <Header />
            <div className="main p-4 p-lg-5 mt-5">
                <Container>
                    <Row className="justify-content-md-center">
                        <Col md={8}>
                            <div className="card p-4 mt-4">
                                <h4 className="mb-3">Edit Staff Salary</h4>
                                <Form onSubmit={handleSubmit}>
                                    <Row>
                                        <Col md={6} className="mb-3">
                                            <Form.Group>
                                                <Form.Label>Employee Name:</Form.Label>
                                                <Form.Control
                                                    as="select"
                                                    name="employee_id"
                                                    value={formData.employee_id}
                                                    onChange={(e) => {
                                                        handleChange(e);
                                                        handleEmployeeChange(e);
                                                    }}
                                                >
                                                    <option value="">Select Employee</option>
                                                    {employees.map((employee) => (
                                                        <option key={employee.employeeData._id} value={employee.employeeData._id}>
                                                            {employee.employeeData.first_name} {employee.employeeData.last_name}
                                                        </option>
                                                    ))}
                                                </Form.Control>
                                            </Form.Group>
                                        </Col>
                                        <Col md={6} className="mb-3">
                                            <Form.Group>
                                                <Form.Label>Select Salary Structure:</Form.Label>
                                                <Form.Control
                                                    as="select"
                                                    name="structureId"
                                                    value={structureId}
                                                    onChange={(e) => {
                                                        handleStructureChange(e);
                                                    }}
                                                >
                                                    <option value="">Select Salary Structure</option>
                                                    {salarySetupData.length > 0 && Object.keys(salarySetupData[0].Salary_Structure).map((key) => (
                                                        <option key={key} value={key}>{salarySetupData[0].Salary_Structure[key].name}</option>
                                                    ))}
                                                </Form.Control>
                                            </Form.Group>
                                        </Col>
                                    </Row>

                                    {loading && <div>Loading...</div>}
                                    {basicFormGroup && (
                                        <Row>
                                            <Col md={6} className="mb-3">
                                                <h5>Basic</h5>
                                                {basicFormGroup}
                                            </Col>
                                        </Row>
                                    )}
                                    {allowenceFormGroup && (
                                        <Row>
                                            <Col md={6} className="mb-3">
                                                <h5>Allowance</h5>
                                                {allowenceFormGroup}
                                            </Col>
                                        </Row>
                                    )}
                                    {deductionFormGroup && (
                                        <Row>
                                            <Col md={6} className="mb-3">
                                                <h5>Deduction</h5>
                                                {deductionFormGroup}
                                            </Col>
                                        </Row>
                                    )}
                                    <Row>
                                        <Col md={4} className="mb-3">
                                            <Form.Group>
                                                <Form.Label>Break Violation</Form.Label>
                                                <Form.Control
                                                    type="number"
                                                    name="break_violation"
                                                    value={formData.break_violation || ""}
                                                    onChange={handleChange}
                                                />
                                            </Form.Group>
                                        </Col>
                                        <Col md={4} className="mb-3">
                                            <Form.Group>
                                                <Form.Label>Leave Violation</Form.Label>
                                                <Form.Control
                                                    type="number"
                                                    name="leave_violation"
                                                    value={formData.leave_violation || ""}
                                                    onChange={handleChange}
                                                />
                                            </Form.Group>
                                        </Col>
                                        <Col md={4} className="mb-3">
                                            <Form.Group>
                                                <Form.Label>Late Violation</Form.Label>
                                                <Form.Control
                                                    type="number"
                                                    name="late_violation"
                                                    value={formData.late_violation || ""}
                                                    onChange={(e) => {
                                                        handleChange(e);

                                                    }}
                                                />
                                            </Form.Group>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col md={4} className="mb-3">
                                            <Form.Group>
                                                <Form.Label>Year</Form.Label>
                                                <Form.Control
                                                    as="select"
                                                    name="year"
                                                    value={formData.year || ""}
                                                    onChange={handleChange}
                                                >
                                                    <option value="">Select Year</option>
                                                    {years.map((year) => (
                                                        <option key={year} value={year}>
                                                            {year}
                                                        </option>
                                                    ))}
                                                </Form.Control>
                                            </Form.Group>
                                        </Col>

                                        <Col md={4} className="mb-3">
                                            <Form.Group>
                                                <Form.Label>Month</Form.Label>
                                                <Form.Control
                                                    as="select"
                                                    name="month"
                                                    value={formData.month || ""}
                                                    onChange={handleChange}
                                                >
                                                    <option value="">Select Month</option>
                                                    {months.map((month) => (
                                                        <option key={month} value={month}>
                                                            {month.charAt(0).toUpperCase() + month.slice(1)}
                                                        </option>
                                                    ))}
                                                </Form.Control>
                                            </Form.Group>
                                        </Col>
                                        <Col md={4} className="mb-3">
                                            <Form.Group>
                                                <Form.Label>Working Days</Form.Label>
                                                <Form.Control
                                                    type="number"
                                                    name="working_days"
                                                    value={formData.working_days || ""}
                                                    onChange={handleChange}
                                                />
                                            </Form.Group>
                                        </Col>
                                        <Col md={4} className="mb-3">
                                            <Form.Group>
                                                <Form.Label>Absent Days</Form.Label>
                                                <Form.Control
                                                    type="number"
                                                    name="absent_days"
                                                    value={formData.absent_days || ""}
                                                    onChange={handleChange}
                                                />
                                            </Form.Group>
                                        </Col>
                                        <Col md={4} className="mb-3">
                                            <Form.Group>
                                                <Form.Label>Present Days</Form.Label>
                                                <Form.Control
                                                    type="number"
                                                    name="present_days"
                                                    value={formData.present_days || ""}
                                                    // onChange={handleChange}
                                                    onChange={(e) => {
                                                        handleChange(e);
                                                        handleNetTotal()
                                                    }}
                                                />
                                            </Form.Group>
                                        </Col>

                                        <p className="">Net Total : {formData.net_total}</p>
                                    </Row>
                                    <Button type="submit" className="mt-3" disabled={loading} onClick={async (e) => {
                                        e.preventDefault();
                                        await handleSubmit()
                                        navigate('/admin/employeesalary')
                                    }}>
                                        {loading ? 'Adding...' : 'Add'}
                                    </Button>
                                </Form>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>
        </React.Fragment>
    );
};

export default StaffSalary;
