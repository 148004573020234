import React, { useState, useEffect, useRef } from 'react';
import _debounce from 'lodash/debounce';
import Header from "../../layouts/Header";
import HeaderMobile from "../../layouts/HeaderMobile";
import { Card, Button, Modal } from "react-bootstrap";
import { Link } from "react-router-dom";
import { DataGrid } from '@mui/x-data-grid';
import { creditleavepagination, viewCreditLeave } from '../../services/api/api';

const ViewCreditleave = () => {
  const tableRef = useRef(null);
  const [creditLeaveData, setCreditLeaveData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [searchText, setSearchText] = useState('');
  
  const [rows, setRows] = useState([]);
  const [totalPages, setTotalPages] = useState(1);
  const [page, setPage] = useState(1);
  const [pageSize, setpageSize] = useState(100);
  const [count, setCount] = useState(0);
  const pageRef = useRef(page);

  useEffect(() => {
    pageRef.current = page;
  }, [page]);

  console.log(rows);


  const customStyles = {
    grid: {
      border: '1px solid #ddd',
    },
    cell: {
      border: 'none', // Remove all borders
      borderBottom: '1px solid #ddd', // Only horizontal border
      padding: '0.5rem 0.5rem',
      color: 'var(--bs-table-color-state, var(--bs-table-color-type, #000000b8))', // Using custom properties for text color
      // backgroundColor: 'white', // Using custom properties for background color
      fontWeight: '500', // Making text important
      fontFamily: 'system-ui, -apple-system, "Segoe UI", Roboto, Helvetica, Arial, sans-serif', // Highly standard font family
    },
    columnHeader: {
      border: 'none', // Remove all borders
      borderBottom: '1px solid #ddd', // Only horizontal border
      padding: '0.5rem 0.5rem',
      color: 'var(--bs-table-color-state, var(--bs-table-color-type, #000))', // Using custom properties for text color
      backgroundColor: 'var(--bs-table-bg)', // Using custom properties for background color

      fontWeight: 'bold',
      fontFamily: 'system-ui, -apple-system, "Segoe UI", Roboto, Helvetica, Arial, sans-serif', // Highly standard font family
    },
    lastColumn: {
      borderRight: 'none',
    },
  };

  // const fetchData = async () => {
  //   try {
  //     const responseData = await viewCreditLeave();

  //     console.log("responseData", responseData);

  //     if (!responseData) {
  //       throw new Error('Failed to fetch data.');
  //     }

  //     // Add a unique id to each row
  //     const dataWithIds = responseData.map((row, index) => ({ id: index + 1, ...row }));

  //     setCreditLeaveData(dataWithIds);
  //   } catch (error) {
  //     console.error('Error fetching data:', error);
  //   } finally {
  //     setLoading(false);
  //   }
  // };


  // useEffect(() => {
  //   fetchData();
  // }, []);


  const fetchData = async () => {
    try {
      const responseData = await creditleavepagination(page, pageSize);

      console.log("responseData", responseData);

      if (!responseData || !responseData.data) {
        throw new Error('Failed to fetch data.');
      }

      // Map the response data to include an id for each row
      const dataWithIds = responseData.data.map((row, index) => ({
        id: index + 1, // Generate an id for each row
        Employee_Name: row.Employee_Name, // Use correct field names
        Total_Leave: row.Total_Leave, // Use correct field names
      }));

      // Set the data to the state variables
      setCreditLeaveData(dataWithIds);
      setRows(dataWithIds);  // Set rows for the DataGrid

      setTotalPages(responseData.totalPages);
      setpageSize(pageSize);
      setCount(responseData.totalRecords);
    } catch (error) {
      console.error('Error fetching data:', error);
    } finally {
      setLoading(false);
    }
  };

  // const fetchData = async () => {
  //   try {
  //     const responseData = await viewCreditLeave();

  //     console.log("responseData", responseData);

  //     if (!responseData) {
  //       throw new Error('Failed to fetch data.');
  //     }

  //     const dataWithIds = responseData.map((row, index) => ({ id: index + 1, ...row }));

  //     setCreditLeaveData(dataWithIds);
  //   } catch (error) {
  //     console.error('Error fetching data:', error);
  //   } finally {
  //     setLoading(false);
  //   }
  // };

  useEffect(() => {
    const debouncedFetchData = _debounce(fetchData, 100);

    debouncedFetchData();

    return () => {
      debouncedFetchData.cancel();
    };
  }, [page, pageSize]);

  const handlePageChange = (event) => {
    setPage(parseInt(event.target.value, 10));
    console.log(event.target.value)
  };

  const handlePageSizeChange = (event) => {
    setpageSize(parseInt(event.target.value, 10));
  };

  const columns = [
    {
      field: 'id',
      headerName: 'Sr.',
      align: 'center',
      headerAlign: 'center',
      width: 50,
      flex: 1,
    },
    {
      field: 'Employee_Name', // Match the field from the API response
      headerName: 'Employee Name',
      width: 250,
      align: 'center',
      flex: 1,
      headerAlign: 'center',
    },
    {
      field: 'Total_Leave', // Match the field from the API response
      headerName: 'Total Leave',
      width: 200,
      align: 'center',
      flex: 1,
      headerAlign: 'center',
    },
  ];


  const handleSearch = (event) => {
    setSearchText(event.target.value);
  };

  const filterRows = () => {
    if (!searchText) {
      return creditLeaveData;
    }

    return creditLeaveData.filter((row) =>
      Object.values(row).some(
        (value) =>
          typeof value === 'string' && value.toLowerCase().includes(searchText.toLowerCase())
      )
    );
  };


  return (
    <React.Fragment>
      <HeaderMobile />
      <Header />
      <div className="main p-4 p-lg-5 mt-5">
        <div className="breadcrumb-warning d-flex justify-content-between ot-card">
          <div>
            <ol className="breadcrumb ot-breadcrumb ot-breadcrumb-basic">
              <li className="breadcrumb-item ">
                <Link to="/admin/dashboard">Dashboard</Link>
              </li>
              <li className="breadcrumb-item active"><Link to="/admin/viewcreditempleave">Credit Leave</Link></li>
              {/* <li className="breadcrumb-item active">Lists</li> */}
            </ol>
          </div>
          {/* <nav aria-label="breadcrumb">
            <Button className="mb-3 custom-submit-btn">
              <span style={{ color: "white" }}>Create Credit Leave</span>
            </Button>
          </nav> */}
        </div>
        <Card className="popup-card empHistory">
          <Card.Body>
            <div style={{ display: 'flex', justifyContent: "center", alignItems: 'center', justifyContent: 'flex-end', marginBottom: "1rem" }}>
              <label htmlFor="searchInput"></label>
              <input
                type="text"
                className='p-1 w-25 ms-2'
                placeholder="Search"
                value={searchText}
                onChange={handleSearch}
              />
            </div>
            {loading ? (
              <div className="loader-container">
                <div className="loader"></div>
              </div>
            ) : (
              // <div style={{ height: 500, width: '100%' }}>
              //   <DataGrid
              //     rows={filterRows()}
              //     columns={columns}
              //     pageSize={5}
              //     responsive
              //     getRowId={(row) => row.id}
              //     components={{
              //       NoRowsOverlay: () => <div style={{ textAlign: 'center', padding: '20px' }}>No data available</div>,
              //     }}
              //   />
              // </div>

              <div style={customStyles.grid}>
                <DataGrid
                  className="gridColor"
                  rows={rows}
                  columns={columns}
                  pageSize={pageSize}
                  pagination
                  paginationMode="client"
                  getRowId={(row) => row.id}
                  components={{
                    Pagination: () => (
                      // <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', padding: '10px' }}>
                      <div className="custom-select-container">
                        <div>
                          <label htmlFor="page-size">Rows per page: </label>
                          <select
                            id="page-size"
                            value={pageSize}
                            onChange={handlePageSizeChange}
                          >
                            <option value={1}>1</option>
                            <option value={10}>10</option>
                            <option value={20}>20</option>
                            <option value={50}>50</option>
                            <option value={100}>100</option>
                          </select>
                        </div>
                        <div>
                          <label htmlFor="page-number">Page: </label>
                          <select
                            className="page-number"
                            id="page-number"
                            value={page}
                            onChange={handlePageChange}
                          >
                            {[...Array(totalPages).keys()].map((pageNumber) => (
                              <option key={pageNumber} value={pageNumber + 1}>
                                {pageNumber + 1}
                              </option>
                            ))}
                          </select>
                        </div>
                      </div>
                    ),
                  }}
                  componentsProps={{
                    cell: { style: customStyles.cell },
                    columnHeader: { style: customStyles.columnHeader },
                  }}
                  rowCount={count}
                  page={page - 1}
                  onPageChange={(newPage) => setPage(newPage + 1)}
                  rowsPerPageOptions={[]} // Avoid displaying the default rows per page options
                />
              </div>
            )}
          </Card.Body>
        </Card>
      </div>

    </React.Fragment>
  );
};

export default ViewCreditleave;

