import React, { useState, useEffect, useRef } from 'react';
import _debounce from 'lodash/debounce';
import Header from "../../../layouts/Header";
import HeaderMobile from "../../../layouts/HeaderMobile";
import { Card, Button, Modal, Row, Col } from "react-bootstrap";
import 'datatables.net';
import { viewDepartment, deleteDepartment, viewdepartmentpagination, BASE_URL, searchdepartmentpagination } from '../../../services/api/api';
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { DataGrid } from '@mui/x-data-grid';
import * as XLSX from 'xlsx'; // Import XLSX library
import { TextField } from '@mui/material';
const ViewDepartment = () => {
  const navigate = useNavigate();
  const tableRef = useRef(null);
  const [departmentData, setDepartmentData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [deleteDepartmentId, setDeleteDepartmentId] = useState(null);
  const [masterId, setMasterId] = useState(null);
  const [searchText, setSearchText] = useState('');

  const [rows, setRows] = useState([]);
  const [totalPages, setTotalPages] = useState(1);
  const [page, setPage] = useState(1);
  const [pageSize, setpageSize] = useState(100);
  const [count, setCount] = useState(0);
  const pageRef = useRef(page);
  const [error, setError] = useState(null);

  useEffect(() => {
    pageRef.current = page;
  }, [page]);

  const customStyles = {
    grid: {
      border: '1px solid #ddd',
    },
    cell: {
      border: 'none', // Remove all borders
      borderBottom: '1px solid #ddd', // Only horizontal border
      padding: '0.5rem 0.5rem',
      color: 'var(--bs-table-color-state, var(--bs-table-color-type, #000000b8))', // Using custom properties for text color
      // backgroundColor: 'white',// Using custom properties for background color
      fontWeight: '500', // Making text important
      fontFamily: 'system-ui, -apple-system, "Segoe UI", Roboto, Helvetica, Arial, sans-serif', // Highly standard font family
    },
    columnHeader: {
      border: 'none', // Remove all borders
      borderBottom: '1px solid #ddd', // Only horizontal border
      padding: '0.5rem 0.5rem',
      color: 'var(--bs-table-color-state, var(--bs-table-color-type, #000))', // Using custom properties for text color
      backgroundColor: 'var(--bs-table-bg)', // Using custom properties for background color

      fontWeight: 'bold',
      fontFamily: 'system-ui, -apple-system, "Segoe UI", Roboto, Helvetica, Arial, sans-serif', // Highly standard font family
    },
    lastColumn: {
      borderRight: 'none',
    },
  };






  // useEffect(() => {
  //   const debouncedFetchData = _debounce(fetchData, 100);
  //   debouncedFetchData();
  //   return () => {
  //     debouncedFetchData.cancel();
  //   };
  // }, [page, pageSize]);

  useEffect(() => {
    if (!searchText) {
      fetchData();
    }
    else {
      searchData();
    }
  }, [page, pageSize, searchText])



  // const fetchData = async () => {
  //   try {
  //     const formattedData = await viewdepartmentpagination(page, pageSize);

  //     console.log(formattedData);


  //     const { data, totalPages } = formattedData;
  //     const departmentsArray = data.map(value => ({
  //       _id: value.item.company_department?._id || 'N/A',  // Use `company_designation` for ID
  //       department_name: value.item.company_department?.department_name,  // Extract designation name
  //     }));
  //     setMasterId(data[0].item._id);

  //     console.log(departmentsArray);

  //     setDepartmentData(departmentsArray);
  //     setTotalPages(formattedData.totalPages);
  //     setpageSize(pageSize);
  //     setRows(departmentsArray);
  //   } catch (error) {
  //     console.error('Error fetching data:', error);
  //   } finally {
  //     setLoading(false);
  //   }
  // };

  const fetchData = async () => {
    try {
      const formattedData = await viewdepartmentpagination(1, 100);

      console.log(formattedData);

      const { success, data, totalPages } = formattedData; // Ensure you check success

      if (success) {
        // Map over the data and handle possible empty or undefined values
        const departmentsArray = data.map(value => ({
          _id: value.item.company_department?._id || 'N/A', // Use company_department for ID
          department_name: value.item.company_department?.department_name || 'N/A', // Extract department name
        }));

        // Set the master ID only if departmentsArray has data
        setMasterId(data[0].item._id);

        console.log(departmentsArray);

        setTotalPages(totalPages);
        setpageSize(departmentsArray.length > 0 ? departmentsArray.length : 0); // Adjust page size based on data length

        if (formattedData.data.length == 0) {
          setDepartmentData([]); // Set to empty array if no records
          console.log('No data available');
        }
        else {

          const departments = formattedData.data.map((item, index) => {
            const departmentData = item.item.company_department || {};

            return {

              id: index + 1,
              _id: departmentData._id,
              department_name: departmentData.department_name || 'N/A',

            };
          });


          setDepartmentData(departments); // Use the response data directly
          setRows(departments);
        }

        // setDepartmentData(departmentsArray);
        // setRows(departmentsArray);
      } else {
        // Handle unsuccessful response
        setDepartmentData([]);
        setTotalPages(0);
        setMasterId(null);
        setRows([]); // Ensure rows are set to empty array when there's no data
      }
    } catch (error) {
      console.error('Error fetching data:', error);
    } finally {
      setLoading(false);
    }
  };
  const searchData = async () => {
    try {
      const formattedData = await searchdepartmentpagination(page, pageSize, searchText);

      console.log(formattedData);

      const { success, data, totalPages } = formattedData; // Ensure you check success

      if (success) {
        // Map over the data and handle possible empty or undefined values
        const departmentsArray = data.map(value => ({
          _id: value.item.company_department?._id || 'N/A', // Use company_department for ID
          department_name: value.item.company_department?.department_name || 'N/A', // Extract department name
        }));

        // Set the master ID only if departmentsArray has data
        setMasterId(data[0].item._id);

        console.log(departmentsArray);

        setTotalPages(totalPages);
        setpageSize(departmentsArray.length > 0 ? departmentsArray.length : 0); // Adjust page size based on data length

        if (formattedData.data.length == 0) {
          setDepartmentData([]); // Set to empty array if no records
          console.log('No data available');
        }
        else {

          const departments = formattedData.data.map((item, index) => {
            const departmentData = item.item.company_department || {};

            return {

              id: index + 1,
              _id: departmentData._id,
              department_name: departmentData.department_name || 'N/A',

            };
          });


          setDepartmentData(departments); // Use the response data directly
          setRows(departments);
        }

        // setDepartmentData(departmentsArray);
        // setRows(departmentsArray);
      } else {
        // Handle unsuccessful response
        setDepartmentData([]);
        setTotalPages(0);
        setMasterId(null);
        setRows([]); // Ensure rows are set to empty array when there's no data
      }
    } catch (error) {
      console.error('Error fetching data:', error);
    } finally {
      setLoading(false);
    }
  };


  const handlePageChange = (event) => {
    const newPage = parseInt(event.target.value, 10);
    setPage(newPage);
    console.log('Selected Page:', newPage);
  };

  const handlePageSizeChange = (event) => {
    console.log('Page Change Event:', event);
    const newPageSize = parseInt(event.target.value, 10);
    setpageSize(newPageSize);
    console.log('Selected Page Size:', newPageSize);
  };

  const getToken = () => {
    const token = sessionStorage.getItem('token');
    return token ? `Bearer ${token}` : '';
  };



  const s2ab = (s) => {
    const buf = new ArrayBuffer(s.length);
    const view = new Uint8Array(buf);
    for (let i = 0; i !== s.length; ++i) {
      view[i] = s.charCodeAt(i) & 0xFF;
    }
    return buf;
  };

  // const exportToExcel = () => {
  //   // Create a new workbook
  //   const workbook = XLSX.utils.book_new();
  //   // Create a new worksheet with the data
  //   const worksheet = XLSX.utils.json_to_sheet(
  //     reversedRows.map((row, index) => ({
  //       'Sr.': index + 1,
  //       'Department Name': row.department_name || '', // Update field name here
  //     }))
  //   );
  //   // Add the worksheet to the workbook
  //   XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');
  //   // Convert the workbook to a binary string
  //   const excelBinaryString = XLSX.write(workbook, { bookType: 'xlsx', type: 'binary' });
  //   // Convert the binary string to a Blob
  //   const excelBlob = new Blob([s2ab(excelBinaryString)], { type: 'application/octet-stream' });
  //   // Create a download link
  //   const downloadLink = document.createElement('a');
  //   downloadLink.href = URL.createObjectURL(excelBlob);
  //   downloadLink.download = 'Department Data.xlsx';
  //   // Trigger the download
  //   downloadLink.click();
  // };

  const handleExportDepartmentData = async () => {
    setLoading(true); // Show loading state

    try {
      const response = await fetch(`${BASE_URL}/exportDepartments`, {
        method: 'POST',
        headers: {
          'Accept': 'application/json',
          "Content-Type": "application/json",
          "Authorization": getToken(),
        },
      });

      if (!response.ok) {
        throw new Error('Network response was not ok');
      }

      const data = await response.json();

      // Convert JSON data to CSV
      const csvData = convertToCSV(data); // Ensure you have a function to convert data to CSV

      // Create a Blob and trigger the download
      const blob = new Blob([csvData], { type: 'text/csv' });
      const link = document.createElement('a');
      link.href = URL.createObjectURL(blob);
      link.download = 'Department Data.csv'; // Change the file name as needed
      link.click();

      // Clean up
      URL.revokeObjectURL(link.href);

      toast.success("Department exported successfully!", {
        position: "top-center",
        autoClose: 1000,
      });


    } catch (error) {
      console.error("Fetch error: ", error);
      setError(error.message || 'An error occurred');



    } finally {
      setLoading(false); // Hide loading state
    }
  };

  function convertToCSV(data) {
    const array = [Object.keys(data[0])].concat(data);

    return array.map(row => {
      return Object.values(row).map(value =>
        typeof value === 'string' ? `"${value.replace(/"/g, '""')}"` : value
      ).join(',');
    }).join('\n');
  }
  const reversedRows = [...departmentData].reverse();

  const columns = [

    {
      field: 'id',
      headerName: 'Sr.', align: 'center', headerAlign: 'center',
      width: 50,
      flex: 1,
      // renderCell: (params) => (
      //   <span>
      //     {rows.indexOf(params.row) + 1}
      //   </span>
      // ),
    },
    {
      field: 'department_name', headerName: 'Department Name', width: 700, align: 'center', flex: 2, headerAlign: 'center',
      // renderCell: (params) => (
      //   <span>{params?.value}</span>
      // ),
    },

    {
      field: 'actions', headerName: 'Actions', width: 200, align: 'center', headerAlign: 'center', flex: 1, renderCell: (params) => (
        <>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="16"
            fill="black"
            className="bi bi-pencil-fill edit ebtn"
            viewBox="0 0 16 16"
            data-id={params.row._id}
            style={{ cursor: 'pointer', marginRight: "15px" }}
            onClick={() => {
              const id = params.row._id;
              navigate(`/edit_department/${id}/${masterId}`);
              // console.log("viewemployee id", id);
            }}
          >
            <path d="M12.854.146a.5.5 0 0 0-.707 0L10.5 1.793 14.207 5.5l1.647-1.646a.5.5 0 0 0 0-.708l-3-3zm.646 6.061L9.793 2.5 3.293 9H3.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.207l6.5-6.5zm-7.468 7.468A.5.5 0 0 1 6 13.5V13h-.5a.5.5 0 0 1-.5-.5V12h-.5a.5.5 0 0 1-.5-.5V11h-.5a.5.5 0 0 1-.5-.5V10h-.5a.499.499 0 0 1-.175-.032l-.179.178a.5.5 0 0 0-.11.168l-2 5a.5.5 0 0 0 .65.65l5-2a.5.5 0 0 0 .168-.11l.178-.178z" />
          </svg>

          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="16"
            fill="black"
            className="bi bi-trash3-fill delete delete-button"
            viewBox="0 0 16 16"
            data-id={params.row._id}
            style={{ cursor: 'pointer' }}
            onClick={() => {
              const id = params.row._id;
              openConfirmationModal(id);
            }}
          >
            <path d="M11 1.5v1h3.5a.5.5 0 0 1 0 1h-.538l-.853 10.66A2 2 0 0 1 11.115 16h-6.23a2 2 0 0 1-1.994-1.84L2.038 3.5H1.5a.5.5 0 0 1 0-1H5v-1A1.5 1.5 0 0 1 6.5 0h3A1.5 1.5 0 0 1 11 1.5Zm-5 0v1h4v-1a.5.5 0 0 0-.5-.5h-3a.5.5 0 0 0-.5.5ZM4.5 5.029l.5 8.5a.5.5 0 1 0 .998-.06l-.5-8.5a.5.5 0 1 0-.998.06Zm6.53-.528a.5.5 0 0 0-.528.47l-.5 8.5a.5.5 0 0 0 .998.058l.5-8.5a.5.5 0 0 0-.47-.528ZM8 4.5a.5.5 0 0 0-.5.5v8.5a.5.5 0 0 0 1 0V5a.5.5 0 0 0-.5-.5Z" />
          </svg>
        </>
      )
    },
  ];
  const handleSearch = (event) => {
    setSearchText(event.target.value);
  };

  const filterRows = () => {
    if (!searchText) {
      return [...departmentData].reverse();
    }

    return [...departmentData].reverse().filter((row) =>
      Object.values(row).some(
        (value) =>
          typeof value === 'string' && value.toLowerCase().includes(searchText.toLowerCase())
      )
    );
  };

  const handleDeleteDepartment = async (id, masterId) => {
    setDeleteDepartmentId(id);
    setShowConfirmation(true);

    try {
      const response = await deleteDepartment(id, masterId);
      if (response && response.status === 'success') {
        if (!toast.isActive('departmentDeletedToast')) {
          toast.success('Department deleted successfully', { toastId: 'departmentDeletedToast', autoClose: 1000 });
        }
        fetchData();
      } else {
        // Handle failure case
      }
    } catch (error) {
      console.error('Error deleting department:', error);
    }
  };





  const openConfirmationModal = (id) => {
    setDeleteDepartmentId(id);
    setShowConfirmation(true);
  };

  const closeConfirmationModal = () => {
    setShowConfirmation(false);
  };

  return (
    <React.Fragment>
      {/* Header components */}
      <HeaderMobile />
      <Header />
      <div className="main p-4 p-lg-5 mt-5">
        {/* Breadcrumb section */}
        <div className="breadcrumb-warning d-flex justify-content-between ot-card">
          <div>
            <ol className="breadcrumb ot-breadcrumb ot-breadcrumb-basic">
              {/* <li className="mx-2">
                  <h4>Holidays</h4>
                </li> */}
              <li class="breadcrumb-item ">
                <Link to="/admin/dashboard">Dashboard</Link>
              </li>
              <li class="breadcrumb-item active"><Link to="/admin/department">Departments</Link></li>
              {/* <li class="breadcrumb-item active">Lists</li> */}
            </ol>
          </div>
          <nav aria-label="breadcrumb">

            <Link to="/admin/adddepartment">
              <div><button className="btn btn-primary" type="submit"><span>
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-plus-lg" viewBox="0 0 16 16">
                  <path fill-rule="evenodd" d="M8 2a.5.5 0 0 1 .5.5v5h5a.5.5 0 0 1 0 1h-5v5a.5.5 0 0 1-1 0v-5h-5a.5.5 0 0 1 0-1h5v-5A.5.5 0 0 1 8 2Z" />
                </svg>Add</span></button></div>
            </Link>

          </nav>
        </div>
        <Card className="popup-card empHistory">
          <Card.Body>
            <div style={{ display: 'flex', justifyContent: "center", alignItems: 'center', justifyContent: 'space-between', marginBottom: "1rem" }}>
              <Button className='btn btn-primary border-0' onClick={handleExportDepartmentData}>Export</Button>
              {/* <div style={{ display: 'flex', alignItems: 'center' }}>
                <label htmlFor="searchInput"></label>
                <input
                  type="text"
                  className='p-1 w-75 ms-2'
                  placeholder="Search"
                  value={searchText}
                  onChange={handleSearch}
                />
              </div> */}
              <div className="col-md-11">
                <Row>

                  <Col md={9} className="mb-3 pt-3">
                  </Col>
                  <Col md={3} className="mb-3 pt-3 d-flex justify-content-end pe-0">
                    <TextField
                      label="Search"
                      variant="outlined"
                      value={searchText}
                      onChange={handleSearch}
                      size="small"

                    />
                  </Col>
                </Row>
              </div>
            </div>
            {loading ? (
              <div className="loader-container">
                <div className="loader"></div>
              </div>
            ) : (

              // <div style={customStyles.grid}>
              //   <DataGrid
              //     className="gridColor"
              //     rows={rows}
              //     columns={columns}
              //     pageSize={pageSize}
              //     pagination
              //     paginationMode="client"
              //     getRowId={(row) => row._id}
              //     components={{
              //       Pagination: () => (
              //         <div className="custom-select-container">
              //           <div>
              //             <label htmlFor="page-size">Rows per page: </label>
              //             <select
              //               id="page-size"
              //               value={pageSize}
              //               onChange={handlePageSizeChange}  // Using the handlePageSizeChange function
              //             >
              //               <option value={1}>1</option>
              //               <option value={10}>10</option>
              //               <option value={20}>20</option>
              //               <option value={50}>50</option>
              //             </select>
              //           </div>
              //           <div>
              //             <label htmlFor="page-number">Page: </label>
              //             <select
              //               className="page-number"
              //               id="page-number"
              //               value={page}
              //               onChange={handlePageChange}  // Using the handlePageChange function
              //             >
              //               {[...Array(totalPages).keys()].map((pageNumber) => (
              //                 <option key={pageNumber} value={pageNumber + 1}>
              //                   {pageNumber + 1}
              //                 </option>
              //               ))}
              //             </select>
              //           </div>
              //         </div>
              //       ),
              //     }}
              //     componentsProps={{
              //       cell: { style: customStyles.cell },
              //       columnHeader: { style: customStyles.columnHeader },
              //     }}
              //     rowCount={count}        // Total row count
              //     page={page - 1}         // Syncing the page number
              //     onPageChange={(newPage) => setPage(newPage + 1)} // This keeps the actual page selection from 0-index to 1-index
              //     onPageSizeChange={handlePageSizeChange} // To handle changes in the page size
              //     rowsPerPageOptions={[]} // To disable the default options provided by the DataGrid
              //   />
              // </div>

              <div className="data-grid-container" style={customStyles.grid}>
                <DataGrid
                  className="gridColor"
                  rows={rows} // Use departmentsArray for the grid rows
                  columns={columns} // Your defined columns
                  pageSize={pageSize} // Current page size
                  pagination
                  paginationMode="client"
                  getRowId={(row) => row._id} // Use '_id' as the unique identifier
                  components={{
                    Pagination: () => (
                      <div className="custom-select-container">
                        <div>
                          <label htmlFor="page-size">Rows per page: </label>
                          <select
                            id="page-size"
                            value={pageSize}
                            onChange={handlePageSizeChange} // Handle page size changes
                          >
                            <option value={50}>50</option>
                            <option value={100}>100</option>
                          </select>
                        </div>
                        <div>
                          <label htmlFor="page-number">Page: </label>
                          <select
                            className="page-number"
                            id="page-number"
                            value={page}
                            onChange={handlePageChange} // Handle page changes
                          >
                            {[...Array(totalPages).keys()].map((pageNumber) => (
                              <option key={pageNumber} value={pageNumber + 1}>
                                {pageNumber + 1}
                              </option>
                            ))}
                          </select>
                        </div>
                      </div>
                    ),
                    NoRowsOverlay: () => (
                      <div style={{ textAlign: 'center', padding: '20px' }}>
                        No Department available
                      </div>
                    ),
                  }}
                  componentsProps={{
                    cell: { style: customStyles.cell }, // Your custom cell styles
                    columnHeader: { style: customStyles.columnHeader }, // Your custom header styles
                  }}
                  rowCount={rows.length} // Use the length of departmentsArray to show total records
                  page={page - 1} // Current page (0-based index)
                  onPageChange={(newPage) => setPage(newPage + 1)} // Handle page change
                  rowsPerPageOptions={[]} // Avoid displaying the default rows per page options
                />
              </div>

            )}
          </Card.Body>


        </Card>
      </div>

      <Modal show={showConfirmation} onHide={closeConfirmationModal}>
        <Modal.Header closeButton>
          <Modal.Title>Confirm Deletion</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Are you sure you want to delete this department?
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={closeConfirmationModal}>
            Cancel
          </Button>
          {/* <Button
              variant="danger"
              onClick={() => {
                handledeleteHoliday(deleteHolidayId);
                closeConfirmationModal();
              }}
            >
              Delete
            </Button> */}
          <Button
            variant="danger"
            onClick={() => {
              handleDeleteDepartment(deleteDepartmentId, masterId);
              closeConfirmationModal();
            }}

          >
            Delete
          </Button>

        </Modal.Footer>
      </Modal>
    </React.Fragment>
  );
};

export default ViewDepartment;






















