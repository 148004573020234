import React, { useState, useEffect } from 'react';
import _debounce from 'lodash/debounce';
import HeaderMobile from '../../../layouts/HeaderMobile';
import Header from '../../../layouts/Header';
import { toast } from "react-toastify";
import { addEmployeeBreak, getemployeeId, singleViewEmployeeAttendance, viewEmployeeBreakCount, checkEmployeeBreakStatus } from '../../../services/api/api';
import img05 from "../../../assets/img/img05.jpg";

const Breakreport = () => {
    const [loading, setLoading] = useState(true);
    const [employee_id, setEmployeeId] = useState(getemployeeId());
    const [attendanceStatusd, setAttendanceStatusd] = useState(null);
    const [breakStatus, setBreakStatus] = useState(localStorage.getItem('break_status') || null);
    const [startTime, setStartTime] = useState(parseInt(localStorage.getItem('start_time')) || 0);
    const [elapsedTime, setElapsedTime] = useState(0);
    const [isRunning, setIsRunning] = useState(false);

    const firstName = sessionStorage.getItem("first_name");
    const lastName = sessionStorage.getItem("last_name");
    const [showFormattedTime, setShowFormattedTime] = useState(true);

    // useEffect(() => {
    // const fetchBreakStatus = async () => {
    // try {
    // setLoading(true);
    // const response = await checkEmployeeBreakStatus({ employee_id });
    // if (response.success) {
    // setBreakStatus(response.type || "Start break");
    // } else {
    // setBreakStatus("Start break");
    // }
    // } catch (error) {
    // console.error('Error checking employee break status:', error);
    // setBreakStatus("Start break");
    // } finally {
    // setLoading(false);
    // }
    // };

    // fetchBreakStatus();
    // }, [employee_id]);

    // useEffect(() => {
    // const debouncedFetchBreak = _debounce(fetchEmployeeBreak, 2000);
    // debouncedFetchBreak();
    // return () => {
    // debouncedFetchBreak.cancel();
    // };
    // }, [employee_id, breakStatus]);
    useEffect(() => {
        const fetchBreakStatus = async () => {
            try {
                setLoading(true);
                const response = await checkEmployeeBreakStatus({ employee_id });
                if (response.success) {
                    setBreakStatus(response.type || "Start break");
                } else {
                    setBreakStatus("Start break");
                }
            } catch (error) {
                console.error('Error checking employee break status:', error);
                setBreakStatus("Start break");
            } finally {
                setLoading(false);
            }
        };

        const debouncedFetchBreak = _debounce(fetchBreakStatus, 100);

        fetchBreakStatus();

        const interval = setInterval(debouncedFetchBreak, 100);

        return () => {
            clearInterval(interval);
            debouncedFetchBreak.cancel();
        };
    }, [employee_id, breakStatus]);

    useEffect(() => {
        let timer;
        if (isRunning) {
            timer = setInterval(() => {
                setElapsedTime(Date.now() - startTime);
            }, 1000);
        } else if (!isRunning && elapsedTime !== 0) {
            clearInterval(timer);
        }
        return () => clearInterval(timer);
    }, [isRunning, startTime, elapsedTime]);

    const formattedTime = () => {
        const totalMinutes = Math.floor(elapsedTime / 60000);
        const totalSeconds = Math.floor((elapsedTime % 60000) / 1000);

        return `${String(totalMinutes).padStart(2, '0')}:${String(totalSeconds).padStart(2, '0')}`;
    };

    const fetchEmployeeBreak = async () => {
        try {
            setLoading(true);
            const apiResponse = await viewEmployeeBreakCount({ employee_id });
            console.log(apiResponse);

            const viewEmpattendance = await singleViewEmployeeAttendance(employee_id);
            console.log("*******************************", viewEmpattendance);

            const viewEmpattendance1 = await checkEmployeeBreakStatus({ employee_id });
            console.log("-------------------------------", viewEmpattendance1.type);

            const attendanceData = viewEmpattendance.data[0]?.attendance_list[0]?.attendance;
            const lastAttendance = attendanceData?.[attendanceData.length - 1];
            console.log(lastAttendance);

            const apiIn = lastAttendance?.IN !== null;
            const apiOut = lastAttendance?.OUT !== null;

            if (apiIn) {
                setAttendanceStatusd("IN");
                const newBreakStatus = ((attendanceStatusd === '' || attendanceStatusd === 'IN') &&
                    (viewEmpattendance1.type === "" || viewEmpattendance1.type === "OUT"))
                    ? 'OUT' : 'IN';
                setBreakStatus(newBreakStatus);
            } else {
                setAttendanceStatusd("OUT");
            }
            if (apiOut) {
                setAttendanceStatusd("OUT");
                setBreakStatus("OUT");
            }
        } catch (error) {
            console.error('Error fetching employee break count:', error);
        } finally {
            setLoading(false);
        }
    };

    // const handleBreak = async () => {
    // try {

    // const newBreakStatus = breakStatus === 'OUT' || breakStatus === "" ? "IN" : "OUT";
    // setBreakStatus(newBreakStatus);
    // const response = await addEmployeeBreak({ employee_id, break_status: newBreakStatus });
    // console.log(response);

    // // if (response.message) {
    // // toast.warning(response.message, { position: 'top-center', autoClose: 5000 });
    // // return;
    // // }
    // if (response.message) {
    // toast.error(response.message, {
    // position: 'top-center', autoClose: 5000, bodyClassName: 'toast-body',
    // toastStyle: { width: '400px', height: '100px', transition: 'all 0.5s ease' }
    // });
    // setShowFormattedTime(false);
    // return;
    // } else {
    // setShowFormattedTime(true);
    // }

    // if (newBreakStatus === 'IN') {
    // setStartTime(Date.now());
    // setIsRunning(true);
    // localStorage.setItem('break_status', 'IN');
    // localStorage.setItem('start_time', Date.now());
    // } else {
    // setIsRunning(false);
    // localStorage.removeItem('break_status');
    // localStorage.removeItem('start_time');

    // let totalBreakDuration = elapsedTime;

    // if (totalBreakDuration < 60000) {
    // totalBreakDuration = 60000;
    // }

    // const totalMinutes = Math.ceil(totalBreakDuration / 60000);



    // setStartTime(0);
    // setElapsedTime(0);
    // // toast.success(
    // // `Your break ended successfully. Total break duration: ${totalMinutes} minutes`,
    // // { position: 'top-center', autoClose: 5000 }
    // // );
    // if(newBreakStatus === 'OUT'){
    // toast.success(
    // `Your break ended successfully. Total break duration: ${totalMinutes} minutes`,
    // { position: 'top-center', autoClose: 5000 }
    // );
    // }
    // }
    // } catch (error) {
    // console.error('Error handling break:', error);
    // }

    // };
    const handleBreak = async () => {
        try {
            // Toggle break status

            const newBreakStatus = breakStatus === 'IN' ? 'OUT' : 'IN';
            setBreakStatus(newBreakStatus);

            // Call API to update break status
            const response = await addEmployeeBreak({ employee_id, break_status: newBreakStatus });

            // Handle response messages
            if (response.message) {
                toast.error(response.message, {
                    position: 'top-center',
                    autoClose: 5000,
                    // bodyClassName: 'toast-body',
                    // toastStyle: { width: '400px', height: '100px', transition: 'all 0.5s ease' }
                });
                setShowFormattedTime(false);
                return;
            } else {
                setShowFormattedTime(true);
            }

            // Update local storage and UI based on break status
            if (newBreakStatus === 'IN') {
                setStartTime(Date.now());
                setIsRunning(true);
                localStorage.setItem('break_status', 'IN');
                localStorage.setItem('start_time', Date.now());
                // Uncomment if you want to notify user when break starts
                // toast.info('Your break started successfully.', { position: 'top-center', autoClose: 5000 });
            } else {
                setIsRunning(false);
                localStorage.removeItem('break_status');
                localStorage.removeItem('start_time');

                // Calculate total break duration
                let totalBreakDuration = elapsedTime;
                if (totalBreakDuration < 60000) {
                    totalBreakDuration = 60000;
                }
                const totalMinutes = Math.ceil(totalBreakDuration / 60000);

                // Notify user when break ends
                toast.success(`Your break ended successfully. Total break duration: ${totalMinutes} minutes`, { position: 'top-center', autoClose: 5000 });

                // Reset state for next break
                setStartTime(0);
                setElapsedTime(0);
            }
        } catch (error) {
            console.error('Error handling break:', error);
        }
    };

    useEffect(() => {
        // Check if break status is 'IN' to resume the timer
        if (localStorage.getItem('break_status') === 'IN') {
            const storedStartTime = parseInt(localStorage.getItem('start_time'));
            if (storedStartTime > 0) {
                setStartTime(storedStartTime);
                setIsRunning(true);
            }
        }
    }, []);

    useEffect(() => {
        localStorage.setItem('break_status', breakStatus);
        localStorage.setItem('start_time', startTime.toString());
    }, [breakStatus, startTime]);

    return (
        <React.Fragment>
            <HeaderMobile />
            <Header />
            <div className="main p-4 p-lg-5 mt-5">
                <div className="mb-4 border-bottom text-center">
                    <h3 className="mb-0 pb- border-bottom custome-btn">Break</h3>
                </div>
                {loading ? (
                    <div className="loader-container">
                        <div className="loader"></div>
                    </div>
                ) : (
                    <div style={{ marginTop: "-17px" }}>
                        <div className="d-flex justify-content-center align-items-center" style={{ marginTop: "-12px" }}>
                            <div className="card p-4 shadow-lg rounded" style={{ maxWidth: "600px" }}>
                                <h2 className="text-center mb-0 p-0" style={{ backgroundColor: 'white', border: '1px solid #ccc' }}>
                                    <h3 className="mb-0 pb- custome-btn1">{firstName} {lastName}</h3>
                                    <img
                                        src={img05}
                                        alt="User Profile"
                                        className="img-fluid rounded-circle"
                                        style={{ width: "150px", height: "100px" }}
                                    />
                                </h2>
                                <button className="btn btn-warning mt-3" onClick={handleBreak}>
                                    <span className="text-center">
                                        <div className='newbtn' style={{ marginTop: '15px' }}>
                                            <button className="btn btnnew-light">
                                                {breakStatus === 'OUT' ? 'Start Break' : breakStatus === 'IN' ? 'End Break' : 'Start Break'}
                                            </button>

                                            {/* {breakStatus === 'IN' && (
<p className="mt-2">{formattedTime()}</p>
)} */}
                                            {breakStatus === 'IN' && showFormattedTime && (
                                                <p className="mt-2">{formattedTime()}</p>
                                            )}

                                        </div>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="400" height="90" fill="currentColor" className="bi bi-box-arrow-in-right mt-0 mb-2" viewBox="0 0 16 16">
                                            <path fillRule="evenodd" d="M6 3.5a.5.5 0 0 1 .5-.5h8a.5.5 0 0 1 .5.5v9a.5.5 0 0 1-.5.5h-8a.5.5 0 0 1-.5-.5v-2a.5.5 0 0 0-1 0v2A1.5 1.5 0 0 0 6.5 14h8a1.5 1.5 0 0 0 1.5-1.5v-9A1.5 1.5 0 0 0 14.5 2h-8A1.5 1.5 0 0 0 5 3.5v2a.5.5 0 0 0 1 0z" />
                                            <path fillRule="evenodd" d="M11.854 8.354a.5.5 0 0 0 0-.708l-3-3a.5.5 0 1 0-.708.708L10.293 7.5H1.5a.5.5 0 0 0 0 1h8.793l-2.147 2.146a.5.5 0 0 0 .708.708z" />
                                        </svg>
                                    </span>
                                </button>
                            </div>
                        </div>
                    </div>
                )}
            </div>
        </React.Fragment>
    );
}

export default Breakreport;