import React, { useState, useEffect } from 'react';
import { debounce } from 'lodash';
import { editLeave, updateLeave } from '../../services/api/api';
import { useNavigate, useParams } from 'react-router-dom';
import Header from '../../layouts/Header';
import HeaderMobile from '../../layouts/HeaderMobile';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Form } from 'react-bootstrap';
import { Link } from "react-router-dom";


const EditLeave = () => {
    const navigate = useNavigate();
    const { id, masterId } = useParams();

    const [state, setState] = useState({
        _id: '',
        leave_type: '',

    });

    const [errors, setErrors] = useState({});
    const [submitting, setSubmitting] = useState(false);
    const [loading, setLoading] = useState(true);
    //     useEffect(() => {
    //       async function fetchLeaveData() {
    //           try {
    //               const leaveData = await editLeave(id, masterId);

    //               if (leaveData && leaveData.success) {
    //                   const leave = leaveData.success;

    //                   setState({
    //                       _id: leave._id || '',
    //                       company_id: leave.company_id || '',
    //                       leave_type: leave.leave_type || '',

    //                   });
    //               } else {
    //                   console.error('No leave data found');
    //               }
    //           } catch (error) {
    //               console.error('Error fetching leave data:', error);
    //           }
    //       }

    //       fetchLeaveData();
    //   }, [id, masterId]);

    useEffect(() => {
        const fetchLeaveData = debounce(async () => {
            try {
                setLoading(true);
                const leaveData = await editLeave(id, masterId);

                if (leaveData && leaveData.success) {
                    const leave = leaveData.success;

                    setState({
                        _id: leave._id || '',
                        company_id: leave.company_id || '',
                        leave_type: leave.leave_type || '',
                    });
                } else {
                    console.error('No leave data found');
                }
                setLoading(false);
            } catch (error) {
                console.error('Error fetching leave data:', error);
                setLoading(false);
            }
        }, 100);

        fetchLeaveData();

        return () => {
            fetchLeaveData.cancel();
        };
    }, [id, masterId]);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setState((prevState) => ({
            ...prevState,
            [name]: value,
        }));
        setErrors((prevErrors) => ({
            ...prevErrors,
            [name]: '',
        }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        const validationErrors = validateForm(state);
        if (Object.keys(validationErrors).length > 0) {
            setErrors(validationErrors);
            return;
        } if (submitting) {
            return;
        }
        setSubmitting(true);

        try {
            const response = await updateLeave(id, masterId, state);
            if (response && response.success && response.success.length > 0) {
                toast.success(response.success[0].message, {
                    position: 'top-center',
                    autoClose: 1000,
                });
            } else {
                toast.success('Leave updated successfully', {
                    position: 'top-center',
                    autoClose: 1000,
                });
                setSubmitting(false);
                navigate('/pages/leave');
            }
        } catch (error) {
            console.error('An error occurred:', error);
            toast.error('An error occurred', {
                position: 'top-right',
                autoClose: 1000,
            });
        }
    };

    const validateForm = (state) => {
        let errors = {};
        return errors;
    };





    //   const handleDateChange = (event) => {
    //     const { name, value } = event.target;

    //     setState((prevState) => ({
    //         ...prevState,
    //         [name]: value,
    //     }));

    // }
    return (
        <React.Fragment>
            <HeaderMobile />
            <Header />



            <div className="main p-4 p-lg-5 mt-5">
                <div className="mb-4 border-bottom text-center">
                    <h3 className="mb-0 pb- border-bottom custome-btn">Edit Leave</h3>
                </div>
                <div>

                    <div className="breadcrumb-warning d-flex justify-content-between ot-card">
                        <div>
                            <ol className="breadcrumb ot-breadcrumb ot-breadcrumb-basic">
                                <li className="breadcrumb-item">
                                    <Link to="/admin/dashboard">Dashboard</Link>
                                </li>
                                <li className="breadcrumb-item active">
                                    <Link to="/pages/hr/leave">Leaves</Link>
                                </li>
                                <li className="breadcrumb-item active">Edit Leave</li>
                            </ol>
                        </div>
                        <nav aria-label="breadcrumb">
                            <Link to="/pages/leave" className="btn btn-primary mb-3">
                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-arrow-left" viewBox="0 0 16 16">
                                    <path fillRule="evenodd" d="M15 8a.5.5 0 0 0-.5-.5H2.707l3.147-3.146a.5.5 0 1 0-.708-.708l-4 4a.5.5 0 0 0 0 .708l4 4a.5.5 0 0 0 .708-.708L2.707 8.5H14.5A.5.5 0 0 0 15 8z" />
                                </svg>
                                Back
                            </Link>
                        </nav>
                    </div>

                    {loading &&
                        <div className="loader-container">
                            <div className="loader"></div>
                        </div>
                    }

                    <div className="row"
                        style={{
                            boxShadow: "0 4px 8px 0 rgba(0,0,0,0.2)",
                            borderRadius: "10px",
                            overflow: "hidden",
                        }}
                    >


                        <div className="col-md-12 formcontrols">
                            <div className="bg-white p-4 rounded" style={{ height: "100%" }}>
                                <form onSubmit={handleSubmit}>
                                    <div className="row">

                                        <div className="mb-3">
                                            <label htmlFor="leave_type" className="form-label">
                                                Leave Type
                                            </label>
                                            <input
                                                type="text"
                                                className={`form-control ${errors.leave_type && 'is-invalid'}`}
                                                id="leave_type"
                                                name="leave_type"
                                                value={state.leave_type}
                                                onChange={handleChange}
                                            />
                                            {errors.leave_type && (
                                                <div className="invalid-feedback">{errors.leave_type}</div>
                                            )}
                                        </div>
                                    </div>
                                    <button type="submit" className="custom-submit-btn" onClick={handleSubmit}
                                        disabled={submitting}>

                                        {submitting ? 'Submitting...' : 'Update'}
                                    </button>
                                </form>
                            </div>
                        </div>
                    </div>


                </div>
            </div>
        </React.Fragment>
    );
};
export default EditLeave
