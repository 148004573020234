import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import bgY1 from "../assets/img/bgY1.png";
import bgS1 from "../assets/img/bgS1.jpg";
import { Button, Card, Col, Form, Row } from "react-bootstrap";
import { updatepassword } from '../services/api/api';
import { toast } from 'react-toastify';

export const ResetPassword = () => {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [passwordError, setPasswordError] = useState('');
    const [confirmPasswordError, setConfirmPasswordError] = useState('');
    const location = useLocation();
    const navigate = useNavigate();
    useEffect(() => {
        const queryParams = new URLSearchParams(location.search);
        const emailFromUrl = queryParams.get("email");
        if (emailFromUrl) {
            setEmail(emailFromUrl); // Set email from URL and make it read-only
        }
    }, [location]);

    const handlePasswordChange = (event) => {
        setPassword(event.target.value);
    };

    const handleConfirmPasswordChange = (event) => {
        setConfirmPassword(event.target.value);
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        let valid = true;

        // Reset previous error messages
        setPasswordError('');
        setConfirmPasswordError('');

        // Validation for password
        if (!password) {
            setPasswordError('Password is required.');
            valid = false;
        }

        // Validation for confirm password
        if (!confirmPassword) {
            setConfirmPasswordError('Confirm password is required.');
            valid = false;
        } else if (password !== confirmPassword) {
            setConfirmPasswordError('Password should match with confirm password');
            valid = false;
        }

        // If the form is valid, proceed with the form submission logic
        if (valid) {
            try {
                const responseData = await updatepassword(email, password); // Call the API to update the password
                console.log('API Response:', responseData);

                if (responseData) {
                    toast.success(responseData.message, {
                        position: 'top-center',
                        autoClose: 1000,
                    });
                    navigate('/signin');
                }
            } catch (error) {
                toast.warning('Error Updating Password', {
                    position: 'top-center',
                    autoClose: 1000,
                });
            }
        } else {
            toast.error('Fill the data properly', {
                position: 'top-center',
                autoClose: 1000,
            });
        }

    };

    return (
        <div className="d-flex align-items-center justify-content-center" style={{ padding: '50px 0', backgroundImage: `url(${bgS1})`, backgroundSize: 'cover' }}>
            <section className="vh-100">
                <div className="container py-1 h-95">
                    <div className="row d-flex justify-content-center align-items-center h-100">
                        <div className="col col-xl-10">
                            <div className="card" style={{ border: 'none', borderRadius: '1rem', boxShadow: '0' }}>
                                <div className="row g-0">
                                    <div className="col-md-7 col-lg-5 d-none d-md-flex justify-content-center align-items-center">
                                        <img
                                            src={bgY1}
                                            alt="login form"
                                            className="img-fluid"
                                            style={{ width: '100%', padding: '150px 0', height: 'auto', objectFit: 'cover' }}
                                        />
                                    </div>

                                    <div className="col-md-5 col-lg-7 d-flex justify-content-center align-items-center">
                                        <Card className="w-100" style={{ maxWidth: '500px' }}>
                                            <Card.Body>
                                                <Card.Title className="mb-4">Reset Your Password</Card.Title>

                                                <Form onSubmit={handleSubmit}>
                                                    <Row className="g-3">
                                                        <Col sm="12">
                                                            <Form.Control
                                                                type="text"
                                                                className="mb-3"
                                                                placeholder="Enter email address"
                                                                value={email}
                                                                readOnly
                                                                style={{ cursor: 'not-allowed' }}
                                                            />

                                                            <Form.Control
                                                                type="password"
                                                                className={`mb-1 ${passwordError ? 'is-invalid' : ''}`}
                                                                placeholder="Enter Password"
                                                                value={password}
                                                                onChange={handlePasswordChange}
                                                            />
                                                            {passwordError && (
                                                                <div className="text-danger mb-3">{passwordError}</div>
                                                            )}

                                                            <Form.Control
                                                                type="password"
                                                                className={`mb-1 ${confirmPasswordError ? 'is-invalid' : ''}`}
                                                                placeholder="Enter Confirm Password"
                                                                value={confirmPassword}
                                                                onChange={handleConfirmPasswordChange}
                                                            />
                                                            {confirmPasswordError && (
                                                                <div className="text-danger mb-3">{confirmPasswordError}</div>
                                                            )}
                                                        </Col>

                                                        <Col sm="12">
                                                            <Button
                                                                variant="primary"
                                                                className="btn btn-primary"
                                                                // style={{ background: "#d35400", border: "#d35400" }}
                                                                type="submit"
                                                                
                                                            >
                                                                Send
                                                            </Button>
                                                        </Col>
                                                    </Row>
                                                </Form>
                                            </Card.Body>
                                        </Card>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    );
};
