import React, { useState, useEffect, useRef } from 'react';
import Header from "../../layouts/Header";
import HeaderMobile from "../../layouts/HeaderMobile";
import { Card, Button, Modal, Row, Col, Form, Table } from "react-bootstrap";
import '../../styles/globals.css';
import { Link } from "react-router-dom";
import { DataGrid } from '@mui/x-data-grid';
import { deleteSingleJobList, getJobFullDetails, getJobRouteLink, getSingleCompanyJobs, searchJoblist } from '../../services/api/api';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { TextField, Tooltip } from '@mui/material';

const Job = () => {
  const [activeButton, setActiveButton] = useState('open');
  const [loading, setLoading] = useState(true);
  const [jobs, setJobs] = useState([]);
  const navigate = useNavigate();
  const token = sessionStorage.getItem('token');
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [deleteJobId, setDeleteJobId] = useState(null);
  const [searchTerm, setSearchTerm] = useState('');
  const [totalPages, setTotalPages] = useState(1);
  const [page, setPage] = useState(1);
  const [pageSize, setpageSize] = useState(100);

  const handleButtonClick = (button) => {
    setActiveButton(button);
  };

  useEffect(() => {
    if (!searchTerm) {

      fetchJobs();
    }
    else {
      searchJobs()
    }
  }, [searchTerm]);

  const handlePageChange = (event) => {
    const newPage = parseInt(event.target.value, 10);
    setPage(newPage);
    console.log('Selected Page:', newPage);
  };

  const handlePageSizeChange = (event) => {
    console.log('Page Change Event:', event);
    const newPageSize = parseInt(event.target.value, 10);
    setpageSize(newPageSize);
    console.log('Selected Page Size:', newPageSize);
  };

  const openConfirmationModal = (id) => {
    setDeleteJobId(id);
    setShowConfirmation(true);
  };

  const handleDeleteConfirm = async () => {

    try {
      const response = await deleteSingleJobList(deleteJobId);
      if (response) {

        toast.success('job deleted successfully');
        fetchJobs();
        handleDeleteCancel();
      } else {
      }
    } catch (error) {
      console.error('Error deleting job:', error);
    }
  };

  const handleDeleteCancel = () => {
    setShowConfirmation(false);
    setDeleteJobId(null);
  };

  let job = jobs.reverse()
  const reversedRows = [...jobs].reverse();
  const columns = [
    {
      field: 'srNo',
      headerName: 'SR No.',
      width: 100,
      renderCell: (params) => {

        // console.log(params.row.length - (params.row.id-1));

        // return params.row.id
        return params.row.length - (params.row.id-1)
      }
    },
    {
      field: 'jobTitle', headerName: 'Job Title', flex: 1, align: 'center',
      headerAlign: 'center',
    },
    {
      field: 'jobType', headerName: 'Job Type', flex: 1, align: 'center',
      headerAlign: 'center',
    },
    {
      field: 'companyName', headerName: 'Company Name', flex: 1, align: 'center',
      headerAlign: 'center',
    },
    {
      field: 'numberOfOpening', headerName: 'Openings', flex: 1, align: 'center',
      headerAlign: 'center',
    },
    {
      field: 'actions',
      headerName: 'Actions',
      width: 150,
      align: 'left',
      headerAlign: 'left',
      flex: 2,
      renderCell: (params) => (
        <>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="16"
            fill="black"
            className="bi bi-pencil-fill edit ebtn"
            viewBox="0 0 16 16"
            style={{ cursor: 'pointer', marginRight: "15px" }}
            onClick={() => {
              navigate(`/pages/recruitment/editJobs/${params.row.id}`);

            }}
          >
            <path d="M12.854.146a.5.5 0 0 0-.707 0L10.5 1.793 14.207 5.5l1.647-1.646a.5.5 0 0 0 0-.708l-3-3zm.646 6.061L9.793 2.5 3.293 9H3.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.207l6.5-6.5zm-7.468 7.468A.5.5 0 0 1 6 13.5V13h-.5a.5.5 0 0 1-.5-.5V12h-.5a.5.5 0 0 1-.5-.5V11h-.5a.5.5 0 0 1-.5-.5V10h-.5a.499.499 0 0 1-.175-.032l-.179.178a.5.5 0 0 0-.11.168l-2 5a.5.5 0 0 0 .65.65l5-2a.5.5 0 0 0 .168-.11l.178-.178z" />
          </svg>

          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="16"
            fill="black"
            className="bi bi-trash3-fill delete delete-button"
            viewBox="0 0 16 16"
            style={{ cursor: 'pointer' }}
            onClick={() => {
              const id = params.row.id;
              openConfirmationModal(id);
            }}
          >
            <path d="M11 1.5v1h3.5a.5.5 0 0 1 0 1h-.538l-.853 10.66A2 2 0 0 1 11.115 16h-6.23a2 2 0 0 1-1.994-1.84L2.038 3.5H1.5a.5.5 0 0 1 0-1H5v-1A1.5 1.5 0 0 1 6.5 0h3A1.5 1.5 0 0 1 11 1.5Zm-5 0v1h4v-1a.5.5 0 0 0-.5-.5h-3a.5.5 0 0 0-.5.5ZM4.5 5.029l.5 8.5a.5.5 0 1 0 .998-.06l-.5-8.5a.5.5 0 1 0-.998.06Zm6.53-.528a.5.5 0 0 0-.528.47l-.5 8.5a.5.5 0 0 0 .998.058l.5-8.5a.5.5 0 0 0-.47-.528ZM8 4.5a.5.5 0 0 0-.5.5v8.5a.5.5 0 0 0 1 0V5a.5.5 0 0 0-.5-.5Z" />
          </svg>
        </>
      ),
    },
  ];

  const fetchJobs = async () => {
    setLoading(true); // Set loading to true when fetching begins
    try {
      const response = await getSingleCompanyJobs();
      console.log(response);

      const formattedJobs = response.data.map((item, index) => ({
        id: index + 1, // Assuming _id is unique for each job
        length: response.data.length,
        jobTitle: item.company_job.jobTitle,
        jobType: item.company_job.jobType,
        companyName: item.company_job.companyName, // Default to "N/A" if empty
        numberOfOpening: item.company_job.numberOfOpening,
      }));
      setJobs(formattedJobs);
      setLoading(false); // Set loading to false after data is set
      setTotalPages(formattedJobs.totalPages); // Set total pages
      setpageSize(formattedJobs.pageSize);
    } catch (error) {
      console.error('Error submitting form:', error);
      setLoading(false); // Ensure loading is false even on error
    }
  };

  const searchJobs = async (e) => {
    // setLoading(true); // Set loading to true when fetching begins
    let searchValue;

    if (e && e.target) {
      searchValue = e.target.value;
      setSearchTerm(searchValue);
    } else {

      searchValue = searchTerm;
    }
    // const { value } = e.target;
    // setSearchTerm(value)
    try {
      const response = await searchJoblist(searchValue);
      console.log(response);

      const formattedJobs = response.data.map(item => ({
        id: item.company_job._id, // Assuming _id is unique for each job
        jobTitle: item.company_job.jobTitle,
        jobType: item.company_job.jobType,
        companyName: item.company_job.companyName, // Default to "N/A" if empty
        numberOfOpening: item.company_job.numberOfOpening,
      }));
      setJobs(formattedJobs);
      setLoading(false); // Set loading to false after data is set
      setTotalPages(response.totalPages); // Set total pages
      setpageSize(response.pageSize);
    } catch (error) {
      console.error('Error submitting form:', error);
      setLoading(false); // Ensure loading is false even on error
    }
  };

  const handleEdit = async (mainId) => {
    try {
      setLoading(true); // Set loading to true when fetching begins
      const response = await getJobFullDetails(mainId);
      console.log(response);

      if (response) {
        setLoading(false); // Ensure loading is false even on error
        navigate(`/pages/recruitment/editForm`);
      }

    } catch (error) {
      console.error('Error submitting form:', error);
    }
  };

  const handleGetUrl = async () => {
    try {
      setLoading(true); // Set loading to true when fetching begins
      const response = await getJobRouteLink();
      console.log(response);

      if (response) {
        setLoading(false); // Ensure loading is false even on error
        localStorage.setItem('joblistingroute', response)
        // navigate(`/jobListing`);
      }

    } catch (error) {
      console.error('Error submitting form:', error);
    }
  };

  return (
    <React.Fragment>
      <HeaderMobile />
      <Header />

      <div className="main p-4 p-lg-5 mt-5">
        <div className="breadcrumb-warning d-flex justify-content-between ot-card">
          <div>
            <ol className="breadcrumb ot-breadcrumb ot-breadcrumb-basic">
              <li className="breadcrumb-item ">
                <Link to="/admin/dashboard">Dashboard</Link>
              </li>
              <li className="breadcrumb-item active"><Link to="/pages/recruitment/job">Jobs</Link></li>
            </ol>
          </div>
          <nav aria-label="breadcrumb">
            <Link to="/pages/recruitment/jobform">
              <Button className="mb-3 custom-submit-btn">
                <span style={{ color: "white" }}>
                  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-plus-lg" viewBox="0 0 16 16">
                    <path fillRule="evenodd" d="M8 2a.5.5 0 0 1 .5.5v5h5a.5.5 0 0 1 0 1h-5v5a.5.5 0 0 1-1 0v-5h-5a.5.5 0 0 1 0-1h5v-5A.5.5 0 0 1 8 2Z" />
                  </svg>
                  Add
                </span>
              </Button>
            </Link>
            <Link to={`/jobListing/${token}`}>
              <Button className="mb-3 custom-submit-btn" onClick={handleGetUrl}>
                <span style={{ color: "white" }}>
                  View Jobs
                </span>
              </Button>
            </Link>
          </nav>
        </div>
        <Card className="popup-card empHistory">
          <Card.Body>
            <div className="col-md-11 mb-3">
              {/* <label htmlFor="searchInput"></label>
              <input
                type="text"
                className='p-1 w-25 ms-2'
                placeholder="Search"
                value={searchTerm}
                onChange={searchJobs}
                /> */}
              <Row>

                <Col md={9} className="mb-3 pt-3 d-flex  pe-0">
                </Col>
                <Col md={3} className="mb-3 pt-3 d-flex justify-content-end pe-0">
                  <TextField
                    label="Search"
                    variant="outlined"
                    value={searchTerm}
                    size="small"
                    onChange={searchJobs}
                  />
                </Col>
              </Row>
            </div>
            {loading ? (
              <div className="loader-container">
                <div className="loader"></div>
              </div>
            ) : (
              <div style={{ height: '70vh', width: '70vw', overflow: 'auto' }}>
                <DataGrid
                  rows={reversedRows}
                  columns={columns}
                  // pageSize={5}
                  pageSize={pageSize}
                  pagination
                  paginationMode="client"
                  // autoHeight
                  getRowId={(row) => row.id} // Using the id field for row identification
                  headerClassName="custom-header"
                  // components={{
                  //   NoRowsOverlay: () => (
                  //     <div style={{ textAlign: 'center', padding: '20px' }}>
                  //       No data available
                  //     </div>
                  //   ),
                  // }}
                  components={{
                    Pagination: () => (
                      <div className="custom-select-container">
                        <div>
                          <label htmlFor="page-size-reversed">Rows per page: </label>
                          <select
                            id="page-size-reversed"
                            value={pageSize}
                            onChange={handlePageSizeChange} // Handle page size changes
                          >
                            {/* <option value={5}>5</option>
                            <option value={10}>10</option>
                            <option value={20}>20</option> */}
                            <option value={50}>50</option>
                            <option value={100}>100</option>
                          </select>
                        </div>
                        <div>
                          <label htmlFor="page-number-reversed">Page: </label>
                          <select
                            className="page-number"
                            id="page-number-reversed"
                            value={page}
                            onChange={handlePageChange} // Handle page changes
                          >
                            {[...Array(totalPages)].map((_, pageNumber) => (
                              <option key={pageNumber} value={pageNumber + 1}>
                                {pageNumber + 1}
                              </option>
                            ))}
                          </select>
                        </div>
                      </div>
                    ),
                    NoRowsOverlay: () => (
                      <div style={{ textAlign: "center", padding: "20px" }}>
                        No data available
                      </div>
                    ),
                  }}
                  rowCount={jobs.length} // Use the length of reversedRows to show total records
                  page={page - 1} // Current page (0-based index)
                  onPageChange={(newPage) => setPage(newPage + 1)} // Handle page change
                  rowsPerPageOptions={[]} // Avoid displaying the default rows per page options
                />
              </div>
            )}
          </Card.Body>
        </Card>
      </div>

      <Modal show={showConfirmation} onHide={handleDeleteCancel}>
        <Modal.Header closeButton>
          <Modal.Title>Confirm Deletion</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>Are you sure you want to delete this job?</p>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleDeleteCancel}>
            Cancel
          </Button>
          <Button variant="danger" onClick={handleDeleteConfirm}>
            Delete
          </Button>
        </Modal.Footer>
      </Modal>
    </React.Fragment >
  );
}

export default Job;
