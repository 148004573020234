import React from 'react';
import { Button } from 'react-bootstrap';
import { Link, useLocation } from 'react-router-dom';

export const DetailedJob = () => {
    const location = useLocation();
    const jobDetails = location.state?.singleJobDetail; // Assuming job details are passed here
    const token = location.state?.token; // Assuming job details are passed here

    console.log(jobDetails);

    return (
        <div
            style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                minHeight: '80vh',
                backgroundColor: '#f7fafc', // Tailwind gray-100
                padding: '1rem', // Add padding to the container
            }}
        >
            <div
                style={{
                    position: 'relative', // Make the parent position relative to allow absolute positioning of the button
                    backgroundColor: '#ffffff', // Tailwind white
                    borderRadius: '1rem', // Tailwind rounded-xl
                    boxShadow: '0 4px 20px rgba(0, 0, 0, 0.1)', // Tailwind shadow-md
                    padding: '2rem', // Increased padding for more space
                    maxWidth: '1000px', // Increased max width
                    width: '100%',
                    margin: '1.5rem 0', // Add margin on top and bottom
                    maxHeight: '90vh', // Increased maximum height
                    overflowY: 'auto', // Enable vertical scrolling
                    scrollbarWidth: 'none', // For Firefox
                    '&::-webkit-scrollbar': {
                        display: 'none', // For Chrome, Safari, and Edge
                    },
                }}
            >
                {/* Apply Now button positioned at the top right corner */}
                <Link to={`/jobCandidateForm/${token}`} style={{ position: 'absolute', top: '1rem', right: '1rem' }}>
                    <Button className="custom-submit-btn">
                        <span style={{ color: "white" }}>
                            Apply Now
                        </span>
                    </Button>
                </Link>

                {jobDetails ? (
                    <>
                        <h4 style={{ fontSize: '1.5rem', marginBottom: '0.5rem' }}>
                            <span style={{ fontSize: '1rem', fontWeight: 'normal' }}>
                                <strong>Job Title: </strong>{jobDetails?.company_job?.jobTitle}
                            </span>
                        </h4>
                        <h4 style={{ fontSize: '1.5rem', marginBottom: '0.5rem' }}>
                            <span style={{ fontSize: '1rem', fontWeight: 'normal' }}>
                                <strong>Location: </strong>{jobDetails?.company_job?.city}, {jobDetails?.company_job?.state}, {jobDetails?.company_job?.country}
                            </span>
                        </h4>

                        <h4 style={{ fontSize: '1.5rem', marginBottom: '0.5rem' }}>
                            <span style={{ fontSize: '1rem', fontWeight: 'normal' }}>
                                <strong>Job Posted On: </strong> {new Date(jobDetails?.company_job?.inserted_at * 1000).toLocaleDateString()}
                            </span>
                        </h4>

                        <h4 style={{ fontSize: '1.5rem', marginBottom: '0.5rem' }}>
                            <span style={{ fontSize: '1rem', fontWeight: 'normal' }}>
                                <strong>Number of Openings: </strong> {jobDetails?.company_job?.numberOfOpening}
                            </span>
                        </h4>

                        <h4 style={{ fontSize: '1.5rem', marginBottom: '0.5rem' }}>
                            <span style={{ fontSize: '1rem', fontWeight: 'normal' }}>
                                <strong>Salary Range: </strong> {jobDetails?.company_job?.fromSalary} - {jobDetails?.company_job?.toSalary}
                            </span>
                        </h4>

                        <h4 style={{ fontSize: '1.5rem' }}>
                            <span style={{ fontSize: '1rem', fontWeight: 'normal' }}>
                                <strong>Link: </strong>
                                <a
                                    href={jobDetails?.company_job?.companyLink}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    style={{
                                        color: '#3182ce', // Tailwind blue-600
                                        textDecoration: 'underline',
                                        overflowWrap: 'break-word', // Ensure long URLs break appropriately
                                        wordBreak: 'break-word', // Break long words
                                    }}
                                >
                                    {jobDetails?.company_job?.companyLink}
                                </a>
                            </span>
                        </h4>

                        <h4 style={{ fontSize: '1.5rem' }}>
                            <span style={{ fontSize: '1rem', fontWeight: 'normal' }}>
                                <strong>Requirements: </strong>
                                <span
                                    style={{
                                        color: '#4a5568',
                                        lineHeight: '1.3'
                                    }}
                                    dangerouslySetInnerHTML={{ __html: jobDetails?.company_job?.requirements }}
                                />
                            </span>
                        </h4>

                        <h4 style={{ fontSize: '1.5rem' }}>
                            <span style={{ fontSize: '1rem', fontWeight: 'normal' }}>
                                <strong>Benefits: </strong>
                                <span
                                    style={{
                                        color: '#4a5568',
                                        lineHeight: '1.3'
                                    }}
                                    dangerouslySetInnerHTML={{ __html: jobDetails?.company_job?.benefits }}
                                />
                            </span>
                        </h4>
                    </>
                ) : (
                    <p style={{ textAlign: 'center', color: '#718096' }}>No job details available</p>
                )}
            </div>
        </div>
    );
};
