import React, { useState, useEffect, useRef } from 'react';
import _debounce from 'lodash/debounce';
import Header from "../../layouts/Header";
import HeaderMobile from "../../layouts/HeaderMobile";
import { Card } from "react-bootstrap";
import { DataGrid } from '@mui/x-data-grid';
import { useNavigate } from 'react-router-dom';
import { BASE_URL, deleteSalarysetup, viewSalarySetup, viewsalarysetuppagination } from '../../services/api/api';
import { toast, ToastContainer } from "react-toastify";
import { Modal, OverlayTrigger, Tooltip } from "react-bootstrap";
import { Button } from "react-bootstrap";
import { Link } from "react-router-dom";

const ViewSalarysetup = () => {
  const [salarySetupData, setSalarySetupData] = useState([]);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();
  const [masterId, setMasterId] = useState();

  const [totalPages, setTotalPages] = useState(1);
  const [page, setPage] = useState(1);
  const [pageSize, setpageSize] = useState(100);
  const [count, setCount] = useState(0);
  const pageRef = useRef(page);
  const [error, setError] = useState(null);


  const customStyles = {
    grid: {
      border: '1px solid #ddd',
    },
    cell: {
      border: 'none', // Remove all borders
      borderBottom: '1px solid #ddd', // Only horizontal border
      padding: '0.5rem 0.5rem',
      color: 'var(--bs-table-color-state, var(--bs-table-color-type, #000000b8))', // Using custom properties for text color
      // backgroundColor: 'var(--bs-table-bg)', // Using custom properties for background color
      fontWeight: '500', // Making text important
      fontFamily: 'system-ui, -apple-system, "Segoe UI", Roboto, Helvetica, Arial, sans-serif', // Highly standard font family
    },
    columnHeader: {
      border: 'none', // Remove all borders
      borderBottom: '1px solid #ddd', // Only horizontal border
      padding: '0.5rem 0.5rem',
      color: 'var(--bs-table-color-state, var(--bs-table-color-type, #000))', // Using custom properties for text color
      backgroundColor: 'var(--bs-table-bg)', // Using custom properties for background color

      fontWeight: 'bold',
      fontFamily: 'system-ui, -apple-system, "Segoe UI", Roboto, Helvetica, Arial, sans-serif', // Highly standard font family
    },
    lastColumn: {
      borderRight: 'none',
    },
  };

  useEffect(() => {
    pageRef.current = page;
  }, [page]);


  const getToken = () => {
    const token = sessionStorage.getItem('token');

    return token ? `Bearer ${token}` : '';


  };
  // useEffect(() => {
  //   fetchData();
  // }, []);

  // const fetchData = async () => {
  //   try {
  //     const responseData = await viewSalarySetup();

  //     if (!responseData || !responseData.data) {
  //       throw new Error('Failed to fetch data.');
  //     }

  //     const masterId = responseData.data[0]._id;
  //     console.log("masterIdsalarysetup",masterId);

  //     setSalarySetupData(responseData.data);
  //     setMasterId(masterId);
  //   } catch (error) {
  //     console.error('Error fetching data:', error);
  //   } finally {
  //     setLoading(false);
  //   }
  // };


  // const fetchData = async () => {
  //   try {
  //     const responseData = await viewSalarySetup();

  //     console.log(responseData);

  //     if (!responseData || !responseData.data) {
  //       throw new Error('Failed to fetch data.');
  //     }

  //     const masterId = responseData.data[0]._id;
  //     console.log("masterIdsalarysetup", masterId);

  //     setSalarySetupData(responseData.data);
  //     setMasterId(masterId);
  //   } catch (error) {
  //     console.error('Error fetching data:', error);
  //   } finally {
  //     setLoading(false);
  //   }
  // };


  const fetchData = async () => {
    try {
      const responseData = await viewsalarysetuppagination(page, pageSize);

      console.log(responseData);

      if (!responseData || !responseData.data) {
        throw new Error('Failed to fetch data.');
      }

      const masterId = responseData.data[0].original._id;
      console.log("masterIdsalarysetup", masterId);

      const { data } = responseData;
      const original = data[0]?.original || {};

      setSalarySetupData(responseData.data);
      setMasterId(masterId);
      // setTotalPages(responseData.totalPages);
      // setpageSize(pageSize);
      setTotalPages(original.totalPages || 1);
      setCount(original.totalRecords || 0);
      setPage(original.page || 1);
      setpageSize(original.pageSize || 2);
    } catch (error) {
      console.error('Error fetching data:', error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    const debouncedFetchData = _debounce(fetchData, 100);

    debouncedFetchData();

    return () => {
      debouncedFetchData.cancel();
    };
  }, [page, pageSize]);

  // const handlePageSizeChange = (event) => {
  //   setpageSize(parseInt(event.target.value, 10));
  // };

  const handlePageSizeChange = (event) => {
    const newPageSize = parseInt(event.target.value, 10);
    setpageSize(newPageSize);

    // Recalculate total pages based on new page size
    const newTotalPages = Math.ceil(count / newPageSize);
    setTotalPages(newTotalPages);

    // Optionally reset page to 1 if it exceeds the new total pages
    if (page > newTotalPages) {
      setPage(newTotalPages);
    }
  };

  const handlePageChange = (event) => {
    setPage(parseInt(event.target.value, 10));
    console.log(event.target.value)
  };


  const handleEditClick = (id, params) => {
    console.log(id);
    console.log(params);

    if (masterId) {
      navigate(`/admin/edit_salary_setup/${id}/${masterId}`);
    } else {
      console.error('Master ID is not available.');
    }
  };

  const [index, setIndex] = useState();
  const columns = [
    {
      field: 'srNo', headerName: 'Sr No.', flex: 1,

    },
    { field: 'name', headerName: 'Name', flex: 1 },
    {
      field: 'actions',
      headerName: 'Actions',
      align: 'center',
      headerAlign: 'center',
      flex: 1,
      renderCell: (params) => (
        <React.Fragment>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="16"
            fill="black"
            className="bi bi-pencil-fill edit ebtn"
            viewBox="0 0 16 16"
            style={{ cursor: 'pointer', marginRight: "15px" }}
            onClick={() => handleEditClick(params.row.id, params)}
          >
            <path d="M12.854.146a.5.5 0 0 0-.707 0L10.5 1.793 14.207 5.5l1.647-1.646a.5.5 0 0 0 0-.708l-3-3zm.646 6.061L9.793 2.5 3.293 9H3.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.207l6.5-6.5zm-7.468 7.468A.5.5 0 0 1 6 13.5V13h-.5a.5.5 0 0 1-.5-.5V12h-.5a.5.5 0 0 1-.5-.5V11h-.5a.5.5 0 0 1-.5-.5V10h-.5a.499.499 0 0 1-.175-.032l-.179.178a.5.5 0 0 0-.11.168l-2 5a.5.5 0 0 0 .65.65l5-2a.5.5 0 0 0 .168-.11l.178-.178z" />
          </svg>
          {/* <svg
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="16"
            fill="currentColor"
            className="bi bi-trash3-fill delete delete-button"
            viewBox="0 0 16 16"
            data-id={params.row.id}
            style={{ cursor: 'pointer' }}
            onClick={() => {
              const id = params.row.id;
              openConfirmationModal(id);
            }}
          >
            <path d="M11 1.5v1h3.5a.5.5 0 0 1 0 1h-.538l-.853 10.66A2 2 0 0 1 11.115 16h-6.23a2 2 0 0 1-1.994-1.84L2.038 3.5H1.5a.5.5 0 0 1 0-1H5v-1A1.5 1.5 0 0 1 6.5 0h3A1.5 1.5 0 0 1 11 1.5Zm-5 0v1h4v-1a.5.5 0 0 0-.5-.5h-3a.5.5 0 0 0-.5.5ZM4.5 5.029l.5 8.5a.5.5 0 1 0 .998-.06l-.5-8.5a.5.5 0 1 0-.998.06Zm6.53-.528a.5.5 0 0 0-.528.47l-.5 8.5a.5.5 0 0 0 .998.058l.5-8.5a.5.5 0 0 0-.47-.528ZM8 4.5a.5.5 0 0 0-.5.5v8.5a.5.5 0 0 0 1 0V5a.5.5 0 0 0-.5-.5Z" />
          </svg> */}
        </React.Fragment>
      )
    },
  ];
  const [deleteSalarysetupId, setdeleteSalarysetupId] = useState(null);
  const [showConfirmation, setShowConfirmation] = useState(false);

  const openConfirmationModal = (id) => {
    setdeleteSalarysetupId(id);
    setShowConfirmation(true);
  };
  const closeConfirmationModal = () => {
    setShowConfirmation(false);
  };

  const rows = salarySetupData.flatMap((item) => {
    if (item.original && item.original.Salary_Structure && Array.isArray(item.original.Salary_Structure)) {
      return item.original.Salary_Structure.map((structure, index) => ({
        id: structure._id,
        srNo: index + 1,
        name: structure.name,
      }));
    } else {
      return [];
    }
  });





  console.log('Processed rows:', rows); // Log the final rows


  const handledeleteSalarysetup = async (id, masterId) => {
    setdeleteSalarysetupId(id);
    setShowConfirmation(true);

    try {
      const response = await deleteSalarysetup(id, masterId);
      // if (response && response.status === 'success') {
      //   if (!toast.isActive('salarysetupDeletedToast')) {
      //     toast.success('Employee salarysetup deleted successfully', { toastId: 'holidayDeletedToast' });
      //   }
      //   fetchData();
      // } else {
      //   // Handle failure case
      // }
      if (response) {
        toast.success('Salary Structure deleted successfully', {
          position: "top-center",
          autoClose: 1000,
        });
        fetchData()
      }
      else {
        toast.error('Something went wrong', {
          position: "top-center",
          autoClose: 1000,
        });
      }
    } catch (error) {
      console.error('Error deleting holiday:', error);
    }
  };




  // const handleExportSalaryStructure = async () => {
  //   setLoading(true); // Show loading state



  //   try {
  //     const response = await fetch(`${BASE_URL}/exportSalarySetup`, {
  //       method: 'POST',
  //       headers: {
  //         'Accept': 'application/json',
  //         "Content-Type": "application/json",
  //         "Authorization": getToken(),
  //       },
  //     });

  //     if (!response.ok) {
  //       throw new Error('Network response was not ok');
  //     }

  //     const data = await response.json();

  //     // Convert JSON data to CSV
  //     const csvData = convertToCSV(data); // Ensure you have a function to convert data to CSV

  //     // Create a Blob and trigger the download
  //     const blob = new Blob([csvData], { type: 'text/csv' });
  //     const link = document.createElement('a');
  //     link.href = URL.createObjectURL(blob);
  //     link.download = 'employee_data.csv'; // Change the file name as needed
  //     link.click();

  //     // Clean up
  //     URL.revokeObjectURL(link.href);



  //   } catch (error) {
  //     console.error("Fetch error: ", error);
  //     setError(error.message || 'An error occurred');



  //   } finally {
  //     setLoading(false); // Hide loading state

  //   }

  // };

  const handleExportSalaryStructure = async () => {
    setLoading(true); // Show loading state

    try {
      const response = await fetch(`${BASE_URL}/exportSalarySetup`, {
        method: 'POST',
        headers: {
          'Accept': 'application/json',
          "Content-Type": "application/json",
          "Authorization": getToken(),
        },
      });

      if (!response.ok) {
        throw new Error('Network response was not ok');
      }

      const data = await response.json();

      // Convert JSON data to CSV
      const csvData = convertToCSV(data); // Ensure you have a function to convert data to CSV

      // Create a Blob and trigger the download
      const blob = new Blob([csvData], { type: 'text/csv' });
      const link = document.createElement('a');
      link.href = URL.createObjectURL(blob);
      link.download = 'salary_structure_data.csv'; // Change the file name as needed
      link.click();

      // Clean up
      URL.revokeObjectURL(link.href);

      // Show success toast message
      toast.success("Salary structure data exported successfully!", {
        position: "top-center",
        autoClose: 1000,
      });

    } catch (error) {
      console.error("Fetch error: ", error);

      // Show error toast message
      toast.error(error.message || 'An error occurred during export');

      setError(error.message || 'An error occurred');
    } finally {
      setLoading(false); // Hide loading state
    }
  };

  function convertToCSV(data) {
    const array = [Object.keys(data[0])].concat(data);

    return array.map(row => {
      return Object.values(row).map(value =>
        typeof value === 'string' ? `"${value.replace(/"/g, '""')}"` : value
      ).join(',');
    }).join('\n');
  }



  return (
    <React.Fragment>
      <HeaderMobile />
      <Header />
      <div className="main p-4 p-lg-5 mt-5">

        <div className="breadcrumb-warning d-flex justify-content-between ot-card">
          <div>
            <ol className="breadcrumb ot-breadcrumb ot-breadcrumb-basic">
              {/* <li className="mx-2">
                  <h4>Holidays</h4>
                </li> */}
              <li class="breadcrumb-item ">
                <Link to="/admin/dashboard">Dashboard</Link>
              </li>
              <li class="breadcrumb-item active"><Link to="/admin/Viewempsalary">Salary Structure</Link></li>
              {/* <li class="breadcrumb-item active">Lists</li> */}
            </ol>
          </div>
          <nav aria-label="breadcrumb">

            {/* <Link to="/admin/salarysetup">
              <div><button className="button-one btn-primary" type="submit"><span>
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-plus-lg" viewBox="0 0 16 16">
                  <path fill-rule="evenodd" d="M8 2a.5.5 0 0 1 .5.5v5h5a.5.5 0 0 1 0 1h-5v5a.5.5 0 0 1-1 0v-5h-5a.5.5 0 0 1 0-1h5v-5A.5.5 0 0 1 8 2Z" />
                </svg>Add Salary Structure</span></button></div>
            </Link> */}

            {salarySetupData.length == 0 && (
              <Link to="/admin/salarysetup">
                <button
                  className="button-one btn-primary"
                  type="button"
                  style={{ cursor: 'pointer' }}
                >
                  <span style={{ cursor: 'pointer' }}>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      fill="currentColor"
                      className="bi bi-plus-lg"
                      viewBox="0 0 16 16"
                    >
                      <path
                        fillRule="evenodd"
                        d="M8 2a.5.5 0 0 1 .5.5v5h5a.5.5 0 0 1 0 1h-5v5a.5.5 0 0 1-1 0v-5h-5a.5.5 0 0 1 0-1h5v-5A.5.5 0 0 1 8 2Z"
                      />
                    </svg>
                    Add
                  </span>
                </button>
              </Link>
            )}

            <ToastContainer />


          </nav>
        </div>

        <div className="col-md-3 mb-3">
          <Button className="btn btn-primary border-0" onClick={() => handleExportSalaryStructure()} style={{ whiteSpace: 'nowrap' }}>
            Export
          </Button>
        </div>


        <Card className="popup-card empHistory">
          <Card.Body>
            {loading ? (
              <div className="loader-container">
                <div className="loader"></div>
              </div>
            ) : (

              <div style={{ height: '100%', width: '100%', backgroundColor: 'white' }}>
                <DataGrid
                  className="gridColor"
                  rows={rows}
                  columns={columns}
                  pageSize={pageSize}
                  pagination
                  responsive
                  autoHeight
                  paginationMode="client"
                  getRowId={(row) => row.id}
                  components={{
                    Pagination: () => (
                      <div className="custom-select-container">
                        <div>
                          <label htmlFor="page-size">Rows per page: </label>
                          <select
                            id="page-size"
                            value={pageSize}
                            onChange={handlePageSizeChange}
                          >
                            <option value={2}>2</option>
                            <option value={10}>10</option>
                            <option value={20}>20</option>
                            <option value={50}>50</option>
                            <option value={100}>100</option>
                          </select>
                        </div>
                        <div>
                          <label htmlFor="page-number">Page: </label>
                          <select
                            className="page-number"
                            id="page-number"
                            value={page}
                            onChange={handlePageChange}
                          >
                            {[...Array(totalPages).keys()].map((pageNumber) => (
                              <option key={pageNumber} value={pageNumber + 1}>
                                {pageNumber + 1}
                              </option>
                            ))}
                          </select>
                        </div>
                      </div>
                    ),
                  }}
                  componentsProps={{
                    cell: { style: customStyles.cell },
                    columnHeader: { style: customStyles.columnHeader },
                  }}
                  rowCount={count}
                  page={page - 1} // DataGrid uses zero-based index for pages
                  onPageChange={(newPage) => setPage(newPage + 1)}
                  rowsPerPageOptions={[]} // Avoid displaying the default rows per page options
                />
              </div>
            )}
          </Card.Body>
        </Card>
      </div>

      <Modal show={showConfirmation} onHide={closeConfirmationModal}>
        <Modal.Header closeButton>
          <Modal.Title>Confirm Deletion</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Are you sure you want to delete this holiday?
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={closeConfirmationModal}>
            Cancel
          </Button>
          <Button
            variant="danger"
            onClick={() => {
              handledeleteSalarysetup(deleteSalarysetupId, masterId);
              closeConfirmationModal();
            }}

          >
            Delete
          </Button>

        </Modal.Footer>
      </Modal>
    </React.Fragment >
  );
};

export default ViewSalarysetup;

