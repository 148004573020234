import React, { useState, useEffect } from "react";
import Header from "../../../layouts/Header";
import HeaderMobile from "../../../layouts/HeaderMobile";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { addResignation } from "../../../services/api/api";
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import { Form } from "react-bootstrap";
import { Button } from "react-bootstrap";

const ResignationForm = () => {
    const navigate = useNavigate();
    const [submitting, setSubmitting] = useState(false);
    const [state, setState] = useState({
        reason: "",
        notice_date: "",
        resignation_date: ""
    });

    const [errors, setErrors] = useState({});
    const getFormattedDefaultDate = () => {
        return 'N/A';
    };
    useEffect(() => {
        setState({
            reason: "",
            notice_date: getFormattedDefaultDate(),
            resignation_date: getFormattedDefaultDate(),
        });
        setErrors({});
    }, []);

  
    const handleChange = (e) => {
        const { name, value } = e.target;
    
        setState((prevState) => ({
            ...prevState,
            [name]: value,
        }));
    
        // Use the updater function to make sure state is updated before validation
        setErrors((prevErrors) => ({
            ...prevErrors,
            [name]: "",  // Clear the error when the field changes
        }));
    };
    
    const handleSubmit = async (e) => {
        e.preventDefault();
        if (submitting) {
            return;
          }
        const validationErrors = validateForm(state);
        if (Object.keys(validationErrors).length > 0) {
            setErrors(validationErrors);
            return;
        }
        setSubmitting(true);

        try {
            const response = await addResignation(state);
            const data = JSON.parse(response);

            if (data.success === true) {
                toast.success(data.message, {
                    position: "top-center",
                    autoClose: 1000,
                });

                // Clear the form fields after successful submission
                setState({
                    reason: "",
                    notice_date: getFormattedDefaultDate(),
                    resignation_date: getFormattedDefaultDate(),
                });

            } else {
                toast.success(data.message, {
                    position: "top-center",
                    autoClose: 1000,
                });
            }
        } catch (error) {
            toast.success("Resignation added successfully ", {
                position: "top-center",
                autoClose: 1000,
            });
        } finally {
            setSubmitting(false);
        }
    };


    const validateForm = (state) => {
        let errors = {};
    
        if (!state.reason.trim()) {
            errors.reason = "Resignation reason is required";
        }
    
        if (!state.notice_date) {
            errors.notice_date = "Notice date is required";
        }
    
        if (!state.resignation_date) {
            errors.resignation_date = "Resignation date is required";
        }
    
        return errors;
    };
    
    
      

    return (
        <React.Fragment>
            <HeaderMobile />
            <Header />
            <div className="main p-4 p-lg-5 mt-5">
                <h4>Add Resignation</h4>
                <div className="breadcrumb-warning d-flex justify-content-between ot-card">
                    <div>
                        <ol className="breadcrumb ot-breadcrumb ot-breadcrumb-basic">
                            <li className="breadcrumb-item">
                                <Link to="/employee/dashboard">Dashboard</Link>
                            </li>
                            {/* <li className="breadcrumb-item active">
                                <Link to="/pages/hr/designation">Resignation</Link>
                            </li> */}
                            <li className="breadcrumb-item active">Add Resignation</li>
                        </ol>
                    </div>
                    <nav aria-label="breadcrumb">
                        {/* <Link to="/pages/hr/resignation" className="btn btn-danger mb-3">
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-arrow-left" viewBox="0 0 16 16">
                                <path fillRule="evenodd" d="M15 8a.5.5 0 0 0-.5-.5H2.707l3.147-3.146a.5.5 0 1 0-.708-.708l-4 4a.5.5 0 0 0 0 .708l4 4a.5.5 0 0 0 .708-.708L2.707 8.5H14.5A.5.5 0 0 0 15 8z" />
                            </svg>
                            Back
                        </Link> */}
                    </nav>
                </div>
                <div className="row"
                    style={{
                        boxShadow: "0 4px 8px 0 rgba(0,0,0,0.2)",
                        borderRadius: "10px",
                        overflow: "hidden",
                    }}
                >

                    <div className="col-md-12 formcontrols">
                        <div className="bg-white p-4 rounded" style={{ height: "100%" }}>
                            <form onSubmit={handleSubmit}>
                                <div className="row">

                                    <div className="col-md-12">
                                        <div className="mb-3">
                                            <label htmlFor="reason" className="form-label">
                                                Reason
                                            </label>
                                            {/* <input
                                                type="text"
                                                className={`form-control ${errors.reason && "is-invalid"}`}
                                                id="reason"
                                                name="reason"
                                                value={state.reason}
                                                onChange={handleChange}
                                                placeholder="Enter reason"
                                            /> */}

                                            <textarea
                                                className={`form-control ${errors.reason && "is-invalid"}`}
                                                id="reason"
                                                name="reason"
                                                value={state.reason}
                                                onChange={handleChange}
                                                style={{ height: "100px", resize: "vertical" }}
                                                placeholder="Enter..."
                                            />

                                            {errors.reason && (
                                                <div className="invalid-feedback">{errors.reason}</div>
                                            )}
                                        </div>
                                    </div>

                                    <div className="col-md-6">
                                        <div className="mb-3">
                                            <label htmlFor="notice_date" className="form-label">
                                                Notice Date
                                            </label>
                                            <input
                                                type="date"
                                                className={`form-control ${errors.notice_date && "is-invalid"}`}
                                                id="notice_date"
                                                name="notice_date"
                                                value={state.notice_date}
                                                onChange={handleChange}
                                            />
                                            {errors.notice_date && <div className="text-danger">{errors.notice_date}</div>}

                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="mb-3">
                                            <label htmlFor="resignation_date" className="form-label">
                                                Resignation Date
                                            </label>
                                            <input
                                                type="date"
                                                className={`form-control ${errors.resignation_date && "is-invalid"}`}
                                                id="resignation_date"
                                                name="resignation_date"
                                                value={state.resignation_date}
                                                onChange={handleChange}
                                            />
                                          {errors.resignation_date && <div className="text-danger">{errors.resignation_date}</div>}
                                            
                                        </div>
                                    </div>
                                </div>


                                {/* Other department fields can be added here */}
                                <button
                  type="submit"
                  className={`custom-submit-btn ${submitting ? 'disabled' : ''}`}
                  disabled={submitting}
                >
                  {submitting ? 'Submitting...' : 'Add Resignation'}
                </button>

                            </form>
                        </div>
                    </div>
                </div>
            </div >
        </React.Fragment >
    );
};

export default ResignationForm
