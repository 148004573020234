import React from "react";
import NotFound from "../pages/NotFound";
// import Signin from "../pages/Signin";
import Signin from "../pages/Signin";
// import Signup from "../pages/Signup";
import Signup from "../pages/Signup";
import VerifyAccount from "../pages/VerifyAccount";
import Candidateform from "../pages/candidateform";
import Thankyoumsg from "../pages/thankyoumsg";
import { ResetPassword } from "../pages/ResetPassword";
import { ForgotPassword } from "../pages/ForgotPassword";
import { JobListing } from "../pages/JobListing";
import JobCandidateform from "../pages/JobCandidateForm";
import { DetailedJob } from "../pages/DetailedJob";

let jobListing = localStorage.getItem('joblistingroute')
const publicRoutes = [
  // { path: "pages/signin", element: <Signin /> },
  { path: "signin", element: <Signin /> },
  { path: "candidate-form", element: <Candidateform /> },
  { path: `/jobListing/:token`, element: <JobListing /> },
  { path: `/viewJobDetails`, element: <DetailedJob /> },
  { path: `/jobCandidateForm/:token`, element: <JobCandidateform /> },

  // { path: "forgotPassword", element: <ResetPassword /> },
  { path: "Thank-you", element: <Thankyoumsg /> },
  // { path: "pages/signup", element: <Signup /> },
  { path: "signup", element: <Signup /> },
  { path: "pages/verify", element: <VerifyAccount /> },
  { path: "pages/forgot", element: <ForgotPassword /> },
  { path: "pages/error-404", element: <NotFound /> },


];

export default publicRoutes;