// import React, { useEffect, useState } from "react";
// import _debounce from 'lodash/debounce';
// import Header from "../../../layouts/Header";
// import HeaderMobile from "../../../layouts/HeaderMobile";
// import { toast } from "react-toastify";
// import "react-toastify/dist/ReactToastify.css";
// import { addEmployeeLeave, viewLeave, getId, addLeave, viewWorkingDays } from "../../../services/api/api";
// import { useNavigate } from "react-router-dom";
// import { Link } from "react-router-dom";
// import { Form } from "react-bootstrap";
// import { Modal, Button } from "react-bootstrap";

// const AddEmpLeave = () => {
//     const [loading, setLoading] = useState(true);
//     const [masterId, setMasterId] = useState();
//     const [isSubmitting, setIsSubmitting] = useState(false);
//     const [currentDate, setCurrentDate] = useState('');
//     const [id, setId] = useState('');
//     useEffect(() => {
//         const empId = getId();
//         setId(empId);
//     }, []);

//     const [modalErrors, setModalErrors] = useState({});

//     useEffect(() => {
//         // Function to format the date as YYYY-MM-DD
//         const formatDate = (date) => {
//             const year = date.getFullYear();
//             const month = (date.getMonth() + 1).toString().padStart(2, '0');
//             const day = date.getDate().toString().padStart(2, '0');
//             return `${year}-${month}-${day}`;
//         };

//         // Get the current date and set it to the state
//         const today = new Date();
//         setCurrentDate(formatDate(today));
//     }, []);


//     const validateModalForm = (state) => {
//         let errors = {};

//         if (!state.leave_type) {
//             errors.leave_type = "Leave type is required";
//         }


//         return errors;
//     };
//     const [workingdays, setWorkingDay] = useState([])
//     // useEffect(() => {
//     //     fetchWorkingDaysData()
//     // }, []);
//     useEffect(() => {
//         const debouncedFetchWorkingDaysData = _debounce(fetchWorkingDaysData, 2000);

//         debouncedFetchWorkingDaysData();

//         return () => {
//             debouncedFetchWorkingDaysData.cancel();
//         };
//     }, []);
//     const fetchWorkingDaysData = async () => {
//         try {
//             const formattedData = await viewWorkingDays();

//             if (!formattedData || !formattedData.length) {
//                 throw new Error('Failed to fetch data.');
//             }

//             const companyWorkingDays = formattedData[0].company_workingday;

//             if (companyWorkingDays && companyWorkingDays.length > 0) {
//                 // Select the last element from the company_workingday array
//                 const lastWorkingDay = companyWorkingDays[companyWorkingDays.length - 1];
//                 setMasterId(formattedData[0]._id);

//                 // Set workingdaysArray with only the last element
//                 const workingdaysArray = [{
//                     _id: lastWorkingDay._id,
//                     monthly_allow_leave: lastWorkingDay.monthly_allow_leave || 'N/A',
//                 }];

//                 setWorkingDay(workingdaysArray);
//             } else {
//                 // Handle the case where company_workingday array is empty
//                 throw new Error('No working days data found.');
//             }
//         } catch (error) {
//             console.error('Error fetching data:', error);
//         } finally {
//             setLoading(false);
//         }
//     };


//     // ... (existing code)



//     const [leaveTypes, setLeaveTypes] = useState([]);
//     useEffect(() => {
//         const debouncedFetchLeaveTypes = _debounce(fetchLeaveTypes, 2000);

//         debouncedFetchLeaveTypes();

//         return () => {
//             debouncedFetchLeaveTypes.cancel();
//         };
//     }, []);
//     const fetchLeaveTypes = async () => {
//         try {
//             const formattedData = await viewLeave();

//             if (!formattedData || !formattedData.length) {
//                 throw new Error('Failed to fetch data.');
//             }

//             const companyLeave = formattedData[0].company_leave;
//             setMasterId(formattedData[0]._id);
//             const leaveArray = Object.values(companyLeave).map(item => ({

//                 _id: item._id,
//                 leave_type: item.leave_type || 'N/A',
//             }));
//             setLeaveTypes(leaveArray);
//         } catch (error) {
//             console.error('Error fetching data:', error);
//         } finally {
//             setLoading(false);
//         }
//     };

//     console.log(leaveTypes)

//     const navigate = useNavigate();
//     const [state, setState] = useState({

//         leave_type: "",
//         from_date: "",
//         to_date: "",
//         total_days: "",
//         remaining_leaves: "",
//         leave_reason: "",

//     });



//     const [errors, setErrors] = useState({});

//     const handleChange = (e) => {
//         const { name, value } = e.target;

//         setState((prevState) => ({
//             ...prevState,
//             [name]: value,
//         }));

//         // Clear error for leave_reason field
//         setErrors((prevErrors) => ({
//             ...prevErrors,
//             leave_reason: "",
//         }));

//         if (name === 'from_date' || name === 'to_date') {
//             calculateTotalDaysAndRemainingLeaves(
//                 name === 'from_date' ? value : state.from_date,
//                 name === 'to_date' ? value : state.to_date
//             );
//         }
//     };


//     const calculateTotalDaysAndRemainingLeaves = (fromDate, toDate) => {
//         const from = new Date(fromDate);
//         const to = new Date(toDate);

//         if (!isNaN(from.getTime()) && !isNaN(to.getTime()) && from <= to) {
//             const timeDifference = to.getTime() - from.getTime();
//             const totalDays = timeDifference / (1000 * 3600 * 24);

//             // Calculate remaining leaves
//             const monthlyAllowLeave = workingdays[0]?.monthly_allow_leave || 0;
//             const remainingLeaves = Math.max(monthlyAllowLeave - totalDays, 0);

//             setState((prevState) => ({
//                 ...prevState,
//                 total_days: totalDays,
//                 remaining_leaves: remainingLeaves,
//             }));
//         } else {
//             // Invalid dates entered, set total days and remaining leaves to empty string
//             setState((prevState) => ({
//                 ...prevState,
//                 total_days: '',
//                 remaining_leaves: '',
//             }));
//         }
//     };


//     const handleLeaveChange = (e) => {
//         const { name, value } = e.target;

//         setState((prevState) => ({
//             ...prevState,
//             [name]: value,
//         }));
//         setErrors((prevErrors) => ({
//             ...prevErrors,
//             [name]: "",
//         }));
//     };



//     const handleSubmit = async (e) => {
//         e.preventDefault();

//         if (isSubmitting) {
//             // If the form is already being submitted, prevent multiple submissions
//             return;
//         }

//         setIsSubmitting(true);

//         const validationErrors = validateForm(state);
//         if (Object.keys(validationErrors).length > 0) {
//             setErrors(validationErrors);
//             setIsSubmitting(false); // Reset submit button state
//             return;
//         }

//         try {
//             const response = await addEmployeeLeave(id, state);
//             const data = JSON.parse(response);

//             if (data.success === true) {
//                 toast.success(data.message, {
//                     position: "top-center",
//                     autoClose: 1000,
//                 });

//                 // Reset the form fields
//                 setState({
//                     leave_type: "",
//                     from_date: "",
//                     to_date: "",
//                     total_days: "",
//                     remaining_leaves: "",
//                     leave_reason: "",
//                 });
//             } else {
//                 toast.warning(data.message, {
//                     position: "top-center",
//                     autoClose: 1000,
//                 });
//             }
//         } catch (error) {
//             toast.success("Leave Added successfully", {
//                 position: "top-center",
//                 autoClose: 1000,
//             });
//         } finally {
//             setIsSubmitting(false);
//             navigate('/employee/dashboard')
//         }
//     };


//     const validateForm = (state) => {
//         let errors = {};
//         if (!state.leave_type) {
//             errors.leave_type = "Leave type is required";
//         }
//         if (!state.from_date) {
//             errors.from_date = "From Date is required";
//         }
//         if (!state.to_date) {
//             errors.to_date = "To Date is required";
//         }
//         // if (!state.total_days) {
//         //     errors.total_days = "Total Days are required";
//         // }
//         if (state.remaining_leaves !== 0 && !state.remaining_leaves) {
//             errors.remaining_leaves = "Remaining Leaves are required";
//         }
//         if (!state.leave_reason) {
//             errors.leave_reason = "Leave Reason is required";
//         }
//         // Add validations for other fields if needed

//         return errors;
//     };

//     return (
//         <React.Fragment>
//             <HeaderMobile />
//             <Header />
//             <div className="main p-4 p-lg-5 mt-5">
//                 <h4>Add Leave</h4>
//                 <div className="breadcrumb-warning d-flex justify-content-between ot-card">
//                     <div>
//                         <ol className="breadcrumb ot-breadcrumb ot-breadcrumb-basic">
//                             <li className="breadcrumb-item">
//                                 <Link to="/employee/dashboard">Dashboard</Link>
//                             </li>

//                             <li className="breadcrumb-item active">Add Leave</li>
//                         </ol>
//                     </div>
//                     <nav aria-label="breadcrumb">
//                     </nav>
//                 </div>
//                 <div className="row"
//                     style={{
//                         boxShadow: "0 4px 8px 0 rgba(0,0,0,0.2)",
//                         borderRadius: "10px",
//                         overflow: "hidden",
//                         border: "1px solid #b6babd"

//                     }}
//                 >
//                     <div className="col-md-12 formcontrols">
//                         <div className="bg-white p-4 rounded" style={{ height: "100%" }}>
//                             <form onSubmit={handleSubmit}>




//                                 <div className="row">
//                                     <div className="col-md-4">

//                                         <div className="mb-3">
//                                             <label htmlFor="leave_type" className="form-label">
//                                                 Leave type
//                                             </label>
//                                             <select
//                                                 className={`form-select ${errors.leave_type && "is-invalid"}`}
//                                                 id="leave_type"
//                                                 name="leave_type"
//                                                 value={state.leave_type || ''}
//                                                 onChange={handleLeaveChange}
//                                             >
//                                                 <option value="">Select Leave Type</option>
//                                                 {leaveTypes.map((leaveType) => (
//                                                     <option key={leaveType._id} value={leaveType.leave_type}>
//                                                         {leaveType.leave_type}
//                                                     </option>
//                                                 ))}
//                                             </select>
//                                             {errors.leave_type && (
//                                                 <div className="invalid-feedback">{errors.leave_type}</div>
//                                             )}

//                                         </div>

//                                     </div>
//                                     <div className="col-md-4">

//                                         <div className="mb-3">
//                                             <label htmlFor="from_date" className="form-label">
//                                                 From Date                            </label>
//                                             <input
//                                                 type="date"
//                                                 className="form-control"
//                                                 id="from_date"
//                                                 name="from_date"
//                                                 value={state.from_date}
//                                                 onChange={handleChange}
//                                                 min={currentDate} 
//                                             />
//                                             {errors.from_date && (
//                                                 <div className="invalid-feedback">{errors.from_date}</div>
//                                             )}
//                                         </div>

//                                     </div>
//                                     <div className="col-md-4">

//                                         <div className="mb-3">
//                                             <label htmlFor="leave_type" className="form-label">
//                                                 To Date
//                                             </label>
//                                             <input
//                                                 type="date"
//                                                 className="form-control"
//                                                 id="to_date"
//                                                 name="to_date"
//                                                 value={state.to_date}
//                                                 onChange={handleChange}
//                                                 min={currentDate} 
//                                             />
//                                             {errors.to_date && (
//                                                 <div className="invalid-feedback">{errors.to_date}</div>
//                                             )}
//                                         </div>

//                                     </div>
//                                 </div>
//                                 <div className="row">
//                                     <div className="col-md-4">
//                                         <div className="mb-3">
//                                             <label htmlFor="leave_type" className="form-label">
//                                                 Total Days
//                                             </label>
//                                             <input
//                                                 type="text"
//                                                 className="form-control"
//                                                 id="total_days"
//                                                 name="total_days"
//                                                 value={state.total_days !== '' ? state.total_days : ''}
//                                                 disabled
//                                                 placeholder="Enter total days"
//                                             />

//                                             {errors.total_days && (
//                                                 <div className="invalid-feedback">{errors.total_days}</div>
//                                             )}
//                                         </div>


//                                     </div>
//                                     <div className="col-md-4">
//                                         <div className="mb-3">
//                                             <label htmlFor="remaining_leaves" className="form-label">
//                                                 Remaining Leaves
//                                             </label>
//                                             <input
//                                                 type="text"
//                                                 className={`form-control ${errors.remaining_leaves && "is-invalid"}`}
//                                                 id="remaining_leaves"
//                                                 name="remaining_leaves"
//                                                 value={state.remaining_leaves}
//                                                 onChange={handleChange}
//                                                 placeholder="Enter remaining leaves"
//                                                 disabled
//                                             />
//                                             {errors.remaining_leaves && (
//                                                 <div className="invalid-feedback">{errors.remaining_leaves}</div>
//                                             )}
//                                         </div>


//                                     </div>
//                                     <div className="col-md-12">

//                                         <div className="mb-3">
//                                             <label htmlFor="leave_reason" className="form-label">
//                                                 Leave Reason
//                                             </label>
//                                             <textarea
//                                                 className={`form-control ${errors.leave_reason && "is-invalid"}`}
//                                                 id="leave_reason"
//                                                 name="leave_reason"
//                                                 value={state.leave_reason}
//                                                 onChange={handleChange}
//                                                 style={{ height: "100px", resize: "vertical" }}
//                                                 placeholder="Enter leave reason"
//                                             />

//                                             {errors.leave_reason && (
//                                                 <div className="invalid-feedback">{errors.leave_reason}</div>
//                                             )}
//                                         </div>
//                                     </div>
//                                 </div>

//                                 <button type="submit" className="btn btn-primary"
//                                     disabled={isSubmitting}>

//                                     {isSubmitting ? 'Submitting...' : 'Add Leave'}
//                                 </button>
//                             </form>
//                         </div>
//                     </div>
//                 </div>
//             </div>
//         </React.Fragment>
//     );
// };

// export default AddEmpLeave

import React, { useEffect, useState } from "react";
import _debounce from 'lodash/debounce';
import Header from "../../../layouts/Header";
import HeaderMobile from "../../../layouts/HeaderMobile";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { addEmployeeLeave, viewLeave, getId, addLeave, viewWorkingDays } from "../../../services/api/api";
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";

const AddEmpLeave = () => {
    const [loading, setLoading] = useState(false);
    const [masterId, setMasterId] = useState('');
    const [currentDate, setCurrentDate] = useState('');
    const [id, setId] = useState('');
    useEffect(() => {
        const empId = getId();
        setId(empId);
    }, []);

    useEffect(() => {
        const formatDate = (date) => {
            const year = date.getFullYear();
            const month = (date.getMonth() + 1).toString().padStart(2, '0');
            const day = date.getDate().toString().padStart(2, '0');
            return `${year}-${month}-${day}`;
        };

        const today = new Date();
        setCurrentDate(formatDate(today));
    }, []);

    const [workingdays, setWorkingDay] = useState([]);

    useEffect(() => {
        const debouncedFetchWorkingDaysData = _debounce(fetchWorkingDaysData, 100);

        debouncedFetchWorkingDaysData();

        return () => {
            debouncedFetchWorkingDaysData.cancel();
        };
    }, []);

    const fetchWorkingDaysData = async () => {
        try {
            const formattedData = await viewWorkingDays();

            if (!formattedData || !formattedData.length) {
                throw new Error('Failed to fetch data.');
            }

            const companyWorkingDays = formattedData[0].company_workingday;

            if (companyWorkingDays && companyWorkingDays.length > 0) {
                const lastWorkingDay = companyWorkingDays[companyWorkingDays.length - 1];
                setMasterId(formattedData[0]._id);

                const workingdaysArray = [{
                    _id: lastWorkingDay._id,
                    monthly_allow_leave: lastWorkingDay.monthly_allow_leave || 'N/A',
                }];

                setWorkingDay(workingdaysArray);
            } else {
                throw new Error('No working days data found.');
            }
        } catch (error) {
            console.error('Error fetching data:', error);
        } finally {
            setLoading(false);
        }
    };

    const [leaveTypes, setLeaveTypes] = useState([]);

    useEffect(() => {
        const debouncedFetchLeaveTypes = _debounce(fetchLeaveTypes, 100);

        debouncedFetchLeaveTypes();

        return () => {
            debouncedFetchLeaveTypes.cancel();
        };
    }, []);

    const fetchLeaveTypes = async () => {
        try {
            setLoading(true);
            const formattedData = await viewLeave();

            if (!formattedData || !formattedData.length) {
                throw new Error('Failed to fetch data.');
            }

            const companyLeave = formattedData[0].company_leave;
            setMasterId(formattedData[0]._id);
            const leaveArray = Object.values(companyLeave).map(item => ({
                _id: item._id,
                leave_type: item.leave_type || 'N/A',
            }));
            setLeaveTypes(leaveArray);
        } catch (error) {
            console.error('Error fetching data:', error);
        } finally {
            setLoading(false);
        }
    };

    const navigate = useNavigate();
    const [state, setState] = useState({
        leave_type: "",
        from_date: "",
        to_date: "",
        total_days: "",
        remaining_leaves: "",
        leave_reason: "",
        month: '',
        year: '',
        emailID: '',
    });

    const handleChange = (e) => {
        const { name, value } = e.target;

        setState(prevState => ({
            ...prevState,
            [name]: value,
            errors: { ...prevState.errors, [name]: "" }
        }));

        if (name == 'from_date' || name == 'to_date') {
            console.log(value);

            calculateTotalDaysAndRemainingLeaves(
                name === 'from_date' ? value : state.from_date,
                name === 'to_date' ? value : state.to_date
            );
        }

        if (name == 'from_date') {
            // Convert value to a Date object
            const dateValue = new Date(value); // value is expected to be in 'YYYY-MM-DD' format

            // Get the year and month
            const year = dateValue.getFullYear();
            const month = dateValue.getMonth() + 1; // Months are 0-indexed

            setState(prevState => ({
                ...prevState,
                month: month,
                year: year,
                errors: { ...prevState.errors, [name]: "" }
            }));
        }


    };

    const calculateTotalDaysAndRemainingLeaves = (fromDate, toDate) => {
        const from = new Date(fromDate);
        const to = new Date(toDate);

        if (!isNaN(from.getTime()) && !isNaN(to.getTime()) && from <= to) {
            const timeDifference = to.getTime() - from.getTime();
            const totalDays = Math.ceil(timeDifference / (1000 * 3600 * 24)) + 1;

            const monthlyAllowLeave = workingdays[0]?.monthly_allow_leave || 0;
            const remainingLeaves = Math.max(monthlyAllowLeave - totalDays, 0);

            setState(prevState => ({
                ...prevState,
                total_days: totalDays,
                remaining_leaves: remainingLeaves,
            }));
        } else {
            setState(prevState => ({
                ...prevState,
                total_days: '',
                remaining_leaves: '',
            }));
        }
    };

    const handleLeaveChange = (e) => {
        const { name, value } = e.target;

        setState(prevState => ({
            ...prevState,
            [name]: value,
            errors: { ...prevState.errors, [name]: "" }
        }));
    };

    const validateForm = (state) => {
        let errors = {};

        if (!state.leave_type) errors.leave_type = "Leave type is required";
        if (!state.from_date) errors.from_date = "From date is required";
        if (!state.to_date) errors.to_date = "To date is required";
        if (state.from_date && state.to_date && new Date(state.from_date) > new Date(state.to_date)) {
            errors.to_date = "To date cannot be before from date";
        }
        if (!state.emailID || !/^\S+@\S+\.\S+$/.test(state.emailID)) {
            errors.emailID = "Valid HR email is required";
        }
        if (!state.leave_reason) errors.leave_reason = "Leave reason is required";

        return errors;
    };

    // const handleSubmit = async (e) => {
    //     e.preventDefault();

    //     const formErrors = validateForm(state);

    //     // Set errors if validation fails
    //     if (Object.keys(formErrors).length > 0) {
    //         setState((prevState) => ({ ...prevState, errors: formErrors }));
    //         return;
    //     }

    //     try {
    //         const response = await addEmployeeLeave(id, state);
    //         const data = JSON.parse(response);

    //         if (response) {
    //             toast.success("Leave applied successfully", {
    //                 position: "top-center",
    //                 autoClose: 3000,
    //             });

    //             setState({
    //                 leave_type: "",
    //                 from_date: "",
    //                 to_date: "",
    //                 total_days: "",
    //                 remaining_leaves: "",
    //                 leave_reason: "",
    //                 emailID: "",
    //                 month: '',
    //                 year: '',
    //             });
    //             navigate('/employee/addleave');
    //         } else {
    //             toast.error("Failed to apply for leave", {
    //                 position: "top-center",
    //                 autoClose: 1000,
    //             });
    //         }
    //     } catch (error) {
    //         toast.error("Failed to apply for leave", {
    //             position: "top-center",
    //             autoClose: 1000,
    //         });
    //     } finally {
    //     }
    // };

    const handleSubmit = async (e) => {
        e.preventDefault();
        const formErrors = validateForm(state);

        if (Object.keys(formErrors).length > 0) {
            setState((prevState) => ({ ...prevState, errors: formErrors }));
            return;
        }

        setState((prevState) => ({ ...prevState, errors: {} }));

        try {
            setLoading(true);
            const response = await addEmployeeLeave(id, state);
            if (response) {
                toast.success("Leave applied successfully", {
                    position: "top-center",
                    autoClose: 3000,
                });

                setState({
                    leave_type: "",
                    from_date: "",
                    to_date: "",
                    total_days: "",
                    remaining_leaves: "",
                    leave_reason: "",
                    emailID: "",
                    month: '',
                    year: '',
                    errors: {},
                });
                navigate('/employee/dashboard');
                setLoading(false);
            } else {
                toast.error("Failed to apply for leave", {
                    position: "top-center",
                    autoClose: 1000,
                });
                setLoading(false);
            }
        } catch (error) {
            toast.error("Failed to apply for leave", {
                position: "top-center",
                autoClose: 1000,
            });
            setLoading(false);
        }
    };


    return (
        <React.Fragment>
            <HeaderMobile />
            <Header />
            <div className="main p-4 p-lg-5 mt-5">
                {/* <h4>Add Leave</h4>
                <div className="breadcrumb-warning d-flex justify-content-between ot-card">
                    <div>
                        <ol className="breadcrumb ot-breadcrumb ot-breadcrumb-basic">
                            <li className="breadcrumb-item">
                                <Link to="/employee/dashboard">Dashboard</Link>
                            </li>
                            <li className="breadcrumb-item active">Add Leave</li>
                        </ol>
                    </div>
                </div> */}
                <button type="button" onClick={() => {
                    navigate('/employee/empleavestatus')
                }} className="btn btn-primary">
                    View Status
                </button>
                <div className="row" style={{
                    boxShadow: "0 4px 8px 0 rgba(0,0,0,0.2)",
                    borderRadius: "10px",
                    overflow: "hidden",
                    border: "1px solid #b6babd"
                }}>
                    <div className="col-md-12 formcontrols">
                        <div className="bg-white p-4 rounded" style={{ height: "100%" }}>
                            <form onSubmit={handleSubmit}>
                                <div className="row">
                                    <div className="col-md-4">
                                        <div className="mb-3">
                                            <label htmlFor="leave_type" className="form-label">
                                                Type
                                            </label>
                                            <select
                                                // className={`form-select ${state.errors && state.errors.leave_type && "is-invalid"}`}
                                                className={`form-select ${state.errors?.leave_type ? "is-invalid" : ""}`}
                                                id="leave_type"
                                                name="leave_type"
                                                value={state.leave_type || ''}
                                                onChange={handleLeaveChange}
                                            >
                                                <option value="">Select Leave Type</option>
                                                {leaveTypes.map((leaveType) => (
                                                    <option key={leaveType._id} value={leaveType.leave_type}>
                                                        {leaveType.leave_type}
                                                    </option>
                                                ))}
                                            </select>
                                            {state.errors && state.errors.leave_type && (
                                                <div className="invalid-feedback">{state.errors.leave_type}</div>
                                            )}
                                        </div>
                                    </div>
                                    <div className="col-md-4">
                                        <div className="mb-3">
                                            <label htmlFor="from_date" className="form-label">
                                                From
                                            </label>
                                            <input
                                                type="date"
                                                // className="form-control"
                                                className={`form-control ${state.errors?.from_date && "is-invalid"}`}
                                                id="from_date"
                                                name="from_date"
                                                value={state.from_date}
                                                onChange={handleChange}
                                                min={currentDate}
                                            />
                                            {state.errors && state.errors?.from_date && (
                                                <div className="invalid-feedback">{state.errors.from_date}</div>
                                            )}
                                        </div>
                                    </div>
                                    <div className="col-md-4">
                                        <div className="mb-3">
                                            <label htmlFor="to_date" className="form-label">
                                                To
                                            </label>
                                            <input
                                                type="date"
                                                // className="form-control"
                                                className={`form-control ${state.errors?.to_date && "is-invalid"}`}
                                                id="to_date"
                                                name="to_date"
                                                value={state.to_date}
                                                onChange={handleChange}
                                                min={currentDate}
                                            />
                                            {state.errors && state.errors?.to_date && (
                                                <div className="invalid-feedback">{state.errors.to_date}</div>
                                            )}
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-4">
                                        <div className="mb-3">
                                            <label htmlFor="total_days" className="form-label">
                                                Total Days
                                            </label>
                                            <input
                                                type="text"
                                                className="form-control"
                                                id="total_days"
                                                name="total_days"
                                                value={state.total_days !== '' ? state.total_days : ''}
                                                disabled
                                            />
                                        </div>
                                    </div>
                                    <div className="col-md-4">
                                        <div className="mb-3">
                                            <label htmlFor="emailID" className="form-label">
                                                HR Email
                                            </label>
                                            <input
                                                type="text"
                                                className={`form-control ${state.errors?.emailID && "is-invalid"}`}
                                                id="emailID"
                                                name="emailID"
                                                value={state.emailID}
                                                onChange={handleChange}
                                                placeholder=""
                                            />
                                            {state.errors?.emailID && (
                                                <div className="invalid-feedback">{state.errors.emailID}</div>
                                            )}
                                        </div>
                                    </div>

                                    {/* <div className="col-md-4">
                                        <div className="mb-3">
                                            <label htmlFor="remaining_leaves" className="form-label">
                                                Remaining Leaves
                                            </label>
                                            <input
                                                type="text"
                                                className={`form-control ${state.errors && state.errors.remaining_leaves && "is-invalid"}`}
                                                id="remaining_leaves"
                                                name="remaining_leaves"
                                                value={state.remaining_leaves}
                                                onChange={handleChange}
                                                placeholder="Remaining leaves"
                                                disabled
                                            />
                                            {state.errors && state.errors.remaining_leaves && (
                                                <div className="invalid-feedback">{state.errors.remaining_leaves}</div>
                                            )}
                                        </div>
                                    </div> */}
                                    <div className="col-md-12">
                                        <div className="mb-3">
                                            <label htmlFor="leave_reason" className="form-label">
                                                Reason
                                            </label>
                                            <textarea
                                                className={`form-control ${state.errors && state.errors.leave_reason && "is-invalid"}`}
                                                id="leave_reason"
                                                name="leave_reason"
                                                value={state.leave_reason}
                                                onChange={handleChange}
                                                style={{ height: "100px", resize: "vertical" }}
                                                placeholder="Enter leave reason"
                                            />
                                            {state.errors && state.errors.leave_reason && (
                                                <div className="invalid-feedback">{state.errors.leave_reason}</div>
                                            )}
                                        </div>
                                    </div>
                                </div>
                                <button type="submit" className="btn btn-primary" disabled={loading}>
                                    {loading ? 'Submitting...' : 'Add'}
                                </button>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
};

export default AddEmpLeave;

