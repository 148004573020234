import React, { useEffect, useState } from "react";
import { Navigate } from "react-router-dom";

const RequireAuth = ({ children, requiredRole }) => {

  const userType = sessionStorage.getItem('userType');
  const isAuthorized = userType == requiredRole;

  return isAuthorized ? children : <Navigate to="/*" replace />;
};

export default RequireAuth;
// import React, { useEffect, useState } from "react";
// import { Navigate } from "react-router-dom";

// const RequireAuth = ({ children }) => {
//    var isAuthenticated = false;
//     const token =  sessionStorage.getItem('isLoggedIn');
//     if (token) {
//         isAuthenticated = true;
//     } else {
//         isAuthenticated = false;
//     }
//   return isAuthenticated ? children : <Navigate to="/signin" replace />;
// };

// export default RequireAuth;
