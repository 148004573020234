import React, { useEffect, useState } from 'react';
import { getViewJobLink, searchJobLink } from '../services/api/api';
import { Row, Col, Card, Button, Form, InputGroup, Modal } from "react-bootstrap";
import { Eye } from "react-bootstrap-icons";
import { Link, useNavigate, useParams } from 'react-router-dom';


export const JobListing = () => {

    const modalStyles = {
        overlay: {
            backgroundColor: 'rgba(0, 0, 0, 0.7)', // semi-transparent background
        },
        content: {
            top: '50%',
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            transform: 'translate(-50%, -50%)',
            padding: '20px',
            border: '1px solid #ccc',
            borderRadius: '8px',
        },
    };

    const [loading, setLoading] = useState(true);
    const [jobs, setJobs] = useState([]);
    const [DetailedJob, setDetailedJobs] = useState([]);
    const [searchTerm, setSearchTerm] = useState('');
    const [currentPage, setCurrentPage] = useState(1);
    const { token } = useParams();
    const [totalPages, setTotalPages] = useState(0);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const navigate = useNavigate();

    const handleCompanyNameClick = (jobs, index) => {
        let singleJobDetail = DetailedJob[index]
        console.log(singleJobDetail);

        // setIsModalOpen(true);
        navigate('/viewJobDetails', { state: { singleJobDetail, token } })
    };

    const handleCloseModal = () => {
        setIsModalOpen(false);
    };

    useEffect(() => {
        if (!searchTerm) {
            fetchJobs();
        }
        else {
            searchJobs();
        }
    }, [currentPage]);


    const fetchJobs = async () => {
        setLoading(true);
        try {
            const response = await getViewJobLink(token, currentPage);
            console.log(response);

            setTotalPages(response.totalPages)
            const formattedJobs = response.data.map(item => {
                const insertedAt = new Date(item.company_job.inserted_at * 1000);
                const today = new Date();
                const yesterday = new Date();
                yesterday.setDate(today.getDate() - 1);
                let displayDate;

                if (insertedAt.toDateString() === today.toDateString()) {
                    displayDate = "Today";
                } else if (insertedAt.toDateString() === yesterday.toDateString()) {
                    displayDate = "Yesterday";
                } else {
                    displayDate = insertedAt.toLocaleDateString();
                }

                return {
                    id: item.company_job._id,
                    jobTitle: item.company_job.jobTitle,
                    jobType: item.company_job.jobType,
                    companyName: item.company_job.companyName,
                    numberOfOpening: item.company_job.numberOfOpening,
                    payTotal: item.company_job.payTotal,
                    country: item.company_job.country,
                    keyResponsibilities: item.company_job.keyResponsibilities,
                    location: `${item.company_job.city}, ${item.company_job.state}, ${item.company_job.country}`,
                    insertedAt: displayDate,
                };
            });

            setJobs(formattedJobs);
            setDetailedJobs(response.data);
        } catch (error) {
            console.error('Error fetching jobs:', error);
        } finally {
            setLoading(false);
        }
    };

    const searchJobs = async (e) => {
        let searchValue;

        if (e && e.target) {
            searchValue = e.target.value;
            setSearchTerm(searchValue);
        } else {

            searchValue = searchTerm;
        }


        try {
            const response = await searchJobLink(token, searchValue, currentPage);
            console.log(response);
            setTotalPages(response.totalPages)

            let formattedJobs;
            if (!response.message) {
                setTotalPages(response.totalPages);
                formattedJobs = response.data.map(item => {
                    const insertedAt = new Date(item.company_job.inserted_at * 1000);
                    const today = new Date();
                    const yesterday = new Date();
                    yesterday.setDate(today.getDate() - 1);
                    let displayDate;

                    if (insertedAt.toDateString() === today.toDateString()) {
                        displayDate = "Today";
                    } else if (insertedAt.toDateString() === yesterday.toDateString()) {
                        displayDate = "Yesterday";
                    } else {
                        displayDate = insertedAt.toLocaleDateString();
                    }

                    return {
                        id: item.company_job._id,
                        jobTitle: item.company_job.jobTitle,
                        jobType: item.company_job.jobType,
                        companyName: item.company_job.companyName,
                        numberOfOpening: item.company_job.numberOfOpening,
                        payTotal: item.company_job.payTotal,
                        country: item.company_job.country,
                        keyResponsibilities: item.company_job.keyResponsibilities,
                        location: `${item.company_job.city}, ${item.company_job.state}, ${item.company_job.country}`,
                        insertedAt: displayDate,
                    };
                });
            }
            else {
                formattedJobs = []
            }

            console.log(formattedJobs);

            setJobs(formattedJobs);
            setDetailedJobs(response.data);
        } catch (error) {
            console.error('Error fetching jobs:', error);
        } finally {
        }
    };

    const paginate = (pageNumber) => {
        setCurrentPage(pageNumber);
        // fetchJobs(); // Fetch jobs for the selected page
    };

    return (
        <div className="JobListingmain p-4 p-lg-5 mt-5 d-flex justify-content-center">
            {loading ? (
                <div className="loader-container">
                    <div className="loader"></div>
                </div>
            ) : (
                <div className="w-100">
                    <Form className="mb-4 text-center">
                        <InputGroup className="mx-auto" style={{ width: '50%' }}>
                            <Form.Control
                                type="text"
                                placeholder="Search for jobs..."
                                value={searchTerm}
                                onChange={searchJobs}
                                className="search-input"
                            />
                        </InputGroup>
                    </Form>

                    <Row xs={1} className="g-4">
                        {jobs.length > 0 ? (
                            jobs.map((job, index) => (
                                <Col key={job.id} className="mb-3">
                                    <Card className="job-card" style={{ width: '100%' }}>
                                        <Card.Body>
                                            <Row>
                                                <Col md={9}>
                                                    <div className="d-flex align-items-center mb-2">
                                                        <strong
                                                            style={{
                                                                color: '#007bff',
                                                                cursor: 'pointer',
                                                                textDecoration: isModalOpen ? 'underline' : 'none', // underline when popup is shown
                                                            }}
                                                            onMouseEnter={(e) => e.currentTarget.style.textDecoration = 'underline'} // add underline on hover
                                                            onMouseLeave={(e) => e.currentTarget.style.textDecoration = 'none'} // remove underline when not hovered
                                                            onClick={() => {
                                                                {
                                                                    handleCompanyNameClick(jobs, index)
                                                                }
                                                            }}
                                                        >
                                                            {job.companyName}
                                                        </strong>
                                                        <span className='ms-2'>| {job.jobTitle}</span>
                                                    </div>
                                                    <div className="d-flex align-items-center">
                                                        <div className="d-flex align-items-center me-3">
                                                            <strong>
                                                                <i className="ri-map-pin-2-line" data-bs-toggle="tooltip" title="" data-bs-original-title="ri-map-pin-2-line" aria-label="ri-map-pin-2-line"></i>
                                                            </strong>
                                                            <span className="ms-1">{job.location} |</span>
                                                        </div>
                                                        <div className="d-flex align-items-center">
                                                            <strong>
                                                                <i className="ri-time-line" data-bs-toggle="tooltip" title="" aria-label="ri-time-line"></i>
                                                            </strong>
                                                            <span className="ms-1">{job.insertedAt} | </span>
                                                        </div>
                                                        <div className="d-flex align-items-center me-3">
                                                            <strong></strong>
                                                            <span className="ms-1">{job.jobType}</span>
                                                        </div>
                                                    </div>
                                                </Col>
                                                <Col md={2} className="d-flex align-items-center">
                                                    <Link to={`/jobCandidateForm/${token}`}>
                                                        <Button className="mb-3 custom-submit-btn">
                                                            <span style={{ color: "white" }}>
                                                                Apply Now
                                                            </span>
                                                        </Button>
                                                    </Link>
                                                </Col>
                                            </Row>
                                        </Card.Body>
                                    </Card>
                                </Col>
                            ))
                        ) : (
                            <Col className="text-center">
                                <p>No matching jobs found.</p>
                            </Col>
                        )}
                    </Row>


                    {/* Pagination Controls */}
                    <div className="pagination mt-4 d-flex justify-content-center">
                        {Array.from({ length: totalPages }, (_, index) => (
                            <Button
                                key={index + 1}
                                onClick={() => paginate(index + 1)}
                                className={`mx-1 ${currentPage == index + 1 ? 'active' : ''}`}
                            >
                                {index + 1}
                            </Button>
                        ))}
                    </div>
                </div>
            )}

            <Modal show={isModalOpen} onHide={handleCloseModal}>
                <Modal.Header closeButton>
                    <Modal.Title>Confirm Deletion</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p>Are you sure you want to delete this employee?</p>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleCloseModal}>
                        Cancel
                    </Button>
                    <Button variant="danger" onClick={handleCloseModal}>
                        Delete
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>
    );
};
