// import React, { useEffect, useState } from 'react';
// import Header from '../layouts/Header';
// import { Button, Card } from 'react-bootstrap';
// import { Grid } from 'gridjs-react';
// import { Link } from "react-router-dom";

// const JobList = () => {
//     const initialPerPage = 10; 
//     const [jobData, setJobData] = useState([
//         ['1', 'Software Engineer', 'Active'],
//         ['2', 'Data Analyst', 'Inactive'],
//         ['3', 'Product Manager', 'Active'],
//         ['4', 'UI/UX Designer', 'Active'],
//         ['5', 'Marketing Specialist', 'Active'],
//         ['6', 'Network Administrator', 'Inactive'],
//         ['7', 'Financial Analyst', 'Active'],
//         ['8', 'Content Writer', 'Active'],
//         ['9', 'HR Manager', 'Active'],
//         ['10', 'Sales Representative', 'Active'],
//         ['11', 'Business Analyst', 'Inactive'],
//         ['12', 'Quality Assurance Tester', 'Active'],
//         ['13', 'Front-end Developer', 'Active'],
//         ['14', 'Graphic Designer', 'Active'],
//         ['15', 'Project Manager', 'Inactive'],
//         ['16', 'Database Administrator', 'Active'],
//         ['17', 'Customer Support Specialist', 'Active'],
//         ['18', 'Accountant', 'Active'],
//         ['19', 'Legal Counsel', 'Active'],
//         ['20', 'Logistics Coordinator', 'Active'],
//         ['21', 'Software Engineer', 'Inactive'],
//         ['22', 'Data Analyst', 'Active'],
//         ['23', 'Product Manager', 'Active'],
//         ['24', 'UI/UX Designer', 'Active'],
//         ['25', 'Marketing Specialist', 'Inactive'],
//         ['26', 'Network Administrator', 'Active'],
//         ['27', 'Financial Analyst', 'Active'],
//         ['28', 'Content Writer', 'Active'],
//         ['29', 'HR Manager', 'Inactive'],
//         ['30', 'Sales Representative', 'Active'],
//         ['31', 'Business Analyst', 'Active'],
//         ['32', 'Quality Assurance Tester', 'Active'],
//         ['33', 'Front-end Developer', 'Active'],
//         ['34', 'Graphic Designer', 'Active'],
//         ['35', 'Project Manager', 'Inactive'],
//         ['36', 'Database Administrator', 'Active'],
//         ['37', 'Customer Support Specialist', 'Active'],
//         ['38', 'Accountant', 'Active'],
//         ['39', 'Legal Counsel', 'Active'],
//         ['40', 'Logistics Coordinator', 'Active'],
//         ['41', 'Software Engineer', 'Inactive'],
//         ['42', 'Data Analyst', 'Active'],
//         ['43', 'Product Manager', 'Active'],
//         ['44', 'UI/UX Designer', 'Active'],
//         ['45', 'Marketing Specialist', 'Active'],
//         ['46', 'Network Administrator', 'Inactive'],
//         ['47', 'Financial Analyst', 'Active'],
//         ['48', 'Content Writer', 'Active'],
//         ['49', 'HR Manager', 'Active'],
//         ['50', 'Sales Representative', 'Active'],
//     ]);

//     const [searchQuery, setSearchQuery] = useState('');
//     const [perPage, setPerPage] = useState(initialPerPage);

//     useEffect(() => {
//         // Fetch job data from an API or source and update the jobData state.
//         // Replace the dummy data with actual data fetching logic.
//     }, []);

//     const handleSearch = (query) => {
//         setSearchQuery(query);
//     };

//     const handleEntriesChange = (event) => {
//         setPerPage(event.target.value);
//     };

//     const CustomRow = ({ row }) => {
//         // Your custom row rendering logic here...
//     };

//     return (
//         <React.Fragment>
//             <Header />
//             <div className="main p-4 p-lg-5 mt-5">
//                 <Card className="popup-card">
//                     <Card.Body>
//                         <h4>Job List</h4>

//                         <div className="breadcrumb-warning d-flex justify-content-between ot-card">
//                             <div>
//                                 <ol className="breadcrumb ot-breadcrumb ot-breadcrumb-basic">
//                                     <li className="breadcrumb-item ">
//                                         <Link to="/dashboard/helpdesk">Dashboard</Link>
//                                     </li>
//                                     <li className="breadcrumb-item active">
//                                         <Link to="/pages/joblist">Branch</Link>
//                                     </li>
//                                     <li className="breadcrumb-item active">Job List</li>
//                                 </ol>
//                             </div>
//                             <nav aria-label="breadcrumb">
//                                 <Button variant="primary" className="mb-3">
//                                     <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-plus-lg" viewBox="0 0 16 16">
//                                         <path fillRule="evenodd" d="M8 2a.5.5 0 0 1 .5.5v5h5a.5.5 0 0 1 0 1h-5v5a.5.5 0 0 1-1 0v-5h-5a.5.5 0 0 1 0-1h5v-5A.5.5 0 0 1 8 2Z" />
//                                     </svg>Create
//                                 </Button>
//                             </nav>
//                         </div>



//                         <div className="breadcrumb-warning d-flex justify-content-between ot-card">
//                             <div>
//                                 <ol className="breadcrumb ot-breadcrumb ot-breadcrumb-basic">
//                                     <li className="breadcrumb-item">
//                                         <label className="mb-0 d-flex align-items-center">
//                                             <span className="mr-2">Show</span>
//                                             <select
//                                                 className="form-select"
//                                                 value={perPage}
//                                                 onChange={handleEntriesChange}
//                                             >
//                                                 <option value="10">10</option>
//                                                 <option value="25">25</option>
//                                                 <option value="50">50</option>
//                                                 <option value="100">100</option>
//                                                 <option value="500">500</option>
//                                                 <option value="1000">1000</option>
//                                             </select>
//                                             <span className="ml-2">Entries</span>
//                                         </label>
//                                     </li>

//                                     <li className="mx-2">
//                         <Button
//                           variant=""
//                           className="mb-3 btn-1"
//                         >
//                           <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-person-hearts" viewBox="0 0 16 16">
//                             <path fill-rule="evenodd" d="M11.5 1.246c.832-.855 2.913.642 0 2.566-2.913-1.924-.832-3.421 0-2.566ZM9 5a3 3 0 1 1-6 0 3 3 0 0 1 6 0Zm-9 8c0 1 1 1 1 1h10s1 0 1-1-1-4-6-4-6 3-6 4Zm13.5-8.09c1.387-1.425 4.855 1.07 0 4.277-4.854-3.207-1.387-5.702 0-4.276ZM15 2.165c.555-.57 1.942.428 0 1.711-1.942-1.283-.555-2.281 0-1.71Z" />
//                           </svg>
//                           Joblists
//                         </Button>

//                       </li>
//                       <li className="mx-2">
//                         <Button variant="" className="mb-3 btn-1">


//                           <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-person-check-fill" viewBox="0 0 16 16">
//                             <path fill-rule="evenodd" d="M15.854 5.146a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708 0l-1.5-1.5a.5.5 0 0 1 .708-.708L12.5 7.793l2.646-2.647a.5.5 0 0 1 .708 0z" />
//                             <path d="M1 14s-1 0-1-1 1-4 6-4 6 3 6 4-1 1-1 1H1zm5-6a3 3 0 1 0 0-6 3 3 0 0 0 0 6z" />
//                           </svg>
//                           Status


//                         </Button>
//                       </li>
//                       <li className="mx-2"> {/* Add mx-2 for spacing */}
//                         <Button variant="" className="mb-3 btn-1">
//                             <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-three-dots" viewBox="0 0 16 16">
//                               <path d="M3 9.5a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3zm5 0a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3zm5 0a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3z" />
//                             </svg>
//                             Actions
//                         </Button>
//                       </li>


//                                 </ol>
//                             </div>
//                             <nav aria-label="breadcrumb">
//                     <Button variant="primary" className="mb-3">


//                       <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-pencil-square" viewBox="0 0 16 16">
//                         <path d="M15.502 1.94a.5.5 0 0 1 0 .706L14.459 3.69l-2-2L13.502.646a.5.5 0 0 1 .707 0l1.293 1.293zm-1.75 2.456-2-2L4.939 9.21a.5.5 0 0 0-.121.196l-.805 2.414a.25.25 0 0 0 .316.316l2.414-.805a.5.5 0 0 0 .196-.12l6.813-6.814z" />
//                         <path fill-rule="evenodd" d="M1 13.5A1.5 1.5 0 0 0 2.5 15h11a1.5 1.5 0 0 0 1.5-1.5v-6a.5.5 0 0 0-1 0v6a.5.5 0 0 1-.5.5h-11a.5.5 0 0 1-.5-.5v-11a.5.5 0 0 1 .5-.5H9a.5.5 0 0 0 0-1H2.5A1.5 1.5 0 0 0 1 2.5v11z" />
//                       </svg>
//                       Export


//                     </Button>
//                   </nav>
//                         </div>
                      
//                         <Grid
//                             data={jobData}
//                             columns={['ID', 'Job Title', 'Status', 'Action']}
//                             search={{ enabled: true, handler: handleSearch, query: searchQuery }}
//                             pagination={{ enabled: true, limit: perPage }}
//                             sort={true}
//                             className={{
//                                 table: 'table table-bordered mb-0',
//                             }}
//                             rowRenderer={(row) => <CustomRow row={row} />}
//                         />
//                     </Card.Body>
//                 </Card>
//             </div>
//         </React.Fragment>
//     );
// };

// export default JobList;





import React, { useState } from "react";
import { Card, Col, Row, Button, FormControl } from "react-bootstrap";
import Footer from "../layouts/Footer";
import Header from "../layouts/Header";
import HeaderMobile from "../layouts/HeaderMobile";
import Pagination from "react-bootstrap/Pagination";
import '../styles/globals.css';
import { Link } from "react-router-dom";
import Form from 'react-bootstrap/Form';
import Modal from 'react-bootstrap/Modal';

const joblists = [
  {
    id: 1,
    name: "John Doe",
    department: "HR",
    position: "Manager",
    email: "john.doe@example.com",
    joiningDate: "2022-01-15",
    status: "active",
  },
  {
    id: 2,
    name: "Jane Smith",
    department: "HR",
    position: "Assistant",
    email: "jane.smith@example.com",
    joiningDate: "2022-02-20",
    status: "active",
  },
  {
    id: 3,
    name: "Alice Johnson",
    department: "Finance",
    position: "Accountant",
    email: "alice.johnson@example.com",
    joiningDate: "2021-11-10",
    status: "active",
  },
  {
    id: 4,
    name: "Bob Brown",
    department: "IT",
    position: "Developer",
    email: "bob.brown@example.com",
    joiningDate: "2021-09-05",
    status: "inactive",
  },
  {
    id: 5,
    name: "Eva Williams",
    department: "Marketing",
    position: "Marketing Manager",
    email: "eva.williams@example.com",
    joiningDate: "2022-03-25",
    status: "active",
  },
  // Add more joblists here
  {
    id: 16,
    name: "William Wilson",
    department: "Finance",
    position: "Financial Analyst",
    email: "william.wilson@example.com",
    joiningDate: "2022-06-20",
    status: "active",
  },
  {
    id: 17,
    name: "Victoria Taylor",
    department: "Marketing",
    position: "Marketing Manager",
    email: "victoria.taylor@example.com",
    joiningDate: "2021-08-15",
    status: "active",
  },
  {
    id: 18,
    name: "David Johnson",
    department: "IT",
    position: "Developer",
    email: "david.johnson@example.com",
    joiningDate: "2022-02-10",
    status: "active",
  },
  {
    id: 19,
    name: "Sophia Brown",
    department: "HR",
    position: "HR Manager",
    email: "sophia.brown@example.com",
    joiningDate: "2021-11-25",
    status: "active",
  },
  {
    id: 20,
    name: "Lucas Martinez",
    department: "Sales",
    position: "Sales Associate",
    email: "lucas.martinez@example.com",
    joiningDate: "2022-05-10",
    status: "active",
  },
  {
    id: 21,
    name: "Mia Davis",
    department: "Marketing",
    position: "Marketing Coordinator",
    email: "mia.davis@example.com",
    joiningDate: "2022-01-15",
    status: "active",
  },
  {
    id: 22,
    name: "Ethan Smith",
    department: "Finance",
    position: "Accountant",
    email: "ethan.smith@example.com",
    joiningDate: "2021-07-10",
    status: "active",
  },
  {
    id: 23,
    name: "Amelia White",
    department: "IT",
    position: "Software Engineer",
    email: "amelia.white@example.com",
    joiningDate: "2022-03-18",
    status: "active",
  },
  {
    id: 24,
    name: "Olivia Smith",
    department: "HR",
    position: "HR Assistant",
    email: "olivia.smith@example.com",
    joiningDate: "2022-02-20",
    status: "active",
  },
  {
    id: 25,
    name: "Benjamin Anderson",
    department: "Sales",
    position: "Sales Manager",
    email: "benjamin.anderson@example.com",
    joiningDate: "2022-02-20",
    status: "active",
  }
];

const Joblists = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const [searchTerm, setSearchTerm] = useState("");
  const [entriesPerPage, setEntriesPerPage] = useState(10);
  const joblistsPerPage = entriesPerPage;

  const indexOfLastJoblist = currentPage * joblistsPerPage;
  const indexOfFirstJoblist = indexOfLastJoblist - joblistsPerPage;

  const filteredJoblists = joblists.filter((joblist) =>
    joblist.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
    joblist.email.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const currentJoblists = filteredJoblists.slice(
    indexOfFirstJoblist,
    indexOfLastJoblist
  );

  const totalPageCount = Math.ceil(filteredJoblists.length / joblistsPerPage);

  const paginate = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const [show, setShow] = useState(false);
  const [isChecked, setIsChecked] = useState(true);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const toggleSwitch = () => {
    if (!isChecked) {
      handleShow();
    } else {
      setIsChecked(!isChecked);
    }
  };

  const handleConfirmation = () => {
    setIsChecked(!isChecked);
    handleClose();
  };

  const previousPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const nextPage = () => {
    if (currentPage < totalPageCount) {
      setCurrentPage(currentPage + 1);
    }
  };
  const rangeStart = (currentPage - 1) * joblistsPerPage + 1;
  const rangeEnd = Math.min(currentPage * joblistsPerPage, filteredJoblists.length);

  const handleEntriesPerPageChange = (e) => {
    setEntriesPerPage(parseInt(e.target.value, 10));
    setCurrentPage(1);
  };

  return (
    <React.Fragment>
      <HeaderMobile />
      <Header />
      <div className="main p-4 p-lg-5 mt-5">
        <Card className="popup-card">
          <Card.Body>
            <Row>
              <Col>
              <h4>Job List</h4>
                <div class="breadcrumb-warning d-flex justify-content-between ot-card"><div>
                  <ol class="breadcrumb ot-breadcrumb ot-breadcrumb-basic"><li class="breadcrumb-item ">
                    <Link to="/dashboard/helpdesk">Dashboard</Link>
                  </li><li class="breadcrumb-item active"><Link to="/pages/joblist">Joblists</Link>
                    </li>
                    {/* <li class="breadcrumb-item active">Lists</li> */}
                    </ol>


                </div><nav aria-label="breadcrumb ">

                    <Button variant="primary" className="mb-3">
                      <Link to="/pages/joblistform"><span style={{ color: "white" }}><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-plus-lg" viewBox="0 0 16 16">
                        <path fill-rule="evenodd" d="M8 2a.5.5 0 0 1 .5.5v5h5a.5.5 0 0 1 0 1h-5v5a.5.5 0 0 1-1 0v-5h-5a.5.5 0 0 1 0-1h5v-5A.5.5 0 0 1 8 2Z" />
                      </svg>Create</span></Link>
                    </Button>
                  </nav></div>

                  <Form.Control
              type="text"
              placeholder="Search by name or email"
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              className="mb-4"
            />
            <div className="breadcrumb-warning d-flex justify-content-between ot-card">
                  <div>
                    <ol className="breadcrumb ot-breadcrumb ot-breadcrumb-basic">
                      <li className="breadcrumb-item">
                        <label className="mb-0 d-flex align-items-center">
                          <span className="mr-2">Show</span>
                          <select
                            className="form-select"
                            value={entriesPerPage}
                            onChange={handleEntriesPerPageChange}
                          >
                            <option value="10">10</option>
                            <option value="25">25</option>
                            <option value="50">50</option>
                            <option value="100">100</option>
                            <option value="500">500</option>
                            <option value="1000">1000</option>
                          </select>
                          <span className="ml-2">Entries</span>
                        </label>
                      </li>
                      <li className="mx-2"> {/* Add mx-2 for spacing */}
                        <Button
                          variant=""
                          className="mb-3 btn-1"
                        >
                          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-person-hearts" viewBox="0 0 16 16">
                            <path fill-rule="evenodd" d="M11.5 1.246c.832-.855 2.913.642 0 2.566-2.913-1.924-.832-3.421 0-2.566ZM9 5a3 3 0 1 1-6 0 3 3 0 0 1 6 0Zm-9 8c0 1 1 1 1 1h10s1 0 1-1-1-4-6-4-6 3-6 4Zm13.5-8.09c1.387-1.425 4.855 1.07 0 4.277-4.854-3.207-1.387-5.702 0-4.276ZM15 2.165c.555-.57 1.942.428 0 1.711-1.942-1.283-.555-2.281 0-1.71Z" />
                          </svg>
                          Job Lists
                        </Button>

                      </li>
                      <li className="mx-2"> {/* Add mx-2 for spacing */}
                        <Button variant="" className="mb-3 btn-1">


                          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-person-check-fill" viewBox="0 0 16 16">
                            <path fill-rule="evenodd" d="M15.854 5.146a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708 0l-1.5-1.5a.5.5 0 0 1 .708-.708L12.5 7.793l2.646-2.647a.5.5 0 0 1 .708 0z" />
                            <path d="M1 14s-1 0-1-1 1-4 6-4 6 3 6 4-1 1-1 1H1zm5-6a3 3 0 1 0 0-6 3 3 0 0 0 0 6z" />
                          </svg>
                          Status


                        </Button>
                      </li>
                      <li className="mx-2"> {/* Add mx-2 for spacing */}
                        <Button variant="" className="mb-3 btn-1">
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-three-dots" viewBox="0 0 16 16">
                              <path d="M3 9.5a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3zm5 0a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3zm5 0a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3z" />
                            </svg>
                            Actions
                        </Button>
                      </li>

                    </ol>
                  </div>
                  <nav aria-label="breadcrumb">
                    <Button variant="primary" className="mb-3">


                      <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-pencil-square" viewBox="0 0 16 16">
                        <path d="M15.502 1.94a.5.5 0 0 1 0 .706L14.459 3.69l-2-2L13.502.646a.5.5 0 0 1 .707 0l1.293 1.293zm-1.75 2.456-2-2L4.939 9.21a.5.5 0 0 0-.121.196l-.805 2.414a.25.25 0 0 0 .316.316l2.414-.805a.5.5 0 0 0 .196-.12l6.813-6.814z" />
                        <path fill-rule="evenodd" d="M1 13.5A1.5 1.5 0 0 0 2.5 15h11a1.5 1.5 0 0 0 1.5-1.5v-6a.5.5 0 0 0-1 0v6a.5.5 0 0 1-.5.5h-11a.5.5 0 0 1-.5-.5v-11a.5.5 0 0 1 .5-.5H9a.5.5 0 0 0 0-1H2.5A1.5 1.5 0 0 0 1 2.5v11z" />
                      </svg>
                      Export


                    </Button>
                  </nav>
                </div>

              </Col>
              <Row>
          {currentJoblists.map((joblist) => (
            <Col key={joblist.id} xs={12} md={6} lg={4}>
              <Card className="mb-4">
                <Card.Body>
                  <Link to={`/joblist/${joblist.id}`}>
                    <Card.Title>{joblist.name}</Card.Title>
                  </Link>
                  <Card.Text>
                    <strong>Department:</strong> {joblist.department}
                  </Card.Text>
                  <Card.Text>
                    <strong>Position:</strong> {joblist.position}
                  </Card.Text>
                  <Card.Text>
                    <strong>Email:</strong> {joblist.email}
                  </Card.Text>
                  <Card.Text>
                    <strong>Status:</strong> {joblist.status}
                  </Card.Text>
                </Card.Body>


                




              </Card>
            </Col>


          ))}
        </Row>

     

        
         
        <Row>
        <div className="d-flex justify-content-between">
                
                    <p>
            Showing {rangeStart} to {rangeEnd} of {filteredJoblists.length} results
          </p>
          <Pagination>
                    <Pagination.Prev onClick={previousPage} />
                      {Array.from({ length: totalPageCount }, (_, index) => (
                        <Pagination.Item
                          key={index}
                          active={index + 1 === currentPage}
                          onClick={() => paginate(index + 1)}
                        >
                          {index + 1}
                        </Pagination.Item>
                      ))}
                      <Pagination.Next onClick={nextPage} />
                    </Pagination>
                  </div>
                  


          


        </Row>
            </Row>
          </Card.Body>
        </Card>
      
      
      </div>
      <Footer />
    </React.Fragment>
  );
};

export default Joblists;


