import React, { useState, useEffect, useRef } from 'react';
import Header from "../../layouts/Header";
import HeaderMobile from "../../layouts/HeaderMobile";
import { Card, Button } from "react-bootstrap";
import '../../styles/globals.css';
import $ from 'jquery';
import 'datatables.net';
import { Link } from "react-router-dom";

const Interviews = () => {
  const tableRef = useRef(null);

  const [activeButton, setActiveButton] = useState('open');

  const handleButtonClick = (button) => {
    setActiveButton(button);
  };

  useEffect(() => {
    if ($.fn.DataTable.isDataTable('#commonTable')) {
      tableRef.current = $('#commonTable').DataTable();
      tableRef.current.destroy();
    }
    if ($.fn.DataTable) {
      tableRef.current = $('#commonTable').DataTable({
        // data: [
        //   Sample data
        //   [1, 'John Doe', 'Software Developer', '23/10/2023', 'Active'],
        //   [2, 'Jack Deo', 'Frontend Developer', '23/10/2023', 'Active'],
        //   [3, 'Alice Johnson', 'HR Manager', '23/10/2023', 'Active'],
        //   [4, 'Bob Smith', 'Accountant', '23/10/2023', 'Inactive'],
        //   [5, 'Eva Brown', 'Web Developer', '23/10/2023', 'Active'],
        //   [6, 'Charlie Wilson', 'UI/UX Designer', '23/10/2023', 'Active'],
        //   [7, 'David Lee', 'Network Administrator', '23/10/2023', 'Inactive'],
        //   [8, 'Ella White', 'Network Engineer', '23/10/2023', 'Active'],
        //   [9, 'Frank Davis', 'Data Analyst', '23/10/2023', 'Active'],
        //   [10, 'Grace Thomas', 'Software Tester', '23/10/2023', 'Active'],
        //   // Add more interview data here
        // ],
        columns: [
          { title: 'Sr.No.' },
          { title: 'Candidate Name' },
          { title: 'Designation' },
          { title: 'Schedule Time' },
          { title: 'Status' },
          { title: 'Actions' }, // Add an Actions column if needed
        ],
        paging: true,
        ordering: true,
        searching: true,
        columnDefs: [
          {
            targets: -1,
            orderable: false,
            searchable: false,
            render: function (data, type, full, meta) {
              return createActionButton(full);
            },
          },
        ],
        responsive: true,
      });
    }

    const updatePaginationStyles = () => {
      const paginationElements = $('.paginate_button');
      paginationElements.each(function (index) {
        const $this = $(this);
        if ($this.hasClass('current')) {
          $this.css({
            border: '0px solid blue',
            padding: '3px 8px 3px 8px',
            borderRadius: '3px',
            color: 'white',
            fontWeight: 500,
            background: '#506fd9',
          });
        }
      });
    };

    tableRef.current.on('draw.dt', updatePaginationStyles);
    updatePaginationStyles();
  }, []);

  const createActionButton = (data) => {
    const editButton = `
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-eye-fill view" viewBox="0 0 16 16">
        <path d="M10.5 8a2.5 2.5 0 1 1-5 0 2.5 2.5 0 0 1 5 0z"/>
        <path d="M0 8s3-5.5 8-5.5S16 8 16 8s-3 5.5-8 5.5S0 8 0 8zm8 3.5a3.5 3.5 0 1 0 0-7 3.5 3.5 0 0 0 0 7z"/>
      </svg>`;
    const deleteButton = `<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-pencil-fill edit " viewBox="0 0 16 16">
        <path d="M12.854.146a.5.5 0 0 0-.707 0L10.5 1.793 14.207 5.5l1.647-1.646a.5.5 0 0 0 0-.708l-3-3zm.646 6.061L9.793 2.5 3.293 9H3.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.207l6.5-6.5zm-7.468 7.468A.5.5 0 0 1 6 13.5V13h-.5a.5.5 0 0 1-.5-.5V12h-.5a.5.5 0 0 1-.5-.5V11h-.5a.5.5 0 0 1-.5-.5V10h-.5a.499.499 0 0 1-.175-.032l-.179.178a.5.5 0 0 0-.11.168l-2 5a.5.5 0 0 0 .65.65l5-2a.5.5 0 0 0 .168-.11l.178-.178z"/>
      </svg>`;
    const viewButton = `<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-trash3-fill delete " viewBox="0 0 16 16">
        <path d="M11 1.5v1h3.5a.5.5 0 0 1 0 1h-.538l-.853 10.66A2 2 0 0 1 11.115 16h-6.23a2 2 0 0 1-1.994-1.84L2.038 3.5H1.5a.5.5 0 0 1 0-1H5v-1A1.5 1.5 0 0 1 6.5 0h3A1.5 1.5 0 0 1 11 1.5Zm-5 0v1h4v-1a.5.5 0 0 0-.5-.5h-3a.5.5 0 0 0-.5.5ZM4.5 5.029l.5 8.5a.5.5 0 1 0 .998-.06l-.5-8.5a.5.5 0 1 0-.998.06Zm6.53-.528a.5.5 0 0 0-.528.47l-.5 8.5a.5.5 0 0 0 .998.058l.5-8.5a.5.5 0 0 0-.47-.528ZM8 4.5a.5.5 0 0 0-.5.5v8.5a.5.5 0 0 0 1 0V5a.5.5 0 0 0-.5-.5Z"/>
      </svg>`;

    return ` ${editButton} <span style="margin-right: 8px;"></span> ${deleteButton} <span style="margin-right: 8px;"></span> ${viewButton} <span style="margin-right: 8px;"></span> `;
  };

  $('#commonTable tbody').on('click', 'button', function () {
    const data = tableRef.current.row($(this).parents('tr')).data();
    const action = $(this).data('action');

    switch (action) {
      case 'view':
        // Handle the view action here
        console.log(`View interview for ${data[0]}`);
        break;
      // Add more action cases as needed
      default:
        break;
    }
  });

  return (
    <React.Fragment>
      <HeaderMobile />
      <Header />
      <div className="main p-4 p-lg-5 mt-5">

      <div className="breadcrumb-warning d-flex justify-content-between ot-card">
          <div>
            <ol className="breadcrumb ot-breadcrumb ot-breadcrumb-basic">
              {/* <li className="mx-2">
                <h4>Candidate</h4>
              </li> */}
              <li class="breadcrumb-item ">
                    <Link to="/dashboard/helpdesk">Dashboard</Link>
                  </li>
                  <li class="breadcrumb-item active"><Link to="/pages/recruitment/interviews">Interviews</Link></li>
                  {/* <li class="breadcrumb-item active">Lists</li> */}
            </ol>
          </div>
         

          <nav aria-label="breadcrumb">
          <Button variant="primary" className="mb-3">
              <Link to="/pages/recruitment/interviewform">
                <span style={{ color: "white" }}>
                  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-plus-lg" viewBox="0 0 16 16">
                    <path fill-rule="evenodd" d="M8 2a.5.5 0 0 1 .5.5v5h5a.5.5 0 0 1 0 1h-5v5a.5.5 0 0 1-1 0v-5h-5a.5.5 0 0 1 0-1h5v-5A.5.5 0 0 1 8 2Z" />
                  </svg>
                  Schedule Interview
                </span>
              </Link>
            </Button>
          </nav>
        </div>
      

        <Card className="popup-card empHistory">
          <Card.Body>
          <div className="breadcrumb-warning d-flex justify-content-between ot-card">
          <div>
            <ol className="breadcrumb ot-breadcrumb ot-breadcrumb-basic">
              <li className="mx-1">
                <Button
                  variant=""
                  className={`mb-3 btn-1 ${activeButton === 'open' ? 'active' : ''}`}
                  onClick={() => handleButtonClick('open')}
                  style={{
                    backgroundColor: activeButton === 'open' ? '#506fd9' : 'white',
                    color: activeButton === 'open' ? 'white' : 'black',
                  }}
                >
                  Open Interviews(0)
                </Button>
              </li>
              <li className="mx-2">
                <Button
                  variant=""
                  className={`mb-3 btn-1 ${activeButton === 'closed' ? 'active' : ''}`}
                  onClick={() => handleButtonClick('closed')}
                  style={{
                    backgroundColor: activeButton === 'closed' ? '#506fd9' : 'white',
                    color: activeButton === 'closed' ? 'white' : 'black',
                  }}
                >
                  Closed Interviews(0)
                </Button>
              </li>
              <li className="mx-2">
                <Button
                  variant=""
                  className={`mb-3 btn-1 ${activeButton === 'actions' ? 'active' : ''}`}
                  onClick={() => handleButtonClick('actions')}
                  style={{
                    backgroundColor: activeButton === 'actions' ? '#506fd9' : 'white',
                    color: activeButton === 'actions' ? 'white' : 'black',
                  }}
                >
                  Interview Actions
                </Button>
              </li>
            </ol>
          </div>
          {/* <nav aria-label="breadcrumb">
            <Button variant="primary" className="mb-3">
              <Link to="/pages/recruitment/interviewform">
                <span style={{ color: "white" }}>
                  Schedule Interview
                </span>
              </Link>
            </Button>
          </nav> */}
        </div>
            <table id="commonTable" className="display table table-striped table-curved" width="100%" ref={tableRef}></table>
          </Card.Body>
        </Card>
      </div>
    </React.Fragment>
  );
}

export default Interviews;
