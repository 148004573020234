import React, { useEffect, useRef, useState } from "react";
import _debounce from 'lodash/debounce';
import { debounce } from 'lodash';
import { Button, Card, Col, Row, Nav, Table, ProgressBar, Form, Badge, ListGroup, Spinner, CardBody } from "react-bootstrap";
import Footer from "../layouts/Footer.js";
import { Link } from "react-router-dom";
import Avatar from "../components/Avatar.js";
import { Bar } from 'react-chartjs-2';
import ReactApexChart from "react-apexcharts";
import { viewAnnouncement, viewHoliday, viewAddEmployeeLeave, editEmployee, getemployeeId, viewEmployeeBreakCount, viewholidaypagination, remainLeavePagination, viewemployeepaggination } from "../services/api/api.js";
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import { getId, searchEmployeeBreakCount, singleViewEmployeeAttendance } from '../services/api/api';
import HolidayVillageIcon from '@mui/icons-material/HolidayVillage';



import img19 from "../assets/img/img19.jpg";
import img27 from "../assets/img/img27.jpg";
import img28 from "../assets/img/img28.jpg";
import img29 from "../assets/img/img29.jpg";
import img30 from "../assets/img/img30.jpg";
import img31 from "../assets/img/img31.jpg";
import img32 from "../assets/img/img32.jpg";
import img33 from "../assets/img/img33.jpg";
import img34 from "../assets/img/img34.jpg";
import img35 from "../assets/img/img35.jpg";
import img36 from "../assets/img/img36.jpg";
import img37 from "../assets/img/img37.jpg";
import img38 from "../assets/img/img38.jpg";
import img39 from "../assets/img/img39.jpg";
import img40 from "../assets/img/img40.jpg";
import img41 from "../assets/img/img41.jpg";
import img42 from "../assets/img/img42.jpg";

// Import images
import img6 from "../assets/img/img6.jpg";
import img7 from "../assets/img/img7.jpg";
import img8 from "../assets/img/img8.jpg";
import img9 from "../assets/img/img9.jpg";
import img10 from "../assets/img/img10.jpg";
import img11 from "../assets/img/img11.jpg";
import img12 from "../assets/img/img12.jpg";
import img15 from "../assets/img/img15.jpg";
import img16 from "../assets/img/img16.jpg";
import img17 from "../assets/img/img17.jpg";
import img18 from "../assets/img/img18.jpg";
import img13 from "../assets/img/img13.jpg";
import img14 from "../assets/img/img14.jpg";




import Header from "../layouts/Header.js";

// Import clock component
import Clock from "../clock.js";
import { useNavigate } from "react-router-dom";
// import { DateCalendarServerRequest } from "../pages/attendance/calender.jsx";
import SingleAttendanceView from "../pages/attendance/calender.jsx";
import EmployeeBreakhistory from "../pages/employee/break/breakhistory.js";
import EmployeeBreakstatic from "../pages/employee/break/breakstatics.js";
import EmployeeBreakCalendar from "../pages/breakemp/empcalender.js";
import DashbaordBreakCalendar from "../pages/breakemp/dashbreakcalender.js";
// import Upcomingbirthday from "./upcomingbirthday.jsx";

export default function EmployeeDashboard() {

  const [holidayData, setHolidayData] = useState([]);
  const [masterId, setMasterId] = useState('');
  const [loading, setLoading] = useState(true);
  const [announcements, setAnnouncements] = useState([]);
  const [addEmployeeDataData, setAddEmployeeDataData] = useState([]);
  const [remainingLeaves, setRemainingLeaves] = useState(0);
  const [birthdayEmployee, setBirthdayEmployees] = useState([]);
  const [todayDate, setTodayDate] = useState('');

  const [events, setEvents] = useState([]);

  const [searchParams, setSearchParams] = useState({ year: new Date().getFullYear(), month: new Date().getMonth() + 1 });
  const calendarRef = useRef(null);
  // const [searchParams, setSearchParams] = useState({ year: 2024, month: 9 }); // Default: September 2024
  const [daysInMonth, setDaysInMonth] = useState(30);

  const [calendarDate, setCalendarDate] = useState({
    year: new Date().getFullYear(),
    month: new Date().getMonth() + 1, // Months are 0-indexed
  });

  const storedId = sessionStorage.getItem("id");

  const CY = new Date().getFullYear();

  const monthsMap = [
    'january', 'february', 'march', 'april', 'may', 'june',
    'july', 'august', 'september', 'october', 'november', 'december'
  ];

  const yearsRange = [];
  for (let i = CY - 100; i <= CY + 100; i++) {
    yearsRange.push(i);
  }


  var showDate = new Date()
  var DisplayDate = showDate.getDay + '-' + showDate.getDate + '-' + showDate.getMonth + '-' + showDate.getFullYear
  var dt = showDate.toDateString()


  useEffect(() => {
    const getCurrentDate = () => {
      const date = new Date();
      const day = String(date.getDate()).padStart(2, '0');
      const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are zero-indexed
      const year = date.getFullYear();
      return `${day}-${month}-${year}`;
    };

    setTodayDate(getCurrentDate());
  }, []);



  const handleSearch = (e) => {
    e.preventDefault();
    fetchEmployeeBreakCount();
  };

  useEffect(() => {
    setTodayDate(getFormattedDate());
    fetchEmployeeData()
  }, [])

  function getFormattedDate() {
    const now = new Date();

    const day = now.getDate().toString().padStart(2, '0');
    const month = (now.getMonth() + 1).toString().padStart(2, '0');
    const year = now.getFullYear().toString().slice(-2);

    let hours = now.getHours();
    let minutes = now.getMinutes();
    const ampm = hours >= 12 ? 'PM' : 'AM';

    hours = hours % 12;
    hours = hours ? hours : 12; // the hour '0' should be '12'
    minutes = minutes < 10 ? '0' + minutes : minutes;

    const formattedDate = `${day}-${month}-${year}`;
    const formattedTime = `${hours}:${minutes} ${ampm}`;

    console.log(formattedDate, formattedTime);


    return `${formattedDate}`;
  }


  const fetchEmployeeData = async () => {
    try {
      setLoading(true);
      const response = await viewemployeepaggination(1, 100);
      console.log(response);

      // Check for a valid response
      if (!response || !response.success || !Array.isArray(response.data)) {
        throw new Error('Failed to fetch data or no data available.');
      }

      const [todayDay, todayMonth, todayYear] = todayDate.split("-"); // Parse todayDate

      // Filter employees where birth_date month matches today's month
      const filteredEmployees = response.data.filter(employee => {
        const birthMonth = employee.employeeData.birth_date.split("-")[1]; // Extract month from birth_date
        return birthMonth === todayMonth; // Match with today's month
      });

      // Sort employees by proximity to today's date
      const sortedEmployees = filteredEmployees
        .map(employee => {
          const [birthYear, birthMonth, birthDay] = employee.employeeData.birth_date.split("-");
          const birthDateThisYear = new Date(todayYear, birthMonth - 1, birthDay);

          // Calculate the number of days until the next birthday this year
          const today = new Date(todayYear, todayMonth - 1, todayDay);
          let daysToBirthday = Math.ceil((birthDateThisYear - today) / (1000 * 60 * 60 * 24));

          // If birthday has passed this month, calculate days until next year
          if (daysToBirthday < 0) {
            const birthDateNextYear = new Date(parseInt(todayYear) + 1, birthMonth - 1, birthDay);
            daysToBirthday = Math.ceil((birthDateNextYear - today) / (1000 * 60 * 60 * 24));
          }

          return {
            ...employee.employeeData,
            daysToBirthday, // Add daysToBirthday to sort by it
          };
        })
        .sort((a, b) => a.daysToBirthday - b.daysToBirthday); // Sort by days until birthday

      setBirthdayEmployees(sortedEmployees); // Set the sorted employees as the birthday list
      console.log("Sorted Employees by birthday proximity:", sortedEmployees);

    } catch (error) {
      console.error('Error fetching data:', error);
    } finally {
      setLoading(false);
    }
  };



  useEffect(() => {
    const debouncedFetchEmployeeBreakCount = _debounce(fetchEmployeeBreakCount, 0);

    debouncedFetchEmployeeBreakCount();

    return () => {
      debouncedFetchEmployeeBreakCount.cancel();
    };
  }, [searchParams]);



  const fetchEmployeeBreakCount = async () => {
    console.log("Search parameters:", searchParams);

    try {
      setLoading(true);
      const employeeId = getId();
      let apiResponse;
      const monthName = monthsMap[searchParams.month - 1];

      if (employeeId) {
        apiResponse = await singleViewEmployeeAttendance(
          employeeId,
          searchParams.year,
          monthName
        );
        console.log("API Response:", apiResponse);
      }

      const parsedEvents = parseApiResponse(apiResponse, searchParams.year, searchParams.month);
      setEvents(parsedEvents);
      console.log("Parsed events:", parsedEvents);

    } catch (error) {
      console.error('Error fetching employee break count:', error);
    } finally {
      setLoading(false);
    }
  };

  const parseApiResponse = (apiResponse, year, month) => {
    const formattedEvents = [];
    const allDates = [];

    // Start and end date calculations
    const startDate = new Date(year, month - 1, 2); // First day of the month
    const endDate = new Date(year, month, 1); // Last day of the month

    const today = new Date().toISOString().split('T')[0]; // Current date in YYYY-MM-DD format

    // Generate dates for the entire month
    for (let d = new Date(startDate); d <= endDate; d.setDate(d.getDate() + 1)) {
      const currentDate = new Date(d).toISOString().split('T')[0]; // Format as YYYY-MM-DD
      console.log(currentDate);

      allDates.push(currentDate); // Add the date to the allDates array
    }

    console.log('All dates in month:', allDates); // Check if there are any incorrect dates
    console.log('start dates :', new Date(startDate)); // Check if there are any incorrect dates
    console.log('end dates:', endDate); // Check if there are any incorrect dates

    // Get holiday dates
    const holidayDates = apiResponse.data[0].holidayList.flatMap(holiday =>
      Object.values(holiday.company_holiday).map(h => h.holiday_date)
    );
    console.log('Holiday dates:', holidayDates);

    // Get working days and weekend days
    const workingDays = apiResponse.data[0].workingDay[0];
    const weekendDays = ['sunday', 'saturday'].filter(day => !workingDays[day]);

    // Process each date in the month
    allDates.forEach((date) => {
      const attendance = apiResponse.data[0].attendance_list.find(att => {
        const attDate = att.attendance_time.split(' ')[0]; // Extract date part from attendance
        return attDate == date;
      });

      if (attendance) {
        let title = '';
        let dotColor = '';

        switch (attendance.attendanceCheck) {
          case 1:
            title = 'Present';
            dotColor = 'green';
            break;
          case 2:
            title = 'Half Day';
            dotColor = 'orange';
            break;
          case 3:
            title = 'Weekend';
            dotColor = 'blue';
            break;
          case 4:
            title = 'Paid Leave';
            dotColor = 'purple';
            break;
          case 0:
          default:
            title = 'Absent';
            dotColor = 'red';
            break;
        }

        formattedEvents.push({
          title,
          date,
          time: attendance.attendance_time.split(' ')[1],
          dotColor,
        });
        console.log(`Attendance found for ${date}: ${title}`);

      } else if (holidayDates.includes(date)) {
        formattedEvents.push({
          title: 'Holiday',
          date: date,
          time: '',
          dotColor: 'blue',
        });
        console.log(`Holiday found for ${date}`);

      } else if (weekendDays.includes(new Date(date).toLocaleDateString('en-US', { weekday: 'long' }).toLowerCase())) {
        formattedEvents.push({
          title: 'Weekend',
          date: date,
          time: '',
          dotColor: 'blue',
        });
        console.log(`Weekend found for ${date}`);

      } else {
        formattedEvents.push({
          title: 'Absent',
          date: date,
          time: '',
          dotColor: '#de3c3c',
        });
        console.log(`Absent for ${date}`);
      }
    });

    console.log('Formatted events:', formattedEvents);
    return formattedEvents;
  };





  const getLastDateOfMonth = (year, month) => {
    return new Date(year, month, 0).getDate(); // Month is 0-indexed, so use 0 for the last day
  };

  useEffect(() => {
    if (calendarRef.current) {
      const calendarApi = calendarRef.current.getApi();
      calendarApi.gotoDate(`${searchParams.year}-${String(searchParams.month).padStart(2, '0')}-01`);
    }
  }, [searchParams]);

  const handleYearChange = (e) => {
    setSearchParams((prev) => ({ ...prev, year: e.target.value }));
    console.log(searchParams);
  };
  
  const handleMonthChange = (e) => {
    setSearchParams((prev) => ({ ...prev, month: e.target.value }));
    console.log(searchParams);
  };


  useEffect(() => {
    setDaysInMonth(getLastDateOfMonth(searchParams.year, searchParams.month));
  }, [searchParams.year, searchParams.month]);


  useEffect(() => {
    fetchRemainingLeaves()

  }, [])

  const fetchRemainingLeaves = async () => {
    try {
      setLoading(true);
      const response = await remainLeavePagination(1, 100);
      console.log(response);

      if (response) {
        const matchingObject = response.data.find(item =>
          item.employee_total_leave.employee_id == storedId
        );


        // Output the result
        if (matchingObject) {
          console.log("Matching Object:", matchingObject);
          setRemainingLeaves(matchingObject.employee_total_leave.totalLeaves)
        } else {
          console.log("No matching object found");
        }
      }
    } catch (error) {
      console.error('Error fetching data:', error);
    } finally {
      setLoading(false);
    }
  };


  useEffect(() => {
    const fetchEmpLeave = async () => {
      try {
        setLoading(true);
        // Reset the remainingLeaves before fetching new data
        setRemainingLeaves(0);

        const formattedData = await viewAddEmployeeLeave();

        if (!formattedData || !formattedData.length) {
          throw new Error('Failed to fetch data.');
        }

        const addEmployeeLeave = formattedData[0].employee_leave;
        setMasterId(formattedData[0]._id);
        const addEmployeeLeaveArray = Object.values(addEmployeeLeave).map(item => ({
          _id: item._id,
          leave_type: item.leave_type || 'N/A',
          from_date: item.from_date || 'N/A',
          to_date: item.to_date || 'N/A',
          total_days: item.total_days || 'N/A',
          remaining_leaves: item.remaining_leaves !== null ? item.remaining_leaves.toString() : '0',
          leave_reason: item.leave_reason || 'N/A',
        }));

        setAddEmployeeDataData(addEmployeeLeaveArray);

        // Calculate total remaining leaves without using reduce
        let totalRemainingLeaves = 0;
        addEmployeeLeaveArray.forEach(item => {
          if (item.remaining_leaves) {
            totalRemainingLeaves = parseInt(item.remaining_leaves, 10);
          }
        });

        setRemainingLeaves(totalRemainingLeaves);
      } catch (error) {
        console.error('Error fetching data:', error);
      } finally {
        setLoading(false);
      }
    };

    // fetchEmpLeave();
    const debouncedFetchEmpLeave = _debounce(fetchEmpLeave, 50);

    debouncedFetchEmpLeave();

    return () => {
      debouncedFetchEmpLeave.cancel();
    };
  }, []);

  // useEffect(() => {
  //   const fetchData = async () => {
  //     try {
  //       const formattedData = await viewAnnouncement();

  //       if (!formattedData || !formattedData.length) {
  //         throw new Error('Failed to fetch data.');
  //       }

  //       const companyAnnouncement = formattedData[0].announcement;
  //       setMasterId(formattedData[0]._id);
  //       const announcementsArray = Object.values(companyAnnouncement).map(item => ({

  //         _id: item._id,
  //         announcement_subject: item.announcement_subject || 'N/A',
  //       }));

  //       setAnnouncements(announcementsArray);
  //     } catch (error) {
  //       console.error('Error fetching data:', error);
  //     } finally {
  //       setLoading(false);
  //     }
  //   };
  //   fetchData()
  // }, []);

  useEffect(() => {
    const debouncedFetchData = _debounce(async () => {
      try {
        const formattedData = await viewAnnouncement();

        if (!formattedData || !formattedData.length) {
          throw new Error('Failed to fetch data.');
        }

        const companyAnnouncement = formattedData[0].announcement;
        setMasterId(formattedData[0]._id);
        const announcementsArray = Object.values(companyAnnouncement).map(item => ({
          _id: item._id,
          announcement_subject: item.announcement_subject || 'N/A',
        }));

        setAnnouncements(announcementsArray);
      } catch (error) {
        console.error('Error fetching data:', error);
      } finally {
        setLoading(false);
      }
    }, 50);

    debouncedFetchData();

    return () => {
      debouncedFetchData.cancel();
    };
  }, []);


  const fetchHolidayData = async () => {
    try {
      const formattedData = await viewHoliday();

      if (!formattedData || !formattedData.length) {
        throw new Error('Failed to fetch data.');
      }

      const companyHoliday = formattedData[0].company_holiday;
      setMasterId(formattedData[0]._id);
      const holidaysArray = Object.values(companyHoliday).map(item => ({

        _id: item._id,
        holiday_name: item.holiday_name || 'N/A',
        holiday_date: item.holiday_date ? (item.holiday_date) : 'N/A',
        // holiday_description: item.holiday_description || 'N/A',
      }));

      setHolidayData(holidaysArray);
    } catch (error) {
      console.error('Error fetching data:', error);
    } finally {
      setLoading(false);
    }
  };

  // useEffect(() => {
  //   fetchHolidayData();
  // }, []);
  useEffect(() => {
    const debouncedFetchHolidayData = _debounce(fetchHolidayData, 50);

    debouncedFetchHolidayData();

    return () => {
      debouncedFetchHolidayData.cancel();
    };
  }, []);


  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Month starts from 0
    const year = date.getFullYear();

    return `${day}/${month}/${year}`;
  };





  const [id, setId] = useState('');
  useEffect(() => {
    const id = getemployeeId();
    setId(id);
  }, []);


  const [state, setState] = useState({
    _id: '',
    company_id: '',
    first_name: '',
    last_name: '',
    email: '',
    joining_date: '',
    phone: '',
    department: '',
    designation: '',
    password: '',
    salary: '',
    shift: '',
    branch: '',
  });

  // useEffect(() => {
  //   async function fetchemployeeData() {
  //     try {

  //       const employeeData = await editEmployee(id);
  //       console.log(employeeData);


  //       if (employeeData && employeeData.success && employeeData.success.length > 0) {
  //         const employee = employeeData.success[0];

  //         setState({
  //           _id: employee._id || '',
  //           company_id: employee.company_id || '',
  //           first_name: employee.first_name || '',
  //           last_name: employee.last_name || '',
  //           email: employee.email || '',
  //           joining_date: employee.joining_date || '',
  //           phone: employee.phone || '',
  //           department: employee.department || '',
  //           designation: employee.designation || '',
  //           branch: employee.branch || '',
  //           // password: employee.password || '',
  //           salary: employee.salary || '',
  //           shift: employee.shift || '',
  //           file: employee.file || [],
  //         });

  //         // const userBranches = employee.branch ? employee.branch.split(',') : [];
  //       } else {
  //         console.error('No employee data found');
  //       }
  //     } catch (error) {
  //       console.error('Error fetching employee data:', error);
  //     }


  //   }

  //   fetchemployeeData();
  // }, [id]);


  useEffect(() => {
    const debouncedFetchEmployeeData = debounce(async () => {
      try {
        const employeeData = await editEmployee(id);
        console.log(employeeData);

        if (employeeData && employeeData.success && employeeData.success.length > 0) {
          const employee = employeeData.success[0];

          setState({
            _id: employee._id || '',
            company_id: employee.company_id || '',
            first_name: employee.first_name || '',
            last_name: employee.last_name || '',
            email: employee.email || '',
            joining_date: employee.joining_date || '',
            phone: employee.phone || '',
            department: employee.department || '',
            designation: employee.designation || '',
            branch: employee.branch || '',
            // password: employee.password || '',
            salary: employee.salary || '',
            shift: employee.shift || '',
            file: employee.file || [],
          });

          // const userBranches = employee.branch ? employee.branch.split(',') : [];
        } else {
          console.error('No employee data found');
        }
      } catch (error) {
        console.error('Error fetching employee data:', error);
      }
    }, 2000);

    debouncedFetchEmployeeData();

    return () => {
      debouncedFetchEmployeeData.cancel();
    };
  }, [id]);






  const [breakcountData, setBreakcountData] = useState([]);

  const [employeeId, setEmployeeId] = useState('');
  const reversedRows = [...breakcountData].reverse();
  const calculateTotalDuration = (breaks) => {
    const totalDuration = breaks.reduce((total, breakItem) => total + breakItem.duration_minutes, 0);
    const hours = Math.floor(totalDuration / 60);
    const minutes = totalDuration % 60;

    return `${hours}h ${minutes}m`;
  };
  const formatTime = (time) => {
    const formattedTime = new Date(`2022-01-01 ${time}`);
    return formattedTime.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });
  };
  useEffect(() => {
    const empId = getId();
    setEmployeeId(empId);
  }, []);




  const fetchData = async () => {
    try {
      const formattedData = await viewEmployeeBreakCount(employeeId);

      if (!formattedData || !formattedData.data.length) {
        throw new Error('Failed to fetch data.');
      }

      const breakList = formattedData.data[0].break_list;
      const branchArray = breakList.flatMap((item, index) =>
        Object.entries(item.attendance).map(([date, attendanceData]) => ({
          id: `${index}_${date}`,
          date: attendanceData.date,
          name: attendanceData.name,
          type: attendanceData.breaks[0].type,
          time: formatTime(attendanceData.breaks[0].time),
          breaks: attendanceData.breaks.map(breakItem => ({
            type: breakItem.type,
            time: formatTime(breakItem.time),
            duration_minutes: breakItem.duration_minutes || 0,
          })),
        }))
      );

      setBreakcountData(branchArray);
    } catch (error) {
      console.error('Error fetching data:', error);
    } finally {
      setLoading(false);
    }
  };

  const debouncedFetchData = _debounce(fetchData, 50);

  useEffect(() => {
    debouncedFetchData();

    return () => {
      debouncedFetchData.cancel();
    };
  }, [employeeId]);

  const dataBar1 = {
    labels: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
    datasets: [{
      data: [20, 60, 50, 45, 50, 60, 70, 40, 45, 35, 25, 30],
      backgroundColor: '#506fd9',
      barPercentage: 0.5
    }, {
      data: [10, 40, 30, 40, 60, 55, 45, 35, 30, 20, 15, 20],
      backgroundColor: '#85b6ff',
      barPercentage: 0.5
    }, {
      data: [8, 30, 40, 35, 40, 45, 35, 30, 25, 10, 20, 15],
      backgroundColor: '#e2e5ec',
      barPercentage: 0.5
    }]
  };

  const optionBar1 = {
    maintainAspectRatio: false,
    responsive: true,
    plugins: {
      legend: {
        display: false
      }
    },
    scales: {
      y: {
        beginAtZero: true,
        max: 80,
        ticks: {
          color: '#a1aab3',
          font: {
            size: 10
          }
        },
        grid: {
          borderColor: '#e2e5ec',
          borderWidth: 1.5,
          color: 'rgba(65,80,95,.08)'
        }
      },
      x: {
        ticks: {
          color: '#313c47'
        },
        grid: {
          color: 'rgba(65,80,95,.08)'
        }
      }
    }
  };


  const [tooltip, setTooltip] = useState({ visible: false, text: '', x: 0, y: 0 });

  const showTooltip = (event, time) => {
    setTooltip({ visible: true, text: time ? `Time: ${time}` : 'No Attendance Recorded', x: event.clientX, y: event.clientY });
  };

  const hideTooltip = () => {
    setTooltip({ ...tooltip, visible: false });
  };




  const dataBar2 = {
    labels: ['Modification', 'Code Request', 'Feature Request', 'Bug Fix', 'Integration', 'Production'],
    datasets: [{
      data: [20, 60, 50, 45, 50, 75],
      backgroundColor: ['#506fd9', '#85b6ff', '#33d685', '#ffc107', '#0dcaf0', '#ea4c89'],
      barPercentage: 0.5
    }, {
      data: [10, 40, 30, 40, 60, 55],
      backgroundColor: '#e2e5ec',
      barPercentage: 0.5
    }]
  };

  const optionBar2 = {
    indexAxis: 'y',
    maintainAspectRatio: false,
    responsive: true,
    plugins: {
      legend: {
        display: false
      }
    },
    scales: {
      x: {
        beginAtZero: true,
        max: 100,
        grid: {
          borderColor: '#e2e5ec',
          color: '#f3f5f9'
        },
        ticks: {
          font: {
            size: 11
          }
        }
      },
      y: {
        grid: {
          borderWidth: 0,
          color: '#f3f5f9'
        },
        ticks: {
          color: '#465463',
          font: {
            size: 13
          }
        }
      }
    }
  };

  const optionOne = {
    chart: {
      parentHeightOffset: 0,
      toolbar: { show: false },
      stacked: true,
      sparkline: { enabled: true }
    },
    colors: ['#506fd9'],
    stroke: {
      curve: 'straight',
      width: 2
    },
    xaxis: { max: 40 },
    yaxis: {
      min: 0,
      max: 80
    },
    fill: {
      type: 'solid',
      opacity: 0.2
    },
    tooltip: { enabled: false }
  }

  // Time to Resolved Complain
  const optionDonut1 = {
    chart: { parentHeightOffset: 0 },
    colors: ['#506fd9', '#e5e9f2'],
    dataLabels: { enabled: false },
    legend: { show: false }
  };

  const optionDonut2 = {
    chart: { parentHeightOffset: 0 },
    colors: ['#4c5366', '#e5e9f2'],
    dataLabels: { enabled: false },
    legend: { show: false }
  }

  // Agent Performance Score
  const optionTwo = {
    chart: {
      parentHeightOffset: 0,
      toolbar: { show: false },
      stacked: true
    },
    colors: ['#4f6fd9'],
    grid: {
      borderColor: 'rgba(72,94,144, 0.08)',
      padding: {
        top: -20,
        left: 0,
        right: -8
      },
      yaxis: {
        lines: { show: false }
      }
    },
    dataLabels: { enabled: false },
    stroke: {
      curve: 'straight',
      width: 1.5
    },
    xaxis: {
      overwriteCategories: ['', 'JAN', 'FEB', 'MAR', 'APR', 'MAY', 'JUN', 'JUL', 'AUG', 'SEP', 'OCT', 'NOV', 'DEC', ''],
      tickAmount: 13,
      axisBorder: { show: false },
      labels: {
        style: {
          colors: '#6e7985',
          fontSize: '10px'
        }
      },
    },
    yaxis: {
      min: 0,
      max: 50,
      show: false
    },
    fill: {
      type: 'gradient',
      gradient: {
        opacityFrom: 0.5,
        opacityTo: 0,
      }
    },
    legend: { show: false },
    tooltip: { enabled: false }
  }

  ///// Skin Switch /////
  const currentSkin = (localStorage.getItem('skin-mode')) ? 'dark' : '';
  const [skin, setSkin] = useState(currentSkin);

  const switchSkin = (skin) => {
    if (skin === 'dark') {
      const btnWhite = document.getElementsByClassName('btn-white');

      for (const btn of btnWhite) {
        btn.classList.add('btn-outline-primary');
        btn.classList.remove('btn-white');
      }
    } else {
      const btnOutlinePrimary = document.getElementsByClassName('btn-outline-primary');

      for (const btn of btnOutlinePrimary) {
        btn.classList.remove('btn-outline-primary');
        btn.classList.add('btn-white');
      }
    }
  }

  switchSkin(skin);

  useEffect(() => {
    switchSkin(skin);
  }, [skin]);

  const breakHistory = [
    {
      id: 1,
      employee: 'John Doe',
      scheduledBreak: '12:00 PM - 12:30 PM',
      status: 'On Break',
    },
    // Add more break history data as needed
  ]; const NoHistoryComponent = () => (
    <div className="text-center py-3">
      <p className="mb-0">No break history available.</p>
      <p className="text-muted">Start managing breaks to track the history.</p>
    </div>
  );

  const padd = {
    paddingLeft: '24px',
  };

  const seriesPie = [44, 55, 13,];
  const optionsPie = {
    chart: {
      type: 'pie',
    },
    labels: ['Utilized(5)', 'Casual(4)', ' Sick(2)'],
  };

  return (
    <React.Fragment>
      <Header onSkin={setSkin} />
      <div className="main main-app p-3 p-lg-4">
        <div className="d-md-flex align-items-center justify-content-between mb-4">
          <div>
            <ol className="breadcrumb fs-sm mb-1">
              <li className="breadcrumb-item"><Link href="/employee/dashboard">Dashboard</Link></li>
              {/* <li className="breadcrumb-item active" aria-current="page">Helpdesk Service</li> */}
            </ol>
            <h4 className="main-title mb-2">Welcome, {state.first_name} {state.last_name}</h4>

            <p className="text-secondary mb-0">{dt}</p>

          </div>
          <div className="d-flex align-items-center gap-2 mt-3 mt-md-0">
            <Button variant="white" className="btn-icon"><i className="ri-share-line fs-18 lh-1"></i></Button>
            <Button variant="white" className="btn-icon"><i className="ri-printer-line fs-18 lh-1"></i></Button>
            <Button variant="primary" className="btn-icon"><i className="ri-bar-chart-2-line fs-18 lh-1"></i></Button>
          </div>
        </div>

        <Row className="g-3">
          <Col md="7" xl="6">
            <Card className="card-one">
              <Card.Header>
                <Card.Title as="h6">Your Attendance</Card.Title>
                <Nav className="nav-icon nav-icon-sm ms-auto">
                  <Nav.Link href=""><i className="ri-refresh-line"></i></Nav.Link>
                  <Nav.Link href=""><i className="ri-more-2-fill"></i></Nav.Link>
                </Nav>
              </Card.Header>
              <CardBody>
                <Form onSubmit={handleSearch}>
                  <Row className="mb-3">
                    <Col>
                      {/* <Form.Control
                        type="number"
                        placeholder="Year"
                        value={searchParams.year}
                        onChange={handleYearChange}
                       
                      /> */}
                      <Form.Control
                        as="select"
                        value={searchParams.year}
                        onChange={handleYearChange}
                      >
                        {yearsRange.map((year) => (
                          <option key={year} value={year}>
                            {year}
                          </option>
                        ))}
                      </Form.Control>
                    </Col>
                    <Col>
                      <Form.Control
                        as="select"
                        value={searchParams.month}
                        onChange={handleMonthChange}
                      >
                        <option value="1">January</option>
                        <option value="2">February</option>
                        <option value="3">March</option>
                        <option value="4">April</option>
                        <option value="5">May</option>
                        <option value="6">June</option>
                        <option value="7">July</option>
                        <option value="8">August</option>
                        <option value="9">September</option>
                        <option value="10">October</option>
                        <option value="11">November</option>
                        <option value="12">December</option>
                      </Form.Control>
                    </Col>
                    {/* <Col>
                      <Button type="submit">Search</Button>
                    </Col> */}
                  </Row>
                </Form>

                {loading ? (
                  <Spinner animation="border" role="status">
                    <span className="visually-hidden">Loading...</span>
                  </Spinner>
                ) : (

                  <FullCalendar
                    ref={calendarRef}
                    plugins={[dayGridPlugin]}
                    initialView="dayGridMonth"
                    initialDate={`${searchParams.year}-${String(searchParams.month).padStart(2, '0')}-01`}
                    events={events}
                    headerToolbar={{
                      left: '',  // No buttons on the left side
                      center: 'title',  // Only display the title (month/year)
                      right: '',  // No buttons on the right side
                    }}
                    eventContent={(eventInfo) => {
                      const dotColor = eventInfo.event.extendedProps.dotColor;
                      const time = eventInfo.event.extendedProps.time;
                      const title = eventInfo.event.title;

                      return (
                        <div style={{ display: 'flex', alignItems: 'center', backgroundColor: 'transparent' }}>
                          <div
                            style={{
                              width: '10px',
                              height: '10px',
                              borderRadius: '30%',
                              backgroundColor: dotColor,
                              border: `2px solid ${dotColor}`,
                              marginRight: '5px',
                              cursor: 'pointer',
                              position: 'absolute',
                              top: '-25px',
                              left: '4px',
                            }}
                            title={time ? `Time: ${time} - ${title}` : title}
                          ></div>
                        </div>
                      );
                    }}
                  />

                )}
                <span className="me-3">
                  <span style={{
                    display: 'inline-block',
                    width: '0.7rem',
                    height: '0.7rem',
                    borderRadius: '30%',
                    backgroundColor: 'green',
                    border: `2px solid green`,
                    marginRight: '5px',
                    cursor: 'pointer',
                  }}
                  ></span>Present
                </span>
                <span className="me-3">
                  <span style={{
                    display: 'inline-block',
                    width: '0.7rem',
                    height: '0.7rem',
                    borderRadius: '30%',
                    backgroundColor: 'red',
                    border: `2px solid red`,
                    marginRight: '5px',
                    cursor: 'pointer',
                  }}
                  ></span>Absent
                </span>
                <span className="me-3">
                  <span style={{
                    display: 'inline-block',
                    width: '0.7rem',
                    height: '0.7rem',
                    borderRadius: '30%',
                    backgroundColor: 'blue',
                    border: `2px solid blue`,
                    marginRight: '5px',
                    cursor: 'pointer',
                  }}
                  ></span>Weekend
                </span>
                <span className="me-3">
                  <span style={{
                    display: 'inline-block',
                    width: '0.7rem',
                    height: '0.7rem',
                    borderRadius: '30%',
                    backgroundColor: 'purple',
                    border: `2px solid purple`,
                    marginRight: '5px',
                    cursor: 'pointer',
                  }}
                  ></span>Holiday/Paid Leave
                </span>
                <span className="me-3">
                  <span style={{
                    display: 'inline-block',
                    width: '0.7rem',
                    height: '0.7rem',
                    borderRadius: '30%',
                    backgroundColor: 'orange',
                    border: `2px solid orange`,
                    marginRight: '5px',
                    cursor: 'pointer',
                  }}
                  ></span>Half Day
                </span>


              </CardBody>
            </Card>
          </Col>
          <Col md="5" xl="6">
            <Card className="card-one">
              <Card.Header>
                <Card.Title as="h6">Notice Board</Card.Title>
                <Nav className="nav-icon nav-icon-sm ms-auto">
                  <Nav.Link href=""><i className="ri-refresh-line"></i></Nav.Link>
                  <Nav.Link href=""><i className="ri-more-2-fill"></i></Nav.Link>
                </Nav>
              </Card.Header>

              {loading ? (
                <div className="loader-container">
                  <div className="loader"></div>
                </div>
              ) : (
                <Card.Body className="p-0">
                  <marquee behavior="scroll" direction="up" height="250">
                    {announcements.map((announcement, index) => (
                      <div className="border rounded p-3 mb-3" key={index}>
                        <h6>
                          {announcement.announcement_subject}
                          <Badge bg="secondary" className="ms-2">
                            {announcement.timestamp}
                          </Badge>
                        </h6>
                        <p className="text-secondary">{announcement.content}</p>
                        <div className="d-flex justify-content-between align-items-center">
                          <Button variant="primary" className="me-2">
                            Like
                          </Button>
                        </div>
                      </div>
                    ))}
                  </marquee>

                </Card.Body>
              )}
            </Card>
          </Col>





          {/* <Col md="7" xl="6">
            <Card className="card-one">
              <Card.Header>
                <Card.Title as="h6">Upcoming birthdays</Card.Title>
                <Nav className="nav-icon nav-icon-sm ms-auto">
                  <Nav.Link href=""><i className="ri-refresh-line"></i></Nav.Link>
                  <Nav.Link href=""><i className="ri-more-2-fill"></i></Nav.Link>
                </Nav>
              </Card.Header>
              <Card.Body>
                <Col xs="12" sm>
                  <Table className="table-music mb-0">
                    <tbody>
                      {[
                        {
                          "img": img19,
                          "title": "Mehul Parmar",
                          "artist": "Senior Developer",
                          "date": "01/12/1997"
                        }, {
                          "img": img30,
                          "title": "Dipali Khopade",
                          "artist": "Web Developer",
                          "date": "01/12/1997"
                        }, {
                          "img": img31,
                          "title": "Neha Dalsania",
                          "artist": "Web Developer",
                          "date": "02/12/1997"
                        }, {
                          "img": img32,
                          "title": "Priti Malode",
                          "artist": "Web Developer",
                          "date": "02/12/1997"
                        }, {
                          "img": img33,
                          "title": "Reena Pipaliya",
                          "artist": "Web Developer",
                          "date": "02/12/1997"
                        }
                      ].map((music, index) => (
                        <tr key={index}>
                          <td className="w-5">{index + 1}</td>
                          <td className="w-75">
                            <div className="media-music">
                              <Link to="" className="media-img"><img src={music.img} alt="" /></Link>
                              <div className="media-body">
                                <h6><Link to="">{music.title}</Link></h6>
                                <span>{music.artist}</span>
                              </div>
                            </div>
                          </td>
                          <td className="w-15"><span className="time">{music.date}</span></td>
                          <td className="w-5">
                            <Link to="" className="dropdown-link"><i className="ri-more-2-fill"></i></Link>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                </Col>
              </Card.Body>
            </Card>
          </Col> */}
          <Col md="6" xl="6">
            <Card className="card-one">
              <Card.Header>
                <Card.Title as="h6">Your Break History</Card.Title>
                <Nav className="nav-icon nav-icon-sm ms-auto">
                  <Nav.Link href=""><i className="ri-refresh-line"></i></Nav.Link>
                  <Nav.Link href=""><i className="ri-more-2-fill"></i></Nav.Link>
                </Nav>
              </Card.Header>
              <Card.Body className="p-0">



                {/* <EmployeeBreakstatic/> */}
                <EmployeeBreakhistory />



                {/* <Table responsive striped bordered hover className="m-0">
                  <thead>
                    <tr>
                      <th>#</th>
                      <th>Employee</th>
                      <th>Scheduled Break</th>
                      <th>Status</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>1</td>
                      <td>John Doe</td>
                      <td>12:00 PM - 12:30 PM</td>
                      <td>
                        <span className="badge bg-success">On Break</span>
                      </td>
                    </tr>
                    <tr>
                      <td>2</td>
                      <td>Jane Smith</td>
                      <td>-</td>
                      <td>
                        <Button variant="primary" size="sm">Start Break</Button>
                      </td>
                    </tr>
                    <tr>
                      <td>3</td>
                      <td>Emily Johnson</td>
                      <td>02:30 PM - 03:00 PM</td>
                      <td>
                        <span className="badge bg-warning text-dark">Pending</span>
                      </td>
                    </tr>
                    <tr>
                      <td>4</td>
                      <td>Michael Brown</td>
                      <td>01:45 PM - 02:15 PM</td>
                      <td>
                        <span className="badge bg-success">On Break</span>
                      </td>
                    </tr>
                    <tr>
                      <td>5</td>
                      <td>Olivia Williams</td>
                      <td>-</td>
                      <td>
                        <Button variant="primary" size="sm">Start Break</Button>
                      </td>
                    </tr>

                  </tbody>
                </Table> */}






              </Card.Body>
            </Card>
          </Col>

          <Col md="6" xl="6">

            <Card className="card-one">
              {/* <Card.Header>
                <Card.Title as="h6">UPCOMING HOLIDAY</Card.Title>
                <Nav className="nav-icon nav-icon-sm ms-auto">
                  <Nav.Link href=""><i className="ri-refresh-line"></i></Nav.Link>
                  <Nav.Link href=""><i className="ri-more-2-fill"></i></Nav.Link>
                </Nav>
              </Card.Header>
              <Card.Body className="p-0">
                <hr className="my-3 opacity-0" />
                <ul className="event-group" style={padd}>
                  {holidayData.map(holiday => (
                    <li className="event-item" key={holiday._id}>
                      <span style={{ width: "50px", height: "50px" }}>
                        <HolidayVillageIcon style={{ fontSize: "2.5rem", color: "blueviolet" }} />
                      </span>
                    
                      <div className="event-body ">
                        <h6><Link to="">{holiday.holiday_name}</Link></h6>
                        <p>{holiday.holiday_date}</p>
                        <p >{holiday.holiday_description}</p>
                      </div>
                    </li>
                  ))}
                </ul>
                <hr className="my-4 opacity-0" />
              </Card.Body> */}

              <Card.Body className="p-4">
                <Card.Title as="h4"><strong>Holidays</strong></Card.Title>
                <div className="mt-4 custom-scrollbar" style={{ height: 'calc(25vh)', overflowY: 'auto', padding: "0 0.5rem" }}>
                  {holidayData && holidayData.length > 0 ? (
                    <div className="space-y-2">
                      {holidayData.map((holiday) => (
                        <div
                          key={holiday._id}
                          className="d-flex mb-3 mt-1 p-2 rounded-lg shadow-lg bg-white"
                          style={{ width: '100%' }}
                        >
                          {/* Icon or Image for the holiday */}
                          {/* <div className="w-10 h-10 rounded-full mr-3 flex justify-center items-center bg-blue-200">
                            <strong className="text-xl text-white">{holiday.holiday_name.charAt(0)}</strong>
                          </div> */}

                          <img
                            src=""
                            alt="Holiday"
                            className="w-10 h-10 rounded-full mr-3"
                            style={{ borderRadius: '50%' }}
                          />
                          {/* Text next to the icon */}
                          <div className="flex flex-col">
                            <div className="text-sm"><strong>{holiday.holiday_name}</strong></div>
                            <div className="text-sm">
                              {new Date(holiday.holiday_date).toLocaleDateString('en-GB')}
                            </div>
                          </div>
                        </div>
                      ))}
                    </div>
                  ) : (
                    <p className="text-center text-lg text-gray-500">No holidays available</p>
                  )}
                </div>
              </Card.Body>

              <Card.Body className="p-4">
                <Card.Title as="h4"><strong>Upcoming Birthday</strong></Card.Title>
                <div className="mt-4 custom-scrollbar" style={{ height: 'calc(25vh)', overflowY: 'auto', padding: "0 0.5rem" }}>
                  {birthdayEmployee && birthdayEmployee.length > 0 ? (
                    <div className="space-y-2">
                      {birthdayEmployee.map((birthday) => {
                        const [birthYear, birthMonth, birthDay] = birthday.birth_date.split("-");
                        const birthDate = new Date(birthYear, birthMonth - 1, birthDay);
                        const [todayDay, todayMonth, todayYear] = todayDate.split("-");
                        const today = new Date(`20${todayYear}`, todayMonth - 1, todayDay);
                        birthDate.setFullYear(today.getFullYear());
                        const timeDifference = birthDate - today;
                        const daysToBirthday = Math.ceil(timeDifference / (1000 * 60 * 60 * 24));

                        let message;
                        let messageClass = "";
                        let icon = "";
                        let birth = "";
                        if (daysToBirthday == 0) {
                          message = `🎉 Happy Birthday, ${birthday.first_name} ${birthday.last_name}! 🎉`;
                          messageClass = "bg-blue-500";
                        } else if (daysToBirthday > 0 && daysToBirthday <= 3) {
                          message = `${birthday.first_name} ${birthday.last_name} - ${daysToBirthday} day${daysToBirthday > 1 ? 's' : ''} to go`;
                          messageClass = "bg-yellow-400";
                          icon = "⏳";
                        } else {
                          message = `${birthday.first_name} ${birthday.last_name}`;
                          birth = new Date(birthday.birth_date).toLocaleDateString('en-GB');
                          messageClass = "bg-green-500";
                          icon = "📅";
                        }

                        return (
                          <div
                            key={birthday._id}
                            className={`d-flex mb-3 mt-1 p-3 rounded-lg shadow-lg bg-white`}
                            style={{ width: '100%' }}
                          >
                            <img
                              src={birthday.image_path}
                              alt="Profile"
                              className="w-10 h-10 rounded-full mr-3"
                              style={{ borderRadius: '50%' }}
                            />
                            {/* Text next to the image */}
                            <div className="flex flex-col">
                              <div className="text-sm"><strong>{message}</strong></div>
                              <div className="text-sm flex items-center"><strong>{icon} {birth}</strong></div>
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  ) : (
                    <p className="text-center text-lg text-gray-500">No birthday today!</p>
                  )}
                </div>
              </Card.Body>
            </Card>

          </Col>

          {/* break statistics
          <Col md="7" xl="7">
            <Card className="card-one">
              <Card.Header>
                <Card.Title as="h6">Break Statics</Card.Title>
                <Nav className="nav-icon nav-icon-sm ms-auto">
                  <Nav.Link href=""><i className="ri-refresh-line"></i></Nav.Link>
                  <Nav.Link href=""><i className="ri-more-2-fill"></i></Nav.Link>
                </Nav>
              </Card.Header>
              <Card.Body>
                <Col xs="12" sm>
                  <DashbaordBreakCalendar />
                </Col>
              </Card.Body>
            </Card>
          </Col> */}


          <Col md="7" xl="6">
            <Card className="card-one">
              <Card.Header>
                <Card.Title as="h6">Remaining Leaves: {remainingLeaves}</Card.Title>
                <Nav className="nav-icon nav-icon-sm ms-auto">
                  <Nav.Link href=""><i className="ri-refresh-line"></i></Nav.Link>
                  <Nav.Link href=""><i className="ri-more-2-fill"></i></Nav.Link>
                </Nav>
              </Card.Header>
              <Card.Body>
                <Col xs="12" sm>
                  <Link to="/employee/addleave">
                    <Button variant="primary">Apply Leave</Button>
                  </Link>
                  <p>Leave Taken In this month: 0</p>
                </Col>
                <ReactApexChart series={seriesPie} options={optionsPie} type="pie" width={380} />


              </Card.Body>
            </Card>
          </Col>
          {/* <Col md="5" xl="6">
            <Card className="card-one">
              <Card.Header>
                <Card.Title as="h6">UPCOMING HOLIDAY</Card.Title>
                <Nav className="nav-icon nav-icon-sm ms-auto">
                  <Nav.Link href=""><i className="ri-refresh-line"></i></Nav.Link>
                  <Nav.Link href=""><i className="ri-more-2-fill"></i></Nav.Link>
                </Nav>
              </Card.Header>
              <Card.Body className="p-0">
                <hr className="my-3 opacity-0" />
                <ul className="event-group" style={padd}>
                  <li className="event-item">
                    <div className="event-icon"><i className="ri-cake-2-line"></i></div>
                    <div className="event-body">
                      <h6><Link to="">New Year</Link></h6>
                      <p>Tomorrow, April 21, 6:00pm</p>
                    </div>
                  </li>
                  <li className="event-item">
                    <div className="event-icon bg-secondary"><i className="ri-restaurant-fill"></i></div>
                    <div className="event-body">
                      <h6><Link to="">Food Event</Link></h6>
                      <p>Friday, April 27, 8:00am</p>
                    </div>
                  </li>
                  <li className="event-item">
                    <div className="event-icon bg-primary"><i className="ri-roadster-line"></i></div>
                    <div className="event-body">
                      <h6><Link to="">Monster Event</Link></h6>
                      <p>Saturday, April 30, 9:00m</p>
                    </div>
                  </li>
                </ul>

                <hr className="my-4 opacity-0" />




              </Card.Body>
            </Card>
          </Col> */}

          {/* <Col md="5" xl="6">
            <Card className="card-one">
              <Card.Header>
                <Card.Title as="h6">UPCOMING HOLIDAY</Card.Title>
                <Nav className="nav-icon nav-icon-sm ms-auto">
                  <Nav.Link href=""><i className="ri-refresh-line"></i></Nav.Link>
                  <Nav.Link href=""><i className="ri-more-2-fill"></i></Nav.Link>
                </Nav>
              </Card.Header>
              <Card.Body className="p-0">
                <hr className="my-3 opacity-0" />
                <ul className="event-group" style={padd}>
                  {holidayData.map(holiday => (
                    <li className="event-item" key={holiday._id}>
                      <span style={{ width: "50px", height: "50px" }}>
                        <HolidayVillageIcon style={{ fontSize: "2.5rem", color: "blueviolet" }} />
                      </span>
                      
                      <div className="event-body ">
                        <h6><Link to="">{holiday.holiday_name}</Link></h6>
                        <p>{holiday.holiday_date}</p>
                        <p >{holiday.holiday_description}</p>
                      </div>
                    </li>
                  ))}
                </ul>
                <hr className="my-4 opacity-0" />
              </Card.Body>
            </Card>

          </Col> */}

          <Col md="5" xl="6">
            <Card className="card-one">
              <Card.Header>
                <Card.Title as="h6">Break Statics</Card.Title>
                <Nav className="nav-icon nav-icon-sm ms-auto">
                  <Nav.Link href=""><i className="ri-refresh-line"></i></Nav.Link>
                  <Nav.Link href=""><i className="ri-more-2-fill"></i></Nav.Link>
                </Nav>
              </Card.Header>
              <Card.Body>
                <Col xs="12" sm>
                  <DashbaordBreakCalendar />
                </Col>
              </Card.Body>
            </Card>
          </Col>

          {/* <Col md="6">
            <Card className="card-one">
              <Card.Body className="p-3">
                <Row className="g-3 row-cols-auto align-items-center">
                  <Col>
                    <div className="apex-donut-one">
                      <ReactApexChart series={[86, 14]} options={optionDonut1} width={160} height={160} type="donut" />
                      <div>
                        <h4 className="ff-numerals text-dark mb-0">86%</h4>
                        <span className="fs-xs text-secondary">Reached</span>
                      </div>
                    </div>
                  </Col>
                  <Col xs="6" xl="7">
                    <h2 className="card-value mb-3"></h2>
                    <h6 className="card-label text-dark fw-semibold mb-1">Total attendance</h6>
                    <p className="fs-sm text-secondary mb-0">The average time taken to resolve complaints.</p>
                  </Col>
                </Row>
              </Card.Body>
            </Card>
          </Col>
          <Col md="6">
            <Card className="card-one">
              <Card.Body className="p-3">
                <Row className="g-3 row-cols-auto align-items-center">
                  <Col>
                    <div className="apex-donut-one">
                      <ReactApexChart series={[68, 32]} options={optionDonut2} width={160} height={160} type="donut" />
                      <div>
                        <h4 className="ff-numerals text-dark mb-0">68%</h4>
                        <span className="fs-xs text-secondary">Reached</span>
                      </div>
                    </div>
                  </Col>
                  <Col xs="6" xl="7">
                    <h2 className="card-value mb-3"></h2>
                    <h6 className="card-label text-dark fw-semibold mb-1">Your attendance</h6>
                    <p className="fs-sm text-secondary mb-0">The average time taken to resolve complaints.</p>
                  </Col>
                </Row>
              </Card.Body>
            </Card>
          </Col> */}
          <Col xl="7">
            <Card className="card-one">
              <Card.Header>
                <Card.Title as="h6">Upcoming Birthday</Card.Title>
                <Nav className="nav-icon nav-icon-sm ms-auto">
                  <Nav.Link href=""><i className="ri-refresh-line"></i></Nav.Link>
                  <Nav.Link href=""><i className="ri-more-2-fill"></i></Nav.Link>
                </Nav>
              </Card.Header>
              <Card.Body>
                {/* <Upcomingbirthday /> */}
              </Card.Body>
            </Card>
          </Col>
          {/* <Col sm="6" xl>
              <Card className="card-one">
                <Card.Header>
                  <Card.Title as="h6">Overall Rating</Card.Title>
                  <Nav className="nav-icon nav-icon-sm ms-auto">
                    <Nav.Link href=""><i className="ri-refresh-line"></i></Nav.Link>
                    <Nav.Link href=""><i className="ri-more-2-fill"></i></Nav.Link>
                  </Nav>
                </Card.Header>
                <Card.Body>
                  <div className="d-flex align-items-baseline gap-2 mb-0">
                    <h1 className="card-value mb-0">4.8</h1>
                    <div className="d-flex gap-1 text-warning fs-20">
                      <i className="ri-star-fill"></i>
                      <i className="ri-star-fill"></i>
                      <i className="ri-star-fill"></i>
                      <i className="ri-star-fill"></i>
                      <i className="ri-star-half-fill"></i>
                    </div>
                  </div>
                  <p className="fs-sm">Measures the quality or your support team's efforts.</p>
  
                  <Table className="table-ratings mb-0">
                    <tbody>
                      {[
                        {
                          "rating": "5.0",
                          "star": ["fill", "fill", "fill", "fill", "fill"],
                          "count": "4,230",
                          "percent": "58%"
                        }, {
                          "rating": "4.0",
                          "star": ["fill", "fill", "fill", "fill", "line"],
                          "count": "1,416",
                          "percent": "26%"
                        }, {
                          "rating": "3.0",
                          "star": ["fill", "fill", "fill", "line", "line"],
                          "count": "980",
                          "percent": "16%"
                        }, {
                          "rating": "2.0",
                          "star": ["fill", "fill", "line", "line", "line"],
                          "count": "798",
                          "percent": "12%"
                        }, {
                          "rating": "1.0",
                          "star": ["fill", "line", "line", "line", "line"],
                          "count": "401",
                          "percent": "8%"
                        }
                      ].map((item, index) => (
                        <tr key={index}>
                          <td><strong>{item.rating}</strong></td>
                          <td>
                            <div className="d-flex gap-1 text-warning fs-16">
                              {item.star.map((star, ind) => (
                                <i key={ind} className={"ri-star-" + star}></i>
                              ))}
                            </div>
                          </td>
                          <td>{item.count}</td>
                          <td>{item.percent}</td>
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                </Card.Body>
              </Card>
            </Col> */}
          {/* <Col sm="5" xl>
            <Card className="card-one">
              <Card.Header>
                <Card.Title as="h6">Performance Score</Card.Title>
                <Nav className="nav-icon nav-icon-sm ms-auto">
                  <Nav.Link href=""><i className="ri-refresh-line"></i></Nav.Link>
                  <Nav.Link href=""><i className="ri-more-2-fill"></i></Nav.Link>
                </Nav>
              </Card.Header>
              <Card.Body>
                <div className="d-flex align-items-baseline gap-2 mb-0">
                  <h1 className="card-value mb-0">9.6</h1>
                  <ProgressBar className="flex-fill ht-5">
                    <ProgressBar now={20} />
                    <ProgressBar now={15} variant="success" />
                    <ProgressBar now={15} variant="warning" />
                    <ProgressBar now={20} variant="info" />
                    <ProgressBar now={10} variant="danger" />
                  </ProgressBar>
                </div>
                <p className="fs-sm">The percentage value assigned to the operating metric.</p>
                <Table className="table-ratings mb-0">
                  <tbody>
                    {[
                      {
                        "dot": "primary",
                        "label": "Mehul",
                        "count": "",
                        "percent": "100%"
                      }, {
                        "dot": "success",
                        "label": "Reena",
                        "count": "",
                        "percent": "95%"
                      }, {
                        "dot": "warning",
                        "label": "Dipali",
                        "count": "",
                        "percent": "80%"
                      }, {
                        "dot": "info",
                        "label": "Priti",
                        "count": "",
                        "percent": "70%"
                      }, {
                        "dot": "danger",
                        "label": "Khushali",
                        "count": "",
                        "percent": "50%"
                      }
                    ].map((item, index) => (
                      <tr key={index}>
                        <td><span className={"badge-dot bg-" + item.dot}></span></td>
                        <td><strong>{item.label}</strong></td>
                        <td>{item.count}</td>
                        <td>{item.percent}</td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              </Card.Body>
            </Card>
          </Col> */}
          {/* <Col xs="12">
              <Card className="card-one">
                <Card.Header>
                  <Card.Title as="h6">Agent Performance Score</Card.Title>
                  <Nav className="nav-icon nav-icon-sm ms-auto">
                    <Nav.Link href=""><i className="ri-refresh-line"></i></Nav.Link>
                    <Nav.Link href=""><i className="ri-more-2-fill"></i></Nav.Link>
                  </Nav>
                </Card.Header>
                <Card.Body>
                  <ReactApexChart series={seriesOne} options={optionTwo} type="area" height={200} className="apex-chart-four mb-5" />
  
                  <Table className="table-agent mb-0" responsive>
                    <thead>
                      <tr>
                        <th>
                          <Form.Check type="checkbox" />
                        </th>
                        <th>Agent ID</th>
                        <th>Agent Name</th>
                        <th>Status</th>
                        <th>Quota</th>
                        <th>Reached</th>
                        <th>Progress</th>
                        <th>Rating</th>
                        <th>&nbsp;</th>
                      </tr>
                    </thead>
                    <tbody>
                      {[
                        {
                          "id": "00035",
                          "avatar": img6,
                          "name": "Allan R. Palban",
                          "email": "allan@themepixels.me",
                          "status": {
                            "badge": "success",
                            "label": "Active"
                          },
                          "quota": 120,
                          "reached": 64,
                          "progress": 50,
                          "star": ["fill", "fill", "fill", "half-fill", "line"]
                        }, {
                          "id": "00028",
                          "avatar": img8,
                          "name": "Charlene S. Plateros",
                          "email": "charlene@themepixels.me",
                          "status": {
                            "badge": "info",
                            "label": "Away"
                          },
                          "quota": 100,
                          "reached": 79,
                          "progress": 70,
                          "star": ["fill", "fill", "fill", "fill", "line"]
                        }, {
                          "id": "00025",
                          "avatar": img10,
                          "name": "Adrian M. Moniño",
                          "email": "adrian@themepixels.me",
                          "status": {
                            "badge": "success",
                            "label": "Active"
                          },
                          "quota": 130,
                          "reached": 108,
                          "progress": 75,
                          "star": ["fill", "fill", "fill", "line", "line"]
                        }, {
                          "id": "00024",
                          "avatar": img11,
                          "name": "Marianne B. Audrey",
                          "email": "marianne@themepixels.me",
                          "status": {
                            "badge": "warning",
                            "label": "Pending"
                          },
                          "quota": 110,
                          "reached": 45,
                          "progress": 50,
                          "star": ["fill", "fill", "fill", "fill", "line"]
                        }, {
                          "id": "00023",
                          "avatar": img12,
                          "name": "Carlyn Y. Salomon",
                          "email": "carlyn@themepixels.me",
                          "status": {
                            "badge": "secondary",
                            "label": "Inactive"
                          },
                          "quota": 125,
                          "reached": 32,
                          "progress": 25,
                          "star": ["fill", "fill", "fill", "half-fill", "line"]
                        }
                      ].map((item, index) => (
                        <tr key={index}>
                          <td>
                            <Form.Check type="checkbox" />
                          </td>
                          <td><span className="ff-numerals">{item.id}</span></td>
                          <td>
                            <div className="d-flex align-items-center gap-2">
                              <Avatar img={item.avatar} />
                              <div>
                                <h6 className="mb-0">{item.name}</h6>
                                <span className="fs-xs text-secondary">{item.email}</span>
                              </div>
                            </div>
                          </td>
                          <td><span className={"badge bg-" + item.status.badge}>{item.status.label}</span></td>
                          <td><span className="ff-numerals">{item.quota}</span></td>
                          <td><span className="ff-numerals">{item.reached}</span></td>
                          <td>
                            <ProgressBar now={item.progress} className="ht-5 mb-0" />
                          </td>
                          <td>
                            <div className="d-flex gap-1 text-warning">
                              {item.star.map((star, ind) => (
                                <i key={ind} className={"ri-star-" + star}></i>
                              ))}
                            </div>
                          </td>
                          <td>
                            <div className="d-flex justify-content-end">
                              <Link to="" className="link-more"><i className="ri-more-2-fill"></i></Link>
                            </div>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                </Card.Body>
              </Card>
            </Col> */}
        </Row>

        <Footer />
      </div>
    </React.Fragment>
  )
}
