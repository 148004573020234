import React, { useState, useEffect } from "react";
import Header from "../../layouts/Header";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { addEmployeeSalary } from "../../services/api/api";
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";

const Employeesalaryform = () => {
    const navigate = useNavigate();

    const [state, setState] = useState({
        employee_name: "",
        basic_salary: "",
        working_days: "",
        present_days: "",
        absent_days: "",
        hra: "",
        conveyance: "",
        pf: "",
        overtime: "",
        incentive: "",
        leave: "",
        break_violation: "",
        late_violation: "",
        net_salary: "",
    });

    const [submitting, setSubmitting] = useState(false);
    const [errors, setErrors] = useState({});

    useEffect(() => {
        setState({
            employee_name: "",
            basic_salary: "",
            working_days: "",
            present_days: "",
            absent_days: "",
            hra: "",
            conveyance: "",
            pf: "",
            overtime: "",
            incentive: "",
            leave: "",
            break_violation: "",
            late_violation: "",
            net_salary: "",
        });
        setErrors({});
    }, []);

    const handleChange = (e) => {
        const { name, value } = e.target;

        setState((prevState) => ({
            ...prevState,
            [name]: value,
        }));

        setErrors((prevErrors) => ({
            ...prevErrors,
            [name]: "",
        }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (submitting) {
            return;
        }

        const validationErrors = validateForm(state);
        if (Object.keys(validationErrors).length > 0) {
            setErrors(validationErrors);
            return;
        }

        setSubmitting(true);

        try {
            const response = await addEmployeeSalary(state);
            const data = JSON.parse(response);

            if (data.success === true) {
                toast.success(data.message, {
                    position: "top-center",
                    autoClose: 1000,
                });
            } else {
                toast.warning(data.message, {
                    position: "top-center",
                    autoClose: 1000,
                });
            }
        } catch (error) {
            toast.error("Error adding employee salary", {
                position: "top-right",
                autoClose: 1000,
            });
        } finally {
            setSubmitting(false);
        }
    };

    const validateForm = (state) => {
        let errors = {};

        if (!state.employee_name.trim()) {
            errors.employee_name = "Employee name is required";
        }

        // Add more validation rules for other fields...

        return errors;
    };

    return (
        <React.Fragment>
            <Header />
            <div className="main p-4 p-lg-5 mt-5">
                <div className="mb-4 border-bottom text-center">
                    <h3 className="mb-0 pb- border-bottom custome-btn">Add Employee Salary</h3>
                </div>
                <div className="breadcrumb-warning d-flex justify-content-between ot-card">
          <div>
            <ol className="breadcrumb ot-breadcrumb ot-breadcrumb-basic">
              <li className="breadcrumb-item">
                <Link to="/admin/dashboard">Dashboard</Link>
              </li>
              <li className="breadcrumb-item active">
                <Link to="/admin/employeesalary">Employee salary</Link>
              </li>
              <li className="breadcrumb-item active">Add Employee salary</li>
            </ol>
          </div>
          {/* <nav aria-label="breadcrumb">
            <Link to="/pages/employee/employees" className="btn btn-danger mb-3">
              <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-arrow-left" viewBox="0 0 16 16">
                <path fillRule="evenodd" d="M15 8a.5.5 0 0 0-.5-.5H2.707l3.147-3.146a.5.5 0 1 0-.708-.708l-4 4a.5.5 0 0 0 0 .708l4 4a.5.5 0 0 0 .708-.708L2.707 8.5H14.5A.5.5 0 0 0 15 8z" />
              </svg>
              Back
            </Link></nav> */}
          <nav aria-label="breadcrumb">
            <Link
              to="/admin/employeesalary"
              className="btn btn-primary mb-3"
              style={{
                backgroundColor: 'white',
                border: '1px solid black',
                color: 'black',
                display: 'flex',
                alignItems: 'center',
                textDecoration: 'none',
                padding: '6px 12px',
                borderRadius: '4px',
              }}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                fill="currentColor"
                className="bi bi-arrow-left me-1"
                viewBox="0 0 16 16"
              >
                <path
                  fillRule="evenodd"
                  d="M15 8a.5.5 0 0 0-.5-.5H2.707l3.147-3.146a.5.5 0 1 0-.708-.708l-4 4a.5.5 0 0 0 0 .708l4 4a.5.5 0 0 0 .708-.708L2.707 8.5H14.5A.5.5 0 0 0 15 8z"
                />
              </svg>
              Back
            </Link>
          </nav>


        </div>
                <div className="row"
                    style={{
                        boxShadow: "0 4px 8px 0 rgba(0,0,0,0.2)",
                        borderRadius: "10px",
                        overflow: "hidden",
                    }}
                >
                    <div className="col-md-12 formcontrols">
                        <div className="bg-white p-4 rounded" style={{ height: "100%" }}>
                            <form onSubmit={handleSubmit}>
                                <div className="row">
                                    <div className="col-md-4">
                                        <div className="mb-3">
                                            <label htmlFor="employee_name" className="form-label fw-bold">
                                                Employee Name
                                            </label>
                                            <input
                                                type="text"
                                                className={`form-control ${errors.employee_name && "is-invalid"}`}
                                                id="employee_name"
                                                name="employee_name"
                                                value={state.employee_name}
                                                onChange={handleChange}
                                                placeholder="Employee Name"
                                            />
                                            {errors.employee_name && (
                                                <div className="invalid-feedback">{errors.employee_name}</div>
                                            )}
                                        </div>
                                    </div>
                                    <div className="col-md-4">
                                        <div className="mb-3">
                                            <label htmlFor="basic_salary" className="form-label fw-bold">
                                                Basic Salary
                                            </label>
                                            <input
                                                type="text"
                                                className="form-control"
                                                id="basic_salary"
                                                name="basic_salary"
                                                value={state.basic_salary}
                                                onChange={handleChange}
                                                placeholder="Basic Salary"
                                            />
                                        </div>
                                    </div>
                                    <div className="col-md-4">
                                        <div className="mb-3">
                                            <label htmlFor="working_days" className="form-label fw-bold">
                                                Working Days
                                            </label>
                                            <input
                                                type="text"
                                                className="form-control"
                                                id="working_days"
                                                name="working_days"
                                                value={state.working_days}
                                                onChange={handleChange}
                                                placeholder="Working Days"
                                            />
                                        </div>
                                    </div>
                                </div>


                                <div className="row">
                                    <div className="col-md-4">
                                        <div className="mb-3">
                                            <label htmlFor="present_days" className="form-label fw-bold">
                                                Present Days
                                            </label>
                                            <input
                                                type="text"
                                                className="form-control"
                                                id="present_days"
                                                name="present_days"
                                                value={state.present_days}
                                                onChange={handleChange}
                                                placeholder="Present Days"
                                            />
                                        </div>


                                    </div>
                                    <div className="col-md-4">
                                        <div className="mb-3">
                                            <label htmlFor="absent_days" className="form-label fw-bold">
                                                Absent Days
                                            </label>
                                            <input
                                                type="text"
                                                className="form-control"
                                                id="absent_days"
                                                name="absent_days"
                                                value={state.absent_days}
                                                onChange={handleChange}
                                                placeholder="Absent Days"
                                            />
                                        </div>

                                    </div>

                                    <div className="col-md-4">
                                        <div className="mb-3">
                                            <label htmlFor="hra" className="form-label fw-bold">
                                                HRA
                                            </label>
                                            <input
                                                type="text"
                                                className="form-control"
                                                id="hra"
                                                name="hra"
                                                value={state.hra}
                                                onChange={handleChange}
                                                placeholder="HRA"
                                            />
                                        </div>
                                    </div>
                                </div>


                                <div className="row">
                                    <div className="col-md-4">
                                        <div className="mb-3">
                                            <label htmlFor="conveyance" className="form-label fw-bold">
                                                Conveyance
                                            </label>
                                            <input
                                                type="text"
                                                className="form-control"
                                                id="conveyance"
                                                name="conveyance"
                                                value={state.conveyance}
                                                onChange={handleChange}
                                                placeholder="Conveyance"
                                            />
                                        </div>
                                    </div>
                                    <div className="col-md-4">
                                        <div className="mb-3">
                                            <label htmlFor="pf" className="form-label fw-bold">
                                                PF
                                            </label>
                                            <input
                                                type="text"
                                                className="form-control"
                                                id="pf"
                                                name="pf"
                                                value={state.pf}
                                                onChange={handleChange}
                                                placeholder="PF"
                                            />
                                        </div>
                                    </div>

                                    <div className="col-md-4">
                                        <div className="mb-3">
                                            <label htmlFor="overtime" className="form-label fw-bold">
                                                Overtime
                                            </label>
                                            <input
                                                type="text"
                                                className="form-control"
                                                id="overtime"
                                                name="overtime"
                                                value={state.overtime}
                                                onChange={handleChange}
                                                placeholder="Overtime"
                                            />
                                        </div>
                                    </div>
                                </div>


                                <div className="row">
                                    <div className="col-md-4">
                                        <div className="mb-3">
                                            <label htmlFor="incentive" className="form-label fw-bold">
                                                Incentive
                                            </label>
                                            <input
                                                type="text"
                                                className="form-control"
                                                id="incentive"
                                                name="incentive"
                                                value={state.incentive}
                                                onChange={handleChange}
                                                placeholder="Incentive"
                                            />
                                        </div>
                                    </div>
                                    <div className="col-md-4">
                                        <div className="mb-3">
                                            <label htmlFor="leave" className="form-label fw-bold">
                                                Leave
                                            </label>
                                            <input
                                                type="text"
                                                className="form-control"
                                                id="leave"
                                                name="leave"
                                                value={state.leave}
                                                onChange={handleChange}
                                                placeholder="Leave"
                                            />
                                        </div>
                                    </div>

                                    <div className="col-md-4">
                                        <div className="mb-3">
                                            <label htmlFor="break_violation" className="form-label fw-bold">
                                                Break Violation
                                            </label>
                                            <input
                                                type="text"
                                                className="form-control"
                                                id="break_violation"
                                                name="break_violation"
                                                value={state.break_violation}
                                                onChange={handleChange}
                                                placeholder="Break Violation"
                                            />
                                        </div>
                                    </div>
                                </div>

                                <div className="row">
                                    <div className="col-md-4">
                                        <div className="mb-3">
                                            <label htmlFor="late_violation" className="form-label fw-bold">
                                                Late Violation
                                            </label>
                                            <input
                                                type="text"
                                                className="form-control"
                                                id="late_violation"
                                                name="late_violation"
                                                value={state.late_violation}
                                                onChange={handleChange}
                                                placeholder="Late Violation"
                                            />
                                        </div>
                                    </div>
                                    <div className="col-md-4">
                                        <div className="mb-3">
                                            <label htmlFor="net_salary" className="form-label fw-bold">
                                                Net Salary
                                            </label>
                                            <input
                                                type="text"
                                                className="form-control"
                                                id="net_salary"
                                                name="net_salary"
                                                value={state.net_salary}
                                                onChange={handleChange}
                                                placeholder="Net Salary"
                                            />
                                        </div>
                                    </div>

                                    <div className="col-md-4">

                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-4">



                                    </div>
                                    <div className="col-md-4">


                                    </div>

                                    <div className="col-md-4">

                                    </div>
                                </div>

                                <button type="submit" className="btn btn-primary" onClick={handleSubmit} disabled={submitting}>
                                    {submitting ? 'Submitting...' : 'Add Employee Salary'}
                                </button>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
};

export default Employeesalaryform;
