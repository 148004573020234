import React, { useEffect, useState } from "react";
import Header from "../../layouts/Header";
import { Link, useNavigate } from "react-router-dom";
import '../../styles/globals.css';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "react-datepicker/dist/react-datepicker-cssmodules.css"; // Import date picker CSS
import { CountryDropdown, countryDropdown } from "react-country-region-selector";
import { addJobDetails, BASE_URL, getAllJobDetails } from "../../services/api/api";
import { toast } from "react-toastify";
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import Select from "react-select";
import countryList from 'react-select-country-list';

const Jobform = () => {
  const getCurrentDateTime = () => {
    const now = new Date();
    return now.toISOString().slice(0, 19).replace("T", " "); // Format: "YYYY-MM-DD HH:MM:SS"
  };

  const navigate = useNavigate();

  const [formData, setFormData] = useState({
    jobTitle: "",
    jobType: "",
    companyName: "",
    branch: [],
    numberOfOpening: null,
    country: "",
    state: "",
    city: "",
    pincode: "",
    address: "",
    advertisingPlatform: [],
    fromSalary: null,
    toSalary: null,
    skills: [],
    companyLink: '',
    benefits: "",
    requirements: '',
  });

  const [companyName, setCompanyName] = useState('');
  const [branchNames, setBranchNames] = useState([]);
  const [formErrors, setFormErrors] = useState({});
  const [textEditorValue, setTextEditorValue] = useState('');
  const [countries, setCountries] = useState([]);
  const [saveInProgress, setSaveInProgress] = useState(false);

  const handleEditorChange = (value) => {
    setTextEditorValue(value);
  };

  useEffect(() => {
    // Only call handleSubmit if the save process is in progress
    if (saveInProgress) {
      handleSubmit();
      setSaveInProgress(false); // Reset the flag
    }
  }, [formData.benefits, formData.requirements, saveInProgress]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData(prevData => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSelectChange = (field, selectedOptions) => {
    const values = selectedOptions ? selectedOptions.map(option => option.value) : [];
    setFormData(prevData => ({
      ...prevData,
      [field]: values,
    }));
  };

  useEffect(() => {
    setCountries(countryList().getData());
  }, []);

  const handleSingleSelectChange = (field, selectedOption) => {
    console.log(selectedOption);

    setFormData(prevData => ({
      ...prevData,
      [field]: selectedOption ? selectedOption.label : "",
    }));
  };

  const handleQuillChange = (field, value) => {
    setFormData(prevData => ({
      ...prevData,
      [field]: value,
    }));
  };

  useEffect(() => {
    fetchCompanyDetails()
  }, [])

  const skillsOptions = [
    { value: "javascript", label: "JavaScript" },
    { value: "react", label: "React" },
    { value: "nodejs", label: "Node.js" },
    { value: "python", label: "Python" },
    { value: "java", label: "Java" },
    { value: "css", label: "CSS" },
  ];
  const advertisingOptions = [
    { value: "Social Media", label: "Social Media" },
    { value: "Digital Media", label: "Digital Media" },
    { value: "Print Media", label: "Print Media" },
    { value: "Website", label: "Website" },
  ];

  const [branchOptions, setBranchOptions] = useState([]);

  const fetchCompanyDetails = async () => {
    try {
      const response = await getAllJobDetails();
      console.log("response", response);
      if (response.success) {
        const companyName = response.data.company_name;
        const branches = response.data[0].company_branch.map(branch => ({
          value: branch.branch_name,
          label: branch.branch_name,
        }));

        setBranchOptions(branches);

        setFormData(prevFormData => ({
          ...prevFormData,
          companyName: companyName,
          branch: [],
        }));
      }
    } catch (error) {
    } finally {

    }
  };

  const handleSubmit = async (event) => {
    console.log(formData);
    // Basic validation (you can expand this as needed)
    const errors = {};

    event.preventDefault()
    // Validate fields
    if (!formData.jobTitle) {
      errors.jobTitle = "Job Title is required.";
    }
    if (!formData.jobType) {
      errors.jobType = "Job Type is required.";
    }
    if (!formData.branch.length) {
      errors.branch = "At least one branch is required.";
    }
    if (formData.numberOfOpening <= 0) {
      errors.numberOfOpening = "Number of Openings must be greater than zero.";
    }
    if (!formData.country) {
      errors.country = "Country is required.";
    }
    if (!formData.state) {
      errors.state = "State is required.";
    }
    if (!formData.city) {
      errors.city = "City is required.";
    }
    if (!formData.pincode) {
      errors.pincode = "Pincode is required.";
    }
    if (!formData.address) {
      errors.address = "Address is required.";
    }
    if (!formData.skills.length) {
      errors.skills = "At least one skill is required.";
    }


    // Check if there are any errors
    if (Object.keys(errors).length > 0) {
      setFormErrors(errors);
      return; // Stop the submission if there are errors
    }

    try {
      const response = await addJobDetails(formData);
      console.log(response);

      if (response) {
        toast.success('Job Added Successfully', {
          position: "top-center",
          autoClose: 1000,
        });
        navigate('/pages/recruitment/job');
      }

      console.log('Form submitted successfully!');
      setFormData({}); // Reset form data if needed
      setFormErrors({}); // Clear any existing errors
    } catch (error) {
      console.error('Error submitting form:', error);
      // Handle submission error (e.g., show an error message)
    }
  };


  const stripHtmlTags = (html) => {
    return html
      .replace(/<li>/g, '• ') // Add bullet point for list items
      .replace(/<\/li>/g, '\n') // Add a newline after each list item
      .replace(/<br\s*\/?>/gi, '\n') // Replace <br> tags with a newline
      .replace(/<\/?p>/gi, '\n') // Replace <p> tags with a newline
      .replace(/<[^>]*>?/gm, '') // Remove remaining HTML tags
      .trim(); // Remove any leading/trailing whitespace
  };


  const handleSave = (e) => {
    e.preventDefault();
    const plainBenefits = stripHtmlTags(formData.benefits);
    const plainRequirements = stripHtmlTags(formData.requirements);
    console.log('Submitted content:', plainBenefits);
    console.log('Submitted content:', plainRequirements);

    // setFormData(prevData => ({
    //   ...prevData,
    //   benefits: plainBenefits,
    //   requirements: plainRequirements,
    // }));
    // setSaveInProgress(true);
  };

  return (
    <React.Fragment>
      <Header />
      <div className="main p-4 p-lg-5 mt-5">
        <div className="breadcrumb-warning d-flex justify-content-between ot-card">
          <div>
            <ol className="breadcrumb ot-breadcrumb ot-breadcrumb-basic">
              <li className="breadcrumb-item">
                <Link to="/dashboard/helpdesk">Dashboard</Link>
              </li>
              <li className="breadcrumb-item active">
                <Link to="/pages/recruitment/job">Jobs</Link>
              </li>
              <li className="breadcrumb-item active">Add Job</li>
            </ol>
          </div>
          <nav aria-label="breadcrumb">
            <Link to="/pages/recruitment/job" className="btn btn-primary mb-3">
              <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-arrow-left" viewBox="0 0 16 16">
                <path fillRule="evenodd" d="M15 8a.5.5 0 0 0-.5-.5H2.707l3.147-3.146a.5.5 0 1 0-.708-.708l-4 4a.5.5 0 0 0 0 .708l4 4a.5.5 0 0 0 .708-.708L2.707 8.5H14.5A.5.5 0 0 0 15 8z" />
              </svg>
              Back
            </Link>
          </nav>
        </div>




        <div className="row" style={{ boxShadow: "0 4px 8px 0 rgba(0,0,0,0.2)", borderRadius: "10px", overflow: "hidden" }}>
          <div className="col-md-12">
            <div className="bg-white p-4 rounded" style={{ height: "100%" }}>
              <form>
                <div className="row">
                  <div className="col-md-6 mb-3">
                    <label htmlFor="companyName" className="form-label">Company Name</label>
                    <input
                      type="text"
                      className={`form-control ${formErrors.companyName && "is-invalid"}`}
                      id="companyName"
                      name="companyName"
                      value={formData.companyName}
                      readOnly
                      style={{ cursor: 'not-allowed' }}
                    // onChange={handleInputChange}
                    />
                    {formErrors.companyName && <div className="invalid-feedback">{formErrors.companyName}</div>}
                  </div>

                  <div className="col-md-6 mb-3">
                    <label htmlFor="branch" className="form-label">Branch</label>
                    <Select
                      isMulti
                      isSearchable
                      options={branchOptions} // Use the fetched branch options
                      value={branchOptions.filter(option => formData.branch.includes(option.value))}
                      onChange={(selected) => handleSelectChange("branch", selected)}
                      className={`form-control p-0 ${formErrors.branch && "is-invalid"}`}
                    />
                    {formErrors.branch && <div className="invalid-feedback">{formErrors.branch}</div>}
                  </div>

                  <div className="col-md-6 mb-3">
                    <label htmlFor="jobTitle" className="form-label">Job Title</label>
                    <input
                      type="text"
                      className={`form-control ${formErrors.jobTitle && "is-invalid"}`}
                      id="jobTitle"
                      name="jobTitle"
                      value={formData.jobTitle}
                      onChange={handleInputChange}
                    />
                    {formErrors.jobTitle && <div className="invalid-feedback">{formErrors.jobTitle}</div>}
                  </div>

                  <div className="col-md-6 mb-3">
                    <label htmlFor="jobType" className="form-label">Job Type</label>
                    <select
                      className={`form-select ${formErrors.jobType && "is-invalid"}`}
                      id="jobType"
                      name="jobType"
                      value={formData.jobType}
                      onChange={handleInputChange}
                    >
                      <option value="">Select Job Type</option>
                      <option value="Full-Time">Full-Time</option>
                      <option value="Part-Time">Part-Time</option>
                    </select>
                    {formErrors.jobType && <div className="invalid-feedback">{formErrors.jobType}</div>}
                  </div>



                  <div className="col-md-6 mb-3">
                    <label htmlFor="skills" className="form-label">Skills</label>
                    <Select
                      isMulti
                      isSearchable
                      options={skillsOptions}
                      value={skillsOptions.filter(option => formData.skills.includes(option.value))}
                      onChange={(selected) => handleSelectChange("skills", selected)}
                      className={`form-control p-0 ${formErrors.skills && "is-invalid"}`}
                    />
                    {formErrors.skills && <div className="invalid-feedback">{formErrors.skills}</div>}
                  </div>

                  <div className="col-md-6 mb-3">
                    <label htmlFor="numberOfOpening" className="form-label">Number of Openings</label>
                    <input
                      type="number"
                      className={`form-control ${formErrors.numberOfOpening && "is-invalid"}`}
                      id="numberOfOpening"
                      name="numberOfOpening"
                      value={formData.numberOfOpening || ""}
                      onChange={handleInputChange}
                    />
                    {formErrors.numberOfOpening && <div className="invalid-feedback">{formErrors.numberOfOpening}</div>}
                  </div>

                  {/* From Salary Input */}
                  <div className="col-md-6 mb-3">
                    <label htmlFor="fromSalary" className="form-label">From Salary</label>
                    <input
                      type="text"
                      className={`form-control ${formErrors.fromSalary && "is-invalid"}`}
                      id="fromSalary"
                      name="fromSalary"
                      value={formData.fromSalary}
                      onChange={handleInputChange}
                    />
                    {formErrors.fromSalary && <div className="invalid-feedback">{formErrors.fromSalary}</div>}
                  </div>

                  {/* To Salary Input */}
                  <div className="col-md-6 mb-3">
                    <label htmlFor="toSalary" className="form-label">To Salary</label>
                    <input
                      type="text"
                      className={`form-control ${formErrors.toSalary && "is-invalid"}`}
                      id="toSalary"
                      name="toSalary"
                      value={formData.toSalary}
                      onChange={handleInputChange}
                    />
                    {formErrors.toSalary && <div className="invalid-feedback">{formErrors.toSalary}</div>}
                  </div>

                  <div className="col-md-6 mb-3">
                    <label htmlFor="country" className="form-label">Country</label>
                    <Select
                      options={countries}
                      value={countries.find(option => option.label == formData.country) || null}
                      onChange={(selected) => handleSingleSelectChange("country", selected)}
                      className={`form-control p-0 ${formErrors.country && "is-invalid"}`}
                      placeholder="Select a country"
                    />
                    {formErrors.country && (
                      <div className="invalid-feedback">{formErrors.country}</div>
                    )}
                  </div>

                  <div className="col-md-6 mb-3">
                    <label htmlFor="state" className="form-label">State</label>
                    <input
                      type="text"
                      className={`form-control ${formErrors.state && "is-invalid"}`}
                      id="state"
                      name="state"
                      value={formData.state}
                      onChange={handleInputChange}
                    />
                    {formErrors.state && <div className="invalid-feedback">{formErrors.state}</div>}
                  </div>

                  <div className="col-md-6 mb-3">
                    <label htmlFor="city" className="form-label">City</label>
                    <input
                      type="text"
                      className={`form-control ${formErrors.city && "is-invalid"}`}
                      id="city"
                      name="city"
                      value={formData.city}
                      onChange={handleInputChange}
                    />
                    {formErrors.city && <div className="invalid-feedback">{formErrors.city}</div>}
                  </div>

                  <div className="col-md-6 mb-3">
                    <label htmlFor="pincode" className="form-label">Pincode</label>
                    <input
                      type="text"
                      className={`form-control ${formErrors.pincode && "is-invalid"}`}
                      id="pincode"
                      name="pincode"
                      value={formData.pincode}
                      onChange={handleInputChange}
                    />
                    {formErrors.pincode && <div className="invalid-feedback">{formErrors.pincode}</div>}
                  </div>

                  <div className="col-md-6 mb-3">
                    <label htmlFor="address" className="form-label">Address</label>
                    <input
                      type="text"
                      className={`form-control ${formErrors.address && "is-invalid"}`}
                      id="address"
                      name="address"
                      value={formData.address}
                      onChange={handleInputChange}
                    />
                    {formErrors.address && <div className="invalid-feedback">{formErrors.address}</div>}
                  </div>

                  <div className="col-md-6 mb-3">
                    <label htmlFor="advertisingPlatform" className="form-label">Advertising Platform</label>
                    <Select
                      isMulti
                      isSearchable
                      options={advertisingOptions}
                      value={advertisingOptions.filter(option => formData.advertisingPlatform.includes(option.value))} // Filter for selected values
                      onChange={(selected) => handleSelectChange("advertisingPlatform", selected)}
                      className={`form-control p-0 ${formErrors.advertisingPlatform && "is-invalid"}`}
                    />
                    {formErrors.advertisingPlatform && <div className="invalid-feedback">{formErrors.advertisingPlatform}</div>}
                  </div>

                  <div className="col-md-6 mb-3">
                    <label htmlFor="companyLink" className="form-label">Company Link(LinkedIn)</label>
                    <input
                      type="text"
                      className={`form-control ${formErrors.companyLink && "is-invalid"}`}
                      id="companyLink"
                      name="companyLink"
                      value={formData.companyLink}
                      onChange={handleInputChange}
                    />
                    {formErrors.companyLink && <div className="invalid-feedback">{formErrors.companyLink}</div>}
                  </div>


                  <div className="col-md-12 mb-3">
                    <label htmlFor="benefits" className="form-label">Benefits</label>
                    <ReactQuill
                      value={formData.benefits}
                      onChange={(value) => handleQuillChange('benefits', value)}
                    />
                    {formErrors.benefits && <div className="invalid-feedback">{formErrors.benefits}</div>}
                  </div>

                  <div className="col-md-12 mb-3">
                    <label htmlFor="requirements" className="form-label">Requirements</label>
                    <ReactQuill
                      value={formData.requirements}
                      onChange={(value) => handleQuillChange('requirements', value)}
                    />
                    {formErrors.requirements && <div className="invalid-feedback">{formErrors.requirements}</div>}
                  </div>
                </div>
                <button className="btn btn-primary" onClick={handleSubmit}>Add</button>
              </form>


            </div >
          </div>
        </div>
      </div>
    </React.Fragment >
  );
};

export default Jobform;
