
import React, { useState, useEffect } from 'react';
import { updateBranch, editBranch, changePassword, getId } from '../../../services/api/api';
import { useNavigate, useParams } from 'react-router-dom';
import Header from '../../../layouts/Header';
import HeaderMobile from '../../../layouts/HeaderMobile';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Form } from 'react-bootstrap';
import { Link } from "react-router-dom";

const Changepassword = () => {
    const [oldPassword, setOldPassword] = useState('');
    const [newPassword, setNewPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [message, setMessage] = useState('');
    const [id, setId] = useState('');
    useEffect(() => {
        const empId = getId();
        setId(empId);



    }, []);

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (newPassword !== confirmPassword) {
            setMessage("Passwords don't match");
        } else {
            const response = await changePassword(id, oldPassword, newPassword, confirmPassword);
            if (response && response.success === 'Password updated successfully') {
                setMessage('Password updated successfully!');
                // Reset form fields
                setOldPassword('');
                setNewPassword('');
                setConfirmPassword('');
            } else {
                setMessage('Failed to update password. Please try again.');
            }
        }
    };



    return (
        <React.Fragment>
            <HeaderMobile />
            <Header />

            <div className="main p-4 p-lg-5 mt-5">
            <div className="mb-4 border-bottom text-center">
                        <h3 className="mb-0 pb- border-bottom custome-btn">Change Password</h3>
                    </div>
                <div>

                  
                    <div className="breadcrumb-warning d-flex justify-content-between ot-card">
                        <div>
                            <ol className="breadcrumb ot-breadcrumb ot-breadcrumb-basic">
                                <li className="breadcrumb-item">
                                    <Link to="/employee/dashboard">Dashboard</Link>
                                </li>
                                {/* <li className="breadcrumb-item active">
                                    <Link to="/pages/hr/branch">Branches</Link>
                                </li> */}
                                <li className="breadcrumb-item active">Change password</li>
                            </ol>
                        </div>
                        {/* <nav aria-label="breadcrumb">
                            <Link to="/pages/hr/branch" className="btn btn-danger mb-3">
                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-arrow-left" viewBox="0 0 16 16">
                                    <path fillRule="evenodd" d="M15 8a.5.5 0 0 0-.5-.5H2.707l3.147-3.146a.5.5 0 1 0-.708-.708l-4 4a.5.5 0 0 0 0 .708l4 4a.5.5 0 0 0 .708-.708L2.707 8.5H14.5A.5.5 0 0 0 15 8z" />
                                </svg>
                                Back
                            </Link>
                        </nav> */}
                    </div>


                    <div className="row"
                        style={{
                            boxShadow: "0 4px 8px 0 rgba(0,0,0,0.2)",
                            borderRadius: "10px",
                            overflow: "hidden",
                        }}
                    >


                        <div className="col-md-12 formcontrols">
                            <div className="bg-white p-4 rounded" style={{ height: "100%" }}>
                                {/* <h2>Chnage password</h2> */}
                                <div className="container ">
                                    <form onSubmit={handleSubmit}>
                                     


                                         <div className="row">

                                            {/* <div className="col-md-4">
                                            <div className="mb-3">
                                            <label htmlFor="oldPassword" className="form-label">
                                                Old password
                                            </label>
                                            <input
                                                type="password"
                                                className="form-control"
                                                id="oldPassword"
                                                value={oldPassword}
                                                placeholder="Enter old password"
                                                onChange={(e) => setOldPassword(e.target.value)}
                                                required
                                            />
                                        </div>
                                            </div> */}

                                            <div className="col-md-6">
                                            <div className="mb-3">
                                            <label htmlFor="newPassword" className="form-label">
                                                New password
                                            </label>
                                            <input
                                                type="password"
                                                className="form-control"
                                                id="newPassword"
                                                value={newPassword}
                                                placeholder="new password"
                                                onChange={(e) => setNewPassword(e.target.value)}
                                                required
                                            />
                                        </div>
                                            </div>
                                            <div className="col-md-6">
                                            <div className="mb-3">
                                            <label htmlFor="confirmPassword" className="form-label">
                                                Confirm password
                                            </label>
                                            <input
                                                type="password"
                                                className="form-control"
                                                id="confirmPassword"
                                                value={confirmPassword}
                                                placeholder="confirm password"
                                                onChange={(e) => setConfirmPassword(e.target.value)}
                                                required
                                            />
                                        </div>
                                            </div>
                                        </div>



                                        {message && <div className="alert alert-info">{message}</div>}
                                        <button type="submit" className="btn btn-primary">
                                            Update Password
                                        </button>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>


                </div>
            </div>
        </React.Fragment>
    );
};

export default Changepassword;
