import React, { useState, useEffect, useRef } from 'react';
import Header from "../../layouts/Header";
import HeaderMobile from "../../layouts/HeaderMobile";
import { Card, Col, Row } from "react-bootstrap";
import '../../styles/globals.css';
import '././../../../src/empfile.css'
import { DataGrid } from '@mui/x-data-grid';
import $ from 'jquery';
import 'datatables.net';
import { deleteUser, updateUserPassword, viewUser } from '../../services/api/api';
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { Button } from "react-bootstrap";
import { Modal } from "react-bootstrap";
import { toast } from "react-toastify";
import * as XLSX from 'xlsx'; // Import XLSX library
import _debounce from 'lodash/debounce';
import { TextField } from '@mui/material';



const Viewuser = () => {
  const navigate = useNavigate();
  const tableRef = useRef(null);
  const [userData, setUserData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [deleteUserId, setDeleteUserId] = useState(null);
  const [searchText, setSearchText] = useState('');

  const [actualPassword, setActualPassword] = useState('');
  const [showPassword, setShowPassword] = React.useState(false);
  const [updating, setUpdating] = React.useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedRow, setSelectedRow] = useState(null);
  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Month starts from 0
    const year = date.getFullYear();

    return `${day}/${month}/${year}`;
  };

  const [state, setState] = useState({
    _id: '',
    company_id: '',
    password: '',
  });


  const fetchData = async () => {
    try {
      const formData = {};
      const response = await viewUser(formData);

      if (!response || !response.success || !response.data) {
        throw new Error("Failed to fetch data.");
      }

      setUserData(response.data);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
    finally {
      setLoading(false);
    }
  };
  // useEffect(() => {
  //   fetchData();
  // }, []);
  useEffect(() => {
    const debouncedFetchData = _debounce(fetchData, 100);

    debouncedFetchData();

    return () => {
      debouncedFetchData.cancel();
    };
  }, []);

  const s2ab = (s) => {
    const buf = new ArrayBuffer(s.length);
    const view = new Uint8Array(buf);
    for (let i = 0; i !== s.length; ++i) {
      view[i] = s.charCodeAt(i) & 0xFF;
    }
    return buf;
  };
  const exportToExcel = () => {
    // Create a new workbook
    const workbook = XLSX.utils.book_new();
    // Create a new worksheet with the data
    const worksheet = XLSX.utils.json_to_sheet(
      reversedRows.map((row, index) => ({
        'Sr.': index + 1,
        'User Name': row.user_name || '', // Update field name for user name
        'Email': row.user_email || '', // Update field name for email
        'Joining Date': formatDate(row.user_add_date), // Update field name for joining date and format the date
        'Designation': row.designation || '', // Update field name for designation
      }))
    );
    // Add the worksheet to the workbook
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');
    // Convert the workbook to a binary string
    const excelBinaryString = XLSX.write(workbook, { bookType: 'xlsx', type: 'binary' });
    // Convert the binary string to a Blob
    const excelBlob = new Blob([s2ab(excelBinaryString)], { type: 'application/octet-stream' });
    // Create a download link
    const downloadLink = document.createElement('a');
    downloadLink.href = URL.createObjectURL(excelBlob);
    downloadLink.download = 'User Data.xlsx'; // Update the file name here if needed
    // Trigger the download
    downloadLink.click();
  };
  const reversedRows = [...userData].reverse();

  const columns = [

    {
      field: 'id',
      headerName: 'Sr. No', align: 'center', headerAlign: 'center',
      width: 50,
      flex: 1,
      renderCell: (params) => (
        <span>
          {reversedRows.indexOf(params.row) + 1}
        </span>
      ),
    },
    { field: 'user_name', headerName: 'Name', width: 150, align: 'center', headerAlign: 'center', flex: 2 },
    {
      field: 'user_email', headerName: 'Email', width: 200, align: 'center', headerAlign: 'center', flex: 4,

    },
    { field: 'user_add_date', headerName: 'Joining Date', width: 186, align: 'center', headerAlign: 'center', flex: 2, },
    { field: 'designation', headerName: 'Designation', width: 200, align: 'center', headerAlign: 'center', flex: 3, },

    {
      field: 'actions', headerName: 'Actions', width: 100, align: 'center', headerAlign: 'center', flex: 2, renderCell: (params) => (
        <>

          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="black"
            style={{ cursor: 'pointer', marginRight: '15px' }}
            height="16"
            viewBox="0 0 448 512"
            onClick={() => {
              const id = params.row._id;
              setSelectedRow(id);
              setIsModalOpen(true);
            }}
          >
            <path d="M144 144v48H304V144c0-44.2-35.8-80-80-80s-80 35.8-80 80zM80 192V144C80 64.5 144.5 0 224 0s144 64.5 144 144v48h16c35.3 0 64 28.7 64 64V448c0 35.3-28.7 64-64 64H64c-35.3 0-64-28.7-64-64V256c0-35.3 28.7-64 64-64zM160 288h128c8.8 0 16 7.2 16 16s-7.2 16-16 16H160c-8.8 0-16-7.2-16-16s7.2-16 16-16z" />
          </svg>



          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="16"
            fill="black"
            className="bi bi-pencil-fill edit ebtn"
            viewBox="0 0 16 16"
            data-id={params.row._id}
            style={{ cursor: 'pointer', marginRight: "15px" }}
            onClick={() => {
              const id = params.row._id;
              navigate(`/edit_user/${id}`);
              // console.log("viewemployee id", id);
            }}
          >
            <path d="M12.854.146a.5.5 0 0 0-.707 0L10.5 1.793 14.207 5.5l1.647-1.646a.5.5 0 0 0 0-.708l-3-3zm.646 6.061L9.793 2.5 3.293 9H3.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.207l6.5-6.5zm-7.468 7.468A.5.5 0 0 1 6 13.5V13h-.5a.5.5 0 0 1-.5-.5V12h-.5a.5.5 0 0 1-.5-.5V11h-.5a.5.5 0 0 1-.5-.5V10h-.5a.499.499 0 0 1-.175-.032l-.179.178a.5.5 0 0 0-.11.168l-2 5a.5.5 0 0 0 .65.65l5-2a.5.5 0 0 0 .168-.11l.178-.178z" />
          </svg>

          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="16"
            fill="black"
            className="bi bi-trash3-fill delete delete-button"
            viewBox="0 0 16 16"
            data-id={params.row._id}
            style={{ cursor: 'pointer' }}
            onClick={() => {
              const id = params.row._id;
              openConfirmationModal(id);
            }}
          >
            <path d="M11 1.5v1h3.5a.5.5 0 0 1 0 1h-.538l-.853 10.66A2 2 0 0 1 11.115 16h-6.23a2 2 0 0 1-1.994-1.84L2.038 3.5H1.5a.5.5 0 0 1 0-1H5v-1A1.5 1.5 0 0 1 6.5 0h3A1.5 1.5 0 0 1 11 1.5Zm-5 0v1h4v-1a.5.5 0 0 0-.5-.5h-3a.5.5 0 0 0-.5.5ZM4.5 5.029l.5 8.5a.5.5 0 1 0 .998-.06l-.5-8.5a.5.5 0 1 0-.998.06Zm6.53-.528a.5.5 0 0 0-.528.47l-.5 8.5a.5.5 0 0 0 .998.058l.5-8.5a.5.5 0 0 0-.47-.528ZM8 4.5a.5.5 0 0 0-.5.5v8.5a.5.5 0 0 0 1 0V5a.5.5 0 0 0-.5-.5Z" />
          </svg>
        </>
      )
    },
  ];
  const handleSearch = (event) => {
    setSearchText(event.target.value);
  };

  const filterRows = () => {
    if (!searchText) {
      return reversedRows;
    }

    return reversedRows.filter((row) =>
      Object.values(row).some(
        (value) =>
          typeof value === 'string' && value.toLowerCase().includes(searchText.toLowerCase())
      )
    );
  };



  const handleDeleteUser = async (id) => {
    console.log("handleDeleteUser", id);
    setDeleteUserId(id);
    setShowConfirmation(true);

    try {
      const response = await deleteUser(id);
      if (response && response.status === 'success') {
        if (!toast.isActive('userDeletedToast')) {
          toast.success('User deleted successfully', { toastId: 'userDeletedToast' });
        }
        fetchData();
      } else {
        console.error('Failed to delete user.');
      }
    } catch (error) {
      console.error('Error deleting user:', error);
      toast.error('An error occurred while deleting');
    }
  };


  const openConfirmationModal = (id) => {
    setDeleteUserId(id);
    setShowConfirmation(true);
  };

  const closeConfirmationModal = () => {
    setShowConfirmation(false);
  };


  const handleModalClose = () => {
    setIsModalOpen(false);
  };

  const handlePasswordChange = (e) => {
    setState((prevState) => ({
      ...prevState,
      password: e.target.value,
    }));
  };

  const handleActualPasswordChange = (e) => {
    setActualPassword(e.target.value);
  };

  const handleUpdatePassword = async (e) => {
    e.preventDefault();
    if (updating) {
      return;
    }
    setUpdating(true);

    try {
      const response = await updateUserPassword(selectedRow, state.password, actualPassword);

      if (response && response.success && response.success.length > 0) {
        toast.success(response.success[0].message, {
          position: 'top-center',
          autoClose: 1000,
        });
        setIsModalOpen(false);
        setState((prevState) => ({
          ...prevState,
          password: '',
        }));
      } else {
        toast.success('Password updated Successfully', {
          position: 'top-right',
          autoClose: 1000,
        });
      }
    } catch (error) {
      console.error('An error occurred:', error);
      toast.error('An error occurred', {
        position: 'top-right',
        autoClose: 1000,
      });
    } finally {
      setUpdating(false);
      fetchData();
      setIsModalOpen(false);
    }
  };
  const toggleShowPassword = () => {
    setShowPassword(!showPassword);
  };
  return (
    <React.Fragment>
      <HeaderMobile />
      <Header />
      {/* Your header components */}
      <div className="main p-4 p-lg-5 mt-5">
        {/* Your breadcrumb section */}
        <div className="breadcrumb-warning d-flex justify-content-between ot-card">
          <div>
            <ol className="breadcrumb ot-breadcrumb ot-breadcrumb-basic">
              {/* <li className="mx-2">
                <h4>Employees</h4>
              </li> */}
              <li class="breadcrumb-item ">
                <Link to="/admin/dashboard">Dashboard</Link>
              </li>
              <li class="breadcrumb-item active"><Link to="/pages/user/viewuser">User</Link></li>
              {/* <li class="breadcrumb-item active">Lists</li> */}
            </ol>
          </div>
          <nav aria-label="breadcrumb">
            <Link to="/pages/user/userform">
              <Button variant="primary" className="mb-3">
                <span style={{ color: "white" }}>
                  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-plus-lg" viewBox="0 0 16 16">
                    <path fill-rule="evenodd" d="M8 2a.5.5 0 0 1 .5.5v5h5a.5.5 0 0 1 0 1h-5v5a.5.5 0 0 1-1 0v-5h-5a.5.5 0 0 1 0-1h5v-5A.5.5 0 0 1 8 2Z" />
                  </svg>
                  Add
                </span>
              </Button>
            </Link>
          </nav>
        </div>
        <Card className="popup-card empHistory">
          <Card.Body>
            <div style={{ display: 'flex', justifyContent: "center", alignItems: 'center', justifyContent: 'space-between', marginBottom: "1rem" }}>
              <Button className='btn btn-primary border-0' onClick={exportToExcel}>Export</Button>
              {/* <div style={{ display: 'flex', alignItems: 'center' }}>
                <label htmlFor="searchInput"></label>
                <input
                  type="text"
                  className='p-1 w-75 ms-2'
                  placeholder="Search"
                  value={searchText}
                  onChange={handleSearch}
                />
              </div> */}
              <div className="col-md-11 ">
                <Row>
                  <Col md={9} className="mb-3 pt-3">
                  </Col>
                  <Col md={3} className="mb-3 pt-3 d-flex justify-content-end pe-0">
                    <TextField
                      label="Search"
                      variant="outlined"
                      value={searchText}
                      onChange={handleSearch}
                      size="small"

                    />
                  </Col>
                </Row>
              </div>
            </div>
            {loading ? (
              <div className="loader-container">
                <div className="loader"></div>
              </div>
            ) : (
              <div style={{ height: 500, width: '100%', backgroundColor: 'white' }}>
                <DataGrid
                  rows={userData}
                  columns={columns}
                  pageSize={5}
                  // checkboxSelection
                  getRowId={(row) => row._id}
                  components={{
                    NoRowsOverlay: () => <div style={{ textAlign: 'center', padding: '20px' }}>No data available</div>,
                  }}
                />
              </div>
            )}
          </Card.Body>

        </Card>
      </div>

      <Modal show={showConfirmation} onHide={closeConfirmationModal}>
        <Modal.Header closeButton>
          <Modal.Title>Confirm Deletion</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Are you sure you want to delete this User?
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={closeConfirmationModal}>
            Cancel
          </Button>
          <Button variant="danger" onClick={() => {
            handleDeleteUser(deleteUserId);
            closeConfirmationModal();
          }}>
            Delete
          </Button>
        </Modal.Footer>
      </Modal>


      <Modal show={isModalOpen} onHide={handleModalClose}>
        <Modal.Header closeButton>
          <Modal.Title>Update Password</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form onSubmit={handleUpdatePassword}>
            <div className="form-group">
              <label htmlFor="actualPassword">New Password:</label>
              <input
                type={showPassword ? 'text' : 'password'}
                className="form-control"
                id="actualPassword"
                value={actualPassword}
                onChange={handleActualPasswordChange}
                required
              />
            </div>
            <div className="form-group">
              <label htmlFor="newPassword">Confirm Password:</label>
              <input
                type={showPassword ? 'text' : 'password'}
                className="form-control"
                id="newPassword"
                value={state.password}
                onChange={handlePasswordChange}
                required
              />
            </div>
            {/* <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              fill="currentColor"
              className="bi bi-eye"
              viewBox="0 0 16 16"
              style={{ cursor: 'pointer' }}
              onClick={toggleShowPassword}
            >
              <path d="M16 8s-3-5.5-8-5.5S0 8 0 8s3 5.5 8 5.5S16 8 16 8M1.173 8a13 13 0 0 1 1.66-2.043C4.12 4.668 5.88 3.5 8 3.5s3.879 1.168 5.168 2.457A13 13 0 0 1 14.828 8q-.086.13-.195.288c-.335.48-.83 1.12-1.465 1.755C11.879 11.332 10.119 12.5 8 12.5s-3.879-1.168-5.168-2.457A13 13 0 0 1 1.172 8z" />
              <path d="M8 5.5a2.5 2.5 0 1 0 0 5 2.5 2.5 0 0 0 0-5M4.5 8a3.5 3.5 0 1 1 7 0 3.5 3.5 0 0 1-7 0" />
            </svg>
            <button type="submit" className="btn btn-primary" disabled={updating} style={{ marginTop: '10px' }}>
              {updating ? 'Updating...' : 'Update Password'}
            </button> */}
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                fill="currentColor"
                className="bi bi-eye"
                viewBox="0 0 16 16"
                style={{ cursor: 'pointer', marginRight: '8px' }} // Adjust the marginRight as needed
                onClick={toggleShowPassword}
              >
                <path d="M16 8s-3-5.5-8-5.5S0 8 0 8s3 5.5 8 5.5S16 8 16 8M1.173 8a13 13 0 0 1 1.66-2.043C4.12 4.668 5.88 3.5 8 3.5s3.879 1.168 5.168 2.457A13 13 0 0 1 14.828 8q-.086.13-.195.288c-.335.48-.83 1.12-1.465 1.755C11.879 11.332 10.119 12.5 8 12.5s-3.879-1.168-5.168-2.457A13 13 0 0 1 1.172 8z" />
                <path d="M8 5.5a2.5 2.5 0 1 0 0 5 2.5 2.5 0 0 0 0-5M4.5 8a3.5 3.5 0 1 1 7 0 3.5 3.5 0 0 1-7 0" />
              </svg>
              <button type="submit" className="btn btn-primary" disabled={updating} style={{ marginTop: '10px' }}>
                {updating ? 'Updating...' : 'Update Password'}
              </button>
            </div>

          </form>
        </Modal.Body>
      </Modal>
    </React.Fragment>
  );

}
export default Viewuser;




















