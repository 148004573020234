
import React, { useState, useEffect, useRef } from 'react';
import _debounce from 'lodash/debounce';
import HeaderMobile from '../../layouts/HeaderMobile';
import Header from '../../layouts/Header';
import { Card, Button, Modal, Row, Col } from "react-bootstrap";
import $ from 'jquery';
import 'datatables.net';
import { viewLeave, deleteLeave } from '../../services/api/api'
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { DataGrid } from '@mui/x-data-grid';
import * as XLSX from 'xlsx'; // Import XLSX library
import { TextField } from '@mui/material';
const ViewLeave = () => {
  const navigate = useNavigate();
  const tableRef = useRef(null);
  const [leaveData, setLeaveData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [leaveId, setDeleteLeaveId] = useState(null);
  const [masterId, setMasterId] = useState();
  const [searchText, setSearchText] = useState('');


  // const fetchData = async () => {
  //   try {
  //     const formattedData = await viewLeave();

  //     if (!formattedData || !formattedData.length) {
  //       throw new Error('Failed to fetch data.');
  //     }

  //     const companyLeave = formattedData[0].company_leave;
  //     setMasterId(formattedData[0]._id);
  //     const leaveArray = Object.values(companyLeave).map(item => ({

  //       _id: item._id,
  //       leave_type: item.leave_type || 'N/A',
  //     }));

  //     setLeaveData(leaveArray);
  //   } catch (error) {
  //     console.error('Error fetching data:', error);
  //   } finally {   
  //     setLoading(false);
  //   }
  // };

  // useEffect(() => {
  //   fetchData();
  // }, []);



  useEffect(() => {
    if (sessionStorage.getItem("userType") !== "employee") {
      const debouncedFetchData = _debounce(fetchData, 100);
      debouncedFetchData();
      return () => {
        debouncedFetchData.cancel();
      };
    }
  }, []);

  const fetchData = async () => {
    try {
      const formattedData = await viewLeave();

      if (!formattedData || !formattedData.length) {
        throw new Error('Failed to fetch data.');
      }

      const companyLeave = formattedData[0].company_leave;
      setMasterId(formattedData[0]._id);
      const leaveArray = Object.values(companyLeave).map((item, index) => ({
        _id: item._id,
        id: index + 1,
        leave_type: item.leave_type || 'N/A',
      }));

      setLeaveData(leaveArray);
    } catch (error) {
      console.error('Error fetching data:', error);
    } finally {
      setLoading(false);
    }
  };

  const s2ab = (s) => {
    const buf = new ArrayBuffer(s.length);
    const view = new Uint8Array(buf);
    for (let i = 0; i !== s.length; ++i) {
      view[i] = s.charCodeAt(i) & 0xFF;
    }
    return buf;
  };

  const exportToExcel = () => {
    // Create a new workbook
    const workbook = XLSX.utils.book_new();
    // Create a new worksheet with the data
    const worksheet = XLSX.utils.json_to_sheet(
      reversedRows.map((row, index) => ({
        'Sr.': index + 1,
        'Leave Type': row.leave_type || '', // Update field name here
      }))
    );
    // Add the worksheet to the workbook
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');
    // Convert the workbook to a binary string
    const excelBinaryString = XLSX.write(workbook, { bookType: 'xlsx', type: 'binary' });
    // Convert the binary string to a Blob
    const excelBlob = new Blob([s2ab(excelBinaryString)], { type: 'application/octet-stream' });
    // Create a download link
    const downloadLink = document.createElement('a');
    downloadLink.href = URL.createObjectURL(excelBlob);
    downloadLink.download = 'Leave Type Data.xlsx';
    // Trigger the download
    downloadLink.click();
  };

  const reversedRows = [...leaveData].reverse();

  const columns = [

    {
      field: 'id',
      headerName: 'Sr. No', align: 'center', headerAlign: 'center',
      width: 150,
      renderCell: (params) => (
        <span>
          {reversedRows.indexOf(params.row) + 1}
        </span>
      ),
    },
    {
      field: 'leave_type', headerName: 'Leave Type', width: 200, align: 'center', headerAlign: 'center', flex: 2
    },
    {
      field: 'actions', headerName: 'Actions', width: 150, align: 'center', headerAlign: 'center', flex: 2, renderCell: (params) => (
        <>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="16"
            fill="black"
            className="bi bi-pencil-fill edit ebtn"
            viewBox="0 0 16 16"
            data-id={params.row._id}
            style={{ cursor: 'pointer', marginRight: "15px" }}
            onClick={() => {
              const id = params.row._id;
              navigate(`/edit_leave/${id}/${masterId}`);
              // console.log("viewemployee id", id);
            }}
          >
            <path d="M12.854.146a.5.5 0 0 0-.707 0L10.5 1.793 14.207 5.5l1.647-1.646a.5.5 0 0 0 0-.708l-3-3zm.646 6.061L9.793 2.5 3.293 9H3.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.207l6.5-6.5zm-7.468 7.468A.5.5 0 0 1 6 13.5V13h-.5a.5.5 0 0 1-.5-.5V12h-.5a.5.5 0 0 1-.5-.5V11h-.5a.5.5 0 0 1-.5-.5V10h-.5a.499.499 0 0 1-.175-.032l-.179.178a.5.5 0 0 0-.11.168l-2 5a.5.5 0 0 0 .65.65l5-2a.5.5 0 0 0 .168-.11l.178-.178z" />
          </svg>

          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="16"
            fill="black"
            className="bi bi-trash3-fill delete delete-button"
            viewBox="0 0 16 16"
            data-id={params.row._id}
            style={{ cursor: 'pointer' }}
            onClick={() => {
              const id = params.row._id;
              openConfirmationModal(id);
            }}
          >
            <path d="M11 1.5v1h3.5a.5.5 0 0 1 0 1h-.538l-.853 10.66A2 2 0 0 1 11.115 16h-6.23a2 2 0 0 1-1.994-1.84L2.038 3.5H1.5a.5.5 0 0 1 0-1H5v-1A1.5 1.5 0 0 1 6.5 0h3A1.5 1.5 0 0 1 11 1.5Zm-5 0v1h4v-1a.5.5 0 0 0-.5-.5h-3a.5.5 0 0 0-.5.5ZM4.5 5.029l.5 8.5a.5.5 0 1 0 .998-.06l-.5-8.5a.5.5 0 1 0-.998.06Zm6.53-.528a.5.5 0 0 0-.528.47l-.5 8.5a.5.5 0 0 0 .998.058l.5-8.5a.5.5 0 0 0-.47-.528ZM8 4.5a.5.5 0 0 0-.5.5v8.5a.5.5 0 0 0 1 0V5a.5.5 0 0 0-.5-.5Z" />
          </svg>
        </>
      )
    },
  ];
  const handleSearch = (event) => {
    setSearchText(event.target.value);
  };

  const filterRows = () => {
    if (!searchText) {
      return reversedRows;
    }

    return reversedRows.filter((row) =>
      Object.values(row).some(
        (value) =>
          typeof value === 'string' && value.toLowerCase().includes(searchText.toLowerCase())
      )
    );
  };

  const [refreshData, setRefreshData] = useState(1);

  const handleDeleteLeave = async (id, masterId) => {
    setDeleteLeaveId(id);
    setShowConfirmation(true);

    try {
      const response = await deleteLeave(id, masterId);
      if (response && response.status === 'success') {
        if (!toast.isActive('leaveDeletedToast')) {
          toast.success('Leave deleted successfully', { toastId: 'leaveDeletedToast', autoClose: 1000 });
        }
        fetchData();
        setRefreshData(prev => prev + 1);
      } else {
        // Handle failure case
      }
    } catch (error) {
      console.error('Error deleting leave:', error);
    }
  };


  const openConfirmationModal = (id) => {
    setDeleteLeaveId(id);
    setShowConfirmation(true);
  };

  const closeConfirmationModal = () => {
    setShowConfirmation(false);
  };

  return (
    <React.Fragment>
      {/* Header components */}
      <HeaderMobile />
      <Header />
      <div className="main p-4 p-lg-5 mt-5">
        {/* Breadcrumb section */}
        <div className="breadcrumb-warning d-flex justify-content-between ot-card">
          <div>
            <ol className="breadcrumb ot-breadcrumb ot-breadcrumb-basic">
              {/* <li className="mx-2">
                    <h4>Holidays</h4>
                  </li> */}
              <li class="breadcrumb-item ">
                <Link to="/admin/dashboard">Dashboard</Link>
              </li>
              <li class="breadcrumb-item active"><Link to="/pages/leave">Leaves</Link></li>
              {/* <li class="breadcrumb-item active">Lists</li> */}
            </ol>
          </div>
          <nav aria-label="breadcrumb">

            <Link to="/pages/leaves/addleave">
              <div><button className="btn btn-primary" type="submit"><span>
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-plus-lg" viewBox="0 0 16 16">
                  <path fill-rule="evenodd" d="M8 2a.5.5 0 0 1 .5.5v5h5a.5.5 0 0 1 0 1h-5v5a.5.5 0 0 1-1 0v-5h-5a.5.5 0 0 1 0-1h5v-5A.5.5 0 0 1 8 2Z" />
                </svg>Add</span></button></div>
            </Link>

          </nav>
        </div>
        <Card className="popup-card empHistory">
          <Card.Body>
            <div style={{ display: 'flex', justifyContent: "center", alignItems: 'center', justifyContent: 'space-between', marginBottom: "1rem" }}>
              <Button className='btn btn-primary border-0' onClick={exportToExcel}>Export</Button>
              {/* <div style={{ display: 'flex', alignItems: 'center' }}>
                <label htmlFor="searchInput"></label>
                <input
                  type="text"
                  className='p-1 w-75 ms-2'
                  placeholder="Search"
                  value={searchText}
                  onChange={handleSearch}
                />
              </div> */}
              <div className="col-md-11">
                <Row>

                  <Col md={9} className="mb-3 pt-3">
                  </Col>
                  <Col md={3} className="mb-3 pt-3 d-flex justify-content-end pe-0">
                    <TextField
                      label="Search"
                      variant="outlined"
                      value={searchText}
                      onChange={handleSearch}
                      size="small"

                    />
                  </Col>
                </Row>
              </div>
            </div>
            {loading ? (
              <div className="loader-container">
                <div className="loader"></div>
              </div>
            ) : (
              <div style={{ height: '70vh', width: '70vw', backgroundColor: 'white' }}>
                <DataGrid
                  rows={leaveData}
                  columns={columns}
                  pageSize={5}
                  // checkboxSelection
                  getRowId={(row) => row._id}
                  components={{
                    NoRowsOverlay: () => <div style={{ textAlign: 'center', padding: '20px' }}>No data available</div>,
                  }}
                />
              </div>
            )}
          </Card.Body>
        </Card>
      </div>

      <Modal show={showConfirmation} onHide={closeConfirmationModal}>
        <Modal.Header closeButton>
          <Modal.Title>Confirm Deletion</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Are you sure you want to delete this leave?
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={closeConfirmationModal}>
            Cancel
          </Button>
          {/* <Button
                variant="danger"
                onClick={() => {
                  handledeleteHoliday(deleteHolidayId);
                  closeConfirmationModal();
                }}
              >
                Delete
              </Button> */}
          <Button
            variant="danger"
            onClick={() => {
              handleDeleteLeave(leaveId, masterId);
              closeConfirmationModal();
            }}

          >
            Delete
          </Button>

        </Modal.Footer>
      </Modal>
    </React.Fragment>
  );
};

export default ViewLeave
