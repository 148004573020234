// import React, { useState, useEffect } from 'react';
// import dayjs from 'dayjs';import { singleViewEmployeeAttendance, getId,viewEmployeeBreakCount } from '.././../services/api/api';
// import '../../styles/globals.css'
// import {
 
//   LocalizationProvider,
//   DateCalendar,
//   DateRangePicker,
// } from '@mui/x-date-pickers';

// import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
// import Badge from '@mui/material/Badge';
// import { PickersDay } from '@mui/x-date-pickers/PickersDay';
// import CheckCircleIcon from '@mui/icons-material/CheckCircle';
// import Modal from 'react-bootstrap/Modal';
// import Button from 'react-bootstrap/Button';
// import Card from 'react-bootstrap/Card';
// import { DayCalendarSkeleton } from '@mui/x-date-pickers/DayCalendarSkeleton';

// function ServerDay(props) {
//   const { day, outsideCurrentMonth, fetchedData, ...other } = props;
//   const [isModalOpen, setModalOpen] = React.useState(false);

//   const getAttendanceForDay = () => {
//     const dayString = day.format('YYYY-MM-DD');

//     return fetchedData.filter((item) =>
//       item.attendance.some((attendanceItem) => attendanceItem.date === dayString)
//     );
//   };

//   const handleDayClick = () => {
//     setModalOpen(true);
//   };

//   const handleCloseModal = () => {
//     setModalOpen(false);
//   };

//   const attendanceForDay = getAttendanceForDay();

//   const hasAttendance = attendanceForDay.length > 0;

//   return (
//     <>
//       <div style={{ cursor: 'pointer' }} onClick={handleDayClick}>
//         <Badge
//           key={day.toString()}
//           overlap="circular"
//           badgeContent={
//             hasAttendance ? (
//               day.isSame(dayjs(), 'day') ? (
//                 <CheckCircleIcon style={{ color: 'green' }} />
//               ) : (
//                 null
//               )
//             ) : null
//           }
//         >
//           <PickersDay {...other} day={day} />
//         </Badge>
//       </div>
//       <Modal show={isModalOpen} onHide={handleCloseModal}>
//         <Modal.Header closeButton>
//           <Modal.Title>{day.format('YYYY-MM-DD')}</Modal.Title>
//         </Modal.Header>
//         <Modal.Body>
//           {attendanceForDay.map((attendanceItem) => (
//             <div key={attendanceItem.id}>
//               <p>Name: {attendanceItem.name}</p>
//               {attendanceItem.attendance.map((item) => (
//                 <p key={item.id}>
//                   Date: {item.date}, IN: {item.IN}, OUT: {item.OUT || 'N/A'}
//                 </p>
//               ))}
//             </div>
//           ))}
//         </Modal.Body>
//         <Modal.Footer>
//           <Button variant="secondary" onClick={handleCloseModal}>
//             Close
//           </Button>
//         </Modal.Footer>
//       </Modal>
//     </>
//   );
// }

  

// function CustomWeekday({ weekday, ...other }) {
//   return (
//     <div className="custom-weekday" style={{ margin: '0 10px' }}>
//       {weekday}
//     </div>
//   );
// }

//  function DateCalendarServerRequest() {
//     const requestAbortController = React.useRef(null);
//     const [isLoading, setIsLoading] = React.useState(false);
//     const [highlightedDays, setHighlightedDays] = React.useState([]);
//     const [loading, setLoading] = useState(true);
//     const [fetchedData, setFetchedData] = useState([]);
//     const [employeeId, setEmployeeId] = useState('');
//     const [value, setValue] = useState(dayjs());
    
//     useEffect(() => {
//      const empId = getId()
//      setEmployeeId(empId)
//     }, []);

//     useEffect(() => {
//         fetchData();
//       }, [employeeId]);
      
//       useEffect(() => {
//         fetchHighlightedDays(initialValue);
//         console.log('Fetched Data:', fetchedData);
//         return () => requestAbortController.current?.abort();
//       }, []);
      
//       const fetchHighlightedDays = () => {
//         const controller = new AbortController();
      
//         // Assuming fetchedData contains the attendance information
//         const allDates = fetchedData.reduce((dates, item) => {
//           return [...dates, ...item.attendance.map((attendanceItem) => attendanceItem.date)];
//         }, []);
      
//         const uniqueDates = [...new Set(allDates)];
      
//         setHighlightedDays(uniqueDates);
//         setIsLoading(false);
      
//         console.log("Matching Dates in API Response:", uniqueDates);
      
//         requestAbortController.current = controller;
//       };
      


//     const initialValue = dayjs();

//     const fetchData = async () => {
//         try {
//             const formattedData = await singleViewEmployeeAttendance(employeeId);
// console.log(formattedData)
//             if (!formattedData || !formattedData.length) {
//                 throw new Error('Failed to fetch data.');
//             }

//             const firstAttendanceItem = formattedData[0].attendance_list[0];
//             console.log(firstAttendanceItem)
//             const viewEmployeeAttendanceArray = firstAttendanceItem.attendance.map((item) => ({
//                 _id: firstAttendanceItem.id,
//                 name: firstAttendanceItem.name || 'N/A',
//                 date: item.date || 'N/A',
//                 time: item.time || 'N/A',
//             }));

//             setFetchedData(viewEmployeeAttendanceArray);
//         } catch (error) {
//             console.error('Error fetching data:', error);
//         } finally {
//             setLoading(false);
//         }
//     };

//     useEffect(() => {
//         fetchData();
//     }, [employeeId]);

//     useEffect(() => {
//         fetchHighlightedDays(initialValue);
//         return () => requestAbortController.current?.abort();
//     }, []);

//     const handleMonthChange = (date) => {
//     if (requestAbortController.current) {
//       requestAbortController.current.abort();
//     }
//     setIsLoading(true);
//     setHighlightedDays([]);
//     setValue(date); // Update the controlled value
//     fetchHighlightedDays(date);
//   };

//   return (
//     <div className="date-calendar-container">
//     <LocalizationProvider dateAdapter={AdapterDayjs}>
//       <DateCalendar
//         value={value}
//         loading={isLoading}
//         onMonthChange={handleMonthChange}
//         renderLoading={() => <DayCalendarSkeleton />}
//         slots={{
//           day: (props) => <ServerDay {...props} fetchedData={fetchedData} />,
//           weekdays: CustomWeekday,
//         }}
//         slotProps={{
//           day: {
//             highlightedDays,
//           },
//         }}
//       />
//     </LocalizationProvider>
//   </div>
// );
// }

// export default DateCalendarServerRequest

import React, { useState, useEffect } from 'react';
import dayjs from 'dayjs';
import Badge from '@mui/material/Badge';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { PickersDay } from '@mui/x-date-pickers/PickersDay';
import { DateCalendar } from '@mui/x-date-pickers/DateCalendar';
import { DayCalendarSkeleton } from '@mui/x-date-pickers/DayCalendarSkeleton';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
// import Modal from '@mui/material/Modal';
import { viewAttendance } from '../../services/api/api';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';

 



function ServerDay(props) {
    const { day, outsideCurrentMonth, ...other } = props;
    const [isModalOpen, setModalOpen] = useState(false);

    const dateToCheck = day.format('YYYY-MM-DD');
    const isDateInFetchedData = () => {
        return props.fetchedData.some((item) => item.date === dateToCheck);
    };

    const isSelected = !outsideCurrentMonth && isDateInFetchedData();

    const handleDayClick = () => {
        setModalOpen(true);
    };

    const handleCloseModal = () => {
        setModalOpen(false);
    };

   
    
    return (
        <>
            <div style={{  cursor: 'pointer' }} onClick={handleDayClick}>
                <Badge
                    key={day.toString()}
                    overlap="circular"
                    badgeContent={isSelected ? <CheckCircleIcon style={{ color: 'green' }} /> : undefined}
                >
                    <PickersDay {...other} outsideCurrentMonth={outsideCurrentMonth} day={day} />
                </Badge>
            </div>
            <Modal show={isModalOpen} onHide={handleCloseModal}>
                <Modal.Header closeButton>
                    <Modal.Title>{dateToCheck}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Card>
                        <Card.Body>
                            <Card.Text>
                                Display here all break report
                            </Card.Text>
                        </Card.Body>
                    </Card>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleCloseModal}>
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
}

function CustomWeekday({ weekday, ...other }) {
    return (
        <div className="custom-weekday" style={{ margin: '0 10px' }}>
            {weekday}
        </div>
    );
}

export function DateCalendarServerRequest() {
    const requestAbortController = React.useRef(null);
    const [isLoading, setIsLoading] = React.useState(false);
    const [highlightedDays, setHighlightedDays] = React.useState([1, 2, 15]);
    const [loading, setLoading] = useState(true);
    const [fetchedData, setFetchedData] = useState([]);

    const fakeFetch = (date, { signal }) => {
        return new Promise((resolve, reject) => {
            const timeout = setTimeout(() => {
                const daysInMonth = date.daysInMonth();
                const daysToHighlight = [1, 2, 3].map(() => Math.round(Math.random() * (daysInMonth - 1) + 1));

                resolve({ daysToHighlight });
            }, 500);

            signal.onabort = () => {
                clearTimeout(timeout);
                reject(new DOMException('aborted', 'AbortError'));
            };
        });
    };

    const initialValue = dayjs();

    const fetchData = async () => {
        try {
            const formattedData = await viewAttendance();

            if (!formattedData || !formattedData.length) {
                throw new Error('Failed to fetch data.');
            }

            const firstAttendanceItem = formattedData[0].attendance_list[0];
            const viewEmployeeAttendanceArray = firstAttendanceItem.attendance.map((item) => ({
                _id: firstAttendanceItem.id,
                name: firstAttendanceItem.name || 'N/A',
                date: item.date || 'N/A',
                time: item.time || 'N/A',
            }));

            setFetchedData(viewEmployeeAttendanceArray);
        } catch (error) {
            console.error('Error fetching data:', error);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchData();
    }, []);

   const fetchHighlightedDays = () => {
    const controller = new AbortController();

    // Assuming fetchedData contains the attendance information
    const allDates = fetchedData.map((item) => dayjs(item.date).date());
    const uniqueDates = [...new Set(allDates)];

    setHighlightedDays(uniqueDates);
    setIsLoading(false);

    requestAbortController.current = controller;
};


    useEffect(() => {
        fetchHighlightedDays(initialValue);
        return () => requestAbortController.current?.abort();
    }, []);

    const handleMonthChange = (date) => {
        if (requestAbortController.current) {
            requestAbortController.current.abort();
        }

        setIsLoading(true);
        setHighlightedDays([]);
        fetchHighlightedDays(date);
    };

    return (
        // <div className="main p-4 p-lg-5 mt-5">
        // <div className="col-md-12">
        //     <Card style={{ boxShadow: "0 4px 8px 0 rgba(0,0,0,0.2)", borderRadius: "10px", overflow: "hidden", border: "none" , display:"flex", justifyContent:"center" , alignItems:"center"}}>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DateCalendar
                defaultValue={initialValue}
                loading={isLoading}
                onMonthChange={handleMonthChange}
                renderLoading={() => <DayCalendarSkeleton />}
                slots={{
                    day: (props) => <ServerDay {...props} fetchedData={fetchedData} />,
                    weekdays: CustomWeekday,
                }}
                slotProps={{
                    day: {
                        highlightedDays,
                    },
                }}
                // style={{ width: '800px', height: '600px' }}
            />
        </LocalizationProvider>
        // </Card>
        // </div>
        // </div>
    );
}


export default DateCalendarServerRequest
