
import React, { useEffect, useState } from 'react';
import _ from 'lodash';
import _debounce from 'lodash/debounce';
import { Link, Navigate, useNavigate } from "react-router-dom";
import Dropdown from 'react-bootstrap/Dropdown'; // Updated import statement
import { Button } from "react-bootstrap";
import userAvatar from "../assets/img/img1.jpg";
// import notification from "../data/Notification";
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormHelperText from '@mui/material/FormHelperText';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Box from '@mui/material/Box';
import { companyLogin, editCompanyprofile, editEmployee, getId, getcompanyId, getemployeeId, singleViewEmployeeAttendance, viewBranch, viewEmployeeBreakCount, viewShift } from "../services/api/api";
import Form from 'react-bootstrap/Form';
import '../styles/globals.css';
import PersonIcon from '@mui/icons-material/Person';
import FreeBreakfastIcon from '@mui/icons-material/FreeBreakfast';
import EventAvailableIcon from '@mui/icons-material/EventAvailable';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import img05 from "../assets/img/img05.jpg";
import { CountryDropdown } from 'react-country-region-selector';
import Employees from '../pages/employee/employees';

export default function Header({ onSkin, branches, handleLocationChange }) {

  useEffect(() => {
    // Retrieve the type from session storage
    const userType = sessionStorage.getItem("type");
  }, []);
  useEffect(() => {
    // Load selectedBranchId from localStorage
    const storedBranchId = localStorage.getItem('selectedBranchId');
    if (storedBranchId) {
      setSelectedBranch(storedBranchId);
    }
  }, []);

  useEffect(() => {
    // Load selectedShiftId from localStorage
    const storedShiftId = localStorage.getItem('selectedShiftId');
    if (storedShiftId) {
      setSelectedShift(storedShiftId);
    }
  }, []);

  const adminName = sessionStorage.getItem("admin_name");
  const firstName = sessionStorage.getItem("first_name");
  const lastName = sessionStorage.getItem("last_name");





  const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
    <Link
      to=""
      ref={ref}
      onClick={(e) => {
        e.preventDefault();
        onClick(e);
      }}
      className="dropdown-link"
    >

      {children}
    </Link>
  ));

  const [shiftData, setShiftData] = useState([]);
  const [selectedShift, setSelectedShift] = useState('');




  const [masterId, setMasterId] = useState('');

  const [branchData, setBranchData] = useState([]);
  const [selectedBranch, setSelectedBranch] = useState('');
  const [loading, setLoading] = useState(true);
  const [attendanceStatusd, setAttendanceStatusd] = useState('OUT');
  const [breakStatusd, setBreakStatusd] = useState('OUT');
  const [employee_id, setEmployeeId] = useState(getemployeeId());

  // const formatShiftTime = (start, end) => {
  //   return `(${start} To ${end})`;
  // };
  const [searchParams, setSearchParams] = useState({
    year: new Date().getFullYear(),
    month: new Date().getMonth() + 1,
  });
  const [checkInTime, setCheckInTime] = useState('');
  const [checkOutTime, setCheckOutTime] = useState('');
  const [totalWorkHours, setTotalWorkHours] = useState(null);
  const calculateWorkHours = (startTime, endTime) => {
    const start = new Date(`1970-01-01T${startTime}`);
    const end = new Date(`1970-01-01T${endTime}`);
    const timeDiff = end - start;
    const hours = Math.floor(timeDiff / (1000 * 60 * 60));
    const minutes = Math.floor((timeDiff % (1000 * 60 * 60)) / (1000 * 60));

    return `${hours}h ${minutes}m`;
  };
  const [svgIcon, setSvgIcon] = useState(null);


  const fetchEmployeeAttendance = async () => {
    try {
      setLoading(true);
      const employeeId = getId();
      const apiResponse = await singleViewEmployeeAttendance(
        employeeId,
        searchParams.year,
        searchParams.month
      );

      const currentDate = new Date().toISOString().split('T')[0];
      const latestAttendance = apiResponse.data[0]?.attendance_list[0]?.attendance.find(
        (entry) => entry.date === currentDate
      );

      const isIn = latestAttendance?.IN !== null;
      const isOut = latestAttendance?.OUT !== null;

      if (isOut) {
        // setAttendanceStatusd('OUT');
        setAttendanceStatusd('OUT')
        setCheckOutTime(latestAttendance.OUT);
        setTotalWorkHours(calculateWorkHours(latestAttendance.IN, latestAttendance.OUT));
        setSvgIcon(
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="350"
            height="150"
            fill="currentColor"
            className="bi bi-door-closed-fill mt-2"
            viewBox="0 0 16 16"
          >
            <path
              d="M12 1a1 1 0 0 1 1 1v13h1.5a.5.5 0 0 1 0 1h-13a.5.5 0 0 1 0-1H3V2a1 1 0 0 1 1-1zm-2 9a1 1 0 1 0 0-2 1 1 0 0 0 0 2"
            />
          </svg>
        );


      } else {
        setAttendanceStatusd('IN');
        setCheckInTime(latestAttendance.IN);
        setTotalWorkHours(null);
        setSvgIcon(
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="350"
            height="150"
            fill="currentColor"
            className="bi bi-building-fill-check mt-2"
            viewBox="0 0 16 16"
          >
            <path
              d="M12.5 16a3.5 3.5 0 1 0 0-7 3.5 3.5 0 0 0 0 7m1.679-4.493-1.335 2.226a.75.75 0 0 1-1.174.144l-.774-.773a.5.5 0 0 1 .708-.708l.547.548 1.17-1.951a.5.5 0 1 1 .858.514"
            />
            <path
              d="M2 1a1 1 0 0 1 1-1h10a1 1 0 0 1 1 1v7.256A4.5 4.5 0 0 0 12.5 8a4.5 4.5 0 0 0-3.59 1.787A.5.5 0 0 0 9 9.5v-1a.5.5 0 0 0-.5-.5h-1a.5.5 0 0 0-.5.5v1a.5.5 0 0 0 .5.5h1a.5.5 0 0 0 .39-.187A4.5 4.5 0 0 0 8.027 12H6.5a.5.5 0 0 0-.5.5V16H3a1 1 0 0 1-1-1zm2 1.5v1a.5.5 0 0 0 .5.5h1a.5.5 0 0 0 .5-.5v-1a.5.5 0 0 0-.5-.5h-1a.5.5 0 0 0-.5.5m3 0v1a.5.5 0 0 0 .5.5h1a.5.5 0 0 0 .5-.5v-1a.5.5 0 0 0-.5-.5h-1a.5.5 0 0 0-.5.5m3.5-.5a.5.5 0 0 0-.5.5v1a.5.5 0 0 0 .5.5h1a.5.5 0 0 0 .5-.5v-1a.5.5 0 0 0-.5-.5zM4 5.5v1a.5.5 0 0 0 .5.5h1a.5.5 0 0 0 .5-.5v-1a.5.5 0 0 0-.5-.5h-1a.5.5 0 0 0-.5.5M7.5 5a.5.5 0 0 0-.5.5v1a.5.5 0 0 0 .5.5h1a.5.5 0 0 0 .5-.5v-1a.5.5 0 0 0-.5-.5zm2.5.5v1a.5.5 0 0 0 .5.5h1a.5.5 0 0 0 .5-.5v-1a.5.5 0 0 0-.5-.5h-1a.5.5 0 0 0-.5.5M3.5 8a.5.5 0 0 0-.5.5v1a.5.5 0 0 0 .5.5h1a.5.5 0 0 0 .5-.5v-1a.5.5 0 0 0-.5-.5z"
            />
          </svg>
        );

      }
    } catch (error) {
      console.error('Error fetching employee break count:', error);
    } finally {
      setLoading(false);
    }
  };
  // useEffect(() => {
  //   const debouncedFetchEmployeeAttendance = _debounce(fetchEmployeeAttendance, 2000);

  //   debouncedFetchEmployeeAttendance();

  //   return () => {
  //     debouncedFetchEmployeeAttendance.cancel();
  //   };
  // }, [searchParams]);


  const fetchEmployeeBreak = async () => {
    try {
      setLoading(true);
      const apiResponse = await viewEmployeeBreakCount(employee_id);
      const currentDate = new Date().toISOString().split('T')[0];
      const latestBreak = apiResponse.data[0]?.break_list[0]?.attendance[currentDate];
      if (latestBreak) {
        const latestBreaks = latestBreak.breaks;
        const lastBreak = latestBreaks[latestBreaks.length - 1];
        setBreakStatusd(lastBreak.type === 'OUT' ? 'OUT' : 'IN');
      } else {
        setBreakStatusd('OUT');
      }
    } catch (error) {
      console.error('Error fetching employee break count:', error);
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    const debouncedFetchBreak = _debounce(fetchEmployeeBreak, 200);
    debouncedFetchBreak();
    return () => {
      debouncedFetchBreak.cancel();
    };
  }, [employee_id]);

  const formatShiftTime = (start, end) => {
    const formatTime = (time) => {
      const date = new Date(`1970-01-01T${time}`);
      return date.toLocaleTimeString('en-US', { hour: 'numeric', minute: 'numeric', hour12: true });
    };

    return `(${formatTime(start)} To ${formatTime(end)})`;
  };


  // const [fetchingBranchData, setFetchingBranchData] = useState(false);

  // useEffect(() => {
  //   fetchData();
  // }, []);

  // const fetchData = async () => {
  //   if (fetchingBranchData) {
  //     return;
  //   }

  //   try {
  //     setFetchingBranchData(true);
  //     const formattedData = await viewBranch();
  //     console.log("formattedData", formattedData);

  //     if (!formattedData || !formattedData.length) {
  //       throw new Error('Failed to fetch data.');
  //     }

  //     const companyBranch = formattedData[0].company_branch;
  //     const branchsArray = Object.values(companyBranch).map(item => ({
  //       branch_name: item.branch_name || 'N/A',
  //     }));

  //     setBranchData(branchsArray);

  //   } catch (error) {
  //   } finally {
  //     setFetchingBranchData(false);
  //     setLoading(false);
  //   }
  // };



  // useEffect(() => {
  //   const debouncedFetchData = _.debounce(fetchData, 100);
  //   const debouncedFetchShift = _.debounce(fetchShift, 100);
  //   debouncedFetchData();
  //   debouncedFetchShift();
  //   return () => {
  //     debouncedFetchData.cancel();
  //     debouncedFetchShift.cancel();
  //   };
  // }, []);




  useEffect(() => {
    if (sessionStorage.getItem("userType") !== "employee") {
      // branch api is temporary commented by hardik 
      // const debouncedFetchData = _.debounce(fetchData, 2000);
      // debouncedFetchData();

      const debouncedFetchShift = _.debounce(fetchShift, 200);
      debouncedFetchShift();

      return () => {
        // debouncedFetchData.cancel();
        debouncedFetchShift.cancel();
      };
    }
  }, []);



  const fetchData = async () => {
    try {
      const formattedData = await viewBranch();
      console.log("formattedData", formattedData);

      if (!formattedData || !formattedData.length) {
        throw new Error('Failed to fetch data.');
      }

      const companyBranch = formattedData[0].company_branch;
      const branchsArray = Object.values(companyBranch).map(item => ({
        _id: item._id,
        branch_name: item.branch_name || 'N/A',
      }));

      setBranchData(branchsArray);
      // console.log(branchData);

    } catch (error) {
    } finally {
      setLoading(false);
    }
  };
  // console.log(branchData);
  const fetchShift = async () => {
    try {
      const formattedData = await viewShift();

      if (!formattedData || !formattedData.length) {
        throw new Error('Failed to fetch data.');
      }

      const companyShift = formattedData[0].company_time;
      setMasterId(formattedData[0]._id);
      const shiftArray = Object.values(companyShift).map(item => ({
        _id: item._id,
        shift_no: item.shift_no || 'N/A',
        company_start_time: item.company_start_time || 'N/A',
        company_end_time: item.company_end_time || 'N/A',
      }));

      setShiftData(shiftArray); // Update the state with shift data
    } catch (error) {
      console.error('Error fetching data:', error);
    } finally {
      setLoading(false);
    }
  };
  const handleChangeBranch = (event) => {
    const selectedBranchId = event.target.value;
    setSelectedBranch(selectedBranchId);
    // Save the selectedBranchId to localStorage
    localStorage.setItem('selectedBranchId', selectedBranchId);
  };

  const handleChangeShift = (event) => {
    const selectedShiftId = event.target.value;
    setSelectedShift(selectedShiftId);
    // Save the selectedShiftId to localStorage
    localStorage.setItem('selectedShiftId', selectedShiftId);
  };


  // useEffect(() => {

  // }, [])









  const [id, setId] = useState('');
  useEffect(() => {
    const id = getemployeeId();
    setId(id);
  }, []);
  const [state, setState] = useState({
    _id: '',
    company_id: '',
    first_name: '',
    last_name: '',
    email: '',
    joining_date: '',
    phone: '',
    department: '',
    designation: '',
    password: '',
    salary: '',
    shift: '',
    branch: '',
  });

  // useEffect(() => {
  //   async function fetchemployeeData() {
  //     try {

  //       const employeeData = await editEmployee(id);
  //       console.log(employeeData);


  //       if (employeeData && employeeData.success && employeeData.success.length > 0) {
  //         const employee = employeeData.success[0];

  //         setState({
  //           _id: employee._id || '',
  //           company_id: employee.company_id || '',
  //           first_name: employee.first_name || '',
  //           last_name: employee.last_name || '',
  //           email: employee.email || '',
  //           joining_date: employee.joining_date || '',
  //           phone: employee.phone || '',
  //           department: employee.department || '',
  //           designation: employee.designation || '',
  //           branch: employee.branch || '',
  //           // password: employee.password || '',
  //           salary: employee.salary || '',
  //           shift: employee.shift || '',
  //           file: employee.file || [],
  //         });

  //         // const userBranches = employee.branch ? employee.branch.split(',') : [];
  //       } else {
  //         console.error('No employee data found');
  //       }
  //     } catch (error) {
  //       console.error('Error fetching employee data:', error);
  //     }
  //   }

  //   fetchemployeeData();
  // }, [id]);


  //   useEffect(() => {
  //     if (sessionStorage.getItem("userType") !== "admin") {
  //     const debouncedFetchEmployeeData = _debounce(fetchemployeeData, 2000);

  //     debouncedFetchEmployeeData();

  //     return () => {
  //         debouncedFetchEmployeeData.cancel();
  //     };
  //   }
  // }, [id]);

  // async function fetchemployeeData() {
  //     try {
  //         const employeeData = await editEmployee(id);
  //         console.log(employeeData);

  //         if (employeeData && employeeData.success && employeeData.success.length > 0) {
  //             const employee = employeeData.success[0];

  //             setState({
  //                 _id: employee._id || '',
  //                 company_id: employee.company_id || '',
  //                 first_name: employee.first_name || '',
  //                 last_name: employee.last_name || '',
  //             });
  //         } else {
  //             console.error('No employee data found');
  //         }
  //     } catch (error) {
  //         console.error('Error fetching employee data:', error);
  //     }
  // }





  const [companyId, setcompanyId] = useState('');
  useEffect(() => {
    const companyId = getcompanyId();
    setcompanyId(companyId);
  }, []);




  const [stateadmin, setStateadmin] = useState({
    _id: '',
    company_id: '',
    company_name: '',
    admin_name: '',
    company_address: '',
    country: '',
    fax: '',
    city: '',
    state: '',
    pincode: '',
    company_email: '',
    admin_contact: '',

  });

  const [errors, setErrors] = useState({});
  // useEffect(() => {
  //   async function fetchCompanyProfileData() {
  //     try {
  //       const companyProfileData = await editCompanyprofile({ companyId });
  //       console.log("companyProfile", companyProfileData);
  //       if (companyProfileData && companyProfileData.success[0]) {
  //         const companyProfile = companyProfileData.success[0];
  //         setStateadmin({
  //           admin_name: companyProfile.admin_name || '',


  //         });
  //       } else {

  //       }
  //     } catch (error) {
  //       console.error('Error fetching company profile data:', error);
  //     }
  //   }

  //   fetchCompanyProfileData();
  // }, [companyId]);

  // useEffect(() => {
  //   const debouncedFetchCompanyProfileData = _debounce(fetchCompanyProfileData, 100);
  //   debouncedFetchCompanyProfileData();
  //   return () => {
  //     debouncedFetchCompanyProfileData.cancel();
  //   };
  // }, [companyId]);

  // async function fetchCompanyProfileData() {
  //   try {
  //     const companyProfileData = await editCompanyprofile({ companyId });
  //     console.log("companyProfile", companyProfileData);
  //     if (companyProfileData && companyProfileData.success[0]) {
  //       const companyProfile = companyProfileData.success[0];
  //       setStateadmin({
  //         admin_name: companyProfile.admin_name || '',
  //       });
  //     } else {
  //     }
  //   } catch (error) {
  //     console.error('Error fetching company profile data:', error);
  //   }
  // }

  // useEffect(() => {
  //   if (sessionStorage.getItem("userType") !== "employee") {
  //     const debouncedFetchCompanyProfileData = _.debounce(fetchCompanyProfileData, 100);
  //     debouncedFetchCompanyProfileData();

  //     return () => {
  //       debouncedFetchCompanyProfileData.cancel();
  //     };
  //   }
  // }, [companyId]);

  // async function fetchCompanyProfileData() {
  //   try {
  //     const companyProfileData = await editCompanyprofile({ companyId });
  //     console.log("companyProfile", companyProfileData);
  //     if (companyProfileData && companyProfileData.success[0]) {
  //       const companyProfile = companyProfileData.success[0];
  //       setStateadmin({
  //         admin_name: companyProfile.admin_name || '',
  //       });
  //     }
  //   } catch (error) {
  //     console.error('Error fetching company profile data:', error);
  //   }
  // }

  // useEffect(() => {
  //   if (sessionStorage.getItem("userType") !== "employee") {
  //     const debouncedFetchCompanyProfileData = _.debounce(fetchCompanyProfileData, 100);
  //     debouncedFetchCompanyProfileData();

  //     return () => {
  //       debouncedFetchCompanyProfileData.cancel();
  //     };
  //   }
  // }, [companyId]);

  async function fetchCompanyProfileData() {
    try {
      setLoading(true);
      const companyProfileData = await editCompanyprofile({ companyId });
      console.log("companyProfile", companyProfileData);
      if (companyProfileData && companyProfileData.success[0]) {
        const companyProfile = companyProfileData.success[0];
        setStateadmin({
          admin_name: companyProfile.admin_name || '',
        });
      }
    } catch (error) {
      console.error('Error fetching company profile data:', error);
    } finally {
      setLoading(false);
    }
  }

  const toggleSidebar = (e) => {
    e.preventDefault();
    let isOffset = document.body.classList.contains("sidebar-offset");
    if (isOffset) {
      document.body.classList.toggle("sidebar-show");
    } else {
      if (window.matchMedia("(max-width: 991px)").matches) {
        document.body.classList.toggle("sidebar-show");
      } else {
        document.body.classList.toggle("sidebar-hide");
      }
    }
  }

  // function NotificationList() {
  //   const notiList = notification.map((item, key) => {
  //     return (
  //       <li className="list-group-item" key={key}>
  //         <div className={(item.status === "online") ? "avatar online" : "avatar"}>{item.avatar}</div>
  //         <div className="list-group-body">
  //           <p>{item.text}</p>
  //           <span>{item.date}</span>
  //         </div>
  //       </li>
  //     )
  //   });

  //   return (
  //     <ul className="list-group">
  //       {notiList}
  //     </ul>
  //   );
  // }

  const skinMode = (e) => {
    e.preventDefault();
    e.target.classList.add("active");

    let node = e.target.parentNode.firstChild;
    while (node) {
      if (node !== e.target && node.nodeType === Node.ELEMENT_NODE)
        node.classList.remove("active");
      node = node.nextElementSibling || node.nextSibling;
    }

    let skin = e.target.textContent.toLowerCase();
    let HTMLTag = document.querySelector("html");

    if (skin === "dark") {
      HTMLTag.setAttribute("data-skin", skin);
      localStorage.setItem('skin-mode', skin);

      onSkin(skin);

    } else {
      HTMLTag.removeAttribute("data-skin");
      localStorage.removeItem('skin-mode');

      onSkin('');
    }

  };

  const sidebarSkin = (e) => {
    e.preventDefault();
    e.target.classList.add("active");

    let node = e.target.parentNode.firstChild;
    while (node) {
      if (node !== e.target && node.nodeType === Node.ELEMENT_NODE)
        node.classList.remove("active");
      node = node.nextElementSibling || node.nextSibling;
    }

    let skin = e.target.textContent.toLowerCase();
    let HTMLTag = document.querySelector("html");

    HTMLTag.removeAttribute("data-sidebar");

    if (skin !== "default") {
      HTMLTag.setAttribute("data-sidebar", skin);
      localStorage.setItem("sidebar-skin", skin);
    } else {
      localStorage.removeItem("sidebar-skin", skin);
    }
  };

  const navigate = useNavigate();

  const handleLogout = () => {
    sessionStorage.removeItem("isLoggedIn");
    // sessionStorage.removeItem("userType");
    sessionStorage.setItem("userType", "");
    sessionStorage.removeItem("mainId");
    sessionStorage.removeItem("salaryStructureId");
    navigate("/signin");
  };

  const customDropdownStyle = {
    height: '32px',
    background: 'rgb(255, 224, 114)',
    borderRadius: '16px',
    display: 'flex',
    WebkitBoxAlign: 'center',
    alignItems: 'center',
    padding: '6px 24px',
    color: 'black',
    fontSize: '14px',
    fontFamily: 'Arial, sans-serif',
  };
  const customDropdownStyleT = {
    height: '32px',
    background: 'rgb(255, 224, 114)',
    borderRadius: '16px',
    display: 'flex',
    WebkitBoxAlign: 'center',
    alignItems: 'center',
    padding: '6px 24px',
    color: '#1f3272',
    fontSize: '14px',
    fontFamily: 'Arial, sans-serif',
  };

  const iconColor = attendanceStatusd === 'IN' ? 'green' : attendanceStatusd === 'OUT' ? 'red' : 'blue';
  const iconColorbreak = breakStatusd === 'IN' ? 'green' : breakStatusd === 'OUT' ? 'red' : 'blue';

  return (
    <div className="header-main px-3 px-lg-4">
      <Link onClick={toggleSidebar} className="menu-link me-3 me-lg-4"><i className="ri-menu-2-fill"></i></Link>

      <div className=" me-auto">
        {/* <input type="text" className="form-control" placeholder="Search" />
        <i className="ri-search-line"></i> */}

        <h3 class="mb-0 pb-  custome-btn1">Windson Payroll</h3>
      </div>



      {sessionStorage.getItem("userType") === "admin" && (
        <>
          <div>
            {/* <FormControl sx={{ m: 1, minWidth: 120 }} size="small">
        <InputLabel id="demo-select-small-label">Location</InputLabel>
        <Select
          // labelId="location-label"
          style={{ borderRadius: 50 }}
          labelId="demo-select-small-label"
          id="location-select"
          onChange={handleLocationChange}
          label="Location"
        >
          {branches.map(branch => (
            <MenuItem key={branch._id} value={branch.branch_name}>
              {branch.branch_name}
            </MenuItem>
          ))}
        </Select>
      </FormControl> */}
          </div>

          {/* <Select
                // labelId="location-label"
                style={{ borderRadius: 50 }}
                labelId="demo-select-shift-label"
                id="location-select"

                onChange={handleLocationChange}
                label="Location"
              >
                <CountryDropdown
                  style={{ borderRadius: 50, textAlign: 'center', width: '100%', backgroundColor: 'white' }}
                  className={`form-control CountryDD ${errors.country && 'is-invalid'}`}
                  value={state.country}
                  onChange={(val) => handleCountryChange(val)}
                  defaultOptionLabel=""
                /> */}
          {/* {branches.map(branch => (
                        <MenuItem key={branch._id} value={branch.branch_name}>
                          {branch.branch_name}
                        </MenuItem>
                      ))} */}
          {/* Hardcoded equivalent of dynamic mapping */}
          {/* {[
                  { _id: 'branch1_id', branch_name: 'Nashik' },
                  { _id: 'branch2_id', branch_name: 'Pune' },
                  { _id: 'branch3_id', branch_name: 'Andheri' },
                  { _id: 'branch4_id', branch_name: 'Mumbai' },
                  { _id: 'branch4_id', branch_name: 'Kolhapur' }
                ].map(branch => (
                  <MenuItem key={branch._id} value={branch.branch_name}>
                    {branch.branch_name}
                  </MenuItem>
                ))} */}
          {/* </Select> */}

          {/* <FormControl sx={{ m: 1, minWidth: 120 }} size="small">
            <InputLabel id="demo-select-small-label">Location</InputLabel>
            <Select
              style={{ borderRadius: 50 }}
              labelId="demo-select-small-label"
              id="demo-select-small"
              value={selectedBranch}
              label="Location"
              onChange={handleChangeBranch}
            >
              {branchData.map((branch) => (
                <MenuItem style={{ borderRadius: 50 }} key={branch._id} value={branch._id}>
                  {branch.branch_name}
                </MenuItem>
              ))}
            </Select>
          </FormControl> */}

          {/* Branch selection  */}
          {/* <FormControl sx={{ m: 1, minWidth: 120 }} size="small">
            <InputLabel id="demo-select-shift-label">Select Branch</InputLabel>
            <Select
              style={{ borderRadius: 50 }}
              labelId="demo-select-shift-label"
              id="demo-select-shift"
              // value={}
              label="Select Branch"
              onChange={handleChangeBranch}
            >
              {branchData.map((value) => (
                <MenuItem key={value._id} value={value._id} >
                  {`${value.branch_name}`}
                </MenuItem>
              ))}
            </Select>
          </FormControl> */}

          {/* Shift Selection  */}
          <FormControl sx={{ m: 1, minWidth: 120 }} size="small">
            <InputLabel id="demo-select-shift-label">Select Shift</InputLabel>
            <Select
              style={{ borderRadius: 50 }}
              labelId="demo-select-shift-label"
              id="demo-select-shift"
              value={selectedShift}
              label="Select Shift"
              onChange={handleChangeShift}
            >
              {shiftData.map((shift) => (
                <MenuItem key={shift._id} value={shift._id}>
                  {`${shift.shift_no} ${formatShiftTime(shift.company_start_time, shift.company_end_time)}`}
                </MenuItem>
              ))}
            </Select>
          </FormControl>


        </>
      )}

      {sessionStorage.getItem("userType") === "employee" && ( // Conditionally render based on admin login status
        <>


          <div>
            {loading ? (
              <div className="loader-containerx1">
                <div className="loaderx1"></div>
              </div>
            ) : (
              <OverlayTrigger
                placement="bottom"
                overlay={<Tooltip id="tooltip-attendance">Attendance</Tooltip>}
              >
                <Link to="/employee/attendance">
                  <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" fill={iconColor} className="bi bi-box-arrow-in-right me-2" viewBox="0 0 16 16">
                    <path fillRule="evenodd" d="M6 3.5a.5.5 0 0 1 .5-.5h8a.5.5 0 0 1 .5.5v9a.5.5 0 0 1-.5.5h-8a.5.5 0 0 1-.5-.5v-2a.5.5 0 0 0-1 0v2A1.5 1.5 0 0 0 6.5 14h8a1.5 1.5 0 0 0 1.5-1.5v-9A1.5 1.5 0 0 0 14.5 2h-8A1.5 1.5 0 0 0 5 3.5v2a.5.5 0 0 0 1 0z" />
                    <path fillRule="evenodd" d="M11.854 8.354a.5.5 0 0 0 0-.708l-3-3a.5.5 0 1 0-.708.708L10.293 7.5H1.5a.5.5 0 0 0 0 1h8.793l-2.147 2.146a.5.5 0 0 0 .708.708z" />
                  </svg>
                </Link>
              </OverlayTrigger>
            )}
          </div>

          {/* <OverlayTrigger
            placement="bottom"
            overlay={<Tooltip id="tooltip-attendance">Break</Tooltip>}
          >
            <Link to={"/employee/break"} style={{ marginRight: "10px" }}>

              <FreeBreakfastIcon style={{ color: iconColorbreak }} />
            </Link>
          </OverlayTrigger> */}



          <div>
            {loading ? (
              <div className="loader-containerx1">
                <div className="loaderx1"></div>
              </div>
            ) : (
              <OverlayTrigger
                placement="bottom"
                overlay={<Tooltip id="tooltip-attendance">Break</Tooltip>}
              >
                <Link to={"/employee/break"} style={{ marginRight: "10px" }}>
                  {/* <FreeBreakfastIcon style={{ color: 'blue' }} /> */}
                  <FreeBreakfastIcon style={{ color: iconColorbreak }} />
                </Link>
              </OverlayTrigger>
            )}
          </div>
        </>


      )}


      <Dropdown className="dropdown-skin" align="end">
        <Dropdown.Toggle as={CustomToggle}>
          <i className="ri-settings-3-line"></i>
        </Dropdown.Toggle>
        <Dropdown.Menu className="mt-10-f">
          <label>Skin Mode</label>
          <nav className="nav nav-skin">
            <Link onClick={skinMode} className={localStorage.getItem("skin-mode") ? "nav-link" : "nav-link active"}>Light</Link>
            <Link onClick={skinMode} className={localStorage.getItem("skin-mode") ? "nav-link active" : "nav-link"}>Dark</Link>
          </nav>
          <hr />
          <label>Sidebar Skin</label>
          <nav id="sidebarSkin" className="nav nav-skin">
            <Link onClick={sidebarSkin} className={!(localStorage.getItem("sidebar-skin")) ? "nav-link active" : "nav-link"}>Default</Link>
            <Link onClick={sidebarSkin} className={(localStorage.getItem("sidebar-skin") === "prime") ? "nav-link active" : "nav-link"}>Prime</Link>
            <Link onClick={sidebarSkin} className={(localStorage.getItem("sidebar-skin") === "dark") ? "nav-link active" : "nav-link"}>Dark</Link>
          </nav>
        </Dropdown.Menu>
      </Dropdown>

      {/* <Dropdown className="dropdown-notification ms-3 ms-xl-4" align="end">
        <Dropdown.Toggle as={CustomToggle}>
          <small>3</small><i className="ri-notification-3-line"></i>
        </Dropdown.Toggle>
        <Dropdown.Menu className="mt-10-f me--10-f">
          <div className="dropdown-menu-header">
            <h6 className="dropdown-menu-title">Notifications</h6>
          </div>
          {NotificationList()}
          <div className="dropdown-menu-footer"><Link to="#">Show all Notifications</Link></div>
        </Dropdown.Menu>
      </Dropdown> */}

      {sessionStorage.getItem("userType") === "admin" && ( // Conditionally render based on admin login status
        <>

          <Dropdown className="dropdown-profile ms-3 ms-xl-4" align="end">
            <Dropdown.Toggle as={CustomToggle}>
              <div className="avatar online" >
                {/* <PersonIcon style={{ fontSize: "2rem" }} /> */}
                <img
                  src={img05}
                  alt="User Profile"
                  className="img-fluid rounded-circle mb-1"
                  style={{ width: "50px", height: "35px" }}
                />
              </div>
            </Dropdown.Toggle>
            <Dropdown.Menu className="mt-10-f">
              <div className="dropdown-menu-body">
                <div className="avatar avatar-xl online mb-3" >
                  <img
                    src={img05}
                    alt="User Profile"
                    className="img-fluid rounded-circle mb-1"
                    style={{ width: "80px", height: "65px" }}
                  />
                </div>
                {/* <h5 className="mb-1 text-dark fw-semibold">{stateadmin.admin_name}</h5> */}
                {loading ? (
                  <p>Loading...</p>
                ) : (
                  <h5 className="mb-1 text-dark fw-semibold">{adminName}</h5>
                )}
                {/* <p className="fs-sm text-secondary">Premium Member</p> */}

                <nav className="nav">
                  <Link to="/companyprofile"><i className="ri-edit-2-line"></i> Edit Profile</Link>
                  {/* <Link to=""><i className="ri-profile-line"></i> View Profile</Link> */}
                  {/* <Link to="/pages/profile"><i className="ri-account-circle-line"></i> User Profile</Link> */}
                </nav>
                <hr />
                <nav className="nav">
                  {/* <Link to=""><i className="ri-question-line"></i> Help Center</Link> */}
                  {/* <Link to=""><i className="ri-lock-line"></i> Privacy Settings</Link> */}
                  {/* <Link to=""><i className="ri-user-settings-line"></i> Account Settings</Link> */}
                  <Link to="/signin" onClick={handleLogout}><i className="ri-logout-box-r-line"></i> Log Out</Link>
                </nav>
              </div>
            </Dropdown.Menu>
          </Dropdown>

        </>
      )}


      {sessionStorage.getItem("userType") === "employee" && ( // Conditionally render based on admin login status
        <>

          <Dropdown className="dropdown-profile ms-3 ms-xl-4" align="end">
            <Dropdown.Toggle as={CustomToggle}>
              <div className="avatar online">

                <img
                  src={img05}
                  alt="User Profile"
                  className="img-fluid rounded-circle mb-1"
                  style={{ width: "100%", height: "35px" }}
                />
              </div>
            </Dropdown.Toggle>
            <Dropdown.Menu className="mt-10-f">
              <div className="dropdown-menu-body">
                <div className="avatar avatar-xl online mb-3 " >
                  <img
                    src={img05}
                    alt="User Profile"
                    className="img-fluid rounded-circle mb-1"
                    style={{ width: "80px", height: "65px" }}
                  />
                </div>
                <h5 className="mb-1 text-dark fw-semibold">{firstName} {lastName}</h5>
                {/* {loading ? (
                  <p>Loading...</p>
                ) : (
                  <h5 className="mb-1 text-dark fw-semibold">{state.first_name} {state.last_name}</h5>
                )} */}


                <nav className="nav">
                  {/* <Link to=""><i className="ri-edit-2-line"></i> Edit Profile</Link> */}
                  <Link to="/employee/profile"><i className="ri-profile-line"></i> View Profile</Link>
                  {/* <Link to="/pages/profile"><i className="ri-account-circle-line"></i> User Profile</Link> */}
                </nav>
                <hr />

                <nav className="nav">
                  <Link to="/employee/changepassword"><i className="ri-profile-line"></i> Change password</Link>
                </nav>
                <hr />
                <nav className="nav">
                  <Link to="/signin" onClick={handleLogout}><i className="ri-logout-box-r-line"></i> Log Out</Link>
                </nav>
              </div>
            </Dropdown.Menu>
          </Dropdown>

        </>
      )}

      {/* <EditEmployee shiftData={shiftData}  /> */}

      {/* <Employees selectedShift={selectedShift} /> */}
    </div>
  )
}